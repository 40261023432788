
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'sparePartCode',
    label: '备件编码',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    slotName: 'sparePartCode'
  },
  {
    prop: 'sparePartName',
    label: '备件名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartSpecification',
    label: '规格型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartClass',
    label: '备件类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartDrawing',
    label: '备件图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sparePartDrawing'
  },
  {
    prop: 'availableQuantity',
    label: '可用库存',
    sortable: false,
    hiddenSearch: true,
    slotName: 'availableQuantity'
  },
  {
    prop: 'stayInQuantity',
    label: '待入库数',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'stayOutQuantity',
    label: '待出库数',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'upperLimit',
    label: '库存上限',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'lowerLimit',
    label: '库存下限',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '当前存货',
    sortable: false,
    hiddenSearch: true
  }
]
