var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrap-wrapss" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("SearchForm", {
          ref: "searchForm",
          attrs: { "form-list-extend": _vm.searchFormList },
          on: { search: _vm.searchTable }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("FunctionHeader", {
          attrs: {
            "search-title": "请输入设备名称/编号",
            tags: _vm.functionHeader.tags,
            "hidden-search": true
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.columns,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          scopedSlots: _vm._u(
            [
              {
                key: "maintainTaskNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "maintenanceBillsDetail",
                        query: { id: row.id, type: "view" }
                      },
                      text: row.maintainTaskNumber
                    }
                  })
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { style: { color: _vm.colors[row.status] } },
                    [
                      _vm._v(
                        _vm._s(_vm._f("maintenanceStatusName")(row.status))
                      )
                    ]
                  )
                }
              },
              {
                key: "maintainSpendTime",
                fn: function(ref) {
                  var row = ref.row
                  return row.maintainSpendTime
                    ? _c("div", {}, [
                        _vm._v(_vm._s(Math.ceil(row.maintainSpendTime / 60)))
                      ])
                    : _vm._e()
                }
              },
              {
                key: "maintenanceItem",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewMaintenanceItem(row)
                        }
                      }
                    },
                    [_vm._v("查看")]
                  )
                }
              },
              {
                key: "spareList",
                fn: function(ref) {
                  var row = ref.row
                  return row.sparePartList
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.viewSpareList(row)
                            }
                          }
                        },
                        [_vm._v("查看")]
                      )
                    : _vm._e()
                }
              }
            ],
            null,
            true
          )
        }),
        _c(
          "MDialog",
          {
            attrs: { width: "400px", title: "跳过原因" },
            on: { onOk: _vm.submitPassMaintenanceBills },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          },
          [
            _c("MFormBuilder", {
              ref: "formBuild",
              attrs: { "form-list": _vm.reasonFormList }
            })
          ],
          1
        ),
        _c("TableDialog", {
          attrs: {
            title: "查看保养项",
            appends: true,
            show: _vm.checkItemVisible,
            columns: _vm.maintenanceItemView,
            "table-data": _vm.checkItemList
          },
          on: {
            "update:show": function($event) {
              _vm.checkItemVisible = $event
            }
          }
        }),
        _c(
          "TableDialog",
          {
            attrs: {
              title: "查看消耗备件",
              appends: true,
              show: _vm.spareVisible,
              columns: _vm.spareColumns,
              "table-data": _vm.spareList,
              "calc-height": 32
            },
            on: {
              "update:show": function($event) {
                _vm.spareVisible = $event
              }
            }
          },
          [
            _c(
              "el-row",
              {
                staticStyle: { "padding-bottom": "15px" },
                attrs: { gutter: 24 }
              },
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _vm._v("设备编号：" + _vm._s(_vm.currentRow.equipmentCode))
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _vm._v("设备名称：" + _vm._s(_vm.currentRow.equipmentName))
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _vm._v("保养人：" + _vm._s(_vm.currentRow.maintainerName))
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }