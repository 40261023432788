export const columns = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 50
  },
  {
    prop: 'templateName',
    label: '模板名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'templateName',
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'equipmentClassNames',
    label: '适用设备分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 2,
      colSpan: 8,
      tagName: 'el-cascader',
      key: 'equipmentClassIdList',
      required: false,
      props: {
        props: {
          label: 'name',
          value: 'id',
          multiple: true,
          checkStrictly: true
        },
        options: [],
        filterable: true,
        clearable: true
      }
    }
  },
  {
    prop: 'customerNames',
    label: '适用客户',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 3,
      tagName: 'el-select',
      required: false,
      key: 'customerIds',
      options: [],
      colSpan: 8,
      props: { multiple: true }
    }
  },
  // {
  //   prop: 'remark',
  //   label: '备注',
  //   sortable: false,
  //   hiddenSearch: true,
  //   form: {
  //     index: 4,
  //     tagName: 'el-input',
  //     required: false,
  //     props: { type: 'textarea' },
  //     colSpan: 8
  //   }
  // },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]

export const columnsAdd = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 50
  },
  {
    prop: 'paramName',
    label: '参数名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'paramName',
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'paramDetail',
    label: '参数详情',
    sortable: false,
    hiddenSearch: true,
    slotName: 'paramDetail',
    form: { index: 2, tagName: 'el-input', colSpan: 8 }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 140
  }
]
