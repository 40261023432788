import columns from './columns'
import { transformColumnsToForm } from '@/utils'
import { experienceType } from '@/config/options.config'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const formList = {
  Experience: transformColumnsToForm(columns.Experience),
  FaultReason: transformColumnsToForm(columns.FaultReason),
  UrgencyLevel: transformColumnsToForm(columns.UrgencyLevel),
  EquipmentPosition: transformColumnsToForm(columns.EquipmentPosition),
  FlowSetting: [
    {
      label: '故障报修是否需审核：',
      key: 'fault_repair_need_review',
      colSpan: 24,
      labelTip: '必须审核则提交了故障报修后进入待审核状态\n不需审核则提交了故障报修后进入待接单状态',
      tagName: 'el-radio-group',
      required: true,
      defaultValue: 0,
      on: {
        change: (val) => { functionList.submitFlowSetting('fault_repair_need_review', val) }
      },
      children: [{
        tagName: 'el-radio',
        innerValue: 'name',
        options: [
          {
            id: 1,
            name: '是'
          },
          {
            id: 0,
            name: '否'
          }
        ],
        props: {
          label: 'id'
        }
      }]
    },
    {
      label: '维修结束是否需验证：',
      key: 'maintenance_need_check',
      colSpan: 24,
      labelTip: '必须验证则维修结束提交后，维修单进入待验证状态，发起人可进行验证\n不需验证则维修结束提交后维修单进入已完成状态',
      tagName: 'el-radio-group',
      required: true,
      defaultValue: 0,
      on: {
        change: (val) => { functionList.submitFlowSetting('maintenance_need_check', val) }
      },
      children: [{
        tagName: 'el-radio',
        innerValue: 'name',
        options: [
          {
            id: 1,
            name: '是'
          },
          {
            id: 0,
            name: '否'
          }
        ],
        props: {
          label: 'id'
        }
      }]
    },
    {
      label: '设备故障审批人：',
      key: 'fault_check_users',
      tagName: 'el-select',
      required: true,
      props: {
        placeholder: '请选择设备故障审批人',
        multiple: true
      },
      on: {
        'visible-change': (val) => { console.log(val); if (!val) functionList.submitFlowSetting('fault_check_users', val) }
      },
      colSpan: 24,
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: []
        }
      ]
    }
  ],
  MaintenanceProject: transformColumnsToForm(columns.MaintenanceProject),
  MaintenanceSetting: transformColumnsToForm(columns.MaintenanceSetting)
}
export const searchFormList = {
  Experience: [
    {
      key: 'type',
      tagName: 'el-select',
      colSpan: 6,
      props: {
        placeholder: '请选择经验类别'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: experienceType
        }
      ]
    },
    {
      key: 'equipmentClassIds',
      tagName: 'el-cascader',
      colSpan: 6,
      props: {
        placeholder: '请选择设备类',
        props: {
          label: 'name',
          value: 'id',
          multiple: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
  ],
  MaintenanceSetting: [
    {
      key: 'equipmentClassIds',
      tagName: 'el-cascader',
      colSpan: 6,
      props: {
        placeholder: '请选择设备类',
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true,
          multiple: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
  ]
}
