<template>
  <div class="scrap-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <PartClass :view="true" @nodeClick="setEquipmentClassId" @refrash="refrash" />
      </template>
      <template slot="right">
        <div class="top">
          <SearchForm
            ref="searchForm"
            :form-list-extend="searchFormListAll"
            :form-data="searchFormData"
            @search="searchTable"
          />
        </div>
        <div class="bottom">
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入备件编码/名称/规格"
            :tags="functionHeader.tags"
            :export-name="functionHeader.exportName"
            :export-params="functionHeader.exportParams"
            :show-export="!selectionData.length ? true : false"
            :style="{marginLeft :selectionData.length ? 0 :'-10px'}"
            @search="$refs.mTable.setTableData(true)"
          >
            <div slot="all" v-show="selectionData.length">
              <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
              <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                已选 
                <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                / {{ total }}项
              </span>
                <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
            </div>
          </FunctionHeader>
          <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div
              slot="availableQuantity"
              slot-scope="{ row }"
              style="width: 100%; display: inline-flex; align-items: center"
            >
              <div style="display: inline-block">
                {{ row.availableQuantity }}
              </div>
              <i
                v-if="
                  row.lowerLimit && row.availableQuantity < row.lowerLimit
                "
                class="icon-lowerLimit"
              />
              <i
                v-if="
                  row.upperLimit && row.availableQuantity > row.upperLimit
                "
                class="icon-upperLimit"
              />
            </div>

            <el-button slot="sparePartCode" slot-scope="{ row }" type="text" @click="viewDetail(row)">{{ row.sparePartCode }}</el-button>
            <div slot="sparePartDrawing" slot-scope="{ row }">
              <el-image
                v-if="row.sparePartDrawing"
                :src="row.sparePartDrawing"
                class="drawing-img"
                :preview-src-list="fixImg(row.sparePartDrawing)"
              />
            </div>
          </MTable>
          <el-dialog v-if="dialogVisible" :show-close="false">
            <img :src="dialogImageUrl" alt="">
          </el-dialog>
        </div>
      </template>
    </Split>
    <edit-part ref="editPart" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import { getUUid } from '@/utils'
import PartClassApi from '@/api/equipment/part-class'
import api from '@/api/equipment/part-nums'
import { Encrypt } from '@/utils/sercet'
import dayjs from 'dayjs'
import Split from '@/components/Split/Split'
import PartClass from '@/components/PartClass/PartClass'
import { getToken } from '@/utils/auth'
import { searchFormListAll } from './form-list'
import warehouseApi from '@/api/information/warehouse/model'
import EditPart from '@/views/equipment/part-account/components/edit-part'

export default {
  name: 'PartNums',
  components: { SearchForm, FunctionHeader, Split, PartClass, EditPart },
  data() {
    return {
      api,
      searchFormListAll,
      params: {
        radio: 'PartNums'
      },
      columns,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        exportName: 'exportSparePartNums',
        exportParams: {}
      },
      split: {
        offset: 15
      },
      getTableDataParams: {
        sparePartClassIds: ''
      },
      options: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false
      },
      equipmentImageUrl: '',
      editId: undefined,
      dialogVisible: false,
      dialogImageUrl: '',
      showSetting: false,
      selectSettingId: undefined,
      selectParts: [],
      searchFormData: {
        quantity: 0
      },
      formOptions: {
        warehouseList: []
      },
      currentType: undefined,
      selectionData: [],
      batchParam: {},
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 32
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 83
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.getWareHouseList()
  },
  methods: {
    selectionChange(data) {
      this.selectionData = data
    },
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    batchExport() {
      const ids = [
        {
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.selectionData.map(item => item.id).join('^'),
          operator: 'in'
        }
      ]
      const params = {
        searchVal: Encrypt(JSON.stringify(ids))
      }
      this.$refs.functionHeader.export(params)
    },
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    viewDetail(row) {
      const data = this._.cloneDeep(row)
      data.id = data.sparePartId
      this.$refs.editPart.modifyOrCopy(data, 'view')
    },
    async getWareHouseList() {
      const res = await warehouseApi.getRepositoryModelList(this.defaultSearch)
      if (res) {
        this.formOptions.warehouseList = res.filter(item => item.type === 0)
        this.searchFormListAll[0].children[0].options = this.formOptions.warehouseList
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const params = {
        sort: order,
        sparePartInfo: this.functionHeader.searchVal,
        sparePartClassIds: this.getTableDataParams.sparePartClassIds,
        type: this.currentType,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData]))
      }
      // 导出
      this.functionHeader.exportParams = params
      // 批量导出
      const { searchVal, ...restParams } = params
      this.batchParam = {
        ...restParams,
        data: [...search, ...this.searchFormatData]
      }
      const res = await api.getSparePartNumsListPage({
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    onSuccess() {
      this.$refs.mTable.setTableData()
    },

    fixList(val) {
      if (val && val.length > 0) {
        const data = val.map(item => item.name)
        return data.join()
      } else {
        return ''
      }
    },
    selectEquipment(row) {
      this.selectParts = row.equipmentList ? row.equipmentList : []
      this.showSetting = true
      this.selectSettingId = row.id
    },
    submitSetting(data) {
      this.showSetting = false
      const datas = data.map(item => item.id)
      this.modifySparePart(datas)
    },
    refrash() {
      this.getTableDataParams.sparePartClassIds = ''
      this.$refs.mTable.setTableData(true)
    },
    fixImg(img) {
      return [img]
    },
    setEquipmentClassId(val) {
      this.getTableDataParams.sparePartClassIds = val
      this.$refs.mTable.setTableData(true)
    },
    searchTable(data, val) {
      let news
      let datas
      if (val === 1) {
        this.currentType = undefined
        news = []
        datas = []
      }
      if (val !== 1) {
        if (data.length > 0) {
          const newData = data.filter(item => item.fieldName === 'type')
          this.currentType = newData.length > 0 && newData[0].fieldValue !== 3 ? newData[0].fieldValue : undefined
          const isZero = data.filter(item => item.fieldName === 'quantity')
          const max = [{
            fieldName: 'quantity',
            fieldType: 'number',
            fieldValue: 0.0,
            operator: 'gt',
            id: getUUid()
          }]
          news = isZero.length > 0 && isZero[0].fieldValue === 1 ? max : ''
        }
        datas = data.filter(item => item.fieldName !== 'quantity' && item.fieldName !== 'type')
      }
      this.searchFormatData = [...datas, ...news]
      this.$refs.mTable.setTableData(true)
    }

  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}
.icon-lowerLimit,
.icon-upperLimit {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.icon-upperLimit {
  background: url("../../../assets/inventory/icon-arrow.png");
  background-size: contain;
}
.icon-lowerLimit {
  transform: scaleY(-1);
  background: url("../../../assets/inventory/icon-arrow.png");
  background-size: contain;
}

.scrap-wrap {
  width: 100%;

  .bottom {
    padding: 10px;
  }
  ::v-deep {
    .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 150px;
    display: block;
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  ::v-deep {
    .e-divider{
      margin: 0px 0 0 0;
    }
  }
}
</style>
