let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const searchFormList = [
  {
    colSpan: 6,
    key: 'status',
    tagName: 'el-select',
    props: {
      placeholder: '状态',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          {
            id: 0,
            name: '草稿'
          },
          {
            id: 1,
            name: '审核中'
          },
          {
            id: 2,
            name: '已审核'
          },
          {
            id: 3,
            name: '执行中'
          },
          {
            id: 4,
            name: '部分执行'
          },
          {
            id: 5,
            name: '已执行'
          }
        ]
      }
    ]
  },
  {
    colSpan: 6,
    key: 'needOperate',
    tagName: 'el-select',
    props: {
      placeholder: '是否需要执行',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          {
            id:0,
            name:"需要"
          },
          {
            id:1,
            name:"不需要"
          }
        ]
      }
    ]
  },
]
export const columns = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'prePlanChangeNumber',
      label: '变更单号',
      sortable: false,
      hiddenSearch: true,
      minWidth: 150,
      slotName:'prePlanChangeNumber'
    },
    {
      prop: 'changeType',
      label: '变更类型',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120,
      slotName:'changeType'
    },
    {
      prop: 'projectNumber',
      label: '项目编号',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'projectName',
      label: '项目名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'projectPrincipalName',
      label: '项目负责人',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'materialName',
      label: '机台号',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'status',
      label: '变更状态',
      hiddenSearch: true,
      sortable: false,
       minWidth: 100,
       slotName:'changeStatus'
    },
    {
      prop: 'needOperate',
      label: '是否需要执行',
      hiddenSearch: true,
      sortable: false,
      minWidth: 120,
      slotName:'needOperate'
    },
    {
      prop: 'businessUnitName',
      label: '业务部门',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
    {
      prop: 'operateTime',
      label: '计划执行时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'creator',
      label: '创建人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      slotName: 'action',
      label: '操作',
      sortable: false,
      hiddenSearch: true,
      fixed: 'right'
    }
  ]
