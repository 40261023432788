<template>
  <div class="searchs">
    <!-- 顶部折叠搜索 -->
    <el-row>
      <el-col :md="12" :gutter="10">
        <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap">
          <el-col :md="8">
            <el-select v-model="searchFormData.stationId" placeholder="工位" collapse-tags multiple clearable filterable @change="searchData">
              <el-option
                v-for="item in formOptions.stationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
          </el-col>
          <el-col :md="8">
            <el-select v-model="searchFormData.callTypeName" collapse-tags placeholder="呼叫类型" multiple clearable filterable @change="searchData">
              <el-option
                v-for="item in formOptions.callTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-col>
          <el-col :md="8">
            <el-select v-model="searchFormData.emergencyDegree" placeholder="紧急程度" clearable @change="searchData">
              <el-option label="紧急" :value="0" />
              <el-option label="普通" :value="1" />
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="!show" type="flex" justify="start" style="flex-flow:row wrap;padding: 10px 0" :gutter="10">
          <el-col class="time-search" :md="16">
            <el-select v-model="dateType" placeholder="工位" style="width: 110px">
              <el-option label="呼叫时间" value="callTime" />
              <el-option label="处理时间" value="handleTime" />
              <el-option label="结束时间" value="lastUpdateTime" />
            </el-select>
            <el-date-picker
              v-model="searchFormData[dateType]"
              style="width: calc(100% - 110px)"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="8">
            <el-select v-model="searchFormData.callName" collapse-tags placeholder="发起人" multiple clearable filterable @change="searchData">
              <el-option
                v-for="item in formOptions.callName"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-col>
          <el-col :md="8" style="margin-top: 10px">
            <el-select v-model="searchFormData.updaterName" collapse-tags placeholder="处理人" multiple clearable filterable @change="searchData">
              <el-option
                v-for="item in formOptions.updaterName"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-col>
          <el-col :md="8" style="margin-top: 10px">
            <el-input v-model="searchFormData.produceOrderNumber" collapse-tags placeholder="订单编码" clearable filterable @change="searchData">
            </el-input>
          </el-col>
          <el-col :md="8" style="margin-top: 10px">
            <el-input v-model="searchFormData.materialsSpecifications" collapse-tags placeholder="规格型号" clearable filterable @change="searchData">
            </el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="6" class="all-btns">
        <el-button size="small" type="primary" @click="searchData">查询</el-button>
        <el-button size="small" @click="resetData">重置</el-button>
        <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
          <span>高级筛选</span>
          <i v-if="show" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getUUid } from '@/utils'
import dayjs from 'dayjs'
import stationApi from '@/api/information/production/station'
import classifyApi from '@/api/call/classify'
import uApi from '@/api/sets/user/user'
import { Encrypt } from '@/utils/sercet'

export default {
  data() {
    return {
      show: true,
      dateType: 'callTime',
      searchFormData: {},
      formOptions: {
        stationList: [],
        callTypeList: [],
        callName: [],
        updaterName: []
      }
    }
  },
  mounted() {
    this.getStationList()
    this.getClassifyList()
    this.getUserList()
  },
  methods: {
    async getStationList() {
      const res = await stationApi.getStationList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.formOptions.stationList = res
      }
    },
    async getClassifyList() {
      const res = await classifyApi.getCallClassifyList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.formOptions.callTypeList = res
      }
    },
    async getUserList() {
      const res = await uApi.getUserList()
      if (res) {
        this.formOptions.callName = res
        this.formOptions.updaterName = res
      }
    },
    changeMore() {
      this.show = !this.show
    },
    // 顶部查询
    searchData() {
      const searchData = []
      Object.keys(this.searchFormData).forEach(key => {
        if (key === 'stationId' && this.searchFormData[key].length) {
          searchData.push({
            id: getUUid(),
            fieldName: 'stationId',
            fieldType: 'number',
            fieldValue: this.searchFormData[key].join('^'),
            operator: 'in'
          })
        }
        if (key === 'callTypeName' && this.searchFormData[key].length) {
          searchData.push({
            id: getUUid(),
            fieldName: 'callTypeName',
            fieldType: 'string',
            fieldValue: this.searchFormData[key].join('^'),
            operator: 'in'
          })
        }
        if (key === 'emergencyDegree' && (this.searchFormData.emergencyDegree || this.searchFormData.emergencyDegree === 0)) {
          searchData.push({
            id: getUUid(),
            fieldName: 'emergencyDegree',
            fieldType: 'number',
            fieldValue: this.searchFormData[key],
            operator: 'in'
          })
        }
        if (key === 'callName' && this.searchFormData[key].length) {
          searchData.push({
            id: getUUid(),
            fieldName: 'callName',
            fieldType: 'string',
            fieldValue: this.searchFormData[key].join('^'),
            operator: 'in'
          })
        }
        if (key === 'updaterName' && this.searchFormData[key].length) {
          searchData.push({
            id: getUUid(),
            fieldName: 'updaterName',
            fieldType: 'string',
            fieldValue: this.searchFormData[key].join('^'),
            operator: 'in'
          })
        }
        if (key === 'produceOrderNumber' && this.searchFormData[key].length) {
          searchData.push({
            id: getUUid(),
            fieldName: 'produceOrderNumber',
            fieldType: 'string',
            fieldValue: this.searchFormData[key],
            operator: 'like'
          })
        }
        if (key === 'materialsSpecifications' && this.searchFormData[key].length) {
          searchData.push({
            id: getUUid(),
            fieldName: 'materialsSpecifications',
            fieldType: 'string',
            fieldValue: this.searchFormData[key],
            operator: 'like'
          })
        }
        if (key === this.dateType && this.searchFormData[this.dateType] && this.searchFormData[this.dateType].length) {
          searchData.push({
            id: getUUid(),
            fieldName: this.dateType,
            fieldType: 'date',
            maxValue: `${dayjs(this.searchFormData[this.dateType][1]).endOf('month').format('YYYY-MM-DD')} 23:59:59`,
            minValue: `${dayjs(this.searchFormData[this.dateType][0]).format('YYYY-MM-DD')} 00:00:00`,
            operator: 'range'
          })
        }
      })
      console.log(searchData)
      this.$emit('searchData', searchData)
    },
    // 顶部重置
    resetData() {
      this.searchFormData = {}
      this.$emit('resetData')
    }
  }
}
</script>

<style scoped lang="scss">
 .searchs{
    padding-top: 10px;
    ::v-deep{
      .el-button-group .el-button{
        height:32px;
      }
      .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
        width: 100%;
        height: 32px;
      }
      .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 100%;
        height: 32px;
      }
      .el-select{
        width: 100%;
      }
    }
    ::v-deep{
      .el-col-md-11,.el-col-md-5,.el-col-md-6{
        margin-bottom: 10px;
      }
   }
   .time-search {
     display: flex;
     ::v-deep {
       .el-input__inner {
         border-radius: 4px 0 0 4px;
       }
       .el-date-editor.el-input__inner {
         border-radius: 0 4px 4px 0;
         border-left: 0;
       }
     }
   }
  .showMore{
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons{
     color: #DDE3EB;
    }
    &:hover{
      background:#F8F8F8;
      i{ color: #607FFF;}
    }
  }
  .all-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }
 }
</style>
