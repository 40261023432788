var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "big-screen-container",
    { ref: "container" },
    [
      _vm.loading
        ? _c("dv-loading")
        : _vm.currentBoard[_vm.currentShow]
        ? [
            _c("div", { staticClass: "top-container" }, [
              _c("div", { staticClass: "title-wrapper" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.currentBoard[_vm.currentShow].screenTitle))
                ]),
                _c("img", {
                  attrs: {
                    src:
                      "https://oss.mubyte.cn/static/%E5%A4%B4%E9%83%A8%402x.png",
                    alt: ""
                  }
                })
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "screen-card-title" }, [
                  _vm._v(_vm._s(_vm.currentBoard[_vm.currentShow].title[0]))
                ]),
                _c("div", { staticClass: "time-container" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://oss.mubyte.cn/static/%E7%BC%96%E7%BB%84%402x.png",
                      alt: ""
                    }
                  }),
                  _c("div", { staticClass: "date-time" }, [
                    _c("div", { staticClass: "date" }, [
                      _vm._v(
                        _vm._s(_vm.currentDate) +
                          "（" +
                          _vm._s(_vm.currentWeek) +
                          "）"
                      )
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.currentTime))
                    ])
                  ])
                ])
              ])
            ]),
            _vm.currentBoard[_vm.currentShow].type === "minix"
              ? [
                  _c(
                    "div",
                    { staticClass: "screen-card-list" },
                    _vm._l(_vm.cardList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "screen-card-wrapper" },
                        [
                          _c("div", { staticClass: "left" }, [
                            _c("img", { attrs: { src: item.src, alt: "" } }),
                            _c("span", [_vm._v(_vm._s(item.name))])
                          ]),
                          _c(
                            "div",
                            { staticClass: "right" },
                            [
                              _c("dv-digital-flop", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  config: _vm.getDigitalConfig(
                                    _vm.singleData[item.key]
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "screen-table-wrapper" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "info",
                          staticStyle: { padding: "6px 0 14px 0" }
                        },
                        [
                          _c("div", { staticClass: "screen-card-title" }, [
                            _vm._v(
                              _vm._s(_vm.currentBoard[_vm.currentShow].title[1])
                            )
                          ]),
                          _c("div")
                        ]
                      ),
                      _c("scroll-board", {
                        style: {
                          width: "100%",
                          height: _vm.tableHeight + "px"
                        },
                        attrs: { config: _vm.scrollBoardConfig },
                        on: {
                          oneScrollEnd: function($event) {
                            return _vm.scrollEnd()
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.slotColumns, function(item, i) {
                              return {
                                key: item.slotName,
                                fn: function(ref) {
                                  var index = ref.index
                                  return [
                                    _c("table-slot-list", {
                                      key: i,
                                      attrs: {
                                        "slot-name": item.slotName,
                                        row: _vm.tableData[index],
                                        column: item
                                      }
                                    })
                                  ]
                                }
                              }
                            })
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            ["table", "chartsTable", "tableTable"].includes(
              _vm.currentBoard[_vm.currentShow].type
            )
              ? [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "0 1.5%",
                        "font-size": "20px !important"
                      }
                    },
                    [
                      _c("scroll-board", {
                        style: {
                          width: "100%",
                          height: _vm.tableHeight + "px"
                        },
                        attrs: { config: _vm.scrollBoardConfig },
                        on: {
                          oneScrollEnd: function($event) {
                            return _vm.scrollEnd()
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.slotColumns, function(item, i) {
                              return {
                                key:
                                  item.slotName === "getDeepName"
                                    ? item.slotName + item.prop
                                    : item.slotName,
                                fn: function(ref) {
                                  var index = ref.index
                                  return [
                                    _c("table-slot-list", {
                                      key: i,
                                      attrs: {
                                        "slot-name": item.slotName,
                                        row: _vm.tableData[index],
                                        column: item
                                      }
                                    })
                                  ]
                                }
                              }
                            })
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.currentBoard[_vm.currentShow].type === "tableTable"
              ? [
                  _c(
                    "div",
                    { staticStyle: { padding: "0 1.5%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "info",
                          staticStyle: { padding: "6px 0 14px 0" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "screen-card-title",
                              staticStyle: { color: "#FFFFFF" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.currentBoard[_vm.currentShow].title[1]
                                )
                              )
                            ]
                          ),
                          _c("div")
                        ]
                      ),
                      _c("scroll-board", {
                        style: {
                          width: "100%",
                          height: _vm.table2Height + "px"
                        },
                        attrs: { config: _vm.scrollBoardConfig2 },
                        on: {
                          oneScrollEnd: function($event) {
                            return _vm.scrollEnd2()
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.slotColumns2, function(item, i) {
                              return {
                                key:
                                  item.slotName === "getDeepName"
                                    ? item.slotName + item.prop
                                    : item.slotName,
                                fn: function(ref) {
                                  var index = ref.index
                                  return [
                                    _c("table-slot-list", {
                                      key: i,
                                      attrs: {
                                        "slot-name": item.slotName,
                                        row: _vm.tableData2[index],
                                        column: item
                                      }
                                    })
                                  ]
                                }
                              }
                            })
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.currentBoard[_vm.currentShow].type === "charts"
              ? [
                  _c(
                    "div",
                    { staticStyle: { padding: "0 1.5%" } },
                    [
                      _c("ve-histogram", {
                        ref: "histogram",
                        attrs: {
                          height: _vm.chartHeight + "px",
                          data: _vm.chartData,
                          settings: _vm.barChartSettings,
                          extend: _vm.barChartExtend
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.currentBoard[_vm.currentShow].type === "chartsTable"
              ? [
                  _c(
                    "div",
                    { staticStyle: { padding: "0 1.5%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "info",
                          staticStyle: { padding: "14px 0 14px 0" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "screen-card-title",
                              staticStyle: { color: "#FFFFFF" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.currentBoard[_vm.currentShow].title[1]
                                )
                              )
                            ]
                          ),
                          _c("div")
                        ]
                      ),
                      _c("ve-line", {
                        ref: "line",
                        attrs: {
                          height: _vm.chartHeight + "px",
                          data: _vm.chartData,
                          settings: _vm.lineChartSettings,
                          extend: _vm.lineChartExtend
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }