import { transformColumnsToForm } from '@/utils'
import { Templates } from './columns'

const formList = {
  Templates: transformColumnsToForm(Templates),
  Parameter: [{
    label: '配置参数',
    key: 'configurationParameter',
    tagName: 'el-input',
    colSpan: 24,
    required: true,
    attrs: {
      placeholder: '输入配置参数'
    }
  }]
}

export default formList
