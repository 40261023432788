var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "procedure-detail-wrappers" }, [
    _c("div", { staticClass: "procedure-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "procedure-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("MFormBuilder", {
              ref: "baseForm",
              attrs: {
                "form-list": _vm.getFormList,
                "form-data": _vm.baseFormData,
                "label-position": "right"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("保养项目")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [_c("ItemsInfo", { attrs: { list: _vm.itemMappingList } })],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("备件更换")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("MaterialExchange", {
              attrs: { list: _vm.materialsMappingList },
              on: { materialsInfo: _vm.materialsInfo }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "order-footer" }, [
      _c("div", { staticClass: "footer-le" }),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.returns } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.buttonLoading,
                size: "small"
              },
              on: { click: _vm.submitForm }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }