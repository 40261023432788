import {changeType, changeStatus } from '@/config/options.config'
export const columns = [
  {
    prop: 'prePlanChangeNumber',
    label: '变更单号'
  },
  {
    prop: 'changeType',
    label: '变更类型',
    form: { options: changeType }
  },
  {
    prop: 'projectNumber',
    label: '项目编号'
  },
  {
    prop: 'projectName',
    label: '项目名称'
  },
  {
    prop: 'materialName',
    label: '机台号'
  },
  {
    prop: 'businessUnitName',
    label: '业务部门'
  },
  {
    prop: 'operateTime',
    label: '计划执行日期'
  },
  {
    prop: 'status',
    label: '变更状态',
    form: { options: changeStatus }
  },
  {
    prop: 'needOperate',
    label: '是否需要执行'
  },
  {
    prop: 'projectPrincipalName',
    label: '项目负责人'
  }
]

export const columns2 = [
  {
    type: 'selection',
    width: 50,
    reserveSelection: true,
    fixed: 'left'
  },

  {
    prop: 'prePlanChangeNumber',
    label: '变更单号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'changeType',
    label: '变更类型',
    width: 50,
    sortable: false,
    hiddenSearch: true,
    slotName:'changeType'
  },
  {
    prop: 'upIsSame',
    label: '上阶物料是否一致',
    sortable: false,
    hiddenSearch: true,
    slotName:'upIsSame'
  },

  {
    prop: 'projectNumber',
    sortable: false,
    hiddenSearch: true,
    label: '项目编号'
  },
  {
    prop: 'projectName',
    sortable: false,
    hiddenSearch: true,
    label: '项目名称'
  },
  {
    prop: 'materialName',
    sortable: false,
    hiddenSearch: true,
    label: '机台号'
  },
  {
    prop: 'businessUnitName',
    sortable: false,
      hiddenSearch: true,
    label: '业务部门'
  },
  {
    prop: 'operateTime',
    sortable: false,
      hiddenSearch: true,
    label: '计划执行日期'
  },
  {
    prop: 'status',
    label: '变更状态',
    width: 50,
    sortable: false,
      hiddenSearch: true,
      slotName:'changeStatus'
  },
  {
    prop: 'needOperate',
    sortable: false,
      hiddenSearch: true,
      slotName:'needOperate',
    label: '是否需要执行'
  }
]

export const faultColumns = [
 {
    type: 'selection',
    width: 50,

    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'planName',
    label: '变更单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planTypeName',
    label: '上阶物料是否一致',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'timeType',
    label: '项目编号',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'timeType',
    label: '项目名称',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'startTime',
    label: '工单编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'finishTime',
    label: '成品料号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'principalName',
    label: '数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'principalDepartName',
    label: '主单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '业务部门',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right'
  }
]

export const materialColumns = [
  {
    type: 'selection',
    width: 50,
    reserveSelection: true,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'materialCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'materialName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  // {
  //   prop: 'specifications',
  //   label: '规格',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  {
    prop: 'upMaterialName',
    label: '上阶料号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'direct',
    label: '方向',
    hiddenSearch: true,
    sortable: false,
    slotName:'direct',
    minWidth:100
  },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName:'status',
    minWidth:100
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false,
    slotName:'quantity',
    minWidth:100
  },
  {
    prop: 'procedureQuantity',
    label: '工序投入数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'mainUnit',
    label: '主单位',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'materialAttribute',
    label: '物料属性',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'prePlanNumber',
    label: '归属工单',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'procedureId',
    label: '归属工序',
    hiddenSearch: true,
    sortable: false,
    slotName:'procedureId'
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right'
  }
]
export const materialBatchColumns = [
  // {
  //   type: 'selection',
  //   width: 50,
  //   reserveSelection: true,
  //   fixed: 'left'
  // },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'materialCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'materialName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'upMaterialName',
    label: '上阶料号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'direct',
    label: '方向',
    hiddenSearch: true,
    sortable: false,
    slotName:'direct',
    minWidth:100
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false,
    slotName:'quantity',
    minWidth:100
  },
  {
    prop: 'procedureQuantity',
    label: '工序投入数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'mainUnit',
    label: '主单位',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'materialAttribute',
    label: '物料属性',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'prePlanNumber',
    label: '归属工单',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureId',
    label: '归属工序',
    hiddenSearch: true,
    sortable: false,
    slotName:'procedureId'
  }
]
export const inspectionColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'inspectionStatus'
  },
  {
    prop: 'cycleCheckTaskNumber',
    label: '巡检单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkPlanNumber',
    label: '计划单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'taskCheckType',
    label: '巡检类别',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checker',
    label: '巡检人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkPlanTime',
    label: '计划日期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkStartTime',
    label: '实际开始',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkEndTime',
    label: '实际完成',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'errorCount',
    label: '异常数',
    hiddenSearch: true,
    sortable: false,
    slotName: 'errorCount'
  },
  {
    prop: 'checkItemList',
    label: '巡检项',
    hiddenSearch: true,
    sortable: false,
    slotName: 'checkItem'
  }
]

export const pointColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'pointStatus'
  },
  {
    prop: 'spotCheckNumber',
    label: '点检单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'spotCheckType',
    label: '点检类别',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checker',
    label: '点检人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkBeginTime',
    label: '实际开始',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkEndTime',
    label: '实际完成',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'errorCount',
    label: '异常数',
    hiddenSearch: true,
    sortable: false,
    slotName: 'errorCount'
  },
  {
    prop: 'pointCheckItem',
    label: '点检项',
    hiddenSearch: true,
    sortable: false,
    slotName: 'checkItem'
  }
]

export const maintenanceColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'maintenanceStatus',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'maintenanceStatus'
  },
  {
    prop: 'maintainTaskNumber',
    label: '保养单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainPlanNumber',
    label: '计划单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainType',
    label: '保养类别',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainerName',
    label: '保养人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainPlanTime',
    label: '计划日期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainStartTime',
    label: '实际开始',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainEndTime',
    label: '实际完成',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'unMaintainCount',
    label: '未保养数',
    hiddenSearch: true,
    sortable: false,
    slotName: 'unMaintainCount'
  },
  {
    prop: 'maintenanceCheckItem',
    label: '保养项',
    hiddenSearch: true,
    sortable: false,
    slotName: 'checkItem'
  },
  {
    prop: 'maintenanceCheckItem',
    label: '备件更换',
    hiddenSearch: true,
    sortable: false,
    slotName: 'spareList'
  }
]
// 报警记录
export const warnColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'time',
    label: '时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'value',
    label: '报警代码',
    hiddenSearch: true,
    sortable: false
  },
]
// 项目工单
export const workColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'productionPlanNumber',
    label: '工单编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'projectNumber',
    label: '项目编码',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'projectName',
    label: '项目名称',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'changeStatus',
    label: '变更执行状态',
    hiddenSearch: true,
    sortable: false,
    minWidth:100,
    slotName: 'changeStatus',
  },
  {
    prop: 'status',
    label: '工单状态',
    hiddenSearch: true,
    sortable: false,
    slotName:'status',
    minWidth:100
  },
  {
    prop: 'planStartTime',
    label: '计划开工时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planEndTime',
    label: '计划完工时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'workAction',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right'
  }
]
// 变更单新增物料字段
export const productionTaskColumn = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'code',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'upMaterial',
    label: '上阶料号',
    sortable: false,
    hiddenSearch: true,
    slotName:'upMaterial'
  },
  {
    prop: 'direct',
    slotName: 'direct',
    label: '方向',
    hiddenSearch: true,
    sortable: false,
    minWidth:140
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    slotName:'quantity',
    minWidth:100
  },
  {
    prop: 'mainUnitName',
    label: '主单位',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  },
  {
    prop: 'attributionProcess',
    label: '归属工序',
    sortable: false,
    hiddenSearch: true,
    slotName:'attributionProcess'
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'action',
    fixed: 'right'
  }
]

