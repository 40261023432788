<template>
  <div class="scrap-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <EquipmentClass ref="equipmentClass" @nodeClick="setEquipmentClassId" @refrash="$refs.mTable.setTableData(true)" />
      </template>
      <template slot="right">
        <div class="bottom">
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入设备编号/名称/SN"
            :tags="!selectionData.length ? functionHeader.tags : []"
            :columns-key="columnsKey"
            :export-name="functionHeader.exportName"
            :export-params="functionHeader.exportParams"
            :import-name="!selectionData.length ? functionHeader.importName : ''"
            :show-export="!selectionData.length ? true : false"
            :tab-radio="params.radio"
            :hidden-mul-search="true"
            @search="$refs.mTable.setTableData(true)"
            @checkSearch="checkSearch"
          >
            <div slot="all" v-show="selectionData.length">
              <el-button v-if="permission('AddFacilityInfo')" type="primary" size="small" @click="allMore('batchAdd')">批量添加质保信息</el-button>
              <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
              <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                已选
                <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                / {{ total }}项
              </span>
                <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
            </div>
          </FunctionHeader>
          <div class="top">
            <SearchForm v-show="!isShow" ref="searchForm" :search-field-data="searchFieldData" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
          </div>
          <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" :columns-setting="isShow" :columns-key="columnsKey" @selection-change="selectionChange">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <el-button slot="code" slot-scope="{ row }" style="white-space: pre-wrap;" type="text" @click="viewDetail(row)">{{ row.code }}</el-button>
            <div slot="type" slot-scope="{ row }" >
              {{ quanlityInfoType[row.type].name }}
            </div>
            <div slot="equipmentClassType" slot-scope="{ row }" type="text">{{ row.equipmentClassType | equipmentClassTypeName }}</div>
            <div slot="action" slot-scope="{ row }">
              <template>
                <el-button v-if="permission('Modify')" type="text" @click="modifyEquipment(row)">修改</el-button>
                <el-divider v-if="permission('Modify')" direction="vertical" />
                <el-button v-if="permission('Copy')" type="text" @click="copyEquipment(row)">复制</el-button>
                <el-divider v-if="permission('Copy')" direction="vertical" />
                <el-button v-if="permission('Del')" type="text" @click="delEquipment(row)">删除</el-button>
              </template>
            </div>
          </MTable>
          <MDialog v-model="visible" title="新增" width="500px" class="extra-forms" @onOk="submitForm">
            <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="formList" />
          </MDialog>
        </div>
      </template>
    </Split>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/xiewei/SearchForm/SearchForm'
import conventionMixin from '@/mixin/conventional-page'
import { searchFormList } from './form-list'
import { columns, getFunctionList } from './columns'
import { getUUid, setTreeData } from '@/utils'
import equipmentClassApi from '@/api/information/equipment/equipment-class'
import api from '@/api/xiewei/facility/facility'
import apis from '@/api/information/equipment/equipment-class'
import { Encrypt } from '@/utils/sercet'
import fixSearchNum from '@/mixin/fix-search-num'
import fixFieldShow from '@/mixin/fix-field-show'
import dayjs from 'dayjs'
import { equipmentType, status } from '@/config/options.config'
import Split from '@/components/Split/Split'
import EquipmentClass from '@/components/xiewei/EquipmentClass/EquipmentClass'
import { getToken } from '@/utils/auth'
import fieldApi from '@/api/xiewei/field/field'
import keApi from '@/api/information/unit'
import { formList } from './components/form-list'
import { quanlityInfoType } from '@/config/options.sales'

export default {
  name: 'FacilityAccountList',
  components: { SearchForm, FunctionHeader, Split, EquipmentClass },
  filters: {
    equipmentClassTypeName(id) {
      return equipmentType.find(item => item.id === id).name
    }
  },
  mixins: [fixFieldShow, fixSearchNum, conventionMixin],
  data() {
    return {
      afterSaleEquStatus:[
        {
          id: 0,
          name: '待上机'
        },
        {
          id: 1,
          name: '正常运行'
        },
        {
          id: 2,
          name: '待返厂'
        },
        {
          id: 3,
          name: '返厂中'
        },
        {
          id: 4,
          name: '待测试'
        },
        {
          id: 5,
          name: '待运出'
        }
      ],
      api,
      fieldApi,
      formList,
      quanlityInfoType,
      searchFieldData: [],
      params: {
        radio: 'Facility'
      },
      columns: columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addEquipment
            }
          }
        ],
        exportName: 'exportFacility',
        importName: 'facility',
        exportParams: {}
      },
      formData: {},
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      searchKeyword: true,
      split: {
        offset: 15
      },
      selectionData: [],
      getTableDataParams: {},
      actionColumn: [
        {
          slotName: 'action',
          label: '操作',
          fixed: 'right',
          sortable: false,
          hiddenSearch: true,
          width: 175
        }
      ],
      tableSign: 'facility',
      visible: false,
      exportSelectionIds: [],
      allCustomer: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - (this.isShow ? - 40 : 120)
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 97
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.fieldList()
    this.getAllCustomer()
  },
  methods: {
    submitForm(callback) {
      const that = this
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await api.addListFacilityWarranty(Object.assign({}, {
            facilityIds: this.exportSelectionIds
          }, this.createSetData(form))
          )
          if (res) {
            this.$message.success('添加成功')
            this.visible = false
            this.$refs.mTable.setTableData(true)
            this.$refs.mTable.tableRefs().clearSelection()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    // 获取客户
    // async getUser() {
    //   const res = await keApi.getCustomerList({
    //     searchVal: Encrypt(JSON.stringify([
    //       {
    //         id: getUUid(),
    //         fieldName: 'isEnabled',
    //         fieldType: 'number',
    //         fieldValue: 1,
    //         operator: 'eq'
    //       }
    //     ]))
    //   })
    //   if (res) {
    //     this.searchFormList[1].children[0].options = res
    //   }
    // },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.searchFormList[1].props.options = setTreeData(data)
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      search.push(
        {
          id: getUUid(),
          fieldName: 'isDeleted',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }
      )
      const searchKey = []
      if (this.functionHeader.searchVal) {
        searchKey.push({
          fieldKey: 'code^name^sn',
          fieldType: 0,
          fieldValue: this.functionHeader.searchVal
        })
      }
      // const extra = []
      // if (this.getTableDataParams.equipmentClassId) {
      //   extra.push({
      //     fieldKey: 'equipment_class_id',
      //     fieldType: 1,
      //     fieldValue: this.getTableDataParams.equipmentClassId
      //   })
      // }
      const { form } = this.$refs.searchForm
      const res = await api.getFacilityListPage({
        page: page.currentPage,
        limit: page.pageSize,
        searchVal: Encrypt(JSON.stringify([...search])),
        fieldParamBean: Encrypt(JSON.stringify([...searchKey, ...this.searchFormatData.filter(item => item.fieldKey !== 'type')])),
        type: form.type,
        equipmentClassId: this.getTableDataParams.equipmentClassId
      })
      if (res) {
        const datas = res.records ? res.records.map(item => {
          item.status = this.afterSaleEquStatus.filter(p => p.id == item.status)[0].name
          return {
            ...item,
            ...item.facilityFieldMap
          }
        }) : []
        callback({
          total: res.total,
          content: datas
        })
        this.total = res.total
      }
      callback()
    },
    async addEquipment() {
      this.$router.push({ name: 'addFacility', query: { type: 'add' }})
    },
    modifyEquipment(row) {
      this.$router.push({ name: 'addFacility', query: { id: row.id, type: 'modify' }})
    },
    copyEquipment(row) {
      this.$router.push({ name: 'addFacility', query: { id: row.id, type: 'copy' }})
    },
    delEquipment(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delFacility({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    },
    selectionChange(data) {
      this.selectionData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() },
        batchAdd: () => { this.batchAdd() }
      }
      functions[command]()
    },
    batchAdd() {
      this.visible = true
    },
    setEquipmentClassId(val) {
      this.getTableDataParams.equipmentClassId = val
      this.$refs.mTable.setTableData(true)
    },
    searchTable(data) {
      const ids = data.map(item => item.fieldKey)
      const hasIndex = ids.indexOf('customer_id')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
        this.searchFormatData.splice(hasIndex, 1)
        const id = data[hasIndex].fieldValue
        const arr = this.allCustomer.filter(item => item.id === this._.first(id))
        const list = this.allCustomer.filter(item => item.id === this._.last(id))
        this.searchFormatData = [...this.searchFormatData, {
          fieldKey: list[0].isCustomer === 1 ? 'customer_site_id' : 'customer_id',
          fieldType: 1,
          fieldValue: list[0].isCustomer === 1 ? list[0].id : arr[0].id
        }]
      }
      // console.log('sousuo=>', this.searchFormatData)
      this.$refs.mTable.setTableData(true)
      // 搜索标识-特殊处理
      this.fixNum(data)
    },
    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'facilityAccountDetail',
        params: { id: row.id }
      })
    },
    createSetData(raw) {
      return {
        warrantyScope: raw.warrantyScope,
        castsAttach: raw.castsAttach,
        warrantyCharacter: raw.warrantyCharacter,
        guaranteePeriodStart: raw.guaranteePeriod[0],
        guaranteePeriodEnd: raw.guaranteePeriod[1]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}
.nodata {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  color: #999;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
.content-container {
  max-height: 300px;
  overflow: auto;
}
.scrap-wrap {
  width: 100%;

  .bottom {
    padding: 10px;
  }
  .extra-forms {
    ::v-deep {
      .el-form-item {
        margin-bottom: 20px!important;
      }
    }
  }

}
</style>
