import { equipmentType, status } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '备件编码',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    slotName: 'code',
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '备件名称',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'specifications',
    label: '规格型号',
    sortable: false,
    hiddenSearch: true,
    form: { index: 4, tagName: 'el-input', required: false }
  },
  {
    prop: 'drawing',
    label: '备件图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing',
    form: {
      index: 6,
      tagName: 'div',
      customSlots: ['drawing'],
      required: false
    }
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    width: 100,
    form: {
      index: 5,
      tagName: 'el-select',
      key: 'mainUnitId'
    }
  },
  {
    prop: 'equipmentNameList',
    label: '关联设备',
    sortable: false,
    hiddenSearch: true,
    slotName: 'equipmentList',
    width: 200
  },
  {
    prop: 'sparePartClassName',
    label: '备件类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      tagName: 'el-select',
      key: 'sparePartClassId',
      required: false
    }
  },
  {
    prop: 'upperLimit',
    label: '库存上限',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'lowerLimit',
    label: '库存下限',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'currentQuantity',
    label: '当前存货',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '备件状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    filters: formatColumns(status),
    form: {
      index: 3,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 7,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 220
  }
]
export const packageColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '设备编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]
export const spareColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'warehouseName',
    label: '仓库名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'sparePartSpecification',
    label: '规格型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'outWarehouseBillNumber',
    label: '源单据号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'unitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '出/入库数量',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'creator',
    label: '制单人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '制单时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
