
export const searchFormListAll = [
  {
    key: 'warehouseId',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择仓库'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'type',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择库存状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'label',
          value: 'value'
        },
        options: [
          {
            value: 3,
            label: '全部库存预警'
          },
          {
            value: 0,
            label: '低于库存下限'
          },
          {
            value: 1,
            label: '高于库存上限'
          },
          {
            value: 2,
            label: '存在库存预警'
          }
        ]
      }
    ]
  },
  {
    colSpan: 5,
    key: 'quantity',
    tagName: 'el-select',
    defaultValue: 0,
    props: {
      placeholder: '请选择是否显示0库存'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'label',
          value: 'value'
        },
        options: [
          {
            value: 0,
            label: '显示0库存'
          },
          {
            value: 1,
            label: '不显示0库存'
          }
        ]
      }
    ]
  }
]

