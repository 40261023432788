<template>
  <div class="orderAdd">
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form
          ref="ruleForm"
          label-position="right"
          :model="ruleForm"
          :rules="rules"
          label-width="110px"
          class="demo-form-inline"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="变更单号" prop="code">
                <el-input
                  v-model="ruleForm.prePlanChangeNumber"
                  placeholder=""
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="变更类型" prop="changeType">
                <el-select v-model="ruleForm.changeType" placeholder="请选择" :disabled="['view','add'].includes($route.query.type)">
                  <el-option
                    v-for="item in changeNewType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工单编号" prop="prePlanId">
                <m-remote-select
                v-model="ruleForm.prePlanId"
                class="input-prepend"
                :searchType="'prePlanId'"
                :placeholder="'请选择工单编号'"
                :interface-fun="api.getPrePlanListPage"
                :options.sync="orderLists"
                filterable
                clearable
                :label-name="'prePlanNumber'"
                :search-name="'prePlanNumber'"
                :value-name ="'prePlanId'"
                @on-change="workChange"
              />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <el-button type="primary" size="small" @click="addMaterials" style="margin-bottom:8px" :disabled="!materialsBomId">新增</el-button>
    <div class="detail-card" style="padding:10px">
        <MTable
          ref="mTable"
          :columns="productionTaskColumn"
          :height="500"
          :data="materialsList"
          :show-page="false"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="upMaterial" slot-scope="{ row }">
            <el-select v-model="row.upMaterialsId" placeholder="请选择" @change="(val) => selectUpMaterials(row,val)">
                      <el-option
                        v-for="item in upMaterialsList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
          </div>
          <div slot="attributionProcess" slot-scope="{ row }">
            <el-select v-model="row.procedureId" placeholder="请选择" @change="(val) => selectProcess(row,val)">
                      <el-option
                        v-for="item in processList"
                        :key="item.procedureId"
                        :label="item.procedureName"
                        :value="item.procedureId"
                      />
                    </el-select>
          </div>
          <div slot="direct" slot-scope="{ row }">
            <el-select v-model="row.directId" placeholder="请选择">
                      <el-option
                        v-for="item in directList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
          </div>
          <div slot="quantity" slot-scope="{ row }">
                      <el-input-number v-model="row.quantity" :min="0" controls-position="right" :step="1"  style="width: 90%"></el-input-number>
                  </div>
          <template slot="action" slot-scope="{ $index }">
            <!-- <el-button type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
              <el-divider direction="vertical" /> -->
            <el-button type="text" @click="del($index)">删除</el-button>
          </template>

        </MTable>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="returnList">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </div>
    <div class="lines" />
    <select-materials
      ref="selectMaterials"
      :visible.sync="showMaterials"
      :title="'添加物料'"
      @submitForm="submitMaterials"
    />
  </div>
</template>
<script>
import api from '@/api/changeManagement/changeManagement'
import { productionTaskColumn } from './columns'
import { changeNewType } from '@/config/options.config'
import FunctionHeader from '@/components/FunctionHeader/index'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import { getUUid } from '@/utils'
export default {
  name: 'AddChangeOrder',
  components: { FunctionHeader,SelectMaterials },
  data() {
    return {
      api,
      productionTaskColumn,
      orderLists:[],
      changeNewType,
      ruleForm: {
        prePlanChangeNumber: '',
        changeType: 0,
        prePlanId:undefined
      },
      showMaterials:false,
      rules: {
        prePlanChangeNumber: [
          { required: true, message: '请输入变更单号', trigger: 'blur' }
        ],
        prePlanId:[
        { required: true, message: '请选择工单', trigger: 'blur' }
        ]
      },
      materialsList:[],
      selectMaterials:[],
      processList:[],
      upMaterialsList:[],
      directList:[
        {
          id:-1,
          name:'减少'
        },
        {
          id:1,
          name:'增加'     
        }
    ],
  materialsBomId:'',
  prePlanNumber:''
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'materialsApplyList') {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },
  async created() {
    await this.getCode()
  },
  mounted(){
  },
  methods: {
    async submitMaterials(val) {
      this.materialsList = this.materialsList.concat(val.map(item=>{
        return {
          ...item,
          id: getUUid(),
          materialsId: item.id
        }
      }))
      this.showMaterials = false
      await this.getProcedureList()
      await this.getUpMaterialsList()
    },
    async getProcedureList(){
      const res = await api.getProcedureList({ prePlanNumber: this.prePlanNumber });
      if (res) {
        this.processList = res;
      }
    },
    async getUpMaterialsList(){
      const res = await api.getAddUpMaterialsList({ materialsBomId: this.materialsBomId});
      if (res) {
        this.upMaterialsList = res;
      }
    },
    workChange(val,val1){
      this.materialsList = []
      if(val1.length){
        this.materialsBomId = val1[0].materialsBomId
        this.prePlanNumber = val1[0].prePlanNumber
      }else{
        this.materialsBomId = ''
        this.prePlanNumber = ''
      }
    },
    selectUpMaterials(row,val){
      const currentRow = this.upMaterialsList.find(item => item.id === val)
      if(currentRow){
        this.$set(row, "upMaterialsLevelId", currentRow.upMaterialsLevelId)
      }
    },
    selectProcess(row,val){
      const currentRow = this.processList.find(item => item.procedureId === val)
      if(currentRow){
        this.$set(row, "procedureDetailsId", currentRow.id)
        this.$set(row, "procedureLocationCode", currentRow.serialsCode)
      }

},
   addMaterials(){
      this.showMaterials = true
    },
    del(index){
    this.materialsList.splice(index, 1)
  },
    // 获取记录编号
    async getCode() {
      const res = await api.getCode()
      if (res) {
        this.ruleForm.prePlanChangeNumber = res
      }
    },
    save() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          const validate = this.materialsList.every(item => item.upMaterialsId)
          if (!validate) {
            return this.$message.info('上阶料号不能为空')
          }
          const validate1 = this.materialsList.every(item => item.directId)
          if (!validate1) {
            return this.$message.info('物料方向不能为空')
          }
          const validate2 = this.materialsList.every(item => item.quantity >0)
          if (!validate2) {
            return this.$message.info('物料数量需大于0')
          }
          const validate3 = this.materialsList.every(item => item.procedureId)
          if (!validate3) {
            return this.$message.info('归属工序不能为空')
          }
  
          let obj = {
             ...this.ruleForm,
             materialsBomId:this.materialsBomId,
             prePlanNumber:this.prePlanNumber,
            itemList:this.materialsList.map(item=>{
             return {
              materialsId:item.materialsId,
              upMaterialsId:item.upMaterialsId,
              upMaterialsLevelId:item.upMaterialsLevelId,
              procedureDetailsId:item.procedureDetailsId,
              procedureLocationCode:item.procedureLocationCode,
              procedureId:item.procedureId,
              quantity:item.quantity,
              direct:item.directId
             }
            })
          }
          console.log(obj)
          const res = await api.addChangeOrder(obj)
          if (res) {
            this.$router.push({ name: 'ChangeManagementList'})
          }
        } else {
          return false
        }
      })
    },
    returnList() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .my-col {
  .el-form-item {
    margin-bottom: 2px;
    .el-form-item__label {
      padding-right: 0;
    }
  }
}
::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-date-editor .el-range-separator,
  .el-date-editor .el-range__icon {
    line-height: 26px;
  }
  .el-input-number {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 15px;
  }
}
.orderAdd {
  width: 100%;
  height: 100%;
  .input-new-tag {
    width: 130px;
  }
  .button-new-tag {
    height: 32px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  .e-btn {
    margin-bottom: 10px;
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
