
export const maintenanceViewColumns = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '保养项',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'needUpload',
    label: '是否必须上传图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'needUpload',
    width: 150
  },
  {
    prop: 'standard',
    label: '保养标准',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sketchMap',
    label: '操作图',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sketchMap'
  }
]
export const columns = [
  {
    prop: 'maintainTaskNumber',
    label: '保养单号',
    sortable: 'custom',
    hiddenSearch: true,
    slotName: 'maintainTaskNumber',
    minWidth: 160
  },
  {
    prop: 'maintainType',
    label: '保养类别',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentCode',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'maintainPlanNumber',
    label: '计划单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'maintainPlanTime',
    label: '计划日期',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'maintainStartTime',
    label: '实际开始',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'maintainEndTime',
    label: '实际结束',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'maintainSpendTime',
    label: '消耗时间（min）',
    sortable: false,
    hiddenSearch: true,
    slotName: 'maintainSpendTime',
    minWidth: 160
  },
  {
    prop: 'maintainerName',
    label: '保养人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status'
  },
  {
    prop: 'maintenanceItem',
    label: '保养项',
    sortable: false,
    hiddenSearch: true,
    slotName: 'maintenanceItem'
  },
  {
    prop: 'pointCheckItem',
    label: '消耗备件',
    sortable: false,
    hiddenSearch: true,
    slotName: 'spareList'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  }
]
export const spareColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 50,
    slotName: 'index'
  },
  {
    prop: 'sparePartDrawing',
    label: '图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sparePartDrawing'
  },
  {
    prop: 'sparePartCode',
    label: '备件编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartName',
    label: '备件名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'requestQuantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '备件状态',
    sortable: false,
    fixed: 'right',
    hiddenSearch: true,
    minWidth: 100,
    slotName: 'spareStatus'
  }
]
export const maintenanceItemView = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: false,
    width: 50,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '保养项名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'standard',
    sortable: false,
    hiddenSearch: true,
    label: '保养标准'
  },
  {
    prop: 'result',
    label: '保养状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'maintenanceResult'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]
