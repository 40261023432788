var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equipment-monitoring" },
    [
      _c("div", { staticClass: "detail-cards" }, [
        _vm.isTitle
          ? _c("div", { staticClass: "card-tit" }, [_vm._v("质保信息")])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "card-bots" },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.addInfo }
                  },
                  [_vm._v("新增")]
                )
              ],
              1
            ),
            _c("MTable", {
              ref: "planTable",
              attrs: {
                "show-page": false,
                height: _vm.tabHeight,
                columns: _vm.columns,
                data: _vm.facilityWarrantyList
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "serviceContent",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [_vm._v(_vm._s(row.serviceContent))])
                    }
                  },
                  {
                    key: "laborCost",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(
                          _vm._s(_vm.quanlityInfoFeeType[row.laborCost].name)
                        )
                      ])
                    }
                  },
                  {
                    key: "sparePartsCost",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(
                          _vm._s(
                            _vm.quanlityInfoFeeType[row.sparePartsCost].name
                          )
                        )
                      ])
                    }
                  },
                  {
                    key: "consumptionOfProductCost",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(
                          _vm._s(
                            _vm.quanlityInfoFeeType[
                              row.consumptionOfProductCost
                            ].name
                          )
                        )
                      ])
                    }
                  },
                  {
                    key: "type",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(_vm._s(_vm.quanlityInfoType[row.type].name))
                      ])
                    }
                  },
                  {
                    key: "guaranteePeriod",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(
                          _vm._s(_vm.fixData(row.guaranteePeriodStart)) +
                            " - " +
                            _vm._s(_vm.fixData(row.guaranteePeriodEnd))
                        )
                      ])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return row.isUpdate === 1
                        ? _c(
                            "div",
                            {},
                            [
                              _vm.type === "new"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delInfos($index)
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                                : [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.modifyInfo(row)
                                          }
                                        }
                                      },
                                      [_vm._v("修改")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.delInfo(row)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ]
                            ],
                            2
                          )
                        : _c(
                            "div",
                            { attrs: { slot: "action" }, slot: "action" },
                            [_vm._v(_vm._s("-"))]
                          )
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      ]),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType], width: "500px" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: {
              "label-position": "right",
              "label-width": "100px",
              "form-data": _vm.formData,
              "form-list": _vm.formList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }