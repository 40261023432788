import { render, staticRenderFns } from "./board.vue?vue&type=template&id=51fc8ace&scoped=true&"
import script from "./board.vue?vue&type=script&lang=js&"
export * from "./board.vue?vue&type=script&lang=js&"
import style0 from "./board.vue?vue&type=style&index=0&id=51fc8ace&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51fc8ace",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mubai-mom-frontend-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51fc8ace')) {
      api.createRecord('51fc8ace', component.options)
    } else {
      api.reload('51fc8ace', component.options)
    }
    module.hot.accept("./board.vue?vue&type=template&id=51fc8ace&scoped=true&", function () {
      api.rerender('51fc8ace', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/bbelc/board.vue"
export default component.exports