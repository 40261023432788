/** write by luomingzhi */
import { formatColumns } from '@/utils'
import { status } from '@/config/options.config'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'name',
    label: '呼叫类型',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { type: 'text' }}
  },
  {
    prop: 'users',
    label: '呼叫人员',
    sortable: false,
    hiddenSearch: true,
    slotName: 'users'
  },
  {
    prop: 'isEnabled',
    label: '呼叫类型状态',
    sortable: false,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    width: 120,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 190
  }
]

export default columns
