<template>
  <div class="account-detail-wrapper">
    <div class="account-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">点检计划详情</span>
      </div>
    </div>
    <div class="account-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <div>
            <el-row class="view-list" style="padding: 10px 15px;color: #393D60;" :gutter="24">
              <!--            暂时这么处理距离问题-->
              <el-col
                v-for="(item, index) in columns"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <div style="color: #9597AE;">{{ item.label }}</div>
                <div class="bot-tis">{{ getValue(item) || '-' }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 15px;">
      <el-tabs v-model="params.radio" class="detail-tabs" type="border-card" tab-position="top" @tab-click="tabClick">
        <el-tab-pane label="点检项目" name="quanityRadio">
          <ItemsInfo :list="baseFormData.itemMappingList" :type="'detail'" :is-template="false" :facility-spot-check-plan-id="baseFormData.id" @refrash="getFacilitySpotCheckPlanDetail" />
        </el-tab-pane>
        <el-tab-pane label="关联设备" name="equipmentRadio">
          <relationEquipment :list="baseFormData.facilityMappingList" :type="'detail'" :facility-spot-check-plan-id="baseFormData.id" :selecte-facility-list="baseFormData.selecteFacility" :customer-id="baseFormData.customerId" @refrash="getFacilitySpotCheckPlanDetail" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>

import api from '@/api/xiewei/facility/point'
import { columns } from './columns'
import { getUUid } from '@/utils'
import ItemsInfo from '@/views/facility/facility-point/components/items-info'
import { maintenanceCycleType } from '@/config/options.sales'
import relationEquipment from '@/views/facility/facility-point/components/relation-equipment'

export default {
  name: 'FacilityMaintenanceTemplateDetail',
  components: { ItemsInfo, relationEquipment },
  data() {
    return {
      columns,
      maintenanceCycleType,
      params: {
        radio: 'quanityRadio'
      },
      baseFormData: {
        id: undefined,
        itemMappingList: [],
        materialsMappingList: []
      },
      dates: [
        {
          id: 0,
          name: '小时'
        },
        {
          id: 1,
          name: '天'
        }
      ]
    }
  },
  computed: {
    tableDialogHeight() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
    },
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 100
    }
  },
  mounted() {
    this.getFacilitySpotCheckPlanDetail()
  },
  methods: {
    materialsInfo() {},
    async getFacilitySpotCheckPlanDetail() {
      const res = await api.getFacilitySpotCheckPlanDetail({ id: this.$route.query.id })
      if (res) {
        this.baseFormData = res
        this.baseFormData.reminderTime = res.reminderTime || res.reminderTime === 0 ? `${res.reminderTime}${this.dates[res.reminderTimeType].name}` : ''
        this.baseFormData.toleranceRange = res.toleranceRange ? '±' + res.toleranceRange : ''
        this.baseFormData.cycleType = this.maintenanceCycleType[res.cycleType].name
        this.baseFormData.operatorName = res.userList ? res.userList.map(item => item.name).join('、') : ''
        this.baseFormData.itemMappingList = res.itemMappingList || []
        this.baseFormData.facilityMappingList = res.facilityMappingList && res.facilityMappingList.length > 0 ? res.facilityMappingList.map(item => {
          return {
            ...item,
            code: item.facilityCode,
            name: item.facilityName
          }
        }) : []
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.form.options.length > 0) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    back() {
      this.$router.push({ name: 'facilityPointList' })
    },
    tabClick() {}
  }
}
</script>

<style scoped lang="scss">
.account-detail-wrapper {
  .account-detail-header {
    width: 100%;
    padding: 10px 0;
  }
  .account-detail-body {
    padding-top: 10px;
    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #DDE3EB;
      }
      // ::v-deep .el-tabs__nav {
      //   position: relative;
      //   left: 50%;
      //   transform: translateX(-50%) !important;
      // }
    }
  }
}
.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.result-wrapper {
  >div {
    display: flex;
    font-size: 16px;
    padding: 10px 0;
    span {
      &:first-child {
        width: 80px;
        text-align-last: justify;
        text-align: justify;
      }
    }
  }
}
::v-deep {
  .table-row-img {
    >img {
      height: 50px;
    }
  }
}
.all-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .checkAll {
    margin-right: 20px;
  }
  .all-li {
    width: 80px;
    height: 26px;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DDE3EB;
    margin-right: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A4B3C6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .active {
    color: #607FFF;
    border-color: #607FFF;
    background: #EFF2FF;
  }
}
</style>
