<template>
  <div class="procedure-detail-wrappers">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder v-if="!isView" ref="baseForm" :form-list="formList" :form-data="baseFormData" label-position="right" />
          <el-row v-else class="view-list" :gutter="24">
            <el-col v-for="item in viewColums" :key="item.id" :span="6">
              <div class="view-list__label">{{ item.label }}</div>
              <div class="view-list__value">{{ getValue(item) || '-' }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">配置参数</div>
        <div class="card-bot">
          <el-button v-if="!isView" size="small" type="primary" class="e-btn" @click="addParams">添加参数</el-button>
          <MTable ref="mTable" :show-page="false" :height="400" :columns="columnsAdd" :data="parameterList" :columns-setting="false">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

            <div v-if="isView" slot="paramName" slot-scope="{ row }">
              <div>{{ row.paramName }}</div>
            </div>
            <div v-else slot="paramName" slot-scope="{ row }" class="table-cell">
              <span class="required-star">*</span>
              <el-input v-model="row.paramName" :min="1" controls-position="right" :step="1" />
            </div>

            <div v-if="isView" slot="paramDetail" slot-scope="{ row }">
              <div>{{ row.paramDetail }}</div>
            </div>
            <div v-else slot="paramDetail" slot-scope="{ row }" class="table-cell">
              <el-input v-model="row.paramDetail" :min="1" controls-position="right" :step="1" />
            </div>

            <div slot="action" slot-scope="{ row, $index }">
              <template>
                <el-button type="text" @click="copyParams(row)">复制</el-button>
                <el-button type="text" @click="delParams($index)">移除</el-button>
              </template>
            </div>
          </MTable>
        </div>
      </div>
    </div>
    <div v-if="!isView" class="order-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formList } from '../form-list'
import { columns, columnsAdd } from '../columns'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import keApi from '@/api/information/unit'
import equipmentClassApi from '@/api/information/equipment-class'
import api from '@/api/xiewei/facility/factory'
import { setTreeData } from '@/utils'

export default {
  name: 'TechnicalParameterAdd',
  data() {
    return {
      baseFormData: {},
      formList,
      params: {},
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '工厂参数模板详情',
        addO: '新增工厂参数模板',
        modifyO: '修改工厂参数模板',
        copyO: '复制工厂参数模板'
      },
      buttonLoading: false,
      parameterList: [],
      equipmentClassList: [],
      customerList: []
    }
  },

  computed: {
    columnsAdd() {
      return this.isView ? columnsAdd.filter(item => item.slotName !== 'action') : columnsAdd
    },

    isView() {
      return this.$route.query.type === 'view'
    },

    viewColums() {
      return columns.filter((item, index) => index > 1 && item.slotName !== 'action')
    },

    getValue() {
      return row => {
        return this.baseFormData[row.prop]
      }
    }
  },

  mounted() {
    this.params = this.$route.query
    this.getEquipmentClassList()
    this.getUser()
    if (this.params.type !== 'add') {
      this.getFactoryParameterDetail()
    }
  },

  methods: {
    returns() {
      this.$router.push({ name: 'factoryParameterList' })
    },

    async getFactoryParameterDetail() {
      const res = await api.getFactoryParameterDetail({ id: this.$route.query.id })
      if (res) {
        this.baseFormData = {
          ...res,
          customerIds: res.customerIds ? this._.dropRight(this._.drop(res.customerIds.split('^'))).map(item => +item) : res.customerIds,
          equipmentClassIds: (res.equipmentClassList || []).reverse().map(item => {
            return item.parentId === -1 ? [item.id] : [item.parentId, item.id]
          }),
          equipmentClassIdList: res.equipmentClassIdList ? JSON.parse(res.equipmentClassIdList) : [],
          templateName: this.params.type === 'copy' ? '' : res.templateName
        }
        this.parameterList = res.parameterList || []
      }
    },

    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        this.equipmentClassList = res
        const equipmentClassList = setTreeData(res)
        this.formList[1].props.options = equipmentClassList
      }
    },

    // 获取客户
    async getUser() {
      const res = await keApi.getCustomerList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.customerList = res
        this.formList[2].children[0].options = res
      }
    },

    // 添加参数
    addParams() {
      this.parameterList.push({})
    },

    // 赋值参数
    copyParams(row) {
      this.parameterList.push({
        ...row
      })
    },

    // 删除参数
    delParams(index) {
      this.parameterList.splice(index, 1)
    },

    submitForm() {
      const that = this
      this.buttonLoading = true
      const isNull = this.parameterList.find(item => !item.paramName)
      if (isNull) {
        this.buttonLoading = false
        return this.$message.error('参数名称不能为空')
      }
      const { form } = this.$refs.baseForm
      const equipmentClassIds = (form.equipmentClassIdList || []).map(item => this._.last(item))
      const equipmentClassNames = this.equipmentClassList.filter(item => equipmentClassIds.includes(item.id))
      const customerNames = this.customerList.filter(item => (form.customerIds || []).includes(item.id))
      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          that.saveData(
            Object.assign({
              ...form,
              customerIds: (form.customerIds || []).join('^'),
              equipmentClassIds: equipmentClassIds.join('^'),
              customerNames: (customerNames || []).map(item => item.name).join('、'),
              equipmentClassNames: (equipmentClassNames || []).map(item => item.name).join('、'),
              parameterList: this.parameterList,
              equipmentClassIdList: form.equipmentClassIdList && form.equipmentClassIdList.length > 0 ? JSON.stringify(form.equipmentClassIdList) : ''
            })
          )
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },

    async saveData(form) {
      const res = await api[`${this.params.type === 'copy' ? 'add' : this.params.type}FactoryParameter`](form)
      if (res) {
        this.$router.push({ name: 'factoryParameterList' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style .li-label-style {
    color: #607fff;
  }
  .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style.active-li {
    background-color: rgba(96, 127, 255, 0.1);
    color: #607fff;
  }
}
.procedure-detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }
    }
  }

  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
      .e-btn {
        margin-bottom: 10px;
      }
    }
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.technical-table-input {
  width: 40%;
}
</style>
