var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "memberRouteSetting_container",
      style: { height: _vm.chromeHeight + "px" }
    },
    [
      _c(
        "div",
        { staticClass: "container_left" },
        [
          _c("h2", [_vm._v("对象列表")]),
          _vm._l(_vm.userList, function(item, index) {
            return _c(
              "div",
              {
                class: {
                  userItem: true,
                  active: index === _vm.curtenIndex
                },
                on: {
                  click: function($event) {
                    return _vm.viewCheckItem(index)
                  }
                }
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "container_right" },
        [
          _c(
            "div",
            { staticClass: "all-btns" },
            [
              _c(
                "el-button",
                {
                  staticClass: "role-btn",
                  attrs: {
                    loading: _vm.loading,
                    size: "small",
                    type: "primary"
                  },
                  on: { click: _vm.saveRole }
                },
                [_vm._v("保存")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.columnsList, height: "700", border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { width: "160", prop: "name", label: "字段名称" }
              }),
              _c("el-table-column", {
                attrs: { width: "110", label: "字段类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", disabled: "" },
                            model: {
                              value: scope.row.type,
                              callback: function($$v) {
                                _vm.$set(scope.row, "type", $$v)
                              },
                              expression: "scope.row.type"
                            }
                          },
                          _vm._l(_vm.typeOptions, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { width: "130", prop: "typeFlag", label: "类型标识" }
              }),
              _c("el-table-column", {
                attrs: { width: "150", label: "字段别名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.newName,
                            callback: function($$v) {
                              _vm.$set(scope.row, "newName", $$v)
                            },
                            expression: "scope.row.newName"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { width: "170", label: "显示/隐藏" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: scope.row.showFlag,
                              callback: function($$v) {
                                _vm.$set(scope.row, "showFlag", $$v)
                              },
                              expression: "scope.row.showFlag"
                            }
                          },
                          [_vm._v("显示")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: scope.row.showFlag,
                              callback: function($$v) {
                                _vm.$set(scope.row, "showFlag", $$v)
                              },
                              expression: "scope.row.showFlag"
                            }
                          },
                          [_vm._v("隐藏")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { width: "150", label: "是否必填" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: scope.row.required,
                              callback: function($$v) {
                                _vm.$set(scope.row, "required", $$v)
                              },
                              expression: "scope.row.required"
                            }
                          },
                          [_vm._v("是")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: scope.row.required,
                              callback: function($$v) {
                                _vm.$set(scope.row, "required", $$v)
                              },
                              expression: "scope.row.required"
                            }
                          },
                          [_vm._v("否")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { width: "160", label: "格式正则验证" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.pattern,
                            callback: function($$v) {
                              _vm.$set(scope.row, "pattern", $$v)
                            },
                            expression: "scope.row.pattern"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { width: "180", label: "格式错误提示" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.errMessage,
                            callback: function($$v) {
                              _vm.$set(scope.row, "errMessage", $$v)
                            },
                            expression: "scope.row.errMessage"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }