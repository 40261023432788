import { columns, zColumns } from './columns'
import { transformColumnsToForm } from '@/utils'
import { quanlityInfoType } from '@/config/options.sales'

export const formList = transformColumnsToForm(columns)
export const formLists = transformColumnsToForm(zColumns)

export const searchFormList = [
  {
    colSpan: 6,
    key: 'model',
    tagName: 'el-input',
    attrs: {
      placeholder: '型号'
    },
    props: {
      clearable: true
    },
    children: []
  },
  {
    key: 'customer_id',
    tagName: 'el-cascader',
    colSpan: 6,
    props: {
      placeholder: '客户/现场',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  },
  {
    colSpan: 6,
    key: 'type',
    tagName: 'el-select',
    props: {
      clearable: true,
      placeholder: '质保状态'
    },
    isDelete: true,
    children: [{
      tagName: 'el-option',
      props: {
        label: 'name',
        value: 'id'
      },
      options: quanlityInfoType
    }]
  }
]

