import { columns } from './columns'
import _ from 'lodash'
import { transformColumnsToForm } from '@/utils'

const newColumns = _.cloneDeep(columns)

const formList = transformColumnsToForm(newColumns)
formList.splice(2, 0, {
    label: '附件信息',
    key: 'attachments',
    tagName: 'div',
    required: true,
    colSpan: 24,
    children: [{
      customSlots: ['drawing']
    }]
  })

export default formList
