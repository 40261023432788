<template>
  <div class="unit-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="Templates">配置模板</el-radio-button>
      <el-radio-button label="Parameter">配置参数</el-radio-button>
    </el-radio-group>
    <div class="top">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        :search-title="searchTitle[params.radio]"
        :radio="params.radio"
        :tags="!exportSelectionIds.length ? functionHeader[`${params.radio}Tags`] : []"
        :columns-key="columnsKey"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="exportSelectionIds.length">
          <el-button type="primary" size="small" @click="allMore('delBatchTemplates')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :columns="columns[params.radio]"
        :height="height"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="templateName" slot-scope="{ row }" type="text" @click="viewTemplates(row)">{{ row.templateName }}</el-button>
        <div slot="action" slot-scope="{ row }">
          <template>
            <el-button type="text" @click="copy(row)">复制</el-button>
            <el-divider direction="vertical" />
            <el-button type="text" @click="modify(row)">修改</el-button>
            <el-divider direction="vertical" />
            <DelPopover @onOk="callback => del(row, callback)" />
          </template>
        </div>
      </MTable>
      <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]" />
      </MDialog>
    </div>
  </div>
</template>

<script>
import DelPopover from '@/components/DelPopover/index'
import FunctionHeader from '@/components/FunctionHeader/index'
import { Templates, Parameter } from './columns'
import formList from './form-list'
import batchExportFront from '@/mixin/batch-export-front'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/facility/configuration'

export default {
  name: 'ConfigurationParameter',
  components: { DelPopover, FunctionHeader },
  mixins: [batchExportFront, conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'Templates'
      },
      columns: {
        Templates,
        Parameter
      },
      selectionData: [],
      searchKeyword: true,
      searchTitle: {
        Templates: '请输入模板名称',
        Parameter: '请输入配置参数'
      },
      functionHeader: {
        searchVal: '',
        TemplatesTags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增模板',
            on: {
              click: this.addTemplates
            }
          }
        ],
        ParameterTags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增配置',
            on: {
              click: this.add
            }
          }
        ]
      },
      visible: false,
      currentType: 'add',
      formList,
      formData: {},
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 242
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  methods: {
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    allMore(command) {
      const functions = {
        delBatchTemplates: () => {
          this.delBatchTemplates()
        }
      }
      functions[command]()
    },

    radioChange() {
      this.exportSelectionIds = []
      this.$refs.mTable.tableRefs().clearSelection()
      this.$refs.mTable.setTableData(true)
    },

    searchTable() {},

    addTemplates() {
      this.$router.push({ name: 'addConfigurationParameter', query: { type: 'add' }})
    },

    copy(row) {
      if (this.params.radio === 'Templates') {
        this.$router.push({ name: 'addConfigurationParameter', query: { id: row.id, type: 'copy' }})
      } else {
        this.modifyOrCopy(row, 'copy')
      }
    },

    modify(row) {
      if (this.params.radio === 'Templates') {
        this.$router.push({ name: 'addConfigurationParameter', query: { id: row.id, type: 'modify' }})
      } else {
        this.modifyOrCopy(row, 'modify')
      }
    },

    viewTemplates(row) {
      this.$router.push({ name: 'addConfigurationParameter', query: { id: row.id, type: 'view' }})
    },

    createSetData(row) {
      return { ...row }
    },

    createResetData(row) {
      return Object.assign(this.createSetData(row), {
        id: row.id
      })
    },

    delBatchTemplates(row) {
      this.$confirm('确定要删除选择模板吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        if (this.params.radio === 'Templates') {
          const res = await api.delBatchTemplates({ ids: this.exportSelectionIds.join('^') })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        } else {
          const res = await api.delBatchParameter({ ids: this.exportSelectionIds.join('^') })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 10px;
}
</style>
