
export const columns = [
  {
    prop: 'name',
    label: '模板名称'
  },
  {
    prop: 'levelName',
    label: '保养级别'
  },
  {
    prop: 'cycleType',
    label: '周期类型'
  },
  {
    prop: 'lifeCycle',
    label: '周期/天'
  },
  {
    prop: 'toleranceRange',
    label: '容差范围/天'
  },
  {
    prop: 'reminderTime',
    label: '提醒提前期'
  },
  {
    prop: 'remark',
    label: '备注'
  },
  {
    prop: 'operatorName',
    label: '执行人'
  }
]

