import _ from 'lodash'
import { maintenanceLevel, maintenanceCycleType } from '@/config/options.sales'
import columns from './columns'
import { transformColumnsToForm } from '@/utils'

const wrapByFormItem = (data) => {
  const newData = []
  data.forEach(item => {
    newData.push({
      tagName: 'el-form-item',
      props: {
        prop: item.key
      },
      style: { margin: 0, position: 'relative' },
      children: [item]
    })
  })
  return newData
}
const numberAndDateFormList = (key1, key2) => [
  {
    key: key1,
    tagName: 'el-input-number',
    props: { controlsPosition: 'right', min: 0, placeholder: '请输入', required: true },
    children: []
  },
  {
    key: key2,
    tagName: 'el-select',
    defaultValue: 0,
    props: { clearable: false, filterable: false },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          {
            id: 0,
            name: '小时'
          },
          {
            id: 1,
            name: '天'
          }
        ]
      }
    ]
  }
]

const newColumns = _.cloneDeep(columns.PlanMaintenanceTemplate)

export const formList = {
  FacilityMaintenancePlan: [
    ...transformColumnsToForm(columns.FacilityMaintenancePlan),
    {
      index: 8,
      label: '提醒提前期',
      tagName: 'div',
      required: true,
      colSpan: 8,
      key: 'reminderTime',
      domProps: { className: 'date-number' },
      children: wrapByFormItem(numberAndDateFormList('reminderTime', 'reminderTimeType'))
    },
    {
      index: 9,
      key: 'remark',
      label: '备注',
      tagName: 'el-input',
      required: false,
      colSpan: 8,
      props: { type: 'textarea' }
    },
    {
      index: 10,
      key: 'operatorIds',
      label: '执行人',
      required: true,
      tagName: 'el-select',
      colSpan: 8,
      children: [{
        tagName: 'el-option',
        innerValue: (val) => {
          return `${val['jobNumber']} - ${val['name']}`
        },
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }],
      props: { multiple: true, filterable: true }
    },
    {
      index: 11,
      key: 'salesOrderNumber',
      label: '销售订单号',
      tagName: 'el-input',
      required: true,
      colSpan: 8,
      rules: [
        {
          required: true,
          pattern: /^XSDD\d{6}$/,
          message: '格式错误，应以XSDD开头，后6位数字',
          trigger: 'blur'
        }
      ]
    },
  ],
  PlanMaintenanceTemplate: [
    ...transformColumnsToForm(newColumns),
    {
      index: 5,
      key: 'toleranceRange',
      label: '容差范围/天',
      tagName: 'el-input-number',
      required: false,
      colSpan: 8,
      props: { controlsPosition: 'right', min: 0, placeholder: '请输入容差范围' }
    },
    {
      index: 6,
      label: '提醒提前期',
      tagName: 'div',
      required: false,
      colSpan: 8,
      key: 'reminderTime',
      domProps: { className: 'date-number' },
      children: wrapByFormItem(numberAndDateFormList('reminderTime', 'reminderTimeType'))
    },
    {
      index: 7,
      key: 'remark',
      label: '备注',
      tagName: 'el-input',
      required: false,
      colSpan: 8,
      props: { type: 'textarea' }
    },
    {
      index: 8,
      key: 'operatorIds',
      label: '执行人',
      tagName: 'el-select',
      required: false,
      colSpan: 16,
      children: [{
        tagName: 'el-option',
        innerValue: (val) => {
          return `${val['jobNumber']} - ${val['name']}`
        },
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }],
      props: { multiple: true, filterable: true }
    }
  ]
}

export const searchFormList = {
  FacilityMaintenancePlan: [
    {
      colSpan: 6,
      key: 'cycleType',
      tagName: 'el-select',
      props: {
        placeholder: '执行逻辑'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: maintenanceCycleType
        }
      ]
    },
    {
      colSpan: 6,
      key: 'customerName',
      tagName: 'el-cascader',
      props: {
        placeholder: '客户/现场',
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    },
    {
      colSpan: 6,
      key: 'level',
      tagName: 'el-select',
      props: {
        placeholder: '保养级别'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: maintenanceLevel
        }
      ]
    },
    {
      colSpan: 6,
      key: 'lifeCycle',
      tagName: 'el-input-number',
      props: { controlsPosition: 'right', min: 0, placeholder: '周期/天' }
    },
    {
      colSpan: 6,
      key: 'nextExecuteTime',
      tagName: 'el-date-picker',
      props: {
        rangeSeparator: '至',
        startPlaceholder: '下次执行时间',
        endPlaceholder: '下次执行时间',
        align: 'center',
        type: 'daterange',
        pickerOptions: []
      }
    }
  ],
  PlanMaintenanceTemplate: [
    {
      colSpan: 6,
      key: 'level',
      tagName: 'el-select',
      props: {
        placeholder: '保养级别'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: maintenanceLevel
        }
      ]
    },
    {
      colSpan: 6,
      key: 'lifeCycle',
      tagName: 'el-input-number',
      props: { controlsPosition: 'right', min: 0, placeholder: '周期/天' }
    }
  ]
}

