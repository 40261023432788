import columns from './columns'
import { transformColumnsToForm } from '@/utils'

export const formList = {
  PointCheckItem: transformColumnsToForm(columns.PointCheckItem),
  PointCheckType: transformColumnsToForm(columns.PointCheckType),
  Inspection: transformColumnsToForm(columns.Inspection)
}
export const searchFormList = {
  PointCheckType: [
    {
      key: 'equipmentClassIds',
      tagName: 'el-cascader',
      colSpan: 6,
      props: {
        placeholder: '请选择设备类',
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true,
          multiple: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
  ],
  Inspection: [
    {
      key: 'equipmentClassIds',
      tagName: 'el-cascader',
      colSpan: 6,
      props: {
        placeholder: '请选择设备类',
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true,
          multiple: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
  ]
}
