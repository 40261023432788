
export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'templateName',
    label: '模板名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'equipmentClassNames',
    label: '适用设备分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  {
    prop: 'customerNames',
    label: '适用客户',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  }
]

