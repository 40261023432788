var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-materials-wrapper" },
    [
      _c(
        "MDialog",
        {
          attrs: { title: _vm.materialsMessage[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "col-span": 6,
                "form-data": _vm.formData,
                "form-list": _vm.formList,
                "custom-rules": _vm.rules,
                disabled: _vm.formDisabled
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "drawing" }, slot: "drawing" },
                [
                  _c(
                    "el-upload",
                    _vm._b(
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          accept: ".png,.jpg",
                          "before-upload": _vm.beforeAvatarUpload,
                          "on-success": _vm.handleAvatarSuccess
                        }
                      },
                      "el-upload",
                      _vm.options,
                      false
                    ),
                    [
                      _vm.equipmentImageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.equipmentImageUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "package" }, slot: "package" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      !_vm.formDisabled
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectEquipment("package")
                                    }
                                  }
                                },
                                [_vm._v("选择设备")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-top": "10px" },
                          attrs: { span: 24 }
                        },
                        [
                          _c("MTable", {
                            ref: "mPackageTable",
                            attrs: {
                              height: 300,
                              "show-page": false,
                              data: _vm.form.equipmentList,
                              columns: _vm.packageColumns
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "index",
                                fn: function(ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "action",
                                fn: function(ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.delPart($index)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("select-setting", {
            ref: "selectSetting",
            attrs: {
              visible: _vm.showSetting,
              "select-materials": _vm.selectParts
            },
            on: {
              "update:visible": function($event) {
                _vm.showSetting = $event
              },
              submitForm: _vm.submitSetting
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }