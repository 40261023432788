/** write by luomingzhi */
import { equipmentRepairType } from '@/config/options.config'

export const repairColumns = [
  {
    prop: 'equipmentCode',
    label: '设备编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { disabled: true }}
  },
  {
    prop: 'type',
    label: '维保类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select', options: equipmentRepairType
    }
  },
  {
    prop: 'submitWorkerName',
    label: '提交人',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select', key: 'submitWorkerId', options: []
    }
  },
  {
    prop: 'submitTime',
    label: '提交时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'submitShiftName',
    label: '提交人班次',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select', key: 'submitShiftId', options: []
    }
  },
  {
    prop: 'status',
    label: '维保状态',
    slotName: 'status',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'repairWorkerName',
    label: '维保人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'repairBeginTime',
    label: '维保开始时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'repairEndTime',
    label: '维保结束时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]

export const balanceColumns = [
  {
    prop: 'A',
    label: '物料',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'B',
    label: '结余数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'C',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]

export const feedingColumns = [
  {
    prop: 'A',
    label: '物料',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'B',
    label: '投料数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'C',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'D',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'E',
    label: '班次',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'F',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]

export const scrapColumns = [
  {
    prop: 'A',
    label: '物料',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'B',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'C',
    label: '报废数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'C',
    label: '报废原因',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'R',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'D',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'E',
    label: '班次',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'F',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  }
]

export const outPutColumns = [
  {
    prop: 'A',
    label: '物料',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'B',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'C',
    label: '报工数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'D',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'R',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'F',
    label: '班次',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'G',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]
