import { operationType } from '@/config/options.config'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '辅材编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    width: 140,
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'className',
    label: '辅材分类',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {  index: 2, tagName: 'el-input', colSpan: 8  }
  },
  {
    prop: 'sn',
    label: 'SN',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {  index: 3, tagName: 'el-input', colSpan: 8  }
  },
  {
    prop: 'model',
    label: '型号',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {  index: 4, tagName: 'el-input', colSpan: 8  }
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status',
    form: { index: 5, options: operationType },
    width: 180
  },
  {
    prop: 'customerName',
    label: '客户',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 6,
      tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 8,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.mulChange(val) }
      }
    }
  },
  {
    prop: 'addUpUserTime',
    label: '累计使用时长',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {  index: 7, tagName: 'el-input', colSpan: 8  }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 8,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' },
      colSpan: 8 
    }
  }
]

export const auxiliaryColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'status',
    label: '辅材状态',
    sortable: false,
    hiddenSearch: true,
    width: 140,
    slotName: 'status'
  },
  {
    prop: 'code',
    label: '辅材编号',
    sortable: false,
    hiddenSearch: true,
    width: 140,
    slotName: 'code',
    form: { index: 2, tagName: 'el-input', colSpan: 12 }
  },
  {
    prop: 'name',
    label: '辅材名称',
    sortable: false,
    hiddenSearch: true,
    width: 140,
    form: { index: 3, tagName: 'el-input', colSpan: 12 }
  },
  {
    prop: 'sn',
    label: 'SN',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 4, tagName: 'el-input', colSpan: 12 }
  },
  {
    prop: 'model',
    label: '型号',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 5, tagName: 'el-input', colSpan: 12 }
  },
  {
    prop: 'className',
    label: '辅材分类',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {
      index: 6,
      tagName: 'el-select',
      key: 'classId',
      options: [],
      colSpan: 12,
    }
  },
  {
    prop: 'addUpUserTime',
    label: '累计使用时长',
    sortable: false,
    hiddenSearch: true,
    width: 180,
  },
  {
    prop: 'customerName',
    label: '关联客户',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 9,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' },
      colSpan: 12
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]

export const auxiliaryRecordColumns = [
  {
    prop: 'operationType',
    label: '操作类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'operationType'
  },
  {
    prop: 'operationTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'operatorName',
    label: '操作人',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'singleUserTime',
    label: '单次使用时长',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'addUpUserTime',
    label: '累计使用时长',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'equipmentName',
    label: '关联设备',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerName',
    label: '关联客户',
    sortable: false,
    hiddenSearch: true
  }
]

export const auxiliaryInstructionRecordColumns = [
  {
    prop: 'billCode',
    label: '配置单号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'inTime',
    label: '运入时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'inOperatorName',
    label: '运入操作人',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'outTime',
    label: '运出时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'outOperatorName',
    label: '运出操作人',
    sortable: false,
    hiddenSearch: true,
  },
  
  {
    slotName: 'action',
    label: '配置明细',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

export const configurationColumns= [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'instruction',
    label: '配置说明',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'shipOutMatch',
    label: '运出匹配',
    sortable: false,
    hiddenSearch: true,
    slotName: 'shipOutMatch'
  },
  {
    prop: 'shipInMatch',
    label: '运入匹配',
    sortable: false,
    hiddenSearch: true,
    slotName: 'shipInMatch'
  }
]