var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      attrs: { width: "1000px", "append-to-body": true, title: "套用模板" },
      on: { onOk: _vm.submitForm, fullScreen: _vm.fullScreenChange },
      model: {
        value: _vm.modalShow,
        callback: function($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow"
      }
    },
    [
      _c(
        "div",
        { staticClass: "materials-info" },
        [
          _c("MTable", {
            ref: "mTable",
            attrs: {
              "highlight-current-row": _vm.single,
              columns: _vm.columns,
              height: _vm.height,
              "set-data-method": _vm.getTableData,
              "columns-setting": false
            },
            on: {
              "selection-change": _vm.selectionChange,
              "current-change": _vm.selectionChange,
              "row-click": _vm.handleRowClick
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "isEnabled",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "is-enabled" }, [
                    _c("div", {
                      style: {
                        background: row.isEnabled ? "#24CAD7" : "#FFCC78"
                      }
                    }),
                    _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }