<template>
  <div class="scrap-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="FacilityMaintenancePlan">保养计划</el-radio-button>
      <el-radio-button label="PlanMaintenanceTemplate">计划模板</el-radio-button>
    </el-radio-group>
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList[params.radio]" @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        :search-title="functionHeader.searchTitle"
        :tags="!selectionData.length ? functionHeader.tags : []"
        :radio="params.radio"
        @search="$refs.mTable.setTableData(true)"
      >
        <div v-if="params.radio === 'FacilityMaintenancePlan' && selectionData.length" slot="all">
          <el-button v-if="permission(params.radio + 'Del')" type="primary" size="small" @click="allMore('allDel')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :columns="columns[params.radio]"
        :height="height"
        :selectShowFalse="params.radio === 'FacilityMaintenancePlan'"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <el-button slot="code" slot-scope="{ row }" style="white-space: pre-wrap" type="text" @click="viewDetails(row)">{{ row.code }}</el-button>
        <el-button slot="name" slot-scope="{ row }" style="white-space: pre-wrap" type="text" @click="viewDetail(row)">{{ row.name }}</el-button>
        <div slot="customerSiteIdList" slot-scope="{ row }">
          {{ row.customerName }}
        </div>
        <div slot="toleranceRange" slot-scope="{ row }">
          {{ row.toleranceRange ? '±' + row.toleranceRange : '' }}
        </div>
        <div slot="level" slot-scope="{ row }">
          {{ row.level ? maintenanceLevel[row.level - 1].name : '-' }}
        </div>
        <div slot="cycleType" slot-scope="{ row }">
          {{ row.cycleType || row.cycleType === 0 ? maintenanceCycleType[row.cycleType].name : '-' }}
        </div>
        <div slot="lifeCycle" slot-scope="{ row }">
          {{ row.lifeCycle || row.lifeCycle === 0 ? row.lifeCycle : '-' }}
        </div>
        <div slot="action" slot-scope="{ row }">
          <template v-if="params.radio === 'FacilityMaintenancePlan'">
            <el-button v-if="permission('Copy')" type="text" @click="copys(row)">复制</el-button>
            <el-divider v-if="permission('Copy')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifys(row, 'modify')">修改</el-button>
            <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Del')" type="text" @click="desPlan(row)">删除</el-button>
          </template>
          <template v-else>
            <el-button v-if="permission('User')" type="text" @click="userPlan(row)">使用</el-button>
            <el-divider v-if="permission('User')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifys(row, 'modify')">修改</el-button>
            <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Del')" type="text" @click="del(row)">删除</el-button>
          </template>
        </div>
      </MTable>
    </div>
    <MDialog v-model="delVisible" :append-to-body="true" title="删除计划" width="500px" @onOk="submitFormDel">
      <div>
        <div style="margin-bottom: 10px">是否同步删除相关联的待执行任务工单？</div>
        <el-radio-group v-model="isDel">
          <el-radio :label="1">删除</el-radio>
          <el-radio :label="0">保留</el-radio>
        </el-radio-group>
      </div>
    </MDialog>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import DelPopover from '@/components/DelPopover/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader/index'
import api from '@/api/xiewei/facility/maintenance-template'
import keApi from '@/api/information/unit'
import columns, { getFunctionList } from './columns'
import { searchFormList } from './form-list'
import TableDialog from '@/components/TableDialog/TableDialog'
import { setTreeData } from '@/utils'
import { maintenanceLevel, maintenanceCycleType } from '@/config/options.sales'
import { getUUid } from '@/utils'

export default {
  name: 'FacilityMaintenanceList',
  components: { TableDialog, DelPopover, SearchForm, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      maintenanceLevel,
      maintenanceCycleType,
      params: {
        radio: 'FacilityMaintenancePlan'
      },
      columns,
      searchFormList,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        searchTitle: '保养计划编号/名称',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addAll
            }
          }
        ]
      },
      formData: {},
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制'
      },
      currentType: 'add',
      visible: false,
      formOptions: {
        equipmentClass: []
      },
      getTableDataParams: {
        planTemplateSign: 0
      },
      searchKeyword: true,
      selectionData: [],
      delVisible: false,
      isDel: 1,
      selectId: '',
      isMul: false,
      extraParams: [],
      allCustomer: [],
      total:0,
      selectionDataList:[]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.getAllCustomer()
  },
  methods: {
    allMore(command) {
      const functions = {
        allDel: () => {
          this.allDel()
        }
      }
      functions[command]()
    },
    selectionChange(data) {
      this.selectionData = data.map(item => item.id)
      this.selectionDataList = data
    },
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },

    // 批量删除
    async allDel() {
      // this.delVisible = true
      this.isDel = 1
      this.isMul = true
      this.submitFormDel()
    },
    userPlan(row) {
      this.$router.push({ name: 'addFacilityMaintenance', query: { type: 'add', templateId: row.id }})
    },
    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'facilityMaintenanceTemplateDetail',
        query: { id: row.id }
      })
    },
    viewDetails(row) {
      this.$router.push({
        name: 'facilityMaintenanceDetail',
        query: { id: row.id }
      })
    },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.searchFormList.FacilityMaintenancePlan[1].props.options = setTreeData(data)
      }
    },
    addAll() {
      if (this.params.radio === 'FacilityMaintenancePlan') {
        this.$router.push({ name: 'addFacilityMaintenance', query: { type: 'add' }})
      } else {
        this.$router.push({ name: 'addFacilityMaintenanceTemplate', query: { type: 'add' }})
      }
    },
    radioChange(label) {
      this.functionHeader.searchTitle = label === 'FacilityMaintenancePlan' ? '保养计划编号/名称' : '模板名称'
      this.searchFormatData = []
      this.getTableDataParams = {}
      this.extraParams =
        label === 'FacilityMaintenancePlan'
          ? []
          : [
            {
              id: getUUid(),
              fieldName: 'planTemplateSign',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq'
            }
          ]
      if (this.$refs.searchForm) this.$refs.searchForm.reset()
      else this.$refs.mTable.setTableData(true)
    },
    searchTable(data) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('customerName')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
        this.searchFormatData.splice(hasIndex, 1)
        const id = data[hasIndex].fieldValue
        const arr = this.allCustomer.filter(item => item.id === this._.first(id))
        const list = this.allCustomer.filter(item => item.id === this._.last(id))
        this.searchFormatData = [
          ...this.searchFormatData,
          {
            id: getUUid(),
            fieldName: list[0].isCustomer === 1 ? 'customerSiteId' : 'customerId',
            fieldType: 'number',
            fieldValue: list[0].isCustomer === 1 ? list[0].id : arr[0].id,
            operator: 'eq'
          }
        ]
      }
      this.$refs.mTable.setTableData(true)
    },
    modifys(row) {
      if (this.params.radio === 'FacilityMaintenancePlan') {
        this.$router.push({ name: 'addFacilityMaintenance', query: { type: 'modify', id: row.id }})
      } else {
        this.$router.push({ name: 'addFacilityMaintenanceTemplate', query: { type: 'modify', id: row.id }})
      }
    },
    copys(row) {
      this.$router.push({ name: 'addFacilityMaintenance', query: { type: 'copy', id: row.id }})
    },
    desPlan(node) {
      // this.delVisible = true
      // this.isDel = 1
      this.isMul = false
      this.selectId = node.id
      this.submitFormDel()
    },
    async submitFormDel() {
      if (this.isMul) {
        const res = await api.delBatchFacilityMaintenancePlan({
          ids: this.selectionData.join('^'),
          isDelete: 0
        })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
        return false
      }
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api[`delFacilityMaintenancePlan`]({ id: this.selectId, isDelete: 0 })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;
  .bottom {
    padding: 10px;
  }
  .m-radio-group {
    margin-left: 10px;
  }
}
.role-tags {
  margin: 5px;
  border-color: #dde3eb;
  color: #9597ae;
}
</style>
