<template>
  <div class="unit-wrap">
    <div class="top">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入模板名称"
        :tags="functionHeader.tags"
        :columns-key="columnsKey"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :selectShowFalse="false"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="templateName" slot-scope="{ row }" type="text" @click="viewTechnical(row)">{{ row.templateName }}</el-button>
        <div slot="action" slot-scope="{ row }">
          <template>
            <el-button type="text" @click="copyTechnical(row)">复制</el-button>
            <el-divider direction="vertical" />
            <el-button type="text" @click="modifyTechnical(row)">修改</el-button>
            <el-divider direction="vertical" />
            <DelPopover @onOk="callback => del(row, callback)" />
          </template>
        </div>
      </MTable>
    </div>
  </div>
</template>

<script>
import DelPopover from '@/components/DelPopover/index'
import FunctionHeader from '@/components/FunctionHeader/index'
import { columns } from './columns'
import { formList, searchFormList } from './form-list'
import batchExportFront from '@/mixin/batch-export-front'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/facility/factory'

export default {
  name: 'FactoryParameterList',
  components: { DelPopover, FunctionHeader },
  mixins: [batchExportFront, conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'FactoryParameter'
      },
      selectionData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增模板',
            on: {
              click: this.addTechnical
            }
          }
        ]
      },
      visible: false,
      columns,
      formList,
      formData: {},
      searchFormList,
      searchFieldName: 'templateName',
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 + 40
    }
  },
  methods: {
    allMore(command) {
      const functions = {
        delBatchTechnical: () => {
          this.delBatchTechnical()
        }
      }
      functions[command]()
    },

    searchTable() {},

    addTechnical() {
      this.$router.push({ name: 'factoryParameterAdd', query: { type: 'add' }})
    },

    copyTechnical(row) {
      this.$router.push({ name: 'factoryParameterAdd', query: { id: row.id, type: 'copy' }})
    },

    modifyTechnical(row) {
      this.$router.push({ name: 'factoryParameterAdd', query: { id: row.id, type: 'modify' }})
    },

    viewTechnical(row) {
      this.$router.push({ name: 'factoryParameterAdd', query: { id: row.id, type: 'view' }})
    }
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 0 10px 10px 10px;
}
</style>
