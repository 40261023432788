var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-plan-detail-wrapper" }, [
    _c("div", { staticClass: "inspection-plan-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "inspectionBillsList" })
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      ),
      _c("div", { staticClass: "right" })
    ]),
    _c(
      "div",
      { staticClass: "inspection-plan-detail-body" },
      [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "el-row",
                { staticClass: "view-list", attrs: { gutter: 24 } },
                [
                  _vm._l(_vm.columns, function(item, index) {
                    return [
                      _vm.baseFormData[item.prop] ||
                      _vm.baseFormData[item.prop] === 0
                        ? _c(
                            "el-col",
                            {
                              key: item.prop,
                              style: { marginBottom: index !== 4 ? "20px" : 0 },
                              attrs: { span: 6 }
                            },
                            [
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(item.label) + "：")
                              ]),
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(_vm.getValue(item)))
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("巡检项")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c("MTable", {
                ref: "pointCheckTable",
                attrs: {
                  height:
                    _vm.$store.state.app.contentHeight -
                    54 -
                    100 -
                    50 -
                    60 -
                    70 -
                    192,
                  columns: _vm.pointCheckItemView,
                  "columns-setting": false,
                  "show-page": false,
                  data: _vm.checkItemList
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "result",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", { staticClass: "is-enabled" }, [
                        _c("div", {
                          style: {
                            background:
                              row.result === 1
                                ? "#24CAD7"
                                : row.result === 0
                                ? "#FFCC78"
                                : ""
                          }
                        }),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              row.result === 1
                                ? "正常"
                                : row.result === 0
                                ? "异常"
                                : ""
                            )
                          )
                        ])
                      ])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewCheckDetail(row, $index)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c(
          "MDialog",
          {
            attrs: { title: "巡检结果详情", "show-footer": false },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          },
          [
            _c("div", { staticClass: "result-wrapper" }, [
              _c("div", [
                _c("span", [_vm._v("检查项：")]),
                _c("span", [_vm._v(_vm._s(_vm.currentData.name))])
              ]),
              _c("div", [
                _c("span", [_vm._v("检查标准：")]),
                _c("span", [_vm._v(_vm._s(_vm.currentData.standard))])
              ]),
              _vm.currentData.sketchMap
                ? _c("div", [
                    _c("span", [_vm._v("操作图：")]),
                    _c(
                      "div",
                      { attrs: { slot: "sketchMap" }, slot: "sketchMap" },
                      _vm._l(_vm.formatArr(_vm.currentData.sketchMap), function(
                        item
                      ) {
                        return _c("el-image", {
                          key: item.id,
                          staticClass: "table-row-img",
                          staticStyle: { "padding-left": "10px" },
                          attrs: {
                            src: item.url,
                            "preview-src-list": [item.url]
                          }
                        })
                      }),
                      1
                    )
                  ])
                : _vm._e(),
              _vm.currentData.result === 1 || _vm.currentData.result === 0
                ? _c("div", [
                    _c("span", [_vm._v("巡检结果：")]),
                    _c("span", [
                      _c("div", { staticClass: "is-enabled" }, [
                        _c("div", {
                          style: {
                            background:
                              _vm.currentData.result === 1
                                ? "#24CAD7"
                                : _vm.currentData.result === 0
                                ? "#FFCC78"
                                : ""
                          }
                        }),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.currentData.result === 1
                                ? "正常"
                                : _vm.currentData.result === 0
                                ? "异常"
                                : ""
                            )
                          )
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.currentData.remark
                ? _c("div", [
                    _c("span", [_vm._v("备注：")]),
                    _c("span", [_vm._v(_vm._s(_vm.currentData.remark))])
                  ])
                : _vm._e(),
              _vm.currentData.sceneMap
                ? _c("div", [
                    _c("span", [_vm._v("图片：")]),
                    _c(
                      "div",
                      { attrs: { slot: "sketchMap" }, slot: "sketchMap" },
                      _vm._l(_vm.formatArr(_vm.currentData.sceneMap), function(
                        item
                      ) {
                        return _c("el-image", {
                          key: item.id,
                          staticClass: "table-row-img",
                          staticStyle: { "padding-left": "10px" },
                          attrs: {
                            src: item.url,
                            "preview-src-list": [item.url]
                          }
                        })
                      }),
                      1
                    )
                  ])
                : _vm._e()
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }