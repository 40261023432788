import { emergencyStatus } from '@/config/options.config'

export const columns = [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'applicationNumber',
    label: '申请单号',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180,
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'creator',
    label: '申请人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status',
    form: { index: 6, tagName: 'el-input', required: false, defaultValue: '待提交', props: { readonly: true }}
  },
  {
    prop: 'faultNumber',
    label: '关联报修单',
    sortable: false,
    hiddenSearch: true,
    width: 180
    // form: { index: 3, tagName: 'el-select',key: 'faultId' }
  },
  {
    prop: 'equipmentName',
    label: '相关设备',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'emergencyLevel',
    label: '紧急程度',
    sortable: false,
    hiddenSearch: true,
    slotName: 'emergencyLevel',
    width: 180,
    form: {
      index: 1,
      tagName: 'el-select',
      options: emergencyStatus,
      defaultValue: 0,
      required: false
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    width: 180,
    hiddenSearch: true,
    sortable: false
  }
]
// 添加备件表格
export const accountColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '备件编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '备件名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'drawing',
    label: '备件图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing'
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right',
    width: 150,
    slotName: 'quantity'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]
