// 列表

export const columnsAll = [
  // {
  //   type: 'selection',
  //   width: 50,
  //   fixed: 'left'
  // },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '处理状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 100
  },
  {
    prop: 'faultNumber',
    label: '报修单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 160
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'equipmentCode',
    label: '设备编码',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'stationName',
    label: '关联工位',
    width: 160,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reporter',
    label: '报修人',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'reporterTel',
    label: '联系方式',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'createTime',
    label: '报修时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'reportTime',
    label: '故障发生时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'faultDesc',
    label: '故障描述',
    hiddenSearch: true,
    sortable: false,
    slotName: 'faultDesc',
    width: 200
  },
  {
    prop: 'emergenceDegree',
    label: '紧急程度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'faultPicture',
    label: '故障图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'faultPicture',
    width: 150
  },
  {
    prop: 'repairNumber',
    label: '维修单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairMan',
    label: '维修人',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    width: 78,
    fixed: 'right'
  }
]

