export default [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'stationNumber',
    label: '工位编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'stationName',
    label: '工位名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'callTime',
    label: '呼叫时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 170
  },
  {
    prop: 'callName',
    label: '发起人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'status',
    label: '处理状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status'
  },
  {
    prop: 'image',
    label: '处理图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'handlePicture'
  },
  {
    prop: 'callTypeName',
    label: '呼叫类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'emergencyDegree',
    label: '紧急程度',
    hiddenSearch: true,
    slotName: 'emergencyDegree',
    sortable: false
  },
  {
    prop: 'callDeptName',
    label: '责任部门',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'callRemark',
    label: '呼叫备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'updaterName',
    label: '处理人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'handleTime',
    label: '处理时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 170
  },
  {
    prop: 'endTime',
    label: '结束时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 170
  },
  {
    prop: 'handleRemark',
    label: '处理备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'produceOrderNumber',
    label: '订单编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 50
  }
]
