<template>
  <div class="fault-wrap">
    <div class="top">
      <SearchForms ref="searchForms" @resetData="resetData" @searchData="searchTable" />
    </div>
    <el-divider class="e-divider" />
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      search-title="输入报修单号/维修单号"
      :tags="functionHeader.tags"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      :import-name="functionHeader.importName"
      style="padding: 10px 0 10px 0"
      @search="$refs.mTable.setTableData(true)"
    >
      <div slot="all" class="all-select">
        <div class="checkAll">
          <el-checkbox v-model="checkeds" :disabled="checkeds" :indeterminate="isIndeterminate" @change="selectCheck">全选</el-checkbox>
        </div>
        <div v-for="(item, index) in allStatus" :key="item.value" class="all-li" :class="item.checked ? 'active' : ''" @click="selectTab(item, index)">{{ item.label }}</div>
      </div>
    </FunctionHeader>
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="status" slot-scope="{ row }">
        <div class="faultStatus" :style="{ background: faultStatus[row.status-1].background, color: faultStatus[row.status-1].color }">{{ faultStatus[row.status-1].name }}</div>
      </div>
      <el-button v-if="row.repairSparePartList" slot="repairSparePartList" slot-scope="{ row }" type="text" @click="viewSpareList(row)">查看</el-button>
      <div slot="faultPicture" slot-scope="{ row }">
        <el-image
          v-if="row.faultPicture"
          :src="fixSinImg(row.faultPicture)"
          class="drawing-img"
          :preview-src-list="fixImg(row.faultPicture)"
        />
      </div>
      <div slot="faultDesc" slot-scope="{ row }">
        <div>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            :content="row.faultDesc"
          >
            <div slot="reference" class="faultDesc">{{ row.faultDesc }}</div>
          </el-popover>
        </div>
      </div>
      <div slot="repairDesc" slot-scope="{ row }">
        <div>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            :content="row.repairDesc"
          >
            <div slot="reference" class="faultDesc">{{ row.repairDesc }}</div>
          </el-popover>
        </div>
      </div>
      <div slot="produceOrderNumber" slot-scope="{ row }">
        <NewPageOpen :path="{name: 'orderDetail', query: {id: row.id}}" :text="row.produceOrderNumber" />
      </div>
      <div slot="repairContinueTime" slot-scope="{ row }">
        {{ fixTime(row.repairContinueTime) }}
      </div>
    </MTable>
    <TableDialog title="查看消耗备件" :show.sync="spareVisible" :columns="spareColumns" :table-data="spareList" :calc-height="32">
      <el-row :gutter="24" style="padding-bottom: 15px">
        <el-col :span="8">设备编号：{{ currentRow.equipmentCode }}</el-col>
        <el-col :span="8">设备名称：{{ currentRow.equipmentName }}</el-col>
      </el-row>
    </TableDialog>
  </div>
</template>
<script>
import TableDialog from '@/components/TableDialog/TableDialog'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForms from './search-forms'
import { faultStatus } from '@/config/options.config'
import api from '@/api/equipment/equipment-fault'
import sapi from '@/api/equipment/equipment'
import uapi from '@/api/sets/user/user'
import eapi from '@/api/equipment/maintenance'
import { columnsAll, spareColumns } from './columns'
import { Encrypt } from '@/utils/sercet'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import baseSetting from '@/api/sets/base-setting'
import DelPopover from '@/components/DelPopover/index'
import { getUUid } from '@/utils'

export default {
  name: 'EquipmentRepair',
  components: { TableDialog, DelPopover, NewPageOpen, FunctionHeader, SearchForms },
  data() {
    return {
      spareColumns,
      spareVisible: false,
      isIndeterminate: false,
      faultStatus,
      searchFormData: [],
      searchFormDatas: {},
      selectKeyword: '',
      functionHeader: {
        searchVal: '',
        tags: [],
        exportName: 'exportEquipmentRepair',
        exportParams: {}
      },
      columns: columnsAll,
      searchDatas: [],
      currentId: undefined,
      formOptions: {
        equipmentCodes: [],
        emergenceDegrees: [],
        reporterList: []
      },
      isFaultCheck: undefined,
      buttonText: '取消报修',
      message: '您确定要取消报修吗？',
      allStatus: [
        {
          value: 2,
          label: '待接单',
          checked: true
        },
        {
          value: 3,
          label: '待维修',
          checked: true
        },
        {
          value: 4,
          label: '维修中',
          checked: true
        },
        {
          value: 5,
          label: '待验证',
          checked: true
        },
        {
          value: 6,
          label: '已完成',
          checked: false
        }
      ],
      checkeds: true,
      currentIdxs: [2, 3, 4, 5],
      currentIdx: false,
      spareList: [],
      currentRow: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10
    }
  },
  watch: {
    checkeds(val) {
      if (val) {
        this.currentIdxs = []
        this.isIndeterminate = false
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      } else {
        this.isIndeterminate = true
      }
    }
  },
  mounted() {
    this.getUser()
    this.getSetting()
    this.getEmergenceDegree()
  },
  methods: {
    viewSpareList(row) {
      this.currentRow = row
      this.spareList = row.repairSparePartList
      this.spareVisible = true
    },
    fixTime(val) {
      if (val) return Math.floor(val / 60) + '分钟'
    },
    selectTab(val, index) {
      // 判断是否是最后一个checked
      const isCheck = this.allStatus.filter(item => item.checked)
      const isAll = this.allStatus.filter(item => !item.checked)
      this.allStatus[index].checked = !this.allStatus[index].checked
      if (this.currentIdxs.includes(val.value)) {
        this.currentIdxs = this.currentIdxs.filter(item => item !== val.value)
        if (isCheck.length === 1) {
          this.checkeds = true
        } else {
          this.checkeds = false
        }
      } else {
        if (isAll.length === 1) {
          this.checkeds = true
        } else {
          this.checkeds = false
        }
        this.currentIdxs.push(val.value)
      }
      this.$refs.mTable.setTableData(true)
    },
    selectCheck() {
      if (this.checkeds) {
        this.currentIdxs = []
        this.$refs.mTable.setTableData(true)
      }
    },
    fixSinImg(img) {
      const imgs = img.split(',')
      return imgs[0]
    },
    fixImg(img) {
      const imgs = img.split(',')
      return imgs
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const searchVal = []
      if (this.currentIdxs.length > 0) {
        searchVal.push({
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: this.currentIdxs.join('^'),
          operator: 'in'
        })
      } else {
        searchVal.push({
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '2^3^4^5^6',
          operator: 'in'
        })
      }
      // console.log(searchVal, this.searchDatas)
      const res = await api.getEquipmentFaultListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...searchVal, ...this.searchDatas]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 设备
    async getSetting() {
      const res = await sapi.getEquipmentList()
      if (res) {
        this.formOptions.equipmentCodes = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.equipmentCodes = res
        }
      }
    },
    // 用户
    async getUser() {
      const res = await uapi.getUserList()
      if (res) {
        this.formOptions.reporterList = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.reporterList = res
        }
      }
    },
    // 紧急程度
    async getEmergenceDegree() {
      const res = await eapi.getUrgencyLevelList()
      if (res) {
        this.formOptions.emergenceDegrees = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.emergenceDegrees = res
        }
      }
    },
    // 确认图纸
    async submitForm(done) {
      await api.getPage({ id: this.selectId })
      this.$message.success('确认成功')
      this.$refs.mTable.setTableData()
      this.visible = false
      done()
    },
    // 更多操作
    more(row, command) {
      const functions = {
        save: () => { this.save(row) },
        del: () => { this.del(row) },
        modify: () => { this.modify(row) },
        singleModify: () => { this.singleModify(row) }
      }
      functions[command]()
    },
    // 只修改交期
    singleModify(item) {
      this.showModify = true
      this.deliveryTime = item.deliveryTime
      this.currentId = item.id
    },
    async modifyForm(done) {
      done()
      if (this.deliveryTime === '' || this.deliveryTime === null) {
        this.$message.error('请选择交期')
        return false
      }
      const data = {
        id: this.currentId,
        deliveryTime: this.deliveryTime
      }
      const res = await api.modifyOrder(data)
      if (res) {
        this.showModify = false
        this.$refs.mTable.setTableData()
      }
    },
    save(row) {
      this.visible = true
      this.selectId = row.id
    },
    allMore(command) {
      const functions = {
        allDel: () => { this.allDel() }
      }
      functions[command]()
    },
    async del(row) {
      await api.deleteOrder({ id: row.id })
      this.$message.success('删除成功')
      this.$refs.mTable.setTableData()
    },
    modify(row) {
      this.$router.push({ path: 'order-add', query: { id: row.id }})
    },
    // 查询d
    searchTable(data, val, keyword) {
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      this.$refs.mTable.setTableData(true)
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.selectKeyword = ''
      this.keyword = ''
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
 .fault-wrap{
    padding: 10px;
    .all-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .checkAll {
        margin-right: 20px;
      }
      .all-li {
        width: 80px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #DDE3EB;
        margin-right: 15px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A4B3C6;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .active {
        color: #607FFF;
        border-color: #607FFF;
        background: #EFF2FF;
      }
    }
    .li-btn{
      margin-right:10px;
      color:#607FFF;
    }
    .color-btns{
      color:#c4c4c4;
    }
    .searchs{
      padding-left: 0;
      padding-top: 0;
    }
    .input-select{
      width:100px;
      display:block;
      }
    .input-with-select{
      width:100%;
    }
    .all-operator{
      height: 32px;
      margin-left: 10px;
      ::v-deep{
        .el-button-group .el-button{
          height: 32px;
        }
      }
    }
 }
 ::v-deep .customer-content div {
   width: 100%;
 }

 .tip-tit {
   height: 18px;
   line-height: 18px;
 }
 div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #303133;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #303133;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #303133;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center !important;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.faultDesc {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
}
::v-deep{
  .e-divider{
  margin: 0px 0 0 0;
  }
}
</style>
