var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-detail-wrapper" }, [
    _c("div", { staticClass: "account-detail-body" }, [
      _c(
        "div",
        {
          staticClass: "detail-card",
          staticStyle: { "margin-bottom": "20px" }
        },
        [
          _c("div", { staticClass: "card-tit" }, [_vm._v("变更单基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot", staticStyle: { padding: "15px" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        padding: "10px 15px",
                        "flex-flow": "row wrap"
                      },
                      attrs: { type: "flex", justify: "start", gutter: 24 }
                    },
                    _vm._l(_vm.columns, function(item, index) {
                      return _c(
                        "el-col",
                        {
                          key: item.prop,
                          style: { marginBottom: index !== 4 ? "20px" : 0 },
                          attrs: { span: 6 }
                        },
                        [
                          _c("span", { staticClass: "bot-tis" }, [
                            _vm._v(_vm._s(item.label) + "：")
                          ]),
                          _c("span", { staticClass: "bot-tis" }, [
                            _vm._v(_vm._s(_vm.getValue(item)))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        { staticStyle: { background: "#FFFFFF" } },
        [
          _c(
            "el-tabs",
            {
              staticClass: "detail-tabs",
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.tabClick },
              model: {
                value: _vm.params.radio,
                callback: function($$v) {
                  _vm.$set(_vm.params, "radio", $$v)
                },
                expression: "params.radio"
              }
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "物料变更", name: "MaterialsChange" }
              }),
              _c("el-tab-pane", {
                attrs: { label: "涉及工单", name: "InvolveWork" }
              })
            ],
            1
          ),
          _vm.params.radio === "MaterialsChange"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c(
                      "FunctionHeader",
                      {
                        attrs: {
                          tags: _vm.functionHeader.tags,
                          "search-title": _vm.searchTitle[_vm.params.radio],
                          isDialog: true
                        },
                        on: {
                          search: function($event) {
                            return _vm.$refs.mTable.setTableData(true)
                          }
                        },
                        model: {
                          value: _vm.functionHeader.searchVal,
                          callback: function($$v) {
                            _vm.$set(_vm.functionHeader, "searchVal", $$v)
                          },
                          expression: "functionHeader.searchVal"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "选择上阶物料",
                              multiple: ""
                            },
                            on: { change: _vm.selectUpperMaterials },
                            model: {
                              value: _vm.upperMaterialsId,
                              callback: function($$v) {
                                _vm.upperMaterialsId = $$v
                              },
                              expression: "upperMaterialsId"
                            }
                          },
                          _vm._l(_vm.upperMaterialsList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              height: "32px",
                              "margin-left": "10px"
                            },
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.batchOperate()
                              }
                            }
                          },
                          [_vm._v("批量操作")]
                        )
                      ],
                      1
                    ),
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        "columns-key": _vm.columnsKey,
                        columns: _vm.tableColumns[_vm.params.radio],
                        "set-data-method": _vm.getTableData,
                        "show-page": false,
                        "row-style": _vm.getRowStyle
                      },
                      on: { "selection-change": _vm.selectionChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "faultStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "faultStatus",
                                    style: {
                                      background:
                                        _vm.faultStatus[row.status - 1]
                                          .background,
                                      color:
                                        _vm.faultStatus[row.status - 1].color
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.faultStatus[row.status - 1].name
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            }
                          },
                          {
                            key: "workOrderStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.workStatus[row.workOrderStatus + 1]
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "inspectionStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm.status.onSiteInspectionStatus[row.status]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "faultStatus",
                                        style: {
                                          background:
                                            _vm.status.onSiteInspectionStatus[
                                              row.status
                                            ].background,
                                          color:
                                            _vm.status.onSiteInspectionStatus[
                                              row.status
                                            ].color
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.status.onSiteInspectionStatus[
                                                row.status
                                              ].name
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            }
                          },
                          {
                            key: "pointStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm.status.pointStatus[row.status]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "faultStatus",
                                        style: {
                                          background:
                                            _vm.status.pointStatus[row.status]
                                              .background,
                                          color:
                                            _vm.status.pointStatus[row.status]
                                              .color
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.status.pointStatus[row.status]
                                                .name
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            }
                          },
                          {
                            key: "maintenanceStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm.status.maintenanceStatus[row.status]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "faultStatus",
                                        style: {
                                          background:
                                            _vm.status.maintenanceStatus[
                                              row.status
                                            ].background,
                                          color:
                                            _vm.status.maintenanceStatus[
                                              row.status
                                            ].color
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.status.maintenanceStatus[
                                                row.status
                                              ].name
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            }
                          },
                          {
                            key: "quantity",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                !row.isEdit
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(row.quantity) +
                                          "/" +
                                          _vm._s(row.totalQuantity)
                                      )
                                    ])
                                  : _c("el-input", {
                                      model: {
                                        value: row.quantity,
                                        callback: function($$v) {
                                          _vm.$set(row, "quantity", $$v)
                                        },
                                        expression: "row.quantity"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "procedureId",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                !row.isEdit || row.direct < 0
                                  ? _c("div", [
                                      _vm._v(_vm._s(row.procedureName))
                                    ])
                                  : _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: row.procedureId,
                                          callback: function($$v) {
                                            _vm.$set(row, "procedureId", $$v)
                                          },
                                          expression: "row.procedureId"
                                        }
                                      },
                                      _vm._l(_vm.processList, function(item) {
                                        return _c("el-option", {
                                          key: item.procedureId,
                                          attrs: {
                                            label: item.procedureName,
                                            value: item.procedureId
                                          }
                                        })
                                      }),
                                      1
                                    )
                              ]
                            }
                          },
                          {
                            key: "direct",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(row.direct > 0 ? "增加" : "减少") +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "status",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.status == 0 ? "未执行" : "已执行"
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "errorCount",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#e02020" } },
                                  [_vm._v(_vm._s(_vm.getErrorCount(row)))]
                                )
                              ])
                            }
                          },
                          {
                            key: "unMaintainCount",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#e02020" } },
                                  [_vm._v(_vm._s(_vm.getUnMaintainCount(row)))]
                                )
                              ])
                            }
                          },
                          {
                            key: "spareList",
                            fn: function(ref) {
                              var row = ref.row
                              return row.sparePartList &&
                                row.sparePartList.length
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewSpareList(row)
                                        }
                                      }
                                    },
                                    [_vm._v("查看")]
                                  )
                                : _vm._e()
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !row.isEdit &&
                                            _vm.params.radio ===
                                              "MaterialsChange",
                                          expression:
                                            "!row.isEdit && params.radio === 'MaterialsChange'"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: row.status != 0
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editMaterialChange(row)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("修改"),
                                          _c("i", {
                                            staticClass: "el-icon-edit"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.params.radio === "PlanNode",
                                          expression:
                                            "params.radio === 'PlanNode'"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onExecute(row)
                                            }
                                          }
                                        },
                                        [_vm._v("执行变更")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row.isEdit,
                                          expression: "row.isEdit"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$set(
                                                row,
                                                "isEdit",
                                                false
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("取消")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onSave(row)
                                            }
                                          }
                                        },
                                        [_vm._v("保存")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-divider", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.params.radio ===
                                          "MaterialsChange",
                                        expression:
                                          "params.radio === 'MaterialsChange'"
                                      }
                                    ],
                                    attrs: { direction: "vertical" }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.params.radio ===
                                            "MaterialsChange",
                                          expression:
                                            "params.radio === 'MaterialsChange'"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: row.status != 0,
                                            type: "text"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.del(row)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "workAction",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewMaterialsDetail(
                                            row,
                                            $index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("物料详情")]
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "MDialog",
                  {
                    attrs: {
                      width: _vm.width - 320 + "px",
                      "append-to-body": true,
                      title: "批量修改物料"
                    },
                    on: {
                      onOk: _vm.submitForm,
                      fullScreen: _vm.fullScreenChange
                    },
                    model: {
                      value: _vm.batchModifyVisible,
                      callback: function($$v) {
                        _vm.batchModifyVisible = $$v
                      },
                      expression: "batchModifyVisible"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "materials-info" },
                      [
                        _c("MTable", {
                          ref: "mDialogTable",
                          attrs: {
                            columns: _vm.materialBatchColumns,
                            height: _vm.dialogHeight,
                            data: _vm.materialsData,
                            "columns-setting": false,
                            "show-page": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "quantity",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [_vm._v(_vm._s(row.quantity))])
                                  ]
                                }
                              },
                              {
                                key: "procedureId",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择"
                                        },
                                        on: { change: _vm.selectProcess },
                                        model: {
                                          value: row.procedureId,
                                          callback: function($$v) {
                                            _vm.$set(row, "procedureId", $$v)
                                          },
                                          expression: "row.procedureId"
                                        }
                                      },
                                      _vm._l(_vm.processBatchList, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          key: item.procedureId,
                                          attrs: {
                                            label: item.procedureName,
                                            value: item.procedureId
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "direct",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.direct > 0 ? "增加" : "减少"
                                        ) +
                                        " "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "status",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          row.status == 0 ? "未执行" : "已执行"
                                        ) +
                                        " "
                                    )
                                  ])
                                }
                              }
                            ],
                            null,
                            false,
                            881687342
                          )
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            : _vm._e(),
          _vm.params.radio === "InvolveWork"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        "columns-key": _vm.columnsKey,
                        columns: _vm.tableColumns[_vm.params.radio],
                        "set-data-method": _vm.getTableData,
                        "show-page": false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "status",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.workStatusMaps[row.status]) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "changeStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.changeStatus == 0
                                        ? "未执行"
                                        : row.changeStatus == 1
                                        ? "已执行"
                                        : "部分执行"
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "progressStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      row.workOrderTempo === 1
                                        ? "#FFF81D"
                                        : row.workOrderTempo === 2
                                        ? "#F20909"
                                        : "#606266"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.progressStatusMaps[
                                          row.workOrderTempo
                                        ]
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            }
                          },
                          {
                            key: "executionDepartment",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.executionDepartmentMaps[
                                        row.executiveUnit
                                      ]
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "priority",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tag-priority",
                                      style: {
                                        color: _vm.priorityStatus[row.priority]
                                          ? _vm.priorityStatus[row.priority]
                                              .color
                                          : "rgb(74, 79, 118)",
                                        background:
                                          (_vm.priorityStatus[row.priority]
                                            ? _vm.priorityStatus[row.priority]
                                                .color
                                            : "rgb(74, 79, 118)") + "20"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.priorityStatus[row.priority]
                                              ? _vm.priorityStatus[row.priority]
                                                  .name
                                              : "普通"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            }
                          },
                          {
                            key: "businessUnit",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.divisionOptionsMaps[row.businessUnit]
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "workType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " + _vm._s(_vm.workTypeMaps[row.type]) + " "
                                )
                              ])
                            }
                          },
                          {
                            key: "productionPlanNumber",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                row.isRepair === 1
                                  ? _c("img", {
                                      staticClass: "icon-img",
                                      attrs: {
                                        src: require("@/assets/plans/repair-icon-have.png")
                                      }
                                    })
                                  : _vm._e(),
                                _c(
                                  "a",
                                  {
                                    staticClass: "li-btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.onHandlePlan("view", row.id)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(row.productionPlanNumber))]
                                )
                              ])
                            }
                          },
                          {
                            key: "processFlowName",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showProcess(row)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.processFlowName))]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "workAction",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: row.changeStatus == 1
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onOperate(row)
                                        }
                                      }
                                    },
                                    [_vm._v("执行")]
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        105117040
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.params.radio === "PlanNode"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "bottom",
                    staticStyle: { padding: "15px", "margin-bottom": "38px" }
                  },
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        "columns-key": _vm.columnsKey,
                        columns: _vm.tableColumns[_vm.params.radio],
                        "set-data-method": _vm.getTableData,
                        "show-page": false
                      },
                      on: { "selection-change": _vm.selectionChange2 },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "needOperate",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.needOperate ? "不需要" : "需要")
                                  )
                                ])
                              ])
                            }
                          },
                          {
                            key: "upIsSame",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(_vm._s(row.upIsSame ? "否" : "是"))
                                ])
                              ])
                            }
                          },
                          {
                            key: "changeType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.changeType || row.changeType === 0
                                        ? _vm.changeType[row.changeType].name
                                        : ""
                                    )
                                  )
                                ])
                              ])
                            }
                          },
                          {
                            key: "changeStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.changeStatus[row.status].name)
                                  )
                                ])
                              ])
                            }
                          }
                        ],
                        null,
                        false,
                        321075535
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]),
    _c("div", { staticClass: "order-footer" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.back()
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.params.radio == "MaterialsChange",
                  expression: "params.radio == 'MaterialsChange'"
                }
              ],
              attrs: { type: "primary", loading: _vm.executeLoading },
              on: {
                click: function($event) {
                  return _vm.onExecute()
                }
              }
            },
            [_vm._v("执行变更")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.params.radio == "PlanNode",
                  expression: "params.radio == 'PlanNode'"
                }
              ],
              attrs: { type: "primary", loading: _vm.executeBatchLoading },
              on: {
                click: function($event) {
                  return _vm.onOperateBatch()
                }
              }
            },
            [_vm._v("批量变更")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }