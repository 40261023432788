<template>
  <div class="edit-materials-wrapper">
    <!--    抽屉-->
    <MDialog v-model="visible" :title="materialsMessage[currentType]" @onOk="submitForm">
      <MFormBuilder
        ref="formBuild"
        :col-span="6"
        :form-data="formData"
        :form-list="formList"
        :custom-rules="rules"
        :disabled="formDisabled"
      >
        <div slot="drawing">
          <el-upload
            class="avatar-uploader"
            v-bind="options"
            accept=".png,.jpg"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="equipmentImageUrl" :src="equipmentImageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </div>
        <!-- 关联设备-->
        <div slot="package">
          <el-row :gutter="20">
            <el-col v-if="!formDisabled" :span="24">
              <el-button type="primary" @click="selectEquipment('package')">选择设备</el-button>
            </el-col>
            <el-col :span="24" style="padding-top: 10px">
              <MTable
                ref="mPackageTable"
                :height="300"
                :show-page="false"
                :data="form.equipmentList"
                :columns="packageColumns"
              >
                <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
                <div slot="action" slot-scope="{ row, $index }">
                  <el-button type="text" @click="delPart($index)">删除</el-button>
                </div>
              </MTable>
            </el-col>
          </el-row>
        </div>

      </MFormBuilder>
      <!-- 关联设备 -->
      <select-setting
        ref="selectSetting"
        :visible.sync="showSetting"
        :select-materials="selectParts"
        @submitForm="submitSetting"
      />
    </MDialog>
  </div>
</template>

<script>
import { formList, packageFormList } from '@/views/equipment/part-account/form-list'
import {
  packageColumns
} from '@/views/equipment/part-account/columns'
import { getUUid } from '@/utils'
import modelApi from '@/api/information/warehouse/model'
import maApi from '@/api/information/materials-attribute'
import { Encrypt } from '@/utils/sercet'
import PartClassApi from '@/api/equipment/part-class'
import { getToken } from '@/utils/auth'
import SelectSetting from '@/components/SelectSetting/SelectSetting'
import api from '@/api/equipment/spare-part'

export default {
  name: 'EditPart',
  components: { SelectSetting },
  data() {
    return {
      showSetting: false,
      selectParts: [],
      packageColumns,
      options: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false
      },
      dialogVisible: false,
      currentType: 'add',
      currentDialogType: 'add',
      dialogFormData: {},
      dialogFormList: {
        package: packageFormList
      },
      dialogType: 'unit',
      dialogForm: {
        showEnableUnit: false,
        showEnablePackage: false,
        conversionFactor: '',
        unitName: ''
      },
      originDialogForm: {},
      formList,
      rules: {
        code: [
          { required: true, message: '请输入备件编码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入备件名称', trigger: 'blur' }
        ],
        mainUnitId: [
          { required: true, message: '请选择单位', trigger: 'change' }
        ]
      },
      formData: {},
      originForm: {},
      form: {
        equipmentIdList: [],
        equipmentList: []
      },
      materialsMessage: {
        add: '新增备件',
        modify: '修改备件',
        copy: '复制备件',
        del: '删除备件',
        view: '查看备件'
      },
      dialogMessage: {
        'add': '新增',
        'modify': '修改',
        'del': '删除',
        'enable': '启用',
        'disable': '停用'
      },
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      currentIndex: 0,
      weightRelative: false,
      formDisabled: false,
      currentTab: 'inventory',
      visible: false,
      formOptions: {
        sparePartClassList: [],
        wareHouseList: [],
        sparePartUnitList: []
      },
      equipmentImageUrl: ''
    }
  },
  async beforeMount() {
    // 根据租户处理备件台账的图片上传url ————————Start————————
    if(sessionStorage.getItem('memberCode') === 'bbelc'){
      this.options.action = `${process.env.VUE_APP_SCENCE_API}api/web/v1/basicData/private/file/upload`
    }
    // 根据租户处理备件台账的图片上传url ————————End————————
    this.originForm = this._.cloneDeep(this.form)
    this.originDialogForm = this._.cloneDeep(this.dialogForm)
  },
  mounted() {
    this.getSparePartClassList()
    this.getWareHouseList()
    this.getUnitList()
  },
  methods: {
    delPart(idx) {
      this.form.equipmentList = this.form.equipmentList.filter((item, index) => index !== idx)
    },
    selectEquipment() {
      this.showSetting = true
    },
    submitSetting(data) {
      this.showSetting = false
      console.log('选择的数据=》', data)
      this.form.equipmentList = data
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.$refs.formBuild.setForm({
          drawing: res.result
        })
        this.equipmentImageUrl = res.result
      } else {
        this.$message.error(res.result)
      }
    },
    handlePictureCardPreview(row) {
      this.dialogImageUrl = row
      this.dialogVisible = true
    },
    tabClick(val) {
      this.currentTab = val.name
    },
    async getSparePartClassList() {
      const res = await PartClassApi.getSparePartClassList()
      if (res) {
        this.formOptions.sparePartClassList = res
        this.formList[3].children[0].options = res
      }
    },
    // 获取单位列表
    async getUnitList() {
      const res = await maApi.getMaterialsUnitList(this.defaultSearch)
      if (res) {
        this.formOptions.sparePartUnitList = res
        this.formList[6].children[0].options = res
      }
    },
    // 获取仓库列表
    async getWareHouseList() {
      const res = await modelApi.getRepositoryModelList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }, {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 0,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.formOptions.wareHouseList = res
        this.formList[13].children[0].options = res
      }
    },
    // 新增备件
    async add(val) {
      await this.getSparePartClassList()
      this.currentType = 'add'
      this.formDisabled = false
      this.form = this._.cloneDeep(this.originForm)
      this.formData = { sparePartClassId: val }
      this.visible = true
      await this.createCode()
    },
    // 修改或复制备件
    async modifyOrCopy(row, command) {
      const res = await api.getSparePartDetail(row.id)
      if (res) {
        this.currentType = command
        this.formData = res
        this.form.equipmentList = res.equipmentList ? res.equipmentList : []
        this.equipmentImageUrl = res.drawing ? res.drawing : ''
        this.formDisabled = command === 'view'
        this.visible = true
      }
      if (command === 'copy') {
        await this.createCode()
      }
    },
    async createCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api.createCode({
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    setForm(key, val) {
      this.$refs.formBuild.setForm({ [key]: val })
    },
    // 确定
    submitForm(callback) {
      this.$refs.formBuild.setForm({
        equipmentIdList: this.form.equipmentList.length > 0 ? this.form.equipmentList.map(item => item.id) : []
      })
      const { form } = this.$refs.formBuild
      if (this.formDisabled) {
        callback(true)
        this.visible = false
        return
      }

      console.log('保存数据', form, this.currentType)
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await api[`${this.currentType}SparePart`](
            this.currentType === 'modify' ? this.createResetData(form) : this.createSetData(form)
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            this.visible = false
            this.$emit('onSuccess')
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      const defaultRepository = this.formOptions.wareHouseList.find(item => item.id === raw.defaultRepositoryId)
      return {
        code: raw.code,
        name: raw.name,
        isLimit: (raw.upperLimit || raw.upperLimit === 0 || raw.lowerLimit || raw.lowerLimit === 0) ? 1 : 0,
        upperLimit: raw.upperLimit,
        lowerLimit: raw.lowerLimit,
        specifications: raw.specifications,
        mainUnitId: raw.mainUnitId,
        mainUnitName: raw.mainUnitName,
        sparePartClassId: raw.sparePartClassId,
        defaultRepositoryName: defaultRepository && defaultRepository.name,
        defaultRepositoryId: defaultRepository && defaultRepository.id,
        equipmentIdList: raw.equipmentIdList,
        remark: raw.remark,
        isEnabled: raw.isEnabled,
        drawing: raw.drawing
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getSuccessMessage() {
      return `${this.materialsMessage[this.currentType]}成功`
    },
    getDialogSuccessMessage() {
      return `${this.dialogMessage[this.currentDialogType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.unit-name {
  height: 40px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
}
div.red {
  color: red;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 150px;
  display: block;
}
</style>
