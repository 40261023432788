import { partApplyStatus } from '@/config/options.config'
import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const searchFormList = [
  {
    key: 'status',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: partApplyStatus
      }
    ]
  },
  {
    key: 'creator',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择申请人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'createTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '制单开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  }
]

export const formList = [
  {
    tagName: 'labelTitle',
    label: '基本信息',
    colSpan: 24
  },

  ...transformColumnsToForm(columns),
  {
    index: 3,
    label: '关联报修单',
    key: 'faultId',
    tagName: 'el-select',
    on: {
      change: (val) => { functionList.equipmentRepaireChange(val) }
    },
    props: {
      clearable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'faultNumber',
          value: 'id'
        }
      }
    ]
  },
  {
    index: 4,
    label: '相关设备',
    key: 'equipmentId',
    tagName: 'el-select',
    props: {
      clearable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    index: 5,
    label: '故障时间',
    key: 'reportTime',
    defaultValue: '',
    tagName: 'el-date-picker',
    required: false
  },

  {
    label: '备注',
    key: 'remark',
    tagName: 'el-input',
    defaultValue: '',
    required: false,
    props: { type: 'textarea' }
  },
  {
    label: '附件',
    key: 'attachments',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['drawing']
    }]
  },
  {
    required: true,
    tagName: 'labelTitle',
    label: '采购明细',
    colSpan: 24
  }

]
formList.sort((a, b) => a.index - b.index)
formList.forEach(item => {
  item.colSpan = (item.colSpan === 24 || item.label === '') ? item.colSpan : 8
})
