
export const columns = [
  {
    prop: 'cycleCheckTaskNumber',
    label: '巡检单号'
  },
  {
    prop: 'taskCheckType',
    label: '巡检类别'
  },
  {
    prop: 'status',
    label: '状态'
  },
  {
    prop: 'equipmentCode',
    label: '设备编号'
  },
  {
    prop: 'equipmentName',
    label: '设备名称'
  },
  {
    prop: 'equipmentClassName',
    label: '设备类'
  },
  {
    prop: 'checkPlanNumber',
    label: '计划单号'
  },
  {
    prop: 'checkPlanTime',
    label: '计划日期'
  },
  {
    prop: 'checkStartTime',
    label: '实际开始'
  },
  {
    prop: 'checkEndTime',
    label: '实际结束'
  },
  {
    prop: 'checkSpendTime',
    label: '消耗时间'
  },
  {
    prop: 'checker',
    label: '执行人'
  },
  {
    prop: 'creator',
    label: '创建人'
  },
  {
    prop: 'createTime',
    label: '创建时间'
  },
  {
    prop: 'skipReason',
    label: '跳过原因'
  }
]

export const pointCheckItemView = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: false,
    width: 50,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '项目名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'standard',
    sortable: false,
    hiddenSearch: true,
    label: '巡检标准'
  },
  {
    prop: 'result',
    label: '巡检结果',
    sortable: false,
    hiddenSearch: true,
    slotName: 'result'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]
