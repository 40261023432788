<template>
  <div class="unit-wrap">
    <div class="top">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入模板名称"
        :tags="!exportSelectionIds.length ? functionHeader.tags : []"
        :columns-key="columnsKey"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="exportSelectionIds.length">
          <el-button type="primary" size="small" @click="allMore('delBatchTechnical')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="name" slot-scope="{ row }" type="text" @click="viewTechnical(row)">{{ row.name }}</el-button>
        <div slot="action" slot-scope="{ row }">
          <template>
            <el-button type="text" @click="copyTechnical(row)">复制</el-button>
            <el-divider direction="vertical" />
            <el-button type="text" @click="modifyTechnical(row)">修改</el-button>
            <el-divider direction="vertical" />
            <DelPopover @onOk="callback => del(row, callback)" />
          </template>
        </div>
      </MTable>
    </div>
  </div>
</template>

<script>
import DelPopover from '@/components/DelPopover/index'
import FunctionHeader from '@/components/FunctionHeader/index'
import { columns } from './columns'
import { formList, searchFormList } from './form-list'
import batchExportFront from '@/mixin/batch-export-front'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/facility/technical'

export default {
  name: 'TechnicalParameterList',
  components: { DelPopover, FunctionHeader },
  mixins: [batchExportFront, conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'Technical'
      },
      selectionData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增模板',
            on: {
              click: this.addTechnical
            }
          }
        ]
      },
      visible: false,
      columns,
      formList,
      formData: {},
      searchFormList,
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 + 40
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  methods: {
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    allMore(command) {
      const functions = {
        delBatchTechnical: () => {
          this.delBatchTechnical()
        }
      }
      functions[command]()
    },

    searchTable() {},

    addTechnical() {
      this.$router.push({ name: 'technicalParameterAdd', query: { type: 'add' }})
    },

    copyTechnical(row) {
      this.$router.push({ name: 'technicalParameterAdd', query: { id: row.id, type: 'copy' }})
    },

    modifyTechnical(row) {
      this.$router.push({ name: 'technicalParameterAdd', query: { id: row.id, type: 'modify' }})
    },

    viewTechnical(row) {
      this.$router.push({ name: 'technicalParameterAdd', query: { id: row.id, type: 'view' }})
    },

    delBatchTechnical(row) {
      this.$confirm('确定要删除选择模板吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delBatchTechnical({ ids: this.exportSelectionIds.join('^') })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 0 10px 10px 10px;
}
</style>
