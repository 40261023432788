<template>
  <div class="account-detail-wrapper">
    <div class="account-detail-body">
      <div class="detail-card" style="margin-bottom:20px;">
        <div class="card-tit">变更单基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <div>
            <el-row
              type="flex"
              justify="start"
              style="padding: 10px 15px; flex-flow: row wrap"
              :gutter="24"
            >
              <el-col
                v-for="(item, index) in columns"
                :key="item.prop"
                :span="6"
                :style="{ marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>


      <div style="background:#FFFFFF">
            <el-tabs v-model="params.radio" @tab-click="tabClick" class="detail-tabs" type="border-card">
              <el-tab-pane label="物料变更" name="MaterialsChange" />
              <!--<el-tab-pane label="文档变更" name="Materials" />-->
              <!--<el-tab-pane label="变更范围" name="Ticket" />-->
              <el-tab-pane label="涉及工单" name="InvolveWork" />
              <!--<el-tab-pane label="批量变更" name="PlanNode" />-->
            </el-tabs>
            <template v-if="params.radio === 'MaterialsChange'">
              <div class="bottom" style="padding:15px;margin-bottom: 38px">
                <FunctionHeader
                  :tags="functionHeader.tags"
                  v-model="functionHeader.searchVal"
                  :search-title="searchTitle[params.radio]"
                  @search="$refs.mTable.setTableData(true)"
                  :isDialog="true"
                >
                <el-select style="width:300px;" v-model="upperMaterialsId" filterable clearable placeholder="选择上阶物料" @change="selectUpperMaterials" multiple>
                      <el-option
                        v-for="item in upperMaterialsList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>

                    <el-button type="primary" size="small" style="height:32px;margin-left:10px;" @click="batchOperate()">批量操作</el-button>
                </FunctionHeader>
                <MTable
                  ref="mTable"
                  :columns-key="columnsKey"
                  :columns="tableColumns[params.radio]"
                  :set-data-method="getTableData"
                  :show-page="false"
                  @selection-change="selectionChange"
                  :row-style="getRowStyle"
                >
                  <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                  <div slot="faultStatus" slot-scope="{ row }">
                    <div
                      class="faultStatus"
                      :style="{
                        background: faultStatus[row.status - 1].background,
                        color: faultStatus[row.status - 1].color,
                      }"
                    >
                      {{ faultStatus[row.status - 1].name }}
                    </div>
                  </div>
                  <div slot="workOrderStatus" slot-scope="{ row }">
                    {{ workStatus[row.workOrderStatus + 1] }}
                  </div>
                  <div slot="inspectionStatus" slot-scope="{ row }">
                    <div
                      v-if="status.onSiteInspectionStatus[row.status]"
                      class="faultStatus"
                      :style="{
                        background: status.onSiteInspectionStatus[row.status].background,
                        color: status.onSiteInspectionStatus[row.status].color,
                      }"
                    >
                      {{ status.onSiteInspectionStatus[row.status].name }}
                    </div>
                  </div>
                  <div slot="pointStatus" slot-scope="{ row }">
                    <div
                      v-if="status.pointStatus[row.status]"
                      class="faultStatus"
                      :style="{
                        background: status.pointStatus[row.status].background,
                        color: status.pointStatus[row.status].color,
                      }"
                    >
                      {{ status.pointStatus[row.status].name }}
                    </div>
                  </div>
                  <div slot="maintenanceStatus" slot-scope="{ row }">
                    <div
                      v-if="status.maintenanceStatus[row.status]"
                      class="faultStatus"
                      :style="{
                        background: status.maintenanceStatus[row.status].background,
                        color: status.maintenanceStatus[row.status].color,
                      }"
                    >
                      {{ status.maintenanceStatus[row.status].name }}
                    </div>
                  </div>
                  <template slot="quantity" slot-scope="{ row }">
                    <div v-if="!row.isEdit">{{ row.quantity }}/{{row.totalQuantity}}</div>
                    <el-input v-else v-model="row.quantity" />
                  </template>
                  <template slot="procedureId" slot-scope="{ row }">
                    <div v-if="!row.isEdit || row.direct < 0">{{ row.procedureName }}</div>
                    <el-select v-else v-model="row.procedureId" placeholder="请选择">
                      <el-option
                        v-for="item in processList"
                        :key="item.procedureId"
                        :label="item.procedureName"
                        :value="item.procedureId"
                      />
                    </el-select>
                  </template>
                  <div slot="direct" slot-scope="{ row }">
                    {{ row.direct > 0 ? "增加" : "减少" }}
                  </div>
                  <div slot="status" slot-scope="{ row }">
                    {{ row.status == 0 ? "未执行" : "已执行" }}
                  </div>
                  <div slot="errorCount" slot-scope="{ row }">
                    <div style="color: #e02020">{{ getErrorCount(row) }}</div>
                  </div>
                  <div slot="unMaintainCount" slot-scope="{ row }">
                    <div style="color: #e02020">{{ getUnMaintainCount(row) }}</div>
                  </div>
                  <el-button
                    v-if="row.sparePartList && row.sparePartList.length"
                    slot="spareList"
                    slot-scope="{ row }"
                    type="text"
                    @click="viewSpareList(row)"
                    >查看</el-button
                  >
                  <div slot="action" slot-scope="{ row }">
                    <span v-show="!row.isEdit && params.radio === 'MaterialsChange'">
                      <el-button
                        type="text"
                        @click="editMaterialChange(row)"
                        :disabled="row.status != 0"
                        >修改<i class="el-icon-edit"
                      /></el-button>
                    </span>
                    <span v-show="params.radio === 'PlanNode'">
                      <el-button type="text" @click="onExecute(row)">执行变更</el-button>
                    </span>
                    <span v-show="row.isEdit">
                      <el-button type="text" @click="$set(row, 'isEdit', false)">取消</el-button>
                      <el-button type="text" @click="onSave(row)">保存</el-button> </span
                    >&nbsp;

                    <el-divider v-show="params.radio === 'MaterialsChange'" direction="vertical" />
                    <span v-show="params.radio === 'MaterialsChange'">
                      <el-button :disabled="row.status != 0" type="text" @click="del(row)">删除</el-button>
                    </span>
                  </div>
                  <div slot="workAction" slot-scope="{ row, $index }">
                    <el-button type="text" @click="viewMaterialsDetail(row, $index)"
                      >物料详情</el-button
                    >
                  </div>
                </MTable>
              </div>
              <MDialog v-model="batchModifyVisible" :width="width- 320 + 'px'" :append-to-body="true" title="批量修改物料" @onOk="submitForm" @fullScreen="fullScreenChange">
        <div class="materials-info">
          <MTable
            ref="mDialogTable"
            :columns="materialBatchColumns"
            :height="dialogHeight"
            :data="materialsData"
            :columns-setting="false"
            :show-page="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <template slot="quantity" slot-scope="{ row }">
                    <div>{{ row.quantity }}</div>
                  </template>
                  <template slot="procedureId" slot-scope="{ row }">
                    <el-select filterable v-model="row.procedureId" placeholder="请选择" @change="selectProcess">
                      <el-option
                        v-for="item in processBatchList"
                        :key="item.procedureId"
                        :label="item.procedureName"
                        :value="item.procedureId"
                      />
                    </el-select>
                  </template>
                  <div slot="direct" slot-scope="{ row }">
                    {{ row.direct > 0 ? "增加" : "减少" }}
                  </div>
                  <div slot="status" slot-scope="{ row }">
                    {{ row.status == 0 ? "未执行" : "已执行" }}
                  </div>
          </MTable>
        </div>
  </MDialog>
            </template>
            <template v-if="params.radio === 'InvolveWork'">
              <div class="bottom" style="padding:15px;margin-bottom: 38px">
                <MTable
                  ref="mTable"
                  :columns-key="columnsKey"
                  :columns="tableColumns[params.radio]"
                  :set-data-method="getTableData"
                  :show-page="false"
                >
                  <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

                  <div slot="status" slot-scope="{ row }">
                    {{ workStatusMaps[row.status] }}
                  </div>
                  <div slot="changeStatus" slot-scope="{ row }">
                    {{ row.changeStatus == 0 ? "未执行" : row.changeStatus == 1? "已执行":"部分执行" }}
                  </div>
                  <div
                    slot="progressStatus"
                    slot-scope="{ row }"
                    :style="{
                      color:
                        row.workOrderTempo === 1
                          ? '#FFF81D'
                          : row.workOrderTempo === 2
                          ? '#F20909'
                          : '#606266',
                    }"
                  >
                    {{ progressStatusMaps[row.workOrderTempo] }}
                  </div>
                  <div slot="executionDepartment" slot-scope="{ row }">
                    {{ executionDepartmentMaps[row.executiveUnit] }}
                  </div>
                  <div
                    slot="priority"
                    slot-scope="{ row }"
                    style="display: flex; align-items: center"
                  >
                    <div
                      :style="{
                        color: priorityStatus[row.priority]
                          ? priorityStatus[row.priority].color
                          : 'rgb(74, 79, 118)',
                        background: `${
                          priorityStatus[row.priority]
                            ? priorityStatus[row.priority].color
                            : 'rgb(74, 79, 118)'
                        }20`,
                      }"
                      class="tag-priority"
                    >
                      {{
                        priorityStatus[row.priority] ? priorityStatus[row.priority].name : "普通"
                      }}
                    </div>
                  </div>
                  <div slot="businessUnit" slot-scope="{ row }">
                    {{ divisionOptionsMaps[row.businessUnit] }}
                  </div>
                  <div slot="workType" slot-scope="{ row }">
                    {{ workTypeMaps[row.type] }}
                  </div>

                  <div slot="productionPlanNumber" slot-scope="{ row }">
                    <img
                      v-if="row.isRepair === 1"
                      src="@/assets/plans/repair-icon-have.png"
                      class="icon-img"
                    />
                    <a class="li-btn" @click="onHandlePlan('view', row.id)">{{
                      row.productionPlanNumber
                    }}</a>
                  </div>
                  <div slot="processFlowName" slot-scope="{ row }">
                    <el-button type="text" @click="showProcess(row)">{{
                      row.processFlowName
                    }}</el-button>
                  </div>
                  <div slot="workAction" slot-scope="{ row }">
                    <el-button type="text" @click="onOperate(row)" :disabled="row.changeStatus == 1"
                      >执行</el-button
                    >
                  </div>
                </MTable>
              </div>
            </template>
            <template v-if="params.radio === 'PlanNode'">
              <div class="bottom" style="padding:15px;margin-bottom: 38px">
                <MTable
                  ref="mTable"
                  :columns-key="columnsKey"
                  :columns="tableColumns[params.radio]"
                  :set-data-method="getTableData"
                  :show-page="false"
                  @selection-change="selectionChange2"
                >

                  <div slot="needOperate" slot-scope="{ row }">
                    <span>{{ row.needOperate ? "不需要" : "需要" }}</span>
                  </div>
                  <div slot="upIsSame" slot-scope="{ row }">
                    <span>{{ row.upIsSame ? "否" : "是" }}</span>
                  </div>

                  <div slot="changeType" slot-scope="{ row }">
                    <span>{{ row.changeType || row.changeType === 0 ? changeType[row.changeType].name:'' }}</span>
                  </div>
                  <div slot="changeStatus" slot-scope="{ row }">
                    <span>{{ changeStatus[row.status].name }}</span>
                  </div>

                </MTable>
              </div>
            </template>
          </div>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="back()">取 消</el-button>
        <el-button type="primary" @click="onExecute()" :loading="executeLoading" v-show="params.radio == 'MaterialsChange'"
          >执行变更</el-button>
          <el-button type="primary" @click="onOperateBatch()" :loading="executeBatchLoading" v-show="params.radio == 'PlanNode'"
          >批量变更</el-button>
      </div>
    </div>
  </div>
</template>

  <script>
import api from "@/api/changeManagement/changeManagement";
import {
  columns,
  columns2,
  inspectionColumns,
  maintenanceColumns,
  pointColumns,
  materialColumns,
  warnColumns,
  workColumns,
  materialBatchColumns
} from "./columns";
import {
  divisionOptionsMaps,
  workStatusMaps,
  progressStatusMaps,
  executionDepartmentMaps,
  progressStatusColorMaps,
  workTypeMaps,
} from "./data";

import FunctionHeader from "@/components/FunctionHeader/index";
import conventionMixin from "@/mixin/conventional-page";
import {
  faultStatus,
  onSiteInspectionStatus,
  maintenanceStatus,
  pointStatus,
  changeStatus,
  changeType,
} from "@/config/options.config";
const colorList = ['#FFE1A5','rgb(96, 232, 255)', 'rgb(56, 227, 0)',
  '#95B2C8', 'rgb(171, 111, 167)',  'rgb(186, 137, 137)', 'rgb(236, 103, 255)', '#FF84A1',
  '#808080', '#90BD61','#38E300', '#F7B500', '#FA6400', 'rgb(50,211,189)',
  '#B814D2', '#CB843B', '#C8CF0B', '#13B1C4', '#009112', '#BCBB95','#C89595', '#44D7B6', 'rgb(123,158,180)', '#0091FF']
export default {
  name: "changeWorkDetails",
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      materialColumns,
      materialBatchColumns,
      divisionOptionsMaps,
      workStatusMaps,
      progressStatusMaps,
      executionDepartmentMaps,
      progressStatusColorMaps,
      workTypeMaps,
      faultStatus,
      changeType,
      changeStatus,
      api,
      params: {
        radio: "MaterialsChange",
      },
      status: {
        onSiteInspectionStatus,
        pointStatus,
        maintenanceStatus,
      },
      workStatus: ["未开工", "进行中", "已结案", "装配完工", "完工", "入库", "冻结"],
      columns,
      columns2,
      warnDataList: [],
      tableColumns: {
        WarnRadio: warnColumns,
        PlanNode: columns2,
        MaterialsChange: materialColumns,
        InspectionRadio: inspectionColumns,
        PointRadio: pointColumns,
        InvolveWork: workColumns,
        MaintenanceRadio: maintenanceColumns,
      },
      equipmentDetail: {},
      functionHeader: {
        searchVal: "",
        tags: [
          {
            hidden: true,
            tagName: "el-button",
            props: { type: "primary", size: "small" },
            style: { width: "auto", background: "#607FFF" },
            innerText: "增加物料",
            on: {
              click: this.add,
            },
          },
        ],
      },
      functionProcessHeader: {
        searchVal: "",
      },
      addTitle: {
        PlanNode: "批量执行",
        MaterialsChange: "增加物料",
        InspectionRadio: "请输入巡检单号",
        PointRadio: "请输入点检单号",
        MaintenanceRadio: "请输入保养单号",
      },
      searchTitle: {
        PlanNode: "请输入变更单号/项目编号/名称",
        MaterialsChange: "请输入名称/编号/规格",
        ProjectWork: "请输入名称/编号/规格",
        InspectionRadio: "请输入巡检单号",
        PointRadio: "请输入点检单号",
        MaintenanceRadio: "请输入保养单号",
      },
      searchFormatData: [],
      checkItemList: [],
      checkItemVisible: false,
      resultVisible: false,
      currentData: {},
      currentRow: {},
      spareList: [],
      spareVisible: false,
      searchFieldName: "",
      searchKeyword: true,
      extraParams: [],
      checkeds: true,
      currentIdxs: [],
      currentIdx: false,
      isIndeterminate: false,
      equipmentName: "",
      currentTab: "MaterialsChange",
      visible: false,
      formData: {
        planTypeId: "",
      },
      currentType: "add",
      dataProcess: [],
      selectedMaterialList: [],
      selectionData: [],
      selectionData2:[],
      selectionData3:[],
      processList: [],
      materialsChangeList: [],
      newMaterialsChangeList: [],
      saveloading: false,
      upperMaterialsList:[],
      upperMaterialsId:[],
      batchModifyVisible:false,
      fullScreen:false,
      materialsData:[],
      processBatchList:[],
      chooseMaterialsList:[],
      executeLoading:false,
      executeBatchLoading:false
    };
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 100 - 60;
    },
    columnsKey() {
      return this.$route.name + this.params.radio;
    },
    getColumns() {
      const num = this._.cloneDeep(this.materialColumns)
      num.pop()
      return num
    },
    dialogHeight() {
        return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 - 44 -52: this.$store.state.app.contentHeight - 289 - 45 - 44 -52
    },
    width() {
          return this.$store.state.app.contentWidth
      },
  },
  watch: {},
  created() {},
  async mounted() {
    await this.getWorkChangeDetail()
    await this.getUpMaterialsList()
  },
  methods: {
    fullScreenChange(val) {
      this.fullScreen = val
    },
    selectionChange2(data) {
      this.selectionData2 = data.map(item => item.id)
    },
        // 物料变更批量操作
        batchOperate(){
          this.getProcedureList()
          this.batchModifyVisible = true
          this.echoData()
      },
echoData(){
  this.$nextTick(() => {
        // 数据回显
        console.log(this.selectionData)
        this.selectionData3 = this._.cloneDeep(this.selectionData)
          this.selectionData3.forEach(item => {
            this.$refs.mDialogTable.tableRefs().toggleRowSelection(item, true)
          })
        })
  },
  selectUpperMaterials(val){
    this.$refs.mTable.tableRefs().clearSelection()
    this.$refs.mTable.setTableData();
  },
  selectProcess(val){
    this.materialsData.forEach(item => {
      item.procedureId = val
  })
  },
  async getUpMaterialsList(){
    const res = await api.getUpMaterialsList({id:this.$route.query.id})
    if(res){
      this.upperMaterialsList = res
    }
  },
  // 批量修改物料
  async submitForm(callback){
      if (this.selectionData3.length === 0) {
        this.$message.info('请至少选择一条物料')
        callback()
        return
      }else{
        const selectIds = this.selectionData3.map(item => item.id)
        let arr = this.materialsData.filter(item => selectIds.includes(item.id)).map(items =>{
           return {
            quantity: parseInt(items.quantity),
            id: items.id,
            procedureId: items.procedureId,
            procedureDetailsId: this.processBatchList.find(
            (obj) => obj.procedureId === items.procedureId
          ).id,
            procedureLocationCode: this.processBatchList.find(
            (obj) => obj.procedureId === items.procedureId
          ).serialsCode
              }
        })
        const res  = await api.updateMaterialsChangeBatch(arr)
        if(res){
          this.batchModifyVisible = false
           this.$refs.mTable.setTableData()
        callback()
        }
      }
  },
  // 删除物料
  async del(row){
    this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delMaterialsChangeItem({ id: row.id })
          if (res) {
            this.$message.success('删除成功')
            // setTableData()不带true时，查询条件也会在
            await this.$refs.mTable.setTableData()
          }
        })
  },
    async onOperateBatch() {
      this.executeBatchLoading = true
      var parmas = {
        id: this.$route.query.id,
        operateIds: this.selectionData2,
      };
      const res = await api.executeBatch(parmas)
      if(res){
        this.executeBatchLoading = false
        this.$refs.mTable.setTableData()
      }
      this.executeBatchLoading = false
    },
    async onOperate(item) {
      var parmas = {
        id: this.$route.query.id,
        planId: item.id,
      };
      item.changeStatus = 1;
      const res = await api.executeOrderWork(parmas);
      this.$refs.mTable.setTableData();
    },
    selectName(item) {
      this.$refs.formBuild.setForm({
        planTypeId: item.id,
        planTypeName: item.name,
      });
    },
    viewMaterialsDetail(row) {
      this.$router.push({
        name: "materialsRequireDetail",
        query: { id: row.workOrderId, type: 0 },
      });
    },
    // 选择物料
    selectionChange(data) {
      this.selectionData = data;
      this.materialsData = data
    },
    // 选择物料弹框
    selectionDialogChange(data){
       this.selectionData3 = data
    },
    // 执行
    async onExecute() {
      this.executeLoading = true
      // 判断一下数量是否小于投入物料数量
      const filteredArr = this.materialsChangeList.filter((item) => item.direct < 0 && item.procedureId && item.status === 0);
      const isAssign = filteredArr.every((item) => +item.quantity <= +item.procedureQuantity);
      if (!isAssign) {
        this.executeLoading = false
        this.$message.error("存在工序投入数量小于减少数量的数据，请修改数量");
        return false;
      }
      const res = await api.executeChangeWork(this.$route.query.id);

      if (res) {
        this.executeLoading = false
        this.$message.success("执行成功");
        await this.getWorkChangeDetail();
        this.$refs.mTable.setTableData();
      }
      this.executeLoading = false
    },
    add() {},
    async onSave(row) {
      let procedureDetailsId = this.processList.find(
        (item) => item.procedureId === row.procedureId
      ).id;
      let procedureCode = this.processList.find(
        (item) => item.procedureId === row.procedureId
      ).serialsCode;
      const res = await api.updateMaterialsChange({
        quantity: parseInt(row.quantity),
        id: row.id,
        procedureId: row.procedureId,
        procedureDetailsId: procedureDetailsId,
        procedureLocationCode: procedureCode,
      });
      if (res) {
        this.$message.success("操作成功");
        this.$refs.mTable.setTableData(true);
        this.$set(row, "isEdit", false);
        delete row.isEdit;
      }
    },
    async getWorkChangeDetail() {
      const res = await api.getChangeWorkDetail({ id: this.$route.query.id });
      if (res) {
        this.equipmentDetail = res;
        this.equipmentName = res.name;
      }
    },
    // 获取工序
    async getProcedureList(){
      const res = await api.getProcedureList({ prePlanNumber: this.equipmentDetail.prePlanNumber })
      if (res) {
        this.processBatchList = res;
      }
    },
    async getTableData(condition, callback) {
      const data = Object.assign({}, ...this.searchFormatData);
      const searchCondition = {
        upMaterialId: this.params.radio === 'MaterialsChange' ? this.upperMaterialsId:'',
        searchKeyword: this.params.radio === 'MaterialsChange' ? this.functionHeader.searchVal:'',
        ...data,
      };
      this.functionHeader.exportParams = searchCondition;
      const res = await this.api[`get${this.params.radio}ListPage`]({
        id: this.$route.query.id,
        ...searchCondition,
      });
      if (res) {
        callback({
          content: this.params.radio === 'MaterialsChange' ? res.map(item=>{
          return {
            ...item,
            selectable:item.status !=0 || item.direct < 0 ? true:false
          }
        }) :res
        });
        this.materialsChangeList = res;
        this.newMaterialsChangeList = this.removeDuplicatesByName(this._.cloneDeep(res))

        this.chooseMaterialsList = this._.cloneDeep(res)
        if(this.upperMaterialsId.length && this.params.radio === 'MaterialsChange'){
          let arr = this.chooseMaterialsList.map(item=>{
          return {
            ...item,
            selectable:item.status !=0 || item.direct < 0 ? true:false
          }
        })
          arr.forEach(item => {
            if (!item.selectable) {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
            }
          })
        }else{
          this.$refs.mTable.tableRefs().clearSelection()
        }
      }
      callback();
    },
    removeDuplicatesByName(data) {
      const countMap = {};
      data.forEach(item => {
          const key = item.materialCode + '-' + item.upMaterialCode;
          countMap[key] = (countMap[key] || 0) + 1;
      })
      // 过滤掉只有一条数据的对象
      const filteredData = data.filter(item => {
          const key = item.materialCode + '-' + item.upMaterialCode;
          return countMap[key] > 1;
      })
      return filteredData;
    },
    async editMaterialChange(row) {
      this.$set(row, "isEdit", true);
      console.log(this.materialsData)
      const res = await api.getProcedureList({ prePlanNumber: row.prePlanNumber });
      if (res) {
        this.processList = res;
      }
    },
    getValue(row) {
      const result = this.equipmentDetail[row.prop];
      if (row.form && row.form.options) {
        const current = row.form.options.find((item) => item.id === result);
        return current && current.name;
      }
      if (row.prop === "needOperate") {
        return this.equipmentDetail.needOperate ? "不需要" : "需要";
      }
      if (row.prop === "deliveryDate") {
        return this.equipmentDetail.deliveryStartDate + "~" + this.equipmentDetail.deliveryEndDate;
      }
      return result;
    },
    back() {
      this.$router.push({ name: "ChangeManagementList" });
    },
    submitPlanNodeForm(callback) {
      callback(true);
    },
    tabClick() {
      if (this.params.radio === "MaterialsChange") {
        this.functionHeader.tags[0].hidden = true;
      } else {
        this.functionHeader.tags[0].hidden = false;
        this.functionHeader.tags[0].innerText = this.addTitle[this.params.radio];
      }
      setTimeout(() => {
        if (this.$refs.mTable) {
          this.$refs.mTable.refreshTable();
          this.$refs.mTable.setTableData(true);
        }
      }, 0);
    },
    getRowStyle({ row, rowIndex }) {
      let arr = this.removeDuplicatesByCode(this.newMaterialsChangeList)
      const selfIndex = arr.findIndex(
        (item) =>
          row.materialCode === item.materialCode && row.upMaterialCode === item.upMaterialCode
      );
      let isNotDuplicate = !this.newMaterialsChangeList.some(item => (
        item.materialCode === row.materialCode && item.upMaterialCode === row.upMaterialCode // 根据需要判断的属性来确定是否重复
      ))
      if (isNotDuplicate) {
        return { background: '#FFFFFF' }
      } else {
        return { background: colorList[selfIndex] }
      }
    },

    removeDuplicatesByCode(arr) {
      const uniqueArr = arr.reduce((unique, item) => {
        const exists = unique.some(
          (el) => el.materialCode === item.materialCode && el.upMaterialCode === item.upMaterialCode
        );
        if (!exists) {
          unique.push(item);
        }
        return unique;
      }, []);
      return uniqueArr;
    },
    viewCheckItem(row) {
      this.checkItemList = row.checkItemList || row.maintenanceItemList;
      this.checkItemVisible = true;
    },
    viewCheckDetail(row) {
      this.currentData = row;
      this.resultVisible = true;
    },
    viewSpareList(row) {
      this.currentRow = row;
      this.spareList = row.sparePartList || [];
      this.spareVisible = true;
    },
    getErrorCount(row) {
      if (row.status === 1 || row.status === 0 || row.status === 4 || !row.checkItemList) {
        return "";
      }
      return row.checkItemList.filter((item) => item.result === 0).length;
    },
    getUnMaintainCount(row) {
      if (row.status === 1 || row.status === 0 || row.status === 4 || !row.maintenanceItemList) {
        return "";
      }
      return row.maintenanceItemList.filter((item) => item.result !== 1).length;
    },
  },
};
</script>

    <style scoped lang="scss">
.account-detail-wrapper {
  .account-detail-header {
    width: 100%;
    padding: 10px 0;
  }
  .account-detail-body {
    padding-top: 10px;
    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #dde3eb;
      }
      // ::v-deep .el-tabs__nav {
      //   position: relative;
      //   left: 50%;
      //   transform: translateX(-50%) !important;
      // }
    }
    ::v-deep  {
      .el-tabs .el-tabs__content {
        padding: 0;
      }
   }
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.page-container {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
.result-wrapper {
  > div {
    display: flex;
    font-size: 16px;
    padding: 10px 0;
    span {
      &:first-child {
        width: 80px;
        text-align-last: justify;
        text-align: justify;
      }
    }
  }
}
::v-deep {
  .table-row-img {
    > img {
      height: 50px;
    }
  }
}
::v-deep {
  .el-form-item__content {
    height: 32px;
  }
}
.all-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .checkAll {
    margin-right: 20px;
  }
  .all-li {
    width: 80px;
    height: 26px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #dde3eb;
    margin-right: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a4b3c6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .active {
    color: #607fff;
    border-color: #607fff;
    background: #eff2ff;
  }
}
</style>
