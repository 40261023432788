<template>
  <div class="configuration-parameter">
    <div v-if="showTitle" class="configuration-parameter__title">配置参数</div>
    <div class="configuration-parameter__main">
      <el-button v-if="!isView && showTemplate" class="e-btn" size="small" type="primary" @click="selectTemplete">套用模板</el-button>
      <el-button v-if="!isView && !facilityId" size="small" type="primary" class="e-btn" @click="addParams">新增</el-button>
      <el-button v-if="facilityId" class="e-btn" size="small" type="primary" @click="add">新增</el-button>
      <MTable ref="mTable" :show-page="false" :height="tabHeight" :columns="columnsAdd" :data="parametersList" :columns-setting="false">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

        <div v-if="isView" slot="configurationParameter" slot-scope="{ row }">
          <div>{{ row.configurationParameter }}</div>
        </div>
        <div v-else slot="configurationParameter" slot-scope="{ row }" class="table-cell">
          <span class="required-star">*</span>
          <el-autocomplete
            v-model="row.configurationParameter"
            class="inline-input"
            :fetch-suggestions="querySearch"
            placeholder="请输入配置参数"
            :trigger-on-focus="false"
          />
        </div>

        <div v-if="isView" slot="configurationDetails" slot-scope="{ row }">
          <div>{{ row.configurationDetails }}</div>
        </div>
        <div v-else slot="configurationDetails" slot-scope="{ row }" class="table-cell">
          <el-input v-model="row.configurationDetails" :min="1" controls-position="right" :step="1" />
        </div>

        <div slot="action" slot-scope="{ $index, row }">
          <template v-if="facilityId">
            <el-button type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
            <el-divider direction="vertical" />
            <el-button type="text" @click="delInfo(row)">删除</el-button>
          </template>
          <template v-else>
            <el-button type="text" @click="delParams($index)">移除</el-button>
          </template>
        </div>
      </MTable>
    </div>
    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitFormBuilder">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>
    <SelectConfigurationParameter
      ref="selectConfigurationParameter"
      :visible.sync="showConfigurationParameter"
      @submitForm="selectConfigurationParameter"
    />
  </div>
</template>

<script>
import DelPopover from '@/components/DelPopover/index'
import { columnsAdd, columnsTable } from './columns'
import api from '@/api/xiewei/facility/configuration-parameter'
import templatesApi from '@/api/xiewei/facility/configuration'
import conventionMixin from '@/mixin/conventional-page'
import SelectConfigurationParameter from './selectConfigurationParameter/selectConfigurationParameter'

export default {
  name: 'ConfigurationParameter',
  components: { DelPopover, SelectConfigurationParameter },
  mixins: [conventionMixin],
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showTemplate: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    facilityId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      columnsTable,
      parametersList: [],
      visible: false,
      currentType: 'add',
      formData: {},
      params: {
        radio: 'FacilityParameter'
      },
      api,
      showConfigurationParameter: false,
      formList: [
        {
          key: 'configurationParameter',
          label: '配置参数',
          colSpan: 24,
          tagName: 'el-autocomplete',
          props: { fetchSuggestions: this.querySearch },
          required: true,
          attrs: {
            placeholder: '请输入配置参数'
          }
        },
        {
          key: 'configurationDetails',
          label: '配置详情',
          colSpan: 24,
          tagName: 'el-input',
          required: false
        }
      ]
    }
  },

  computed: {
    columnsAdd() {
      return this.isView && !this.facilityId ? columnsAdd.filter(item => item.slotName !== 'action') : columnsAdd
    },
    tabHeight() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 184
    }
  },

  watch: {
    list(newVal) {
      this.parametersList = newVal
    },
    parametersList(newVal) {
      this.$emit('update:list', newVal)
    }
  },

  mounted() {},

  methods: {
    // 添加参数
    addParams() {
      this.parametersList.push({})
    },

    // 删除参数
    delParams(index) {
      this.parametersList.splice(index, 1)
    },

    async querySearch(queryString, cb) {
      const res = await templatesApi.getParameterList({
        searchKeyword: queryString
      })
      const data = res.map(item => {
        return {
          value: item.configurationParameter,
          id: item.id
        }
      })
      cb(data)
    },

    createSetData(row) {
      return { ...row }
    },

    createResetData(row) {
      return Object.assign(this.createSetData(row), {
        id: row.id
      })
    },

    async delInfo(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api[`del${this.params.radio}`]({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$emit('refrash')
        }
      })
    },

    submitFormBuilder(callback) {
      const { form } = this.$refs.formBuild
      const data = {
        ...form,
        facilityId: this.facilityId
      }
      this.$refs.formBuild.formRefs().validate(async valid => {
        if (valid) {
          const res = await this.api[`${this.currentType}${this.params.radio}`](
            this.currentType !== 'modify' ? this.createSetData(data) : this.createResetData(data)
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            this.$emit('refrash')
            this.visible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },

    selectTemplete() {
      this.showConfigurationParameter = true
    },

    async selectConfigurationParameter(val) {
      this.showConfigurationParameter = false
      const res = await templatesApi.getTemplatesDetail({ id: val[0].id })
      this.parametersList = res.list || []
    }
  }
}
</script>

<style lang="scss" scoped>
.configuration-parameter {
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;

  &__title {
    height: 48px;
    background: #e5e7ea;
    border-radius: 2px 2px 0px 0px;
    line-height: 48px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    padding-left: 22px;
  }

  &__main {
    font-size: 14px;
    padding: 10px 15px;

    .e-btn {
      margin-bottom: 10px;
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      ::v-deep.el-autocomplete {
        width: 100%;
      }
    }
  }
}
</style>
