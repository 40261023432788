<template>
  <div class="apply-all">
    <div class="apply-bg">
      <div class="apply-container" style="">
        <div class="apply-header">
          <div><img src="@/assets/apply/logo.png" alt=""></div>
          <div style="display:flex;justify-content: space-between;align-items: center;">
            <div>应用中心</div>
            <div style="padding-left:40px;display: flex;align-items: center;">
              <img src="@/assets/apply/touxiang.png" alt="">
              <div style="display:inline-block;padding-left:10px;">{{ userInfo.name }}</div>
            </div>
          </div>
        </div>
        <div class="order">
          <div class="orderlist">
            <div class="orderd">

              <div><img :src="image" alt="" style="width:75px;height:75px;"></div>
              <div style="margin-left:10px;">
                <div>{{ applyTitle }}
                  <div
                    v-if="permission('Del')"
                    style="display:inline-block;cursor: pointer;"
                    @click="deleteApply(detailId)"
                  >
                    <i class="el-icon-delete" style="margin-left:20px" />
                    <span style="color:#607FFF;font-size:12px;margin-left:5px">删除</span>
                  </div>
                </div>
                <div class="apply-text">{{ applyContent }}</div>

                <div
                  :class="{ 'getActive': active }"
                  class="get-default"
                  @mouseenter="hoverGet()"
                  @mouseleave="hoverRemove()"
                  @click="getConsult()"
                >获取</div>
              </div>
            </div>
            <div class="orderd">
              <div>
                <span style="color:#607FFF;font-size:18px;">{{ price }}</span>
                <span style="font-size:12px;color:#c2c2d1;">元起</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="apply-container" style="display: flex;justify-content: space-between;">

      <!-- 应用选项卡 -->
      <div class="apply-tab">
        <el-tabs v-model="activeName">
          <el-tab-pane label="介绍" name="first">
            <!-- 编辑 -->
            <div v-if="isEdit" class="edit_container jieshao">
              <quill-editor
                ref="text"
                v-model="content"
                class="editor"
                :options="editorOption"
                @change="onEditorChange($event)"
                @ready="onEditorReady($event)"
              />
            </div>
            <div v-else class="ql-editor" v-html="content" />
          </el-tab-pane>
          <el-tab-pane label="付费方式" name="second">
            <div v-if="isEdit" class="edit_container pay">
              <quill-editor
                ref="text"
                v-model="paymode"
                class="editor"
                :options="editorOption2"
                @change="onEditorChange2($event)"
                @ready="onEditorReady2($event)"
              />
            </div>
            <div v-else class="ql-editor" v-html="paymode" />
          </el-tab-pane>
          <div v-show="isEdit" style="width:70px;height:50px;margin:0 auto">
            <el-button class="button" @click="onEditor">提交</el-button>
          </div>
        </el-tabs>

      </div>
      <!-- 编辑按钮-->
      <!-- v-if="userInfo.userName == 'mubai' || userInfo.userName == 'admin'" -->
      <div v-if="permission('Modify')" class="edit-button" @click="editApply()">
        编辑
      </div>
    </div>

  </div>
</template>
<script>
import { titleConfig } from './edit'
import api from '@/api/apply/market'
export default {
  name: 'ApplyDetails',
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      activeName: 'first',
      detailId: this.$route.query.id,
      image: '',
      applyTitle: '',
      applyContent: '',
      price: '',
      active: false,
      content: '',
      editorOption: {
        placeholder: '请输入内容...',
        theme: 'snow'
      },
      paymode: '',
      editorOption2: {
        placeholder: '请输入付费方式...',
        theme: 'snow'
      },
      isEdit: false
    }
  },
  created() {
    this.getApplyDetails()
  },
  mounted() {

  },
  methods: {
    async getApplyDetails() {
      const res = await api.getApplyDetails({ id: this.$route.query.id })
      if (res) {
        this.image = res.picture
        this.applyTitle = res.name
        this.applyContent = res.description
        this.price = res.cost
        this.content = res.introduction
        this.paymode = res.payment
      }
    },
    // 删除
    async deleteApply(id) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'

      }).then(async() => {
        const res = await api.delApply({ id: id })
        if (res) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.$router.push({ path: '/apply' })
        }
      }).catch(() => {

      })
    },
    // 获取
    getConsult() {
      this.$confirm('请联系客户开通，联系方式：021-3412 7798', '咨询', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
      }).catch(() => {

      })
    },
    hoverGet() {
      this.active = true
    },
    hoverRemove() {
      this.active = false
    },

    // 编辑
    editApply() {
      this.isEdit = true
    },
    // 提交修改
    async onEditor() {
      const res = await api.modifyApplyDetails({
        id: this.$route.query.id,
        introduction: this.content,
        payment: this.paymode
      })
      if (res) {
        this.$message.success('修改成功')
        this.getApplyDetails()
        this.isEdit = false
      }
    },
    // 介绍内容改变事件
    onEditorChange({ quill, html, text }) {
      this.content = html
    },
    onEditorReady(quill) {
      this.initTitle()
      document.querySelector('.jieshao div.ql-editor').innerHTML = this.content
    },
    onEditorReady2(quill) {
      this.initTitle()
      document.querySelector('.pay div.ql-editor').innerHTML = this.paymode
    },
    // 工具栏中文化
    initTitle() {
      for (const item of titleConfig) {
        const tip = document.querySelectorAll('.quill-editor ' + item.Choice)
        if (!tip) continue
        for (let i = 0; i < tip.length; i++) {
          tip[i].setAttribute('title', item.title)
        }
      }
    },
    // 付费方式改变事件
    onEditorChange2({ quill, html, text }) {
      this.paymode = html
    }
  }
}

</script>
<style scoped lang="scss">
.apply-all {
  overflow: auto;
  position: absolute;
  width: 100%;
}

.apply-container {
  width: 80%;
  margin: 0 auto;
}

.apply-header {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

}

.apply-bg {
  background: url('https://oss.mubyte.cn/static/bj.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.apply-tab {
  ::v-deep .el-tabs {
    .el-tabs__header {
      .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {

          .el-tabs__nav {
            .el-tabs__active-bar {
              width: 100px;
            }

            .el-tabs__item {
              width: 100px;
              padding: 0;
              text-align: center;
            }
          }
        }
      }
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }

  }
}

.order {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.orderd {
  margin-top: 10px;
  display: flex;
}

.get-default {
  background-color: #dfe5fb;
  width: 80px;
  height: 23px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
  color: #607fff;
  margin-top: 10px;
}

.getActive {
  background-color: #607fff;
  color: #fff;
  cursor: pointer;
}

.apply-text {
  font-size: 12px;
  color: #c2c2d1;
  margin-top: 10px;
}

.edit-button {
  width: 80px;
  height: 25px;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  color: #607fff;
  border: 1px solid #607fff;
  position: absolute;
  right: 10%;
  margin-top: 10px;
  cursor: pointer;
}

.edit_container {
  height: 320px;

  .editor {
    height: 240px;
  }
}
</style>
<style lang="scss">
.edit_container{
 .ql-snow .ql-tooltip[data-mode=link]::before {
  content: "请输入链接地址:" !important;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px !important;
  content: '保存' !important;
  padding-right: 0px !important;
}

.ql-snow .ql-tooltip[data-mode=video]::before {
  content: "请输入视频地址:" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '14px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  content: '10px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: '18px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  content: '32px' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: '标题1' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: '标题2' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: '标题3' !important;

}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {

  content: '标题4' !important;

}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {

  content: '标题5' !important;

}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,

ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {

  content: '标题6' !important;

}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,

.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体' !important;

}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  content: '衬线字体' !important;

}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  content: '等宽字体' !important;

}
}
</style>
