<template>
  <div class="package-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="CallClassify">异常类型</el-radio-button>
<!--      <el-radio-button label="JudgSettings">判定人设置</el-radio-button>-->
      <el-radio-button label="CallReason">异常原因</el-radio-button>
    </el-radio-group>
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :search-title="searchTitle[params.radio]"
      :tags="functionHeader.tags"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable ref="mTable" :columns="columns[params.radio]" :columns-key="columnsKey" :height="height"
            :set-data-method="getTableData">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}"/>
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="abnormalTypeId" slot-scope="{ row }">
        <div>{{ row.abnormalTypeId | abnormalTypeName }}</div>
      </div>
      <div slot="users" slot-scope="{ row }">
        <el-button type="text" @click="viewUserAndUserGroups(row)">查看</el-button>
      </div>

      <div slot="users" slot-scope="{ row }">
        <el-button type="text" @click="viewUserAndUserGroups(row)">查看</el-button>
      </div>
      <div slot="type" slot-scope="{ row }">
        {{ row.type|abnormalClassifyCriteria }}
      </div>
      <div slot="majorType" slot-scope="{ row }">
        {{ row.majorType|majorTypeOptions }}
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('SetUser') && params.radio === 'JudgSettings'" type="text"
                   @click="setUserAndUserGroup(row)">设置人员
        </el-button>
        <el-divider v-if="permission('SetUser') && params.radio === 'JudgSettings'" direction="vertical"/>

        <el-button v-if="permission('Status') && params.radio === 'CallClassify'" type="text"
                   @click="switchStatus(row,params.radio)">{{ row.isEnabled ? '停用' : '启用' }}
        </el-button>
        <el-divider v-if="permission('Status') && params.radio === 'CallClassify'" direction="vertical"/>

        <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission('Del')" direction="vertical"/>

        <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
        <!-- <el-divider v-if="permission('Del')" direction="vertical" /> -->
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]"/>
    </MDialog>

    <MDialog v-model="setPersonVisible" title="选择呼叫通知人员" @onOk="submitUserAndUserGroup">
      <SelectUserAndUserGroup ref="userAndUserGroup" v-model="transferSelectIds"/>
    </MDialog>
    <MDialog v-model="showRelation" title="关联流程" @onOk="submitRelation">
      <div class="item-wrapper">
        <div class="label">选择流程：</div>
        <div>
          <el-radio-group v-model="defaultRelation">
            <el-radio v-for="items in lists" :key="items.id" :label="items.id">
              {{ items.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </MDialog>

    <TableDialog title="查看人员" :show.sync="userVisible" :columns="userColumns" :table-data="userAndGroupList"/>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/call/classify'
import { CallClassify, JudgSettings,CallReason, getFunctionList } from './columns'
import formList from './form-list'
import TableDialog from '@/components/TableDialog/TableDialog'
import { userColumns } from '@/views/information/production/station/columns'
import SelectUserAndUserGroup from '@/components/SelectUserAndUserGroup/SelectUserAndUserGroup'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { abnormalClassifyCriteria, majorTypeOptions } from '@/config/options.config'

var callListArr = []

export default {
  name: 'ExceptionSetting',
  components: { SelectUserAndUserGroup, TableDialog, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      userVisible: false,
      userAndGroupList: [],
      userColumns,
      params: {
        radio: 'CallClassify'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ]
      },
      columns: {
        CallClassify,
        JudgSettings,
        CallReason
      },
      visible: false,
      formList,
      formData: {},
      currentType: 'add',
      setPersonVisible: false,
      transferSelectIds: [],
      showRelation: false,
      searchTitle: {
        CallClassify: '请输入异常类型',
        JudgSettings: '请输入异常分类',
        CallReason: '请输入异常原因'
      },
      lists: [
        {
          id: 0,
          name: '无'
        },
        {
          id: 1,
          name: '设备报修'
        },
        {
          id: 2,
          name: '质检'
        }
      ],
      defaultRelation: '',
      baseDate: {},
      callList:[]
    }
  },
  beforeMount() {
    getFunctionList({
      typeChange: this.typeChange
    })
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 245
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    }
  },
  filters: {
    abnormalClassifyCriteria(val) {
      if (val == 0 || val) return abnormalClassifyCriteria.find((item) => item.id === val).name
    },
    majorTypeOptions(val) {
      if (val == 0 || val) return majorTypeOptions.find((item) => item.id === val).name
    },
    abnormalTypeName(val) {
      if (val == 0 || val) {
        return callListArr.find((item) => item.id === val).name
      }
    }
  },
  methods: {
    async radioChange() {
      if(this.params.radio === 'CallReason'){
        const callList = await this.api.getCallClassifyListPage({
          page: 1,
          limit: 100
        })
        this.callList = callList.records
        callListArr = callList.records
        this.formList[this.params.radio][2].children[0].options = callList.records
      }
      this.$refs.mTable.setTableData(true)
    },
    async typeChange(val) {
      if (this.$refs.formBuild) {
        this.$refs.formBuild.setForm({
          classifyId: ''
        })
      }
      this.formList[this.params.radio][1].children[0].options = []
      let res
      if (val === 0) {
        res = await api.getBusinessUnitList()
      } else {
        res = await api.getPullDownList()
      }
      if (res) {
        this.formList[this.params.radio][1].children[0].options = res
      }
    },
    async getTableData(condition, callback) {
      const { page } = condition
      const params = {
        search: this.functionHeader.searchVal
      }
      const res = await this.api[`get${this.params.radio}ListPage`]({
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      })
      if (res) {
        this.showTable = res.total
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    async submitRelation(done) {
      const res = await api.modifyCallClassify({
        id: this.baseDate.id,
        callProcess: this.defaultRelation ? this.defaultRelation : 0
      })
      if (res) {
        this.$message.success('关联成功')
        this.showRelation = false
        this.$refs.mTable.setTableData()
      }
      done()
    },
    del(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api[`del${this.params.radio}`]({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    relation(row) {
      this.baseDate = this._.cloneDeep(row)
      this.defaultRelation = this.baseDate.callProcess
      console.log(this.baseDate, '999=>', this.defaultRelation)
      this.showRelation = true
    },
    async setUserAndUserGroup(row) {
      let res
      if (this.params.radio === 'CallClassify') {
        res = await api.getCallTypeUsers(row.id)
      }else {
        res = await api.getJudgSettingsUsers(row.id)
      }
      if (res) {
        this.formData = row
        const user = res.userList ? res.userList.map(item => item.key) : []
        const userGroup = res.userGroupList ? res.userGroupList.map(item => item.key) : []
        this.transferSelectIds = [...userGroup, ...user]
        this.setPersonVisible = true
      }
    },
    async viewUserAndUserGroups(row) {
      let res
      if (this.params.radio === 'CallClassify') {
        res = await api.getCallTypeUsers(row.id)
      }else {
        res = await api.getJudgSettingsUsers(row.id)
      }
      if (res) {
        const user = res.userList || []
        const userGroup = res.userGroupList || []
        this.userAndGroupList = [...userGroup, ...user]
        if (this.userAndGroupList.length) {
          this.userVisible = true
        } else {
          this.$message.info('暂未设置人员！')
        }
      }
    },
    submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api[`${this.currentType}${this.params.radio}`](
            this.currentType !== 'modify' ? this.createSetData(form) : this.createResetData(form)
          )
          if (res) {
            // this.setUser(form.name)
            this.$message.success(this.getSuccessMessage())
            if (this.continuousAdd) {
              this.formData = {}
            } else {
              this.visible = false
            }
            this.$refs.mTable.setTableData()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    async setUser(name) {
      let res
      if (this.params.radio === 'CallClassify') {
        res = await api.getCallClassifyList({
          searchVal: Encrypt(JSON.stringify([{
            id: getUUid(),
            fieldName: 'name',
            fieldType: 'string',
            fieldValue: name,
            operator: 'eq'
          }]))
        })
      } else {
        res = await api.getJudgSettingsList()
      }

      if (res) {
        this.setUserAndUserGroup(res[0])
      }
    },
    async submitUserAndUserGroup(callback) {
      if (this.transferSelectIds.length) {
        const formatIds = this.$refs.userAndUserGroup.getFormatData()
        let res
        if (this.params.radio === 'CallClassify') {
          res = await api.configUser({
            id: this.formData.id,
            userGroupIdList: formatIds.userGroup,
            userIdList: formatIds.user
          })
        } else {
          res = await api.configJudgSettingsUser({
            id: this.formData.id,
            userGroupIdList: formatIds.userGroup,
            userIdList: formatIds.user
          })
        }

        if (res) {
          callback()
          this.setPersonVisible = false
          this.$message.success('设置成功')
        }
        callback()
      } else {
        this.$message.info('请选择用户或用户组')
        callback()
      }
    },
    createSetData(raw) {
      if(this.params.radio === 'CallClassify'){
        return {
          name: raw.name,
          isEnabled: raw.isEnabled,
          remark: raw.remark
        }
      }else if(this.params.radio === 'JudgSettings'){
        return {
          type: raw.type,
          classifyId: raw.classifyId,
          majorType: raw.majorType
        }
      }else if(this.params.radio === 'CallReason'){
        return {
          code: raw.code,
          name: raw.name,
          abnormalTypeId: raw.abnormalTypeId,
          isEnabled: raw.isEnabled,
          remark: raw.remark
        }
      }
      // return this.params.radio === 'CallClassify' ? {
      //   name: raw.name,
      //   isEnabled: raw.isEnabled,
      //   remark: raw.remark
      // } : {
      //   type: raw.type,
      //   classifyId: raw.classifyId,
      //   majorType: raw.majorType
      // }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.package-wrap {
  padding: 10px;

  .item-wrapper {
    display: flex;
    justify-content: flex-start;

    .label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
    }
  }
}
</style>

