var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        _vm._b(
          {
            ref: "upload",
            attrs: { "file-list": _vm.fileList, "list-type": "picture-card" },
            scopedSlots: _vm._u([
              {
                key: "file",
                fn: function(ref) {
                  var file = ref.file
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-image", {
                        ref: "imageView",
                        staticClass: "el-upload-list__item-thumbnail",
                        attrs: { src: file.url, "preview-src-list": [file.url] }
                      }),
                      _c(
                        "span",
                        { staticClass: "el-upload-list__item-actions" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "el-upload-list__item-preview",
                              on: {
                                click: function($event) {
                                  return _vm.handlePictureCardPreview(file)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-zoom-in" })]
                          ),
                          !_vm.disabled
                            ? _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDownload(file)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-download" })]
                              )
                            : _vm._e(),
                          !_vm.disabled
                            ? _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleRemove(file)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          },
          "el-upload",
          _vm.uploadProps,
          false
        ),
        [
          _c("i", {
            staticClass: "el-icon-plus",
            attrs: { slot: "default" },
            slot: "default"
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }