import { CallClassify,JudgSettings,CallReason } from './columns'
import { transformColumnsToForm } from '@/utils'

const formList = {
    JudgSettings: transformColumnsToForm(JudgSettings),
    CallClassify: transformColumnsToForm(CallClassify),
    CallReason:transformColumnsToForm(CallReason),
  }

  export default formList
