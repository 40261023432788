<template>
  <div class="inventory-account-container">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data="searchFormData"
        @search="searchTable"
      >
        <!-- <SelectMultipleMaterials slot="materialsM" ref="multipleMaterial" @selectChange="materialsChange" /> -->
      </SearchForm>
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入申请单号"
        :tags="!selectData.length ? functionHeader.tags : []"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export="!selectData.length ? true : false"
        @search="$refs.mTable.setTableData(true)"
      >
      <div slot="center" v-show="selectData.length">
        <el-button v-if="permission('Del')" type="primary" size="small" @click="more({}, 'batchDel')">批量删除</el-button>
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="more({}, 'batchExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="status" slot-scope="{ row }">{{ row.status | statusName }}</div>
        <div slot="emergencyLevel" slot-scope="{ row }">{{ row.emergencyLevel | emergency }}</div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="permission('Submit')" :disabled="row.status == 1" type="text" @click="submitApply(row)">提交</el-button>
          <el-divider v-if="permission('Submit')" direction="vertical" />

          <el-button v-if="permission('Modify')" :disabled="row.status == 1" type="text" @click="modifyOrCopy(row, 'modify')">编辑</el-button>
          <el-divider v-if="permission('Modify')" direction="vertical" />

          <el-button v-if="permission('Del')" :disabled="row.status == 1" type="text" @click="del(row, 'del')">删除</el-button>
        </div>
      </MTable>
    </div>

    <CURDDialog :visible.sync="visible" :data="formData" @submit="submitDialog" />
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import { searchFormList } from './form-list'
import api from '@/api/equipment/part-apply'
import SelectMultipleMaterials from '@/components/SelectMultipleMaterials/SelectMultipleMaterials'
import { inType, outType } from '@/config/options.config'
import userApi from '@/api/sets/user/user'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { export_json_to_excel } from '@/utils/plug/to-excel'
import CURDDialog from './components/add-part.vue'

export default {
  name: 'PartApply',
  components: { SelectMultipleMaterials, SearchForm, FunctionHeader, CURDDialog },
  filters: {
    statusName(val) {
      switch (val) {
        case 0: return '待提交'
        case 1: return '已提交'
        default: return '已采购'
      }
    },
    emergency(val) {
      switch (val) {
        default: return '一般'
        case 1: return '紧急'
        case 2: return '特级'
      }
    }
  },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'SparePartProcurement'
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新建申请',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'sparePartProcurement',
        exportParams: {}
      },
      inOrOutTypeList: [outType, inType],
      inOrOutType: '',
      type: 0,
      selectData: [],
      visible: false,
      formData: {},
      searchFieldName: 'applicationNumber',
      extraParams: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 62
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }

  },
  mounted() {
    this.getUserList()
  },
  methods: {
    // 删除
    async del(row) {
      const res = await api.delSparePart({
        id: row.id
      })
      if (res) {
        this.$message.success('删除成功')
        this.$refs.mTable.setTableData(true)
      }
    },

    async getUserList() {
      const res = await userApi.getUserList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },
    // 提交申请
    async submitApply(row) {
      const res = await api.submitSparePartProcurement({ id: row.id })
      if (res) {
        this.$message.success('提交成功')
        this.$refs.mTable.setTableData()
      }
    },
    async modifyOrCopy(row, command) {
      console.log(row)
      const res = await api.getSparePartProcurementDetail(row.id)
      if (res) {
        this.currentType = command
        this.formData = res
        this.visible = true
      }
    },
    // materialsChange(key, val) {
    //   this.extraParams = []
    //   if (val) {
    //     this.extraParams.push({
    //       id: getUUid(),
    //       fieldName: 'materialsId',
    //       fieldType: 'number',
    //       fieldValue: val.id,
    //       operator: 'eq'
    //     })
    //   }
    //   this.$refs.mTable.setTableData(true)
    // },
    selectionChange(data) {
      this.selectData = data
    },
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },

    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    // 更多操作
    more(row, command) {
      const functions = {
        batchDel: () => {
          const validate = this.selectData.every(item => item.status !== 1)
          if (!validate) {
            return this.$message.info('选中数据中包含已提交数据')
          }
          this.delBatch()
        },
        batchExport: () => { this.batchExportFront() }
      }
      functions[command]()
    },
    batchExportFront() {
      this.$refs.functionHeader.export({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.selectData.map(item => item.id).join('^'),
          operator: 'in'
        }]))
      })
    },
    delBatch() {
      this.currentType = 'del'
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delBatchApply(this.selectData.map(item => item.id))
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    batchExport() {
      const params = {
        key: this.columns.map(item => item.prop),
        header: this.columns.map(item => item.label),
        content: this.selectData,
        merges: [],
        data: this.tableData,
        filename: '错误数据'
      }
      export_json_to_excel(params)
    },
    submitDialog() {
      this.$refs.mTable.setTableData()
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.inventory-account-container {
  width: 100%;

  .bottom {
    padding: 10px;
  }
}
::v-deep .customer-content div {
  width: 100%;
}
.input-with-select {
  width: 100%;

  .input-select {
    width: 105px !important;
  }
}
::v-deep {
  .el-input-group {
    width: 100%;

    .el-input-group__prepend {
      width: 105px;
    }
  }
}
</style>
