/** write by luomingzhi */
import { equipmentStatus, equipmentType, status } from '@/config/options.config'

export const columns = [
  {
    prop: 'code',
    label: '设备编码'
  },
  {
    prop: 'name',
    label: '设备名称'
  },
  {
    prop: 'equipmentClassType',
    label: '设备属性'
  },
  {
    prop: 'status',
    label: '设备使用状态',
    form: { options: equipmentStatus }
  },
  {
    prop: 'buyTime',
    label: '采购日期'
  },
  {
    prop: 'equipmentClassName',
    label: '设备类'
  },
  {
    prop: 'isEnabled',
    label: '设备状态',
    form: { options: status }
  },
  {
    prop: 'stationName',
    label: '所属工位'
  },
  {
    prop: 'remark',
    label: '备注'
  }
]

export const maintainColumns = [
  {
    prop: 'workOrderCode',
    label: '保养单号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'workOrderCode'
  },
  {
    prop: 'facilityPlanName',
    label: '保养计划',
    sortable: false,
    hiddenSearch: true,
    slotName: 'facilityPlanName'
  },
  {
    prop: 'level',
    label: '保养级别',
    sortable: false,
    hiddenSearch: true,
    slotName: 'level'
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'executionStartTime',
    label: '服务时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executionStartTime',
    width: 340
  },
  {
    prop: 'executorName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  }
]

export const repairsColumns = [
  {
    prop: 'workOrderCode',
    label: '工单编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'workOrderCode'
  },
  {
    prop: 'demandCode',
    label: '关联需求',
    sortable: false,
    hiddenSearch: true,
    slotName: 'demandCode'
  },
  {
    prop: 'faultCode',
    label: '故障码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'faultTime',
    label: '故障时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'executionStartTime',
    label: '服务时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executionStartTime',
    width: 340
  },
  {
    prop: 'executorName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  }
]

export const spotCheckColumns = [
  {
    prop: 'workOrderCode',
    label: '点检单号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'workOrderCode'
  },
  {
    prop: 'facilitySpotCheckPlanName',
    label: '点检计划',
    sortable: false,
    hiddenSearch: true,
    slotName: 'facilitySpotCheckPlanName'
  },
  {
    prop: 'executiveOutcome',
    label: '执行结果',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executiveOutcome'
  },
  {
    prop: 'processMode',
    label: '异常处理',
    sortable: false,
    hiddenSearch: true,
    slotName: 'processMode'
  },
  {
    prop: 'maintenanceWorkOrder',
    label: '维修工单',
    hiddenSearch: true,
    sortable: false,
    slotName: 'maintenanceWorkOrder'
  },
  {
    prop: 'executionStartTime',
    label: '执行时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executionStartTime',
    width: 340
  },
  {
    prop: 'executorName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  }
]

export const fileColumns = [
  {
    prop: 'name',
    label: '附件名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name'
  },
  {
    prop: 'uploadTime',
    label: '上传时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'user',
    label: '上传人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]

export const reformColumns = [
  {
    prop: 'workOrderCode',
    label: '工单编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'workOrderCode'
  },
  {
    prop: 'demandCode',
    label: '关联需求',
    sortable: false,
    hiddenSearch: true,
    slotName: 'demandCode'
  },
  {
    prop: 'executionStartTime',
    label: '服务时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executionStartTime',
    width: 340
  },
  {
    prop: 'executorName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  }
]

export const sparePartColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'materialsCode'
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'usageQuantity',
    label: '使用数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'ownershipSparePartsAssets',
    label: '备件资产所属',
    sortable: false,
    hiddenSearch: true,
    slotName: 'ownershipSparePartsAssets'
  },
  {
    prop: 'orderCoder',
    label: '关联工单',
    sortable: false,
    hiddenSearch: true,
    slotName: 'orderCoder'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    width: 340
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  }
]

export const processColumns = [
  {
    prop: 'name',
    label: '装机步骤',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'updateTime',
    label: '完成时间',
    sortable: false,
    hiddenSearch: true,
    width: 340
  },
  {
    prop: 'updaterName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  }
]

export const installedColumns = [
  {
    prop: 'workOrderCode',
    label: '工单编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'workOrderCode'
  },
  {
    prop: 'demandCode',
    label: '关联需求',
    sortable: false,
    hiddenSearch: true,
    slotName: 'demandCode'
  },
  {
    prop: 'executionStartTime',
    label: '服务时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executionStartTime',
    width: 340
  },
  {
    prop: 'executorName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  },
]

export const auxiliaryMaintainColumns = [
  {
    prop: 'className',
    label: '辅材类型',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'code',
    label: '辅材编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'sn',
    label: '辅材SN',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'lastUpdateTime',
    label: '换上时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'cumulativeUsageTime',
    label: '当前使用时长',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'updater',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
]

export const auxiliaryRecordColumns = [
  {
    prop: 'operationType',
    label: '操作类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'operationType'
  },
  {
    prop: 'operationTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'operatorName',
    label: '操作人',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'auxiliaryClassName',
    label: '辅材类型',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'auxiliaryMaterialCode',
    label: '辅材编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'auxiliaryMaterialSn',
    label: '辅材SN',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerName',
    label: '关联客户',
    sortable: false,
    hiddenSearch: true
  }
]

export const auxiliaryRecordColumns2 = [
  {
    prop: 'operationType',
    label: '操作类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'operationType'
  },
  {
    prop: 'operationTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'operatorName',
    label: '操作人',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'singleUserTime',
    label: '单次使用时长',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'addUpUserTime',
    label: '累计使用时长',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'equipmentName',
    label: '关联设备',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerName',
    label: '关联客户',
    sortable: false,
    hiddenSearch: true
  }
]

export const auxiliaryInstructionRecordColumns = [
  {
    prop: 'billCode',
    label: '配置单号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'inTime',
    label: '运入时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'inOperatorName',
    label: '运入操作人',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'outTime',
    label: '运出时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'outOperatorName',
    label: '运出操作人',
    sortable: false,
    hiddenSearch: true,
  },

  {
    slotName: 'action',
    label: '配置明细',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

export const configurationColumns= [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'instruction',
    label: '配置说明',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'shipOutMatch',
    label: '运出匹配',
    sortable: false,
    hiddenSearch: true,
    slotName: 'shipOutMatch'
  },
  {
    prop: 'shipInMatch',
    label: '运入匹配',
    sortable: false,
    hiddenSearch: true,
    slotName: 'shipInMatch'
  }
]
