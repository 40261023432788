import { transformColumnsToForm } from '@/utils'
import { columns } from './columns'
import { dateList, status } from '@/config/options.config'
import projectSettingColumns from '@/views/equipment/project-setting/columns'
import dayjs from 'dayjs'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

const wrapByFormItem = (data) => {
  const newData = []
  data.forEach(item => {
    newData.push({
      tagName: 'el-form-item',
      props: {
        prop: item.key
      },
      style: { margin: 0, position: 'relative' },
      children: [item]
    })
  })
  return newData
}

const numberAndDateFormList = (key1, key2) => [
  {
    key: key1,
    tagName: 'el-input-number',
    defaultValue: 1,
    props: { controlsPosition: 'right', min: 0, placeholder: '请输入' },
    children: []
  },
  {
    key: key2,
    tagName: 'el-select',
    defaultValue: 0,
    props: { clearable: false, filterable: false },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: dateList(key1 === 'remindTime' ? 'hour' : 'year')
      }
    ]
  }
]
const formList = [
  ...transformColumnsToForm(columns),
  {
    index: 4,
    key: 'planTime',
    label: '计划开始日期',
    tagName: 'el-date-picker',
    required: true,
    props: {
      pickerOptions: {
        disabledDate: (date) => {
          return dayjs().format('YYYY-MM-DD') > dayjs(date).format('YYYY-MM-DD')
        }
      }
    },
    children: []
  },
  {
    index: 5,
    label: '提醒提前',
    tagName: 'div',
    required: true,
    key: 'remindTime',
    labelTip: '巡检任务单提前生成的时间',
    domProps: { className: 'date-number' },
    children: wrapByFormItem(numberAndDateFormList('remindTime', 'remindTimeType'))
  },
  {
    index: 5,
    label: '',
    colSpan: 12,
    tagName: 'div',
    required: false,
    children: []
  },
  {
    index: 6,
    label: '循环方式',
    key: 'cycleType',
    required: true,
    colSpan: 6,
    tagName: 'el-radio-group',
    defaultValue: 0,
    on: {
      change: (val) => { functionList.cycleTypeChange(val) }
    },
    children: [{
      tagName: 'el-radio',
      innerValue: 'name',
      options: [
        {
          id: 0,
          name: '单次'
        },
        {
          id: 1,
          name: '多次'
        }
      ],
      props: {
        label: 'id'
      }
    }]
  },
  {
    hidden: true,
    index: 7,
    label: '循环周期',
    key: 'cycleTime',
    tagName: 'div',
    required: true,
    domProps: { className: 'date-number' },
    children: wrapByFormItem(numberAndDateFormList('cycleTime', 'cycleTimeType'))
  },
  {
    index: 8,
    label: '允许跳过',
    key: 'isPass',
    colSpan: 24,
    tagName: 'el-radio-group',
    defaultValue: 1,
    required: true,
    children: [{
      tagName: 'el-radio',
      innerValue: 'name',
      options: [
        {
          id: 1,
          name: '允许'
        },
        {
          id: 0,
          name: '不允许'
        }
      ],
      props: {
        label: 'id'
      }
    }]
  },
  {
    index: 9,
    label: '启用状态',
    colSpan: 24,
    key: 'isEnabled',
    tagName: 'el-radio-group',
    required: true,
    defaultValue: 1,
    children: [{
      tagName: 'el-radio',
      innerValue: 'name',
      options: [
        {
          id: 1,
          name: '启用'
        },
        {
          id: 0,
          name: '不启用'
        }
      ],
      props: {
        label: 'id'
      }
    }]
  },
  {
    index: 10,
    label: '是否在开始巡检时，校验机台存在该计划早期未完成巡检的任务',
    key: 'isExist',
    tagName: 'el-radio-group',
    required: true,
    colSpan: 24,
    defaultValue: 1,
    on: {
      change: (val) => {}
    },
    children: [{
      tagName: 'el-radio',
      innerValue: 'name',
      options: [
        {
          id: 1,
          name: '启用'
        },
        {
          id: 0,
          name: '不启用'
        }
      ],
      props: {
        label: 'id'
      }
    }]
  },
  {
    index: 11,
    label: '备注',
    colSpan: 24,
    key: 'remark',
    tagName: 'el-input',
    required: false,
    props: { type: 'textarea' },
    children: []
  }
]

formList.sort((a, b) => a.index - b.index)
formList.forEach(item => {
  item.colSpan = (item.colSpan === 24 || item.label === '') ? item.colSpan : 6
})

const searchFormList = [
  {
    key: 'checkType',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择巡检类别'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    key: 'equipmentClassIds',
    tagName: 'el-cascader',
    colSpan: 6,
    props: {
      placeholder: '请选择设备类',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true,
        multiple: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  },
  {
    key: 'isEnabled',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择是否生效'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: status
      }
    ]
  }
]

const addInspectionItemFormList = [
  ...transformColumnsToForm([...projectSettingColumns.PointCheckItem, {
    prop: 'sketchMap',
    label: '操作图',
    form: {
      index: 4,
      tagName: 'div',
      key: 'sketchMap',
      required: false,
      colSpan: 24,
      customSlots: ['sketchMap']
    }
  }])
]

export default {
  formList,
  searchFormList,
  addInspectionItemFormList
}
