export const columnsAdd = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 20
  },
  {
    prop: 'configurationParameter',
    label: '配置参数',
    sortable: false,
    hiddenSearch: true,
    slotName: 'configurationParameter'
  },
  {
    prop: 'configurationDetails',
    label: '配置详情',
    sortable: false,
    hiddenSearch: true,
    slotName: 'configurationDetails'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 60
  }
]

export const columnsTable = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 20
  },
  {
    prop: 'configurationParameter',
    label: '配置参数模板名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'configurationParameter'
  },
  {
    prop: 'configurationDetails',
    label: '配置详情',
    sortable: false,
    hiddenSearch: true,
    slotName: 'configurationDetails'
  }
]
