<template>
  <div class="procedure-detail-wrappers">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder ref="baseForm" :form-list="getFormList" :form-data="baseFormData" label-position="right" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">关联设备</div>
        <div class="card-bot">
          <relationEquipment
            :customer-id="customerId"
            :list="facilityMappingList"
            :selecte-facility-list="baseFormData.selecteFacility"
            @facilityInfo="facilityInfo"
            @selectFacilitys="selecteFacilitys"
          />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">保养项目</div>
        <div class="card-bot">
          <ItemsInfo :list="itemMappingList" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">备件更换</div>
        <div class="card-bot">
          <MaterialExchange :list="materialsMappingList" @materialsInfo="materialsInfo" />
        </div>
      </div>
    </div>
    <div class="order-footer">
      <div class="footer-le" />
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
    <MDialog v-model="delVisible" :append-to-body="true" title="修改计划" width="500px" @onOk="submitFormDel">
      <div>
        <div style="margin-bottom: 10px">修改计划后，是否删除与原计划相关联的待执行任务工单？</div>
        <el-radio-group v-model="isDel">
          <el-radio :label="1">删除</el-radio>
          <el-radio :label="0">保留</el-radio>
        </el-radio-group>
      </div>
    </MDialog>
  </div>
</template>

<script>
import { formList } from './form-list'
import { Encrypt } from '@/utils/sercet'
import { getUUid, setTreeData } from '@/utils'
import ItemsInfo from '@/views/facility/facility-maintenance/components/items-info'
import MaterialExchange from '@/views/facility/facility-maintenance/components/material-exchange'
import relationEquipment from '@/views/facility/facility-maintenance/components/relation-equipment'
import api from '@/api/xiewei/facility/maintenance-template'
import uApi from '@/api/sets/user/user'
import fieldApi from '@/api/xiewei/field/field'
import keApi from '@/api/information/unit'
import { getFunctionList } from './columns'

export default {
  name: 'AddFacilityMaintenance',
  components: { ItemsInfo, MaterialExchange, relationEquipment },
  data() {
    return {
      baseFormData: {},
      formList,
      params: {},
      messages: {
        add: '新增成功',
        modify: '修改成功',
        viewO: '保养计划详情',
        addO: '新增保养计划',
        modifyO: '修改保养计划'
      },
      buttonLoading: false,
      itemMappingList: [],
      materialsMappingList: [],
      facilityMappingList: [],
      userData: {},
      selecteFacility: [],
      delVisible: false,
      isDel: 1,
      selectList: [],
      materialList: [],
      allCustomer: [],
      customerId: undefined,
      cycleType: 0
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getFormList() {
      const formList = this.formList['FacilityMaintenancePlan']
      if (this.cycleType === 1) {
        return formList.filter(item => item.key !== 'toleranceRange')
      } else {
        return formList
      }
    }
  },
  beforeMount() {
    getFunctionList({
      mulChange: this.mulChange,
      typeChange: this.typeChange
    })
  },
  mounted() {
    this.params = this.$route.query
    if (this.params.templateId) {
      this.getPlanMaintenanceTemplateDetail()
    }
    this.getUser()
    this.getAllCustomer()
    if (this.params.type !== 'add') {
      this.getFacilityMaintenancePlanDetail()
    }
    if (this.params.type !== 'view') {
      this.createFieldCode()
    }
  },
  methods: {
    mulChange(val) {
      const arr = this.allCustomer.filter(item => item.id === this._.first(val))
      const list = this.allCustomer.filter(item => item.id === this._.last(val))
      this.$refs.baseForm.setForm({
        customerId: +list[0].isCustomer === 0 ? list[0].id : arr[0].id,
        customerSiteId: +list[0].isCustomer === 1 ? list[0].id : '',
        customerName: list[0].name
      })
      this.customerId = arr[0].id
      this.facilityMappingList = []
      this.baseFormData.selecteFacility = ''
    },

    customerChange(val) {
      this.customerId = val
      this.facilityMappingList = []
      this.baseFormData.selecteFacility = ''
    },

    typeChange(val) {
      this.cycleType = val
    },

    async createFieldCode() {
      const res = await fieldApi.getFieldIsExist({
        numberVersion: '保养计划'
      })
      if (res && +res === 0) {
        if (['add', 'copy'].includes(this.params.type)) {
          this.$refs.baseForm.setForm({
            code: '系统生成'
          })
          this.baseFormData.code = '系统生成'
        }
        this.formList['FacilityMaintenancePlan'][0].props.disabled = true
      }
    },
    selecteFacilitys(val) {
      this.selecteFacility = val
    },
    returns() {
      this.$router.back()
    },
    async getPlanMaintenanceTemplateDetail() {
      const res = await api.getPlanMaintenanceTemplateDetail({ id: this.$route.query.templateId })
      if (res) {
        this.showDetail(res)
      }
    },
    async getFacilityMaintenancePlanDetail() {
      const res = await api.getFacilityMaintenancePlanDetail({ id: this.$route.query.id })
      if (res) {
        this.showDetail(res)
      }
    },
    showDetail(res) {
      this.baseFormData = Object.assign({}, res, {
        operatorIds: res.operatorIds ? res.operatorIds.split('^').map(item => Number(item)) : [],
        lifeCycle: res.lifeCycle || res.lifeCycle === 0 ? res.lifeCycle : undefined,
        toleranceRange: res.toleranceRange || res.toleranceRange === 0 ? res.toleranceRange : undefined,
        reminderTime: res.reminderTime || res.reminderTime === 0 ? res.reminderTime : undefined,
        code: this.params.type === 'copy'||this.params.type === 'add' ? this.baseFormData.code : res.code,
        customerSiteIdList: res.customerSiteIdList ? res.customerSiteIdList.split('^').map(item => Number(item)) : []
      })
      this.cycleType = this.baseFormData.cycleType
      this.customerId = this.baseFormData.customerId
      this.itemMappingList = res.itemMappingList || []
      this.materialsMappingList =
        res.materialsMappingList && res.materialsMappingList.length > 0
          ? res.materialsMappingList.map(item => {
            return {
              ...item,
              code: item.materialsCode,
              name: item.materialsName,
              quantity: item.quantity || item.quantity === 0 ? item.quantity : undefined
            }
          })
          : []
      this.facilityMappingList =
        res.facilityMappingList && res.facilityMappingList.length > 0
          ? res.facilityMappingList.map(item => {
            return {
              ...item,
              code: item.facilityCode,
              name: item.facilityName
            }
          })
          : []
      this.selecteFacility = res.selecteFacility ? JSON.parse(res.selecteFacility) : []
    },
    materialsInfo(val) {
      this.materialsMappingList = val
    },
    facilityInfo(val) {
      this.facilityMappingList = val
    },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.formList['FacilityMaintenancePlan'][2].props.options = setTreeData(data)
      }
    },

    // 获取执行人
    async getUser() {
      const res = await uApi.getUserList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.formList['FacilityMaintenancePlan'][10].children[0].options = res
      }
    },

    submitForm() {
      const that = this
      let list = []
      let facilityMappingList = []
      this.buttonLoading = true
      const { form } = this.$refs.baseForm
      if (form.lifeCycle === 0 || form.lifeCycle < 0) {
        this.buttonLoading = false
        return this.$message.error('周期必须大于0')
      }
      if ((form.toleranceRange || form.toleranceRange === 0) && (form.toleranceRange < 0 || form.toleranceRange === 0)) {
        this.buttonLoading = false
        return this.$message.error('容差范围必须大于0')
      }
      if (form.reminderTime === undefined || form.reminderTime === 0 || form.reminderTime < 0) {
        this.buttonLoading = false
        return this.$message.error('提醒提前期必须大于0')
      }
      if (this.facilityMappingList.length === 0) {
        this.buttonLoading = false
        return this.$message.error('请至少添加一台设备')
      }
      if (this.itemMappingList.length === 0) {
        this.buttonLoading = false
        return this.$message.error('请至少添加一个保养项目')
      }
      if (this.materialsMappingList.length > 0) {
        const isTrue = this.materialsMappingList.every(item => item.quantity > 0 && item.quantity !== 0)
        if (!isTrue) {
          this.buttonLoading = false
          return this.$message.error('备件更换里的数量必须大于0')
        }
        list = this.materialsMappingList.map(item => {
          return {
            materialsId: item.materialsId,
            materialsCode: item.code,
            materialsName: item.name,
            materialsTypeName: item.materialsTypeName,
            mainUnitName: item.mainUnitName,
            quantity: item.quantity,
            describeInfo: item.describeInfo
          }
        })
      }
      if (this.facilityMappingList.length > 0) {
        facilityMappingList = this.facilityMappingList.map(item => {
          return {
            facilityId: item.facilityId,
            facilityCode: item.code,
            facilityName: item.name,
            sn: item.sn,
            model: item.model,
            equipmentClassName: item.equipmentClassName,
            customerName: item.customerName
          }
        })
      }
      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          if (this.params.type === 'modify') {
            // this.delVisible = true
            // this.isDel = 1
            // this.buttonLoading = false
            // this.selectList = facilityMappingList
            // this.materialList = list
            that.saveData(
              Object.assign(
                {},
                {
                  ...that.createResetData(form),
                  isDelete: 0,
                  itemMappingList: that.itemMappingList,
                  materialsMappingList: list,
                  facilityMappingList: facilityMappingList,
                  selecteFacility: that.selecteFacility.length > 0 ? JSON.stringify(that.selecteFacility) : ''
                }
              )
            )
            return
          }
          that.saveData(
            Object.assign(
              {},
              {
                ...that.createSetData(form),
                itemMappingList: that.itemMappingList,
                materialsMappingList: list,
                facilityMappingList: facilityMappingList,
                selecteFacility: that.selecteFacility.length > 0 ? JSON.stringify(that.selecteFacility) : ''
              }
            )
          )
        } else {
          this.buttonLoading = false
        }
      })
    },
    async submitFormDel(callback) {
      const { form } = this.$refs.baseForm
      await this.saveData(
        Object.assign(
          {},
          {
            ...this.createResetData(form),
            isDelete: this.isDel,
            itemMappingList: this.itemMappingList,
            materialsMappingList: this.materialList,
            facilityMappingList: this.selectList,
            selecteFacility: this.selecteFacility.length > 0 ? JSON.stringify(this.selecteFacility) : ''
          }
        )
      )
      callback()
    },
    async saveData(form) {
      const res = await api[`${this.params.type}FacilityMaintenancePlan`](form)
      this.buttonLoading = false
      if (res) {
        this.$router.push({ name: 'facilityMaintenanceList' })
      }
      this.delVisible = false
    },
    createSetData(raw) {
      return {
        code: raw.code === '系统生成' ? -1 : raw.code,
        name: raw.name,
        level: raw.level,
        cycleType: raw.cycleType || raw.cycleType === 0 ? raw.cycleType : -1,
        lifeCycle: raw.lifeCycle || raw.lifeCycle === 0 ? raw.lifeCycle : -1,
        lifeCycleType: 0,
        toleranceRange: raw.toleranceRange || raw.toleranceRange === 0 ? raw.toleranceRange : -1,
        reminderTime: raw.reminderTime ? raw.reminderTime : -1,
        reminderTimeType: raw.reminderTimeType,
        operatorIds: raw.operatorIds ? raw.operatorIds.join('^') : '',
        remark: raw.remark,
        startTime: raw.startTime,
        customerId: raw.customerId,
        customerSiteId: raw.customerSiteId,
        customerSiteIdList: raw.customerSiteIdList ? raw.customerSiteIdList.join('^') : '',
        customerName: raw.customerName,
        salesOrderNumber: raw.salesOrderNumber
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.procedure-detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }
  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.flex-mode {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    margin-right: 20px;
  }
  ::v-deep .el-radio-group {
    vertical-align: initial;
  }
  ::v-deep .el-radio {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456b;
  }
}
.flex-modes {
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 20px;
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    margin-right: 20px;
  }
  .mode-ri {
    .ri-top {
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;
      .top-tit {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393d60;
        line-height: 36px;
      }
      .ri-num {
        width: 130px;
        margin: 0 20px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dde3eb;
      }
    }
  }
}
.bot-tis {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #393d60;
  display: inline-block;
}
</style>
