<template>
  <div class="memberRouteSetting_container" :style="{height: chromeHeight + 'px'}">
    <div class="container_left">
      <h2>对象列表</h2>
      <template>
        <div v-for="(item,index) in userList" :class="{
          userItem:true,active: index === curtenIndex
        }"
             @click="viewCheckItem(index)">
          {{ item.name }}
        </div>
      </template>
    </div>
    <div class="container_right">
      <div class="all-btns">
        <el-button :loading="loading" size="small" type="primary" class="role-btn" @click="saveRole">保存</el-button>
      </div>
      <el-table
        :data="columnsList"
        height="700"
        border
        style="width: 100%">
        <el-table-column
          width="160"
          prop="name"
          label="字段名称">
        </el-table-column>
<!--        <el-table-column-->
<!--          prop="code"-->
<!--          label="字段代码">-->
<!--        </el-table-column>-->
        <el-table-column
          width="110"
          label="字段类型">
          <template slot-scope="scope">
            <el-select v-model="scope.row.type" placeholder="请选择" disabled>
              <el-option
                v-for="item in typeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          prop="typeFlag"
          label="类型标识">
        </el-table-column>
        <el-table-column
          width="150"
          label="字段别名">
          <template slot-scope="scope">
            <el-input v-model="scope.row.newName" />
          </template>
        </el-table-column>
        <el-table-column
          width="170"
          label="显示/隐藏">
          <template slot-scope="scope">
            <el-radio v-model="scope.row.showFlag" :label="1">显示</el-radio>
            <el-radio v-model="scope.row.showFlag" :label="0">隐藏</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          label="是否必填">
          <template slot-scope="scope">
            <el-radio v-model="scope.row.required" :label="1">是</el-radio>
            <el-radio v-model="scope.row.required" :label="0">否</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          label="格式正则验证">
          <template slot-scope="scope">
            <el-input v-model="scope.row.pattern" />
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="格式错误提示">
          <template slot-scope="scope">
            <el-input v-model="scope.row.errMessage" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import api from '@/api/columnsSetApi'

export default {
  name: 'MemberRouteSet',
  data() {
    return {
      userList: [

      ],
      columnsList:[
        // {
        //   id:1,
        //   name:'需求编号',
        //   code:'code',
        //   type:1,
        //   showFlag:1,
        //   newName:'需求流水111',
        //   required:1,
        //   pattern:'',
        //   errMessage:''
        // },
        // {
        //   id:2,
        //   name:'服务类型',
        //   code:'type',
        //   type:1,
        //   showFlag:1,
        //   newName:'服务类型222',
        //   pattern:'/\d{6}/',
        //   errMessage:"请输入6位数字",
        //   required:1,
        // },
        // {
        //   id:3,
        //   name:'销售订单号',
        //   code:'salesOrderNumber',
        //   type:1,
        //   showFlag:1,
        //   newName:'销售订单号333',
        //   pattern:'/^XSDD\\d{6}$/',
        //   errMessage:"格式错误，应以XSDD开头，后6位数字7777",
        //   required:1,
        // },
      ],
      curtenIndex: 0,
      loading: false,
      selectedUserMsg:{},
      typeOptions:[
        {
          id: 1,
          name: '文本'
        },
        {
          id: 2,
          name: '数字'
        },
        {
          id: 3,
          name: '人员'
        },
        {
          id: 4,
          name: '日期'
        }
      ]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192 - 35
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    chromeHeight() {
      return document.documentElement.clientHeight - 120
    }
  },
  async created() {
    const res = await api.getColumnsList()
    if (res) {
      this.userList = res
      this.viewCheckItem(0)
    }
  },
  beforeMount() {

  },
  methods: {
    async viewCheckItem(index) {
      this.curtenIndex = index

      const userRoute = await api.getColumnsDetail({code:this.userList[index].code})
      if (userRoute) {
        this.columnsList = userRoute.sort((a,b) => a.id - b.id)
      }else {
        this.columnsList = []
      }
    },
    async saveRole() {
      this.loading = true

      const res = await api.setColumnsDetail(this.columnsList)
      if (res) {
        this.$message.success('保存成功')
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-table thead {
  color: #909399;
  font-weight: 500;
}
.el-table .has-gutter th {
  background-color: #f5f7fa!important;
}
.memberRouteSetting_container {
  width: 100%;
  box-sizing: border-box;
  display: flex;

  .container_left {
    width: 15%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 10px;

    .userItem {
      height: 45px;
      padding-left: 15px;
      font-size: 15px;
      line-height: 45px;
      cursor: pointer;

      &.active {
        color: #FFFFFF;
        background: #717bdf;
        font-weight: bold;
      }
    }
  }

  .container_right {
    width: 85%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;

    .all-btns {
      display: flex;
      justify-content: flex-end;
      padding: 15px;
      .label {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      .role-btn {
        height: 32px;
        margin-top: 4px;
      }
    }
  }
}
</style>

