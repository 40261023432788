import inspectionSearchFormList from '@/views/equipment/inspection-bills/form-list'
import pointSearchFormList from '@/views/equipment/point-bills/form-list'
import maintenanceSearchFormList from '@/views/equipment/maintenance-bills/form-list'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'

const cloneInspectionSearchFormList = cloneDeep(inspectionSearchFormList.searchFormList)
cloneInspectionSearchFormList.splice(2, 1)
const clonePointSearchFormList = cloneDeep(pointSearchFormList.searchFormList)
clonePointSearchFormList.splice(2, 1)
const cloneMaintenanceSearchFormList = cloneDeep(maintenanceSearchFormList.searchFormList)
cloneMaintenanceSearchFormList.splice(2, 1)
const pickerOptions = {
  shortcuts: [{
    text: '今天',
    onClick(picker) {
      const end = dayjs().format('YYYY-MM-DD 23:59:59')
      const start = dayjs().format('YYYY-MM-DD 00:00:00')
      // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: '昨天',
    onClick(picker) {
      const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
      const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
      // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一周',
    onClick(picker) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      picker.$emit('pick', [start, end])
    }
  }]
}

export const faultSearchFormList = [
  {
    colSpan: 6,
    key: 'reportTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '报修开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'datetimerange',
      defaultTime: ['00:00:00', '23:59:59'],
      pickerOptions
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '故障发生开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'datetimerange',
      defaultTime: ['00:00:00', '23:59:59'],
      pickerOptions
    },
    children: []
  },
  {
    key: 'reporterId',
    tagName: 'el-select',
    colSpan: 4,
    props: {
      placeholder: '请选择报修人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'emergenceDegreeId',
    tagName: 'el-select',
    colSpan: 4,
    props: {
      placeholder: '请选择紧急程度'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]

export const inspectionFormList = cloneInspectionSearchFormList
export const pointFormList = clonePointSearchFormList
export const maintenanceFormList = cloneMaintenanceSearchFormList
