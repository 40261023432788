
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

const PointCheckItem = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '检查项',
    sortable: false,
    hiddenSearch: true,
    form: { index: 0, tagName: 'el-input', props: { type: 'textarea' }}
  },
  {
    prop: 'needUpload',
    label: '是否必须上传图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'needUpload',
    form: {
      index: 2,
      required: false,
      tagName: 'el-switch',
      props: {
        activeText: '是',
        inactiveText: '否',
        activeValue: 1,
        inactiveValue: 0
      }
    }
  },
  {
    prop: 'standard',
    label: '检查标准',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 98
  }
]

const PointCheckItemAdd = (type = 'inspection') => [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: type === 'inspection' ? '巡检项' : '点检项',
    sortable: false,
    hiddenSearch: true,
    form: { index: 0, tagName: 'el-input', props: { type: 'textarea' }}
  },
  {
    prop: 'needUpload',
    label: '是否必须上传图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'needUpload',
    form: {
      index: 2,
      required: false,
      tagName: 'el-switch',
      props: {
        activeText: '是',
        inactiveText: '否',
        activeValue: 1,
        inactiveValue: 0
      }
    }
  },
  {
    prop: 'standard',
    label: type === 'inspection' ? '巡检标准' : '点检标准',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]

const PointCheckItemView = (type = 'inspection') => [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: type === 'inspection' ? '巡检项' : '点检项',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'needUpload',
    label: '是否必须上传图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'needUpload',
    width: 150
  },
  {
    prop: 'standard',
    label: type === 'inspection' ? '巡检标准' : '点检标准',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sketchMap',
    label: '操作图',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sketchMap'
  }
]

const SelectCheckItem = [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  ...PointCheckItem.slice(0, 4)
]

const PointCheckType = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'spotCheckType',
    label: '点检类别',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 0,
      tagName: 'el-input',
      on: {
        blur: (val) => { functionList.checkEquipmentClassChange(val) }
      }
    }
  },
  {
    prop: 'pointCheckItem',
    label: '点检项',
    sortable: false,
    hiddenSearch: true,
    slotName: 'pointCheckItem',
    form: {
      index: 2,
      tagName: 'div',
      key: 'checkItemList',
      colSpan: 24,
      required: false,
      customSlots: ['pointCheckItem']
    }
  },
  {
    prop: 'name',
    label: '设备类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-cascader',
      key: 'equipmentClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.checkEquipmentClassChange(val) }
      }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 98
  }
]

const Inspection = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '设备类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-cascader',
      key: 'equipmentClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.equipmentClassChange(val) }
      }
    }
  },
  {
    prop: 'pointCheckItem',
    label: '巡检项',
    sortable: false,
    hiddenSearch: true,
    slotName: 'pointCheckItem',
    form: {
      index: 2,
      tagName: 'div',
      key: 'checkItemList',
      required: false,
      colSpan: 24,
      customSlots: ['pointCheckItem']
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 98
  }
]

export default {
  PointCheckItem,
  PointCheckItemAdd,
  PointCheckItemView,
  PointCheckType,
  Inspection,
  SelectCheckItem
}
