<template>
  <div class="scrap-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('ExperienceList')" label="Experience">维保经验</el-radio-button>
      <el-radio-button v-if="permission('FaultReasonList')" label="FaultReason">故障原因</el-radio-button>
      <el-radio-button v-if="permission('UrgencyLevelList')" label="UrgencyLevel">紧急程度</el-radio-button>
      <el-radio-button v-if="permission('EquipmentPositionList')" label="EquipmentPosition">设备部位</el-radio-button>
      <el-radio-button v-if="permission('FlowSettingList')" label="FlowSetting">流程设置</el-radio-button>
      <el-radio-button v-if="permission('MaintenanceProjectList')" label="MaintenanceProject">保养项目</el-radio-button>
      <el-radio-button v-if="permission('MaintenanceSettingList')" label="MaintenanceSetting">保养配置</el-radio-button>
    </el-radio-group>
    <template v-if="params.radio !== 'FlowSetting'">
      <div class="top">
        <SearchForm
          v-if="['Experience', 'MaintenanceSetting'].includes(params.radio)"
          ref="searchForm"
          :form-list-extend="searchFormList[params.radio]"
          @search="searchTable"
        />
        <div v-else style="height: 57px;border-bottom: 1px solid #DDE3EB" />
      </div>
      <div class="bottom">
        <FunctionHeader
          v-model="functionHeader.searchVal"
          :search-title="params.radio === 'Experience' ? '请输入经验编码/设备类' : '请输入保养项'"
          :tags="functionHeader.tags"
          :radio="params.radio"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          :import-name="functionHeader.importName"
          :hidden-search="!['Experience', 'MaintenanceProject'].includes(params.radio)"
          :columns-key="columnsKey"
          @search="$refs.mTable.setTableData(true)"
        />
        <MTable v-if="permission(params.radio + 'List')" ref="mTable" :columns="columns[params.radio]" :height="height" :columns-key="columnsKey" :columns-setting="params.radio === 'Experience' || params.radio === 'MaintenanceProject' || params.radio === 'FaultReason'|| params.radio === 'UrgencyLevel'|| params.radio === 'EquipmentPosition' " :set-data-method="getTableData">
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          <div slot="experienceType" slot-scope="{ row }">{{ row.type | experienceTypeName }}</div>
          <el-button slot="pointCheckItem" slot-scope="{ row }" type="text" @click="viewCheckItem(row)">查看</el-button>
          <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
            <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
            <div>{{ row.needUpload ? '是' : '否' }}</div>
          </div>
          <div slot="action" slot-scope="{ row }">
            <el-button v-if="params.radio !== 'MaintenanceSetting' && permission(params.radio + 'Copy')" type="text" @click="modifyOrCopy(row, 'copy')">复制</el-button>
            <el-divider v-if="params.radio !== 'MaintenanceSetting' && permission(params.radio + 'Copy')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
            <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
            <DelPopover v-if="permission(params.radio + 'Del')" @onOk="(callback) => deleteRow(row, callback)" />
          </div>
        </MTable>

        <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
          <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]">
            <div slot="pointCheckItem">
              <el-button type="primary" style="margin-bottom: 10px" @click="pushCheckItem">添加</el-button>
              <MTable ref="pointCheckTable" :height="$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 192" :columns="columns.MaintenanceProject" :columns-setting="false" :show-page="false" :data="selectCheckCheckItem">
                <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
                <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
                  <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
                  <div>{{ row.needUpload ? '是' : '否' }}</div>
                </div>
                <div slot="action" slot-scope="{ row, $index }">
                  <el-button type="text" @click="uploadPic(row, $index)">操作图</el-button>
                  <el-divider direction="vertical" />
                  <el-button type="text" @click="delRow(row, $index)">删除</el-button>
                </div>
              </MTable>
            </div>
          </MFormBuilder>
        </MDialog>
        <MDialog v-model="selectCheckItemVisible" title="添加保养项" @onOk="submitSelectCheckItem">
          <FunctionHeader
            v-model="functionHeaderS.searchVal"
            :search-title="'请输入保养项'"
            :tags="functionHeaderS.tags"
            :radio="params.radio"
            :columns-setting="false"
            @search="$refs.pointCheckTableSelect.setTableData(true)"
          />
          <MTable ref="pointCheckTableSelect" :height="$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 56" :columns="columns.SelectMaintenanceProject" :columns-setting="false" :set-data-method="getCheckItemTableData" @selection-change="selectionChange">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
              <div>{{ row.needUpload ? '是' : '否' }}</div>
            </div>
          </MTable>
        </MDialog>
        <TableDialog title="查看保养项" :show.sync="checkItemVisible" :columns="columns.MaintenanceProjectView" :table-data="checkItemList" />

        <MDialog v-model="uploadPicVisible" title="上传操作图" @onOk="submitUploadPic">
          <upload-pic ref="uploadPic" :upload-file-list="uploadFileList" />
        </MDialog>
        <MDialog v-model="dialogAddVisible" title="新增维保" @onOk="submitDialogAddForm">
          <MFormBuilder ref="formBuildAdd" :form-list="formList.MaintenanceProject" />
        </MDialog>
      </div>
    </template>
    <div v-else style="padding: 20px;">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]" />
    </div>
  </div>
</template>

<script>
import columns, { getFunctionList as getFunctionList2 } from './columns'
import { formList, searchFormList, getFunctionList } from './form-list'
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import equipmentClassApi from '@/api/information/equipment-class'
import api from '@/api/equipment/maintenance'
import userApi from '@/api/sets/user/user'
import DelPopover from '@/components/DelPopover/index'
import { setTreeData } from '@/utils'
import { experienceType } from '@/config/options.config'
import baseSetting from '@/api/sets/base-setting'
import { Encrypt } from '@/utils/sercet'
import settingMinix from '@/views/equipment/project-setting/setting.minix'
import TableDialog from '@/components/TableDialog/TableDialog'
import UploadPic from '@/views/equipment/project-setting/upload-pic'

export default {
  name: 'MaintenanceSetting',
  components: { UploadPic, TableDialog, DelPopover, SearchForm, FunctionHeader },
  filters: {
    experienceTypeName(id) {
      if (!id && id !== 0) return ''
      return experienceType.find(item => item.id === id).name
    }
  },
  mixins: [conventionMixin, settingMinix],
  data() {
    return {
      api,
      params: {
        radio: 'Experience'
      },
      columns,
      searchFormList,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {

            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: () => {
                this.add()
                if (this.params.radio === 'Experience') this.createCode()
              }
            }
          }
        ],
        exportName: 'exportExperience',
        importName: '',
        exportParams: {}
      },
      functionHeaderS: {
        searchVal: '',
        tags: [
          {

            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.dialogAdd
            }
          }
        ]
      },
      formList,
      formData: {},
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制'
      },
      currentType: 'add',
      visible: false,
      stationVisible: false,
      stationList: [],
      formOptions: {
        equipmentClass: [],
        userList: []
      },
      getTableDataParams: {},
      searchKeyword: true,
      settingConfigs: [],
      settingGeyKey: 'MaintenanceProject',
      dialogAddKey: 'MaintenanceProject',
      continuousAdd: false
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    columnsKey() {
      console.log('ck:',this.$route.name + this.params.radio)
      return this.$route.name + this.params.radio
    }
  },
  beforeMount() {
    getFunctionList({
      submitFlowSetting: this.submitFlowSetting
    })
    getFunctionList2({
      equipmentClassChange: this.equipmentClassChange
    })
  },
  mounted() {
    this.getEquipmentClassList()
    this.getUserList()
  },
  methods: {
    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        const equipmentClassList = setTreeData(res)
        this.searchFormList.Experience[1].props.options = equipmentClassList
        this.formList.Experience[2].props.options = equipmentClassList
        this.formList.MaintenanceSetting[0].props.options = equipmentClassList
        this.searchFormList.MaintenanceSetting[0].props.options = equipmentClassList
        this.formOptions.equipmentClass = res
      }
    },
    async getUserList() {
      const res = await userApi.getUserList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.formOptions.userList = res
        this.formList.FlowSetting[2].children[0].options = res
      }
    },
    async getBaseSetting() {
      const res = await baseSetting.getList2()
      if (res) {
        this.settingConfigs = res
        const ischeck = res.find(item => item.code === 'fault_repair_need_review').value
        if (ischeck == 0) {
          this.formList.FlowSetting[2].hidden = true
        } else {
          this.formList.FlowSetting[2].hidden = false
        }
        this.$refs.formBuild.setForm({
          'fault_repair_need_review': +res.find(item => item.code === 'fault_repair_need_review').value,
          'maintenance_need_check': +res.find(item => item.code === 'maintenance_need_check').value,
          'fault_check_users': JSON.parse(res.find(item => item.code === 'fault_check_users').value).map(item => item.id)
        })
      }
    },
    async createCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api.createCode({
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    radioChange(label) {
      this.functionHeader.exportName = `export${label}`
      this.functionHeader.importName = label === 'MaintenanceProject' ? 'maintenanceProject' : ''
      this.searchFormatData = []
      this.getTableDataParams = {}
      if (label !== 'FlowSetting') {
        if (this.$refs.searchForm) this.$refs.searchForm.reset()
        else this.$refs.mTable.setTableData(true)
      } else {
        this.getBaseSetting()
      }
    },
    add() {
      this.formData = {}
      this.selectCheckCheckItem = []
      this.currentType = 'add'
      this.continuousAdd = this.params.radio === 'MaintenanceProject'
      this.visible = true
    },
    modifyOrCopy(row, command) {
      this.formData = row
      this.continuousAdd = false
      if (this.formData.equipmentClassIdList && row.remark) {
        this.formData.equipmentClassIdList = JSON.parse(row.remark)
      }
      if (this.params.radio === 'MaintenanceSetting') {
        this.selectCheckCheckItem = this._.cloneDeep(row.maintenanceItemList)
      }
      this.currentType = command
      this.visible = true
      if (command === 'copy') this.createCode()
    },
    async deleteRow(row, callback) {
      if (this.params.radio !== 'MaintenanceSetting') {
        await this.del(row, callback)
      } else {
        const res = await api.delMaintenanceSetting(row.equipmentClassId)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      }
    },
    viewCheckItem(row) {
      this.checkItemList = row.maintenanceItemList
      this.checkItemVisible = true
    },
    searchTable(data, val) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('equipmentClassIds')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
        this.getTableDataParams = { equipmentClassIds: data[hasIndex].fieldValue.map(item => this._.last(item)).join('^') }
        this.searchFormatData.splice(hasIndex, 1)
      }
      if (val === 1) {
        this.getTableDataParams = {}
      }
      this.$refs.mTable.setTableData(true)
    },
    async submitFlowSetting(code, val) {
      const current = this.settingConfigs.find(item => item.code === code)
      let newVal = val
      if (code === 'fault_check_users') {
        const ids = this.$refs.formBuild.form['fault_check_users']
        newVal = this.formOptions.userList.filter(item => ids.includes(item.id)).map(item => ({ id: item.id, name: item.name }))
      }
      const res = await baseSetting.modifySingleSetting({
        id: current.id,
        code,
        value: JSON.stringify(newVal)
      })
      if (res) {
        await this.getBaseSetting()
      }
    },
    createSetData(raw) {
      let obj = {}
      switch (this.params.radio) {
        case 'Experience':
          obj = {
            code: raw.code,
            type: raw.type,
            faultDesc: raw.faultDesc,
            repairDesc: raw.repairDesc,
            equipmentClassIdList: raw.equipmentClassIdList.map(item => this._.last(item)),
            remark: JSON.stringify(raw.equipmentClassIdList)
          }
          break
        case 'FaultReason':
          obj = {
            reason: raw.reason
          }
          break
        case 'UrgencyLevel':
          obj = {
            name: raw.name
          }
          break
        case 'EquipmentPosition':
          obj = {
            part: raw.part
          }
          break
        case 'MaintenanceProject':
          obj = {
            name: raw.name,
            standard: raw.standard,
            needUpload: raw.needUpload || 0
          }
          break
        case 'MaintenanceSetting':
          obj = {
            equipmentClassId: Array.isArray(raw.equipmentClassId) ? this._.last(raw.equipmentClassId) : raw.equipmentClassId,
            maintenanceItemList: this.selectCheckCheckItem.map(item => ({
              id: item.id,
              name: item.name,
              standard: item.standard,
              needUpload: item.needUpload || 0,
              sketchMap: item.sketchMap
            }))
          }
          break
        default: break
      }
      return obj
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;
  .bottom {
    padding: 10px;
  }
  .m-radio-group {
    margin-left: 10px;
  }
}
.role-tags {
  margin: 5px;
  border-color: #DDE3EB;
  color: #9597AE;
}
</style>
