import _ from 'lodash'
import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(columns)

export const formList = transformColumnsToForm(newColumns)

export const searchFormList = [
  {
    colSpan: 6,
    key: 'model',
    tagName: 'el-input',
    attrs: {
      placeholder: '型号'
    },
    props: {
      clearable: true
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'customer_id',
    tagName: 'el-select',
    props: {
      placeholder: '客户'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]

