<template>
  <div class="package-wrap">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入类型名称"
      :tags="functionHeader.tags"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="users" slot-scope="{ row }">
        <el-button type="text" @click="viewUserAndUserGroups(row)">查看</el-button>
      </div>
      <div slot="action" slot-scope="{ row }">
        <!-- <el-button v-if="permission('SetUser')" type="text" @click="setUserAndUserGroup(row)">设置人员</el-button>
        <el-divider v-if="permission('SetUser')" direction="vertical" />
        <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission('Modify')" direction="vertical" />
        <el-dropdown @command="(command) => { more(row, command) }">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
            <el-dropdown-item v-if="permission('Relation')" command="relation">关联流程</el-dropdown-item>
            <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
            <el-dropdown-item v-if="permission('Status')" command="status">{{ row.isEnabled ? '停用' : '启用' }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->

        <!-- 新的 ————————Start———————— -->
        <el-button v-if="permission('SetUser')" type="text" @click="setUserAndUserGroup(row)">设置人员</el-button>
        <el-divider v-if="permission('SetUser')" direction="vertical" />

        <el-button v-if="permission('Relation')" type="text" @click="relation(row)">关联流程</el-button>
        <el-divider v-if="permission('Relation')" direction="vertical" />

        <el-button v-if="permission('Status')" type="text" @click="switchStatus(row,params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" />

        <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission('Del')" direction="vertical" />

        <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
        <!-- 新的 ————————End———————— -->
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>

    <MDialog v-model="setPersonVisible" title="选择呼叫通知人员" @onOk="submitUserAndUserGroup">
      <SelectUserAndUserGroup ref="userAndUserGroup" v-model="transferSelectIds" />
    </MDialog>
    <MDialog v-model="showRelation" title="关联流程" @onOk="submitRelation">
      <div class="item-wrapper">
        <div class="label">选择流程：</div>
        <div>
          <el-radio-group v-model="defaultRelation">
            <el-radio v-for="items in lists" :key="items.id" :label="items.id">
              {{ items.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </MDialog>

    <TableDialog title="查看人员" :show.sync="userVisible" :columns="userColumns" :table-data="userAndGroupList" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/call/classify'
import columns from './columns'
import formList from './form-list'
import TableDialog from '@/components/TableDialog/TableDialog'
import { userColumns } from '@/views/information/production/station/columns'
import SelectUserAndUserGroup from '@/components/SelectUserAndUserGroup/SelectUserAndUserGroup'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'CallClassify',
  components: { SelectUserAndUserGroup, TableDialog, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      userVisible: false,
      userAndGroupList: [],
      userColumns,
      params: {
        radio: 'CallClassify'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ]
      },
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      setPersonVisible: false,
      transferSelectIds: [],
      showRelation: false,
      lists: [
        {
          id: 0,
          name: '无'
        },
        {
          id: 1,
          name: '设备报修'
        },
        {
          id: 2,
          name: '质检'
        }
      ],
      defaultRelation: '',
      baseDate: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  methods: {
    async submitRelation(done) {
      // if (this.defaultRelation === '') {
      //   this.$message.error('请选择流程')
      //   done()
      //   return false
      // }
      const res = await api.modifyCallClassify({
        id: this.baseDate.id,
        callProcess: this.defaultRelation ? this.defaultRelation : 0
      })
      if (res) {
        this.$message.success('关联成功')
        this.showRelation = false
        this.$refs.mTable.setTableData()
      }
      done()
    },
    // 更多操作
    more(row, command) {
      const functions = {
        status: () => { this.switchStatus(row, this.params.radio) },
        del: () => { this.del(row) },
        relation: () => { this.relation(row) }
      }
      functions[command]()
    },
    relation(row) {
      this.baseDate = this._.cloneDeep(row)
      this.defaultRelation = this.baseDate.callProcess
      console.log(this.baseDate, '999=>', this.defaultRelation)
      this.showRelation = true
    },
    async setUserAndUserGroup(row) {
      const res = await api.getCallTypeUsers(row.id)
      if (res) {
        this.formData = row
        const user = res.userList ? res.userList.map(item => item.key) : []
        const userGroup = res.userGroupList ? res.userGroupList.map(item => item.key) : []
        this.transferSelectIds = [...userGroup, ...user]
        this.setPersonVisible = true
      }
    },
    async viewUserAndUserGroups(row) {
      const res = await api.getCallTypeUsers(row.id)
      if (res) {
        const user = res.userList || []
        const userGroup = res.userGroupList || []
        this.userAndGroupList = [...userGroup, ...user]
        if (this.userAndGroupList.length) {
          this.userVisible = true
        } else {
          this.$message.info('暂未设置人员！')
        }
      }
    },
    submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api[`${this.currentType}${this.params.radio}`](
            this.currentType !== 'modify' ? this.createSetData(form) : this.createResetData(form)
          )
          if (res) {
            this.setUser(form.name)
            this.$message.success(this.getSuccessMessage())
            if (this.continuousAdd) {
              this.formData = {}
            } else {
              this.visible = false
            }
            this.$refs.mTable.setTableData()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    async setUser(name) {
      const res = await api.getCallClassifyList({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'name',
          fieldType: 'string',
          fieldValue: name,
          operator: 'eq'
        }]))
      })
      if (res) {
        this.setUserAndUserGroup(res[0])
      }
    },
    async submitUserAndUserGroup(callback) {
      if (this.transferSelectIds.length) {
        const formatIds = this.$refs.userAndUserGroup.getFormatData()
        const res = await api.configUser({
          id: this.formData.id,
          userGroupIdList: formatIds.userGroup,
          userIdList: formatIds.user
        })
        if (res) {
          callback()
          this.setPersonVisible = false
          this.$message.success('设置成功')
        }
        callback()
      } else {
        this.$message.info('请选择用户或用户组')
        callback()
      }
    },
    createSetData(raw) {
      return {
        name: raw.name,
        isEnabled: raw.isEnabled,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.package-wrap {
  padding: 10px;
  .function-header-wrap {
    padding-top: 0;
  }
  .item-wrapper {
    display: flex;
    justify-content: flex-start;
    .label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
    }
  }
}
</style>

