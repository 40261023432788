import { experienceType } from '@/config/options.config'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

const Experience = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '经验编码',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'type',
    label: '经验类别',
    sortable: false,
    hiddenSearch: true,
    slotName: 'experienceType',
    form: { tagName: 'el-select', options: experienceType }
  },
  {
    prop: 'equipmentClassNames',
    label: '设备类',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-cascader',
      key: 'equipmentClassIdList',
      props: {
        props: {
          label: 'name',
          value: 'id',
          multiple: true,
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
  },
  {
    prop: 'faultDesc',
    label: '故障描述',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      colSpan: 24,
      props: { type: 'textarea' }
    }
  },
  {
    prop: 'repairDesc',
    label: '维修描述',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      colSpan: 24,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]
const FaultReason = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'reason',
    label: '故障原因',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: true,
      colSpan: 24,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]
const UrgencyLevel = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '紧急程度',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: true,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]
const EquipmentPosition = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'part',
    label: '设备部位',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: true,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 150
  }
]

const MaintenanceProject = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '保养项',
    sortable: false,
    hiddenSearch: true,
    form: { index: 0, tagName: 'el-input', props: { type: 'textarea' }}
  },
  {
    prop: 'needUpload',
    label: '是否必须上传图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'needUpload',
    form: {
      index: 2,
      required: false,
      tagName: 'el-switch',
      props: {
        activeText: '是',
        inactiveText: '否',
        activeValue: 1,
        inactiveValue: 0
      }
    }
  },
  {
    prop: 'standard',
    label: '保养标准',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]

const MaintenanceSetting = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '设备类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-cascader',
      key: 'equipmentClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.equipmentClassChange(val) }
      }
    }
  },
  {
    prop: 'pointCheckItem',
    label: '保养项',
    sortable: false,
    hiddenSearch: true,
    slotName: 'pointCheckItem',
    form: {
      index: 2,
      tagName: 'div',
      key: 'checkItemList',
      required: false,
      colSpan: 24,
      customSlots: ['pointCheckItem']
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]

const MaintenanceProjectView = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '保养项',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'needUpload',
    label: '是否必须上传图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'needUpload',
    width: 150
  },
  {
    prop: 'standard',
    label: '保养标准',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sketchMap',
    label: '操作图',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sketchMap'
  }
]

const SelectMaintenanceProject = [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  ...MaintenanceProject.slice(0, 4)
]
export default {
  Experience,
  FaultReason,
  UrgencyLevel,
  EquipmentPosition,
  MaintenanceProject,
  MaintenanceSetting,
  MaintenanceProjectView,
  SelectMaintenanceProject
}
