var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "procedure-detail-wrappers" }, [
    _c("div", { staticClass: "procedure-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "procedure-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            !_vm.isView
              ? _c("MFormBuilder", {
                  ref: "baseForm",
                  attrs: {
                    "form-list": _vm.formList,
                    "form-data": _vm.baseFormData,
                    "label-position": "right"
                  }
                })
              : _c(
                  "el-row",
                  { staticClass: "view-list", attrs: { gutter: 24 } },
                  _vm._l(_vm.viewColums, function(item) {
                    return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                      _c("div", { staticClass: "view-list__label" }, [
                        _vm._v(_vm._s(item.label))
                      ]),
                      _c("div", { staticClass: "view-list__value" }, [
                        _vm._v(_vm._s(_vm.getValue(item) || "-"))
                      ])
                    ])
                  }),
                  1
                )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("配置参数")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            !_vm.isView
              ? _c(
                  "el-button",
                  {
                    staticClass: "e-btn",
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.addParams }
                  },
                  [_vm._v("添加参数")]
                )
              : _vm._e(),
            _c("MTable", {
              ref: "mTable",
              attrs: {
                "show-page": false,
                height: 400,
                columns: _vm.columnsAdd,
                data: _vm.parameterList,
                "columns-setting": false
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "paramName",
                    fn: function(ref) {
                      var row = ref.row
                      return _vm.isView
                        ? _c("div", {}, [
                            _c("div", [_vm._v(_vm._s(row.paramName))])
                          ])
                        : _c(
                            "div",
                            { staticClass: "table-cell" },
                            [
                              _c("span", { staticClass: "required-star" }, [
                                _vm._v("*")
                              ]),
                              _c("el-input", {
                                attrs: {
                                  min: 1,
                                  "controls-position": "right",
                                  step: 1
                                },
                                model: {
                                  value: row.paramName,
                                  callback: function($$v) {
                                    _vm.$set(row, "paramName", $$v)
                                  },
                                  expression: "row.paramName"
                                }
                              })
                            ],
                            1
                          )
                    }
                  },
                  {
                    key: "paramDetail",
                    fn: function(ref) {
                      var row = ref.row
                      return _vm.isView
                        ? _c("div", {}, [
                            _c("div", [_vm._v(_vm._s(row.paramDetail))])
                          ])
                        : _c(
                            "div",
                            { staticClass: "table-cell" },
                            [
                              _c("el-input", {
                                attrs: {
                                  min: 1,
                                  "controls-position": "right",
                                  step: 1
                                },
                                model: {
                                  value: row.paramDetail,
                                  callback: function($$v) {
                                    _vm.$set(row, "paramDetail", $$v)
                                  },
                                  expression: "row.paramDetail"
                                }
                              })
                            ],
                            1
                          )
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.copyParams(row)
                                  }
                                }
                              },
                              [_vm._v("复制")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.delParams($index)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          ]
                        ],
                        2
                      )
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      ])
    ]),
    !_vm.isView
      ? _c("div", { staticClass: "order-footer" }, [
          _c(
            "div",
            { staticClass: "footer-ri" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.returns } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.buttonLoading,
                    size: "small"
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }