import { quanlityInfoServeType, quanlityInfoType, quanlityInfoFeeType } from '@/config/options.sales'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'serviceContent',
    label: '服务内容',
    hiddenSearch: true,
    sortable: false,
    slotName: 'serviceContent',
    form: { tagName: 'el-select', colSpan: 24, options: quanlityInfoServeType,
      props: { multiple: true }
    }
  },
  {
    prop: 'laborCost',
    label: '人工费',
    hiddenSearch: true,
    sortable: false,
    slotName: 'laborCost',
    form: { tagName: 'el-select', colSpan: 24, options: quanlityInfoFeeType }
  },
  {
    prop: 'sparePartsCost',
    label: '备件费',
    hiddenSearch: true,
    sortable: false,
    slotName: 'sparePartsCost',
    form: { tagName: 'el-select', colSpan: 24, options: quanlityInfoFeeType }
  },
  {
    prop: 'consumptionOfProductCost',
    label: '耗品费',
    hiddenSearch: true,
    sortable: false,
    slotName: 'consumptionOfProductCost',
    form: { tagName: 'el-select', colSpan: 24, options: quanlityInfoFeeType }
  },
  {
    prop: 'type',
    label: '类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'type',
    form: { tagName: 'el-select', colSpan: 24, options: quanlityInfoType.filter(item => item.id !== 3) }
  },
  {
    prop: 'guaranteePeriod',
    label: '服务期限',
    hiddenSearch: true,
    sortable: false,
    slotName: 'guaranteePeriod',
    form: {
      key: 'guaranteePeriod',
      tagName: 'el-date-picker',
      colSpan: 24,
      props: {
        type: 'daterange',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd'
      }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

export const techColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'name',
    label: '参数名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'name',
    form: { tagName: 'el-input', colSpan: 24 }
  },
  {
    prop: 'unit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'unit',
    form: { tagName: 'el-input', colSpan: 24, required: false }
  },
  {
    prop: 'standardValues',
    label: '标准值',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'standardValues',
    form: {
      required: false,
      colSpan: 24,
      tagName: 'div',
      customSlots: ['standardValues']
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

export const factoryColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'factoryParameterName',
    label: '参数名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'factoryParameterName',
    form: { tagName: 'el-input', colSpan: 24 }
  },
  {
    prop: 'factoryParameterDetail',
    label: '参数详情',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'factoryParameterDetail',
    form: { tagName: 'el-input', colSpan: 24, required: false }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]
