<template>
  <div class="scrap-wrap">
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入保养计划名称/单号"
        :tags="functionHeader.tags"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
        <NewPageOpen v-if="isShowChild" slot="planCode" slot-scope="{ row }" :path="{ name: 'maintenancePlanDetail', query: { id: row.id, type: 'view' }}" :text="row.planNumber" />
        <div v-if="row.cycleTime" slot="cycleTime" slot-scope="{ row }">{{ row.cycleTime }}{{ row.cycleTimeType | cycleType }}</div>
        <el-button slot="pointCheckItem" slot-scope="{ row }" type="text" @click="viewMaintenanceItem(row)">查看</el-button>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.isEnabled ? '启用' : '不启用' }}</div>
        </div>
        <div slot="action" slot-scope="{ row }">
          <NewPageOpen v-if="permission('Modify') && isShowChild" :path="{ name: 'maintenancePlanDetail', query: { id: row.id, type: 'modify' }}" text="修改" />
          <el-divider v-if="permission('Modify')" direction="vertical" />
          <DelPopover v-if="permission('Del')" @onOk="(callback) => del(row, callback)" />
        </div>
      </MTable>

      <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
      </MDialog>

      <TableDialog title="查看保养项" :show.sync="maintenanceItemVisible" :columns="maintenanceViewColumns" :table-data="maintenanceItemList" />
    </div>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import formList from './form-list'
import { columns } from './columns'
import { maintenanceViewColumns } from './detail/columns'
import { getUUid, setTreeData } from '@/utils'
import equipmentClassApi from '@/api/information/equipment-class'
import api from '@/api/equipment/maintenance-plan'
import { Encrypt } from '@/utils/sercet'
import conventionMixin from '@/mixin/conventional-page'
import TableDialog from '@/components/TableDialog/TableDialog'
import DelPopover from '@/components/DelPopover/index'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import { dateList } from '@/config/options.config'
import fixCommonPage from '@/mixin/fix-common-page'
import curdApi from '@/api/CURDSelect'

export default {
  name: 'MaintenancePlanList',
  components: { NewPageOpen, DelPopover, TableDialog, SearchForm, FunctionHeader },
  filters: {
    cycleType(val) {
      return dateList('year')[val].name
    }
  },
  mixins: [conventionMixin, fixCommonPage],
  data() {
    return {
      api,
      params: {
        radio: 'MaintenancePlan'
      },
      columns,
      maintenanceViewColumns,
      searchFormList: formList.searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: () => { this.$router.push({ name: 'maintenancePlanDetail', query: { type: 'add' }}) }
            }
          }
        ]
      },
      formList: formList.formList,
      formData: {},
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      currentType: 'add',
      visible: false,
      formOptions: {
        equipmentClassList: []
      },
      uploadFileList: [],
      searchKeyword: true,
      maintenanceItemVisible: false,
      maintenanceItemList: []
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 20
    }
  },
  mounted() {
    this.getEquipmentClassList()
    this.getTypes()
  },
  methods: {
    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        const equipmentClassList = setTreeData(res)
        this.searchFormList[1].props.options = equipmentClassList
        this.formList[3].props.options = equipmentClassList
        this.formOptions.equipmentClassList = res
      }
    },
    // 获取类别
    async getTypes() {
      const res = await curdApi.getEquipmentMaintainClassList()
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    async getMaintenanceTypeList() {
      const res = await api.getMaintenanceTypeList()
      if (res) {
        this.searchFormList[0].children[0].options = res.map(item => ({ id: item, name: item }))
      }
    },
    searchTable(data, val) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('equipmentClassIds')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
         if(data[hasIndex].fieldValue.length>0){
 this.searchFormatData.splice(hasIndex, 1, {
          id: getUUid(),
          fieldName: 'equipmentClassId',
          fieldType: 'number',
          fieldValue: data[hasIndex].fieldValue.map(item => this._.last(item)).join('^'),
          operator: 'in'
        })
        }
        else{
          this.searchFormatData.splice(hasIndex, 1)
        }
       
      }
      this.$refs.mTable.setTableData(true)
    },
    async changeIsEnabled(row) {
      const res = await api.modifyInspectionPlan({
        id: row.id,
        isEnabled: row.isEnabled ? 0 : 1
      })
      if (res) {
        this.$refs.mTable.setTableData()
      }
    },
    viewMaintenanceItem(row) {
      if (!row.maintenanceItemList || row.maintenanceItemList.length === 0) return this.$message.info('暂无保养项')
      this.maintenanceItemList = row.maintenanceItemList
      this.maintenanceItemVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;

  .bottom {
    padding: 10px;
  }
}
::v-deep{
  .e-divider{
  margin: 0px 0 0 0;
  }
}
</style>
