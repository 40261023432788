<template>
  <big-screen-container ref="container">
    <dv-loading v-if="loading" />
    <template v-else-if="currentBoard[currentShow]">
      <div class="top-container">
        <div class="title-wrapper">
          <span class="title">{{ currentBoard[currentShow].screenTitle }}</span>
          <img src="https://oss.mubyte.cn/static/%E5%A4%B4%E9%83%A8%402x.png" alt="">
        </div>
        <div class="info">
          <div class="screen-card-title">{{ currentBoard[currentShow].title[0] }}</div>
          <div class="time-container">
            <img src="https://oss.mubyte.cn/static/%E7%BC%96%E7%BB%84%402x.png" alt="">
            <div class="date-time">
              <div class="date">{{ currentDate }}（{{ currentWeek }}）</div>
              <div class="time">{{ currentTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="currentBoard[currentShow].type === 'minix'">
        <div class="screen-card-list">
          <div v-for="(item, index) in cardList" :key="index" class="screen-card-wrapper">
            <div class="left">
              <img :src="item.src" alt="">
              <span>{{ item.name }}</span>
            </div>
            <div class="right">
              <dv-digital-flop :config="getDigitalConfig(singleData[item.key])" style="width: 100%" />
            </div>
          </div>
        </div>

        <div class="screen-table-wrapper">
          <div class="info" style="padding: 6px 0 14px 0">
            <div class="screen-card-title">{{ currentBoard[currentShow].title[1] }}</div>
            <div />
          </div>
          <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}" @oneScrollEnd="scrollEnd()">
            <template v-for="(item, i) in slotColumns" :slot="item.slotName" slot-scope="{ index }">
              <table-slot-list
                :key="i"
                :slot-name="item.slotName"
                :row="tableData[index]"
                :column="item"
              />
            </template>
          </scroll-board>
        </div>
      </template>

      <template v-if="['table', 'chartsTable', 'tableTable'].includes(currentBoard[currentShow].type)">
        <div style="padding: 0 1.5%;font-size: 20px !important;">
          <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}" @oneScrollEnd="scrollEnd()">
            <template v-for="(item, i) in slotColumns" :slot="item.slotName === 'getDeepName' ? item.slotName + item.prop : item.slotName" slot-scope="{ index }">
              <table-slot-list
                :key="i"
                :slot-name="item.slotName"
                :row="tableData[index]"
                :column="item"
              />
            </template>
          </scroll-board>
        </div>
      </template>

      <template v-if="currentBoard[currentShow].type === 'tableTable'">
        <div style="padding: 0 1.5%;">
          <div class="info" style="padding: 6px 0 14px 0">
            <div class="screen-card-title" style="color: #FFFFFF">{{ currentBoard[currentShow].title[1] }}</div>
            <div />
          </div>
          <scroll-board :config="scrollBoardConfig2" :style="{width:'100%', height: table2Height + 'px'}" @oneScrollEnd="scrollEnd2()">
            <template v-for="(item, i) in slotColumns2" :slot="item.slotName === 'getDeepName' ? item.slotName + item.prop : item.slotName" slot-scope="{ index }">
              <table-slot-list
                :key="i"
                :slot-name="item.slotName"
                :row="tableData2[index]"
                :column="item"
              />
            </template>
          </scroll-board>
        </div>
      </template>

      <template v-if="currentBoard[currentShow].type === 'charts'">
        <div style="padding: 0 1.5%;">
          <ve-histogram
            ref="histogram"
            :height="chartHeight + 'px'"
            :data="chartData"
            :settings="barChartSettings"
            :extend="barChartExtend"
          />
        </div>
      </template>

      <template v-if="currentBoard[currentShow].type === 'chartsTable'">
        <div style="padding: 0 1.5%;">
          <div class="info" style="padding: 14px 0 14px 0">
            <div class="screen-card-title" style="color: #FFFFFF">{{ currentBoard[currentShow].title[1] }}</div>
            <div />
          </div>
          <ve-line
            ref="line"
            :height="chartHeight + 'px'"
            :data="chartData"
            :settings="lineChartSettings"
            :extend="lineChartExtend"
          />
        </div>
      </template>
    </template>
  </big-screen-container>
</template>

<script>
const dayPic = 'https://oss.mubyte.cn/static/%E6%97%A5.png'
const weekPic = 'https://oss.mubyte.cn/static/%E5%91%A8.png'
const monthPic = 'https://oss.mubyte.cn/static/%E6%9C%88.png'
import dayjs from 'dayjs'
import api from '@/api/statistics-board/all-statistics'
import BigScreenContainer from '@/components/BigScreenContainer/BigScreenContainer'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import TableSlotList from '@/views/statistics-board/board-manage/components/custom-table/table-slot-list'
import list from '@/views/dashboard/bbelc/list'
export default {
  name: 'Paper',
  components: { TableSlotList, BigScreenContainer, scrollBoard },
  inject: ['reload'],
  data() {
    return {
      cardList: [{
        name: '当日产量',
        src: dayPic,
        key: 'day'
      }, {
        name: '当周产量',
        src: weekPic,
        key: 'week'
      }, {
        name: '当月产量',
        src: monthPic,
        key: 'month'
      }],
      lineChartSettings: {},
      barChartSettings: {},
      barChartExtend: {
        legend: {
          show: false
        },
        xAxis: {
          splitLine: {
            show: false,
            interval: (index, value) => index === 0,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          },
          axisLabel: {
            fontSize: 20
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#607FFF'
            }
          },
          axisLabel: {
            fontSize: 20
          }
        },
        tooltip: {
          show: false
        },
        series: {
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: '#FFFFFF18'
          },
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#35C1F9' // 0% 处的颜色
              }, {
                offset: 1, color: '#188DF1' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: true,
            position: 'top',
            valueAnimation: true,
            fontSize: 20
          }
        },
        textStyle: {
          color: '#fff'
        }
      },
      lineChartExtend: {
        legend: {
          right: 10,
          textStyle: {
            color: '#fff',
            fontSize: 18
          }
        },
        xAxis: {
          axisLabel: {
            fontSize: 18
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#9597AE'
            }
          },
          axisLabel: {
            fontSize: 18
          }
        },
        tooltip: {
          trigger: 'axis',
          appendToBody: true
        },
        series: {
          type: 'line',
          showBackground: true,
          backgroundStyle: {
            color: '#FFFFFF18'
          },
          smooth: false,
          areaStyle: {}
        },
        textStyle: {
          color: '#fff'
        }
      },
      chartData: {
        columns: [],
        rows: []
      },
      currentDate: dayjs().format('YYYY-MM-DD'),
      currentTime: dayjs().format('HH:mm:ss'),
      weeks: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      currentWeek: '',
      scrollBoardConfig: {
        header: [],
        data: []
      },
      scrollBoardConfig2: {
        header: [],
        data: []
      },
      tableHeight: 100,
      table2Height: 100,
      chartHeight: 100,
      slotColumns: [],
      slotColumns2: [],
      tableData: [],
      tableData2: [],
      currentShow: 0,
      oneScrollEnd: false,
      oneScrollEnd2: false,
      loading: false,
      timeTen: false,
      currentBoard: [],
      singleData: { day: 0, week: 0, month: 0 }
    }
  },
  beforeMount() {
    const id = this.$route.params.id
    const memberCode = sessionStorage.getItem('memberCode')
    if (this.$route.name === 'kiki' || this.permission(memberCode + id, false)) {
      this.currentBoard = list[this.$route.name][id]
    } else {
      this.$router.push('/404')
    }
  },
  mounted() {
    const id = this.$route.params.id
    const memberCode = sessionStorage.getItem('memberCode')
    if (this.$route.name !== 'kiki' && !this.permission(memberCode + id, false)) return false
    this.getInitData()
    this.$nextTick(this.getHeight)
    window.onresize = this.getHeight
    this.fullScreen()
    setInterval(() => {
      this.reload()
    }, 1000 * 60 * 30)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.launchFullscreen)
  },
  methods: {
    fullScreen() {
      document.body.addEventListener('click', this.launchFullscreen)
      this.currentWeek = this.weeks[dayjs().day()]
      setInterval(() => {
        this.currentTime = dayjs().format('HH:mm:ss')
        this.currentDate = dayjs().format('YYYY-MM-DD')
        this.currentWeek = this.weeks[dayjs().day()]
        if (this.$refs.line) {
          this.$refs.line.resize()
        }
        if (this.$refs.histogram) {
          this.$refs.line.resize()
        }
      }, 1000)
    },
    getInitData() {
      const currentType = this.currentBoard[this.currentShow].type
      switch (currentType) {
        case 'minix': this.getMinixData(); break
        case 'charts': this.getChartData(); break
        case 'chartsTable': this.getChartsAndTableData(); break
        case 'tableTable': this.getTableDataAndTableData(); break
        default: this.getTableData(); break
      }
    },
    getMinixData() {
      this.getTableData()
      this.getSingleData()
    },
    getChartsAndTableData() {
      this.getTableData()
      this.getChartData(false)
    },
    getTableDataAndTableData() {
      this.getTableData()
      this.getTableData2()
    },
    async getSingleData() {
      const { singleUrlAndCondition } = this.currentBoard[this.currentShow]
      const res = await api.getTableData(singleUrlAndCondition[0], {
        ...singleUrlAndCondition[1],
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        if (!res[0]) return
        Object.keys(res[0]).forEach(item => {
          if (item.indexOf('day') !== -1) {
            this.singleData.day = res[0][item]
          }
          if (item.indexOf('week') !== -1) {
            this.singleData.week = res[0][item]
          }
          if (item.indexOf('month') !== -1) {
            this.singleData.month = res[0][item]
          }
        })
      } else {
        this.reload()
      }
    },
    async getTableData() {
      this.loading = true
      this.getHeight()
      const slotKeys = this.currentBoard[this.currentShow].columnsKey.map(item => item.split(':')[1]).filter(item => item)
      this.slotColumns = this.currentBoard[this.currentShow].tableColumns.filter(item => slotKeys.indexOf(item.slotName) !== -1)
      const { dataUrlAndCondition, columns, columnsKey, columnWidth, screenTitle } = this.currentBoard[this.currentShow]
      const res = await api.getTableData(dataUrlAndCondition[0], {
        ...dataUrlAndCondition[1],
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        if (dataUrlAndCondition[2]) {
          this.tableData = dataUrlAndCondition[2](res[0] && res[0].totalList ? res[0].totalList : res)
        } else {
          this.tableData = res[0] && res[0].totalList ? res[0].totalList : res
        }
        const delayIndex = []
        this.tableData.forEach((item, index) => {
          if (item.isDelay) {
            delayIndex.push(index)
          }
        })
        this.scrollBoardConfig = {
          carousel: 'page',
          header: columns,
          rowNum: this.tableData.length < 8 ? this.tableData.length : 8,
          headerHeight: 50,
          hoverPause: false,
          headerBGC: 'linear-gradient(180deg, #5160FF 0%, #2336FF 100%)',
          oddRowBGC: '#082182',
          evenRowBGC: '#0E3973',
          columnWidth,
          waitTime: 10000,
          delayIndex,
          align: columns.map(item => 'center'),
          data: this.tableData.map((item, index) => (columnsKey.map((c, i) => {
            if (c.indexOf('slot:') !== -1) {
              const slots = c.split(':')
              return `${slots[1]}${slots[2] || ''}|${index}`
            }
            if (c === 'index') {
              return index + 1
            }
            return item[c]
          })))
        }
        this.loading = false
        this.typeSwitch()
      } else {
        this.reload()
      }
    },
    async getTableData2() {
      this.loading = true
      this.getHeight()
      const slotKeys = this.currentBoard[this.currentShow].columnsKey2.map(item => item.split(':')[1]).filter(item => item)
      this.slotColumns2 = this.currentBoard[this.currentShow].tableColumns2.filter(item => slotKeys.indexOf(item.slotName) !== -1)
      const { dataUrlAndCondition2, columns2, columnsKey2, columnWidth2 } = this.currentBoard[this.currentShow]
      const res = await api.getTableData(dataUrlAndCondition2[0], {
        ...dataUrlAndCondition2[1],
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        if (dataUrlAndCondition2[2]) {
          this.tableData2 = dataUrlAndCondition2[2](res[0] && res[0].totalList ? res[0].totalList : res)
        } else {
          this.tableData2 = res[0] && res[0].totalList ? res[0].totalList : res
        }
        const delayIndex = []
        this.tableData2.forEach((item, index) => {
          if (item.isDelay) {
            delayIndex.push(index)
          }
        })
        this.scrollBoardConfig2 = {
          carousel: 'page',
          header: columns2,
          rowNum: this.tableData2.length < 8 ? this.tableData2.length : 8,
          headerHeight: 50,
          hoverPause: false,
          headerBGC: 'linear-gradient(180deg, #5160FF 0%, #2336FF 100%)',
          oddRowBGC: '#082182',
          evenRowBGC: '#0E3973',
          columnWidth: columnWidth2,
          waitTime: 10000,
          delayIndex,
          align: columns2.map(item => 'center'),
          data: this.tableData2.map((item, index) => (columnsKey2.map((c, i) => {
            if (c.indexOf('slot:') !== -1) {
              const slots = c.split(':')
              return `${slots[1]}${slots[2] || ''}|${index}`
            }
            if (c === 'index') {
              return index + 1
            }
            return item[c]
          })))
        }
        this.loading = false
        this.typeSwitch2()
      } else {
        this.reload()
      }
    },
    async getChartData(isSwitch = true) {
      this.loading = true
      this.getHeight()
      // eslint-disable-next-line prefer-const
      let { currentData, dataUrlAndCondition, chartsUrlAndCondition } = this.currentBoard[this.currentShow]
      if (chartsUrlAndCondition) {
        dataUrlAndCondition = chartsUrlAndCondition
      }
      const res = await api.getTableData(dataUrlAndCondition[0], {
        ...dataUrlAndCondition[1],
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        this.chartData.columns = ['月份', ...res.map(item => this._.get(item, currentData.chartLegendKey))]
        const months = res[0].totalList.map(item => ({ '月份': item[currentData.chartXKey] }))
        months.forEach((month, index) => {
          res.forEach(item => {
            Object.assign(month, {
              [this._.get(item, currentData.chartLegendKey)]: item.totalList[index][currentData.chartDataKey]
            })
          })
        })
        this.chartData.rows = months
        this.loading = false
        if (isSwitch) this.typeSwitch()
      } else {
        this.reload()
      }
    },
    scrollEnd() {
      this.oneScrollEnd = true
      if (this.tableData.length < this.tableData2.length) return false
      if (this.timeTen) {
        setTimeout(this.getCurrentData, 5000)
      }
    },
    scrollEnd2() {
      this.oneScrollEnd2 = true
      if (this.tableData.length > this.tableData2.length) return false
      if (this.timeTen) {
        setTimeout(this.getCurrentData, 5000)
      }
    },
    typeSwitch() {
      if (this.tableData.length < this.tableData2.length) return false
      const timeout = this.currentBoard.length === 1 ? 1000 * 60 * 30 : 30000
      setTimeout(() => {
        if (this.oneScrollEnd) {
          this.oneScrollEnd = false
          this.getCurrentData()
        } else {
          this.timeTen = true
        }
      }, timeout)
    },
    typeSwitch2() {
      if (this.tableData.length > this.tableData2.length) return false
      const timeout = this.currentBoard.length === 1 ? 1000 * 60 * 30 : 30000
      setTimeout(() => {
        if (this.oneScrollEnd2) {
          this.oneScrollEnd2 = false
          this.getCurrentData()
        } else {
          this.timeTen = true
        }
      }, timeout)
    },
    getCurrentData() {
      this.currentShow += 1
      if (this.currentShow > (this.currentBoard.length - 1)) {
        this.currentShow = 0
      }
      const currentType = this.currentBoard[this.currentShow].type
      this.oneScrollEnd = ['charts'].includes(currentType)
      switch (currentType) {
        case 'minix': this.getMinixData(); break
        case 'charts': this.getChartData(); break
        case 'chartsTable': this.getChartsAndTableData(); break
        case 'tableTable': this.getTableDataAndTableData(); break
        default: this.getTableData(); break
      }
    },
    fixNumber2(number) {
      const newNumber = Number(number)
      return Number(newNumber.toFixed(2))
    },
    getDigitalConfig(number) {
      return {
        number: [Number(number)],
        content: '{nt}',
        style: {
          fontSize: 80,
          color: '#fff'
        }
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      const { type } = this.currentBoard[this.currentShow]
      if (type === 'minix') {
        this.tableHeight = (window.innerHeight * 0.67) / scale
      }
      if (type === 'table') {
        this.tableHeight = (window.innerHeight * 0.86) / scale
      }
      if (type === 'charts') {
        this.chartHeight = (window.innerHeight * 0.86) / scale
      }
      if (type === 'chartsTable') {
        this.tableHeight = (window.innerHeight * 0.31) / scale
        this.chartHeight = (window.innerHeight * 0.50) / scale
      }
      if (type === 'tableTable') {
        this.tableHeight = (window.innerHeight * 0.31) / scale
        this.table2Height = (window.innerHeight * 0.48) / scale
      }
    },
    launchFullscreen() {
      this.getHeight()
      const element = document.documentElement
      if (element.requestFullscreencreen) {
        element.requestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.top-container {
  color: #FFFFFF;
  .title-wrapper {
    text-align: center;
    position: relative;
    .title {
      font-size: 33px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 80%;
      margin: 0 auto;
    }
  }
  .info {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5%;
    position: relative;
    top: -20px;
    .time-container {
      position: relative;
      display: inline-block;
      width: 16%;
      top: 2px;
      img {
        width: 100%;
      }
      .date-time {
        position: absolute;
        top: 55%;
        left: 60%;
        transform: translate(-50%, -50%);
        color: #00B7EF;
        .date {
          font-size: 18px;
          word-wrap: normal;
          white-space:nowrap;
        }
        .time {
          font-size: 27px;
          font-weight: 700;
        }
      }
    }
  }
}
.info {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .screen-card-title {
    font-size: 25px;
    padding: 10px 20px 10px 40px;
    letter-spacing: 5px;
    background: linear-gradient(90deg, #2337FF 0%, rgba(35, 54, 255, 0) 100%);
  }
}

.screen-card-list {
  color: #FFFFFF;
  padding: 0 1.5%;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  top: -8px;
  .screen-card-wrapper {
    width: 26%;
    display: inline-flex;
    border-radius: 4px;
    overflow: hidden;
    .left {
      box-sizing: content-box;
      width: 34%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 3% 0;
      background: #34489D;
      img {
        width: 38%;
        padding-bottom: 10px;
      }
      span {
        //padding-top: 15px;
        font-size: 25px;
        word-wrap: normal;
        white-space:nowrap;
      }
    }
    .right {
      width: 66%;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #0D2485;
    }
  }
  ::v-deep {
    .dv-digital-flop {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 5px;
      height: 100px;
    }
  }
}

.screen-table-wrapper {
  color: #fff;
  padding: 0 1.5%;
  ::v-deep {
    .dv-scroll-board {
      div {
        font-size: 18px !important;
      }
    }
    .table-slot-list {
      height: 100%;
      >div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-progress {
          width: 100%;
        }
        .mv-count-down {
          color: #fff;
        }
      }
    }
    .el-progress-bar__outer {
      background: #00166A !important;
      div {
        font-size: 14px !important;
      }
    }
  }
}
</style>
