<template>
  <div class="procedure-detail-wrappers">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.push({ name: 'facilityAccountList' })">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder ref="baseForm" :form-list="getFormList" :form-data="baseFormData" label-position="right" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">装机信息</div>
        <div class="card-bot">
          <MFormBuilder ref="baseForms" :form-list="formLists" :form-data="baseFormDatas" label-position="right" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">技术参数</div>
        <div class="card-bot">
          <TechnicalParameter :list="facilityMappingParametersList" @facilityInfoParams="facilityInfoParams" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">配置参数</div>
        <ConfigurationParameter :show-title="false" :show-template="true" :list.sync="parametersList" />
      </div>
      <div class="detail-card">
        <div class="card-tit">工厂参数</div>
        <FactoryParameter :list="facilityFactoryParameterMappingList" @factoryInfoParams="factoryInfoParams" />
      </div>
      <div class="detail-card">
        <div class="card-tit">额外信息</div>
        <div class="card-bot">
          <MFormBuilder ref="fieldForm" :form-list="fieldFormList" :form-data="fieldFormData" label-position="right" />
        </div>
      </div>
    </div>
    <div class="order-footer">
      <div class="footer-le">
        <!-- <span>创建人：</span>
        <span>创建时间：2021-03-21 14：2</span> -->
      </div>
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formList, formLists } from './form-list'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import QuanlityInfo from '@/views/facility/account/components/quanlity-info'
import TechnicalParameter from '@/views/facility/account/components/technical-parameter'
import FactoryParameter from '@/views/facility/account/components/factory-parameter'
import ConfigurationParameter from '@/views/facility/configuration/components/configuration-parameter'
import keApi from '@/api/information/unit'
import equipmentClassApi from '@/api/information/equipment-class'
import api from '@/api/xiewei/facility/facility'
import { setTreeData } from '@/utils'
import fieldApi from '@/api/xiewei/field/field'
import { paramsTypeList } from '@/config/options.sales'
import fixFieldShow from '@/mixin/fix-field-show'
import { getFunctionList } from './columns'

export default {
  name: 'AddFacility',
  components: { FactoryParameter, QuanlityInfo, TechnicalParameter, ConfigurationParameter },
  mixins: [fixFieldShow],
  data() {
    return {
      fieldApi,
      formLists,
      tableSign: 'facility',
      paramsTypeList,
      currentSign: 'add',
      fieldFormList: [],
      fieldFormData: {},
      baseFormData: {},
      formList,
      params: {},
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '设备详情',
        addO: '新增设备',
        modifyO: '修改设备',
        copyO: '复制设备'
      },
      buttonLoading: false,
      fieldForm: [],
      facilityMappingParametersList: [],
      parametersList: [],
      facilityFactoryParameterMappingList: [],
      baseFormDatas: {},
      allCustomer: []
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getFormList() {
      return formList.filter(item => {
        return item.key !== 'type' && item.key !== "status"
      })
    }
  },
  beforeMount() {
    getFunctionList({
      mulChange: this.mulChange
    })
  },
  mounted() {
    this.params = this.$route.query
    this.getEquipmentClassList()
    this.getAllCustomer()
    if (this.params.type === 'add') {
      this.fieldList()
    }
    if (this.params.type !== 'add') {
      this.getEquipmentDetail()
    }
  },
  methods: {
    mulChange(val) {
      const arr = this.allCustomer.filter(item => item.id === this._.first(val))
      const list = this.allCustomer.filter(item => item.id === this._.last(val))
      const id = +list[0].isCustomer === 0 ? list[0].id : arr[0].id
      this.$refs.baseForm.setForm({
        customerId: id,
        customerSiteId: +list[0].isCustomer === 1 ? list[0].id : '',
        customerName: list[0].name
      })
    },
    returns() {
      this.$router.push({ name: 'facilityAccountList' })
    },
    async getEquipmentDetail() {
      const res = await api.getFacilityDetail({ id: this.$route.query.id })
      if (res) {
        this.baseFormDatas = res.facilityAssemble || {}
        this.baseFormData = {
          ...res,
          customerSiteIdList: res.customerSiteIdList ? res.customerSiteIdList.split('^').map(item => Number(item)) : []
        }
        this.facilityMappingParametersList = res.facilityMappingParametersList
          ? res.facilityMappingParametersList.map(item => {
            return {
              ...item,
              standardValuesMax: item.standardValuesMax || item.standardValuesMax === 0 ? item.standardValuesMax : undefined,
              standardValuesMin: item.standardValuesMin || item.standardValuesMin === 0 ? item.standardValuesMin : undefined
            }
          })
          : []
        this.fieldForm = res.facilityFieldList || []
        this.parametersList = res.facilityConfigurationParameterList || []
        this.facilityFactoryParameterMappingList = res.facilityFactoryParameterMappingList || []
        this.fixFieldData(1)
      }
    },
    facilityInfoParams(val) {
      this.facilityMappingParametersList = val
    },
    factoryInfoParams(val) {
      this.facilityFactoryParameterMappingList = val
    },
    // 获取设备类
    async getEquipmentClassList() {
      // const res = await equipmentClassApi.getEquipmentClassList()
      const res = await equipmentClassApi.getAfterSaleEquipmentClassList()
      if (res) {
        const equipmentClassList = setTreeData(res)
        this.formList[4].props.options = equipmentClassList
      }
    },
    // 获取客户
    // async getUser() {
    //   const res = await keApi.getCustomerList({
    //     searchVal: Encrypt(
    //       JSON.stringify([
    //         {
    //           id: getUUid(),
    //           fieldName: 'isEnabled',
    //           fieldType: 'number',
    //           fieldValue: 1,
    //           operator: 'eq'
    //         }
    //       ])
    //     )
    //   })
    //   if (res) {
    //     this.formList[5].children[0].options = res
    //   }
    // },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.formList[6].props.options = setTreeData(data)
      }
    },
    submitForm() {
      const that = this
      this.buttonLoading = true
      const { form } = this.$refs.baseForm
      const { form: fieldForm } = this.$refs.fieldForm
      const { form: assembelForm } = this.$refs.baseForms
      const isTrue = this.facilityMappingParametersList.length > 0 ? this.facilityMappingParametersList.every(item => item.name) : true
      const isNull = this.parametersList.find(item => !item.configurationParameter)

      if (!isTrue) {
        this.buttonLoading = false
        return this.$message.error('参数名称不能为空')
      }

      if (isNull) {
        this.buttonLoading = false
        return this.$message.error('配置参数名称不能为空')
      }
      // const list = this.facilityMappingParametersList.length > 0 ? this.facilityMappingParametersList.filter(item => ((item.standardValuesMin || item.standardValuesMin === 0) && (item.standardValuesMax || item.standardValuesMax === 0)) && (item.standardValuesMin > item.standardValuesMax || item.standardValuesMin === item.standardValuesMax)) : []
      // if (list.length > 0) {
      //   this.buttonLoading = false
      //   return this.$message.error('标准值的最小值必须小于最大值')
      // }
      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          this.$refs.fieldForm.formRefs().validate(async valids => {
            if (valids) {
              that.saveData(
                Object.assign(
                  {},
                  {
                    ...form,
                    customerSiteIdList: form.customerSiteIdList ? form.customerSiteIdList.join('^') : '',
                    equipmentClassId: Array.isArray(form.equipmentClassId) ? this._.last(form.equipmentClassId) : form.equipmentClassId,
                    facilityFieldList: this.fixSaveData(fieldForm),
                    facilityMappingParametersList: this.facilityMappingParametersList,
                    facilityConfigurationParameterList: this.parametersList,
                    facilityFactoryParameterMappingList: this.facilityFactoryParameterMappingList,
                    facilityAssemble: {
                      ...this.createSetDatas(assembelForm)
                    }
                  }
                )
              )
            } else {
              this.buttonLoading = false
            }
          })
        } else {
          this.buttonLoading = false
        }
      })
    },
    async saveData(form) {
      const res = await api[`${this.params.type}Facility`](form)
      this.buttonLoading = false
      if (res) {
        this.$router.push({ name: 'facilityAccountList' })
      }
    },
    createSetDatas(raw) {
      return {
        moveInTime: raw.moveInTime,
        surveyTime: raw.surveyTime,
        startingUpTime: raw.startingUpTime,
        debuggingTime: raw.debuggingTime,
        checkAndAcceptTime: raw.checkAndAcceptTime
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style .li-label-style {
    color: #607fff;
  }
  .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style.active-li {
    background-color: rgba(96, 127, 255, 0.1);
    color: #607fff;
  }
}
.procedure-detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }
  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.flex-mode {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    margin-right: 20px;
  }
  ::v-deep .el-radio-group {
    vertical-align: initial;
  }
  ::v-deep .el-radio {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456b;
  }
}
.flex-modes {
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 20px;
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    margin-right: 20px;
  }
  .mode-ri {
    .ri-top {
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;
      .top-tit {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393d60;
        line-height: 36px;
      }
      .ri-num {
        width: 130px;
        margin: 0 20px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dde3eb;
      }
    }
  }
}
.bot-tis {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #393d60;
  display: inline-block;
}
</style>
