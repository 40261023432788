var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("ExperienceList")
            ? _c("el-radio-button", { attrs: { label: "Experience" } }, [
                _vm._v("维保经验")
              ])
            : _vm._e(),
          _vm.permission("FaultReasonList")
            ? _c("el-radio-button", { attrs: { label: "FaultReason" } }, [
                _vm._v("故障原因")
              ])
            : _vm._e(),
          _vm.permission("UrgencyLevelList")
            ? _c("el-radio-button", { attrs: { label: "UrgencyLevel" } }, [
                _vm._v("紧急程度")
              ])
            : _vm._e(),
          _vm.permission("EquipmentPositionList")
            ? _c("el-radio-button", { attrs: { label: "EquipmentPosition" } }, [
                _vm._v("设备部位")
              ])
            : _vm._e(),
          _vm.permission("FlowSettingList")
            ? _c("el-radio-button", { attrs: { label: "FlowSetting" } }, [
                _vm._v("流程设置")
              ])
            : _vm._e(),
          _vm.permission("MaintenanceProjectList")
            ? _c(
                "el-radio-button",
                { attrs: { label: "MaintenanceProject" } },
                [_vm._v("保养项目")]
              )
            : _vm._e(),
          _vm.permission("MaintenanceSettingList")
            ? _c(
                "el-radio-button",
                { attrs: { label: "MaintenanceSetting" } },
                [_vm._v("保养配置")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.params.radio !== "FlowSetting"
        ? [
            _c(
              "div",
              { staticClass: "top" },
              [
                ["Experience", "MaintenanceSetting"].includes(_vm.params.radio)
                  ? _c("SearchForm", {
                      ref: "searchForm",
                      attrs: {
                        "form-list-extend": _vm.searchFormList[_vm.params.radio]
                      },
                      on: { search: _vm.searchTable }
                    })
                  : _c("div", {
                      staticStyle: {
                        height: "57px",
                        "border-bottom": "1px solid #DDE3EB"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "bottom" },
              [
                _c("FunctionHeader", {
                  attrs: {
                    "search-title":
                      _vm.params.radio === "Experience"
                        ? "请输入经验编码/设备类"
                        : "请输入保养项",
                    tags: _vm.functionHeader.tags,
                    radio: _vm.params.radio,
                    "export-name": _vm.functionHeader.exportName,
                    "export-params": _vm.functionHeader.exportParams,
                    "import-name": _vm.functionHeader.importName,
                    "hidden-search": ![
                      "Experience",
                      "MaintenanceProject"
                    ].includes(_vm.params.radio),
                    "columns-key": _vm.columnsKey
                  },
                  on: {
                    search: function($event) {
                      return _vm.$refs.mTable.setTableData(true)
                    }
                  },
                  model: {
                    value: _vm.functionHeader.searchVal,
                    callback: function($$v) {
                      _vm.$set(_vm.functionHeader, "searchVal", $$v)
                    },
                    expression: "functionHeader.searchVal"
                  }
                }),
                _vm.permission(_vm.params.radio + "List")
                  ? _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.columns[_vm.params.radio],
                        height: _vm.height,
                        "columns-key": _vm.columnsKey,
                        "columns-setting":
                          _vm.params.radio === "Experience" ||
                          _vm.params.radio === "MaintenanceProject" ||
                          _vm.params.radio === "FaultReason" ||
                          _vm.params.radio === "UrgencyLevel" ||
                          _vm.params.radio === "EquipmentPosition",
                        "set-data-method": _vm.getTableData
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "experienceType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(_vm._f("experienceTypeName")(row.type))
                                )
                              ])
                            }
                          },
                          {
                            key: "pointCheckItem",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewCheckItem(row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            }
                          },
                          {
                            key: "needUpload",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", { staticClass: "is-enabled" }, [
                                _c("div", {
                                  style: {
                                    background: row.needUpload
                                      ? "#24CAD7"
                                      : "#FFCC78"
                                  }
                                }),
                                _c("div", [
                                  _vm._v(_vm._s(row.needUpload ? "是" : "否"))
                                ])
                              ])
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.params.radio !== "MaintenanceSetting" &&
                                  _vm.permission(_vm.params.radio + "Copy")
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.modifyOrCopy(
                                                row,
                                                "copy"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("复制")]
                                      )
                                    : _vm._e(),
                                  _vm.params.radio !== "MaintenanceSetting" &&
                                  _vm.permission(_vm.params.radio + "Copy")
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" }
                                      })
                                    : _vm._e(),
                                  _vm.permission(_vm.params.radio + "Modify")
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.modifyOrCopy(
                                                row,
                                                "modify"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("修改")]
                                      )
                                    : _vm._e(),
                                  _vm.permission(_vm.params.radio + "Modify")
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" }
                                      })
                                    : _vm._e(),
                                  _vm.permission(_vm.params.radio + "Del")
                                    ? _c("DelPopover", {
                                        on: {
                                          onOk: function(callback) {
                                            return _vm.deleteRow(row, callback)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        3245689519
                      )
                    })
                  : _vm._e(),
                _c(
                  "MDialog",
                  {
                    attrs: { title: _vm.messages[_vm.currentType] },
                    on: { onOk: _vm.submitForm },
                    model: {
                      value: _vm.visible,
                      callback: function($$v) {
                        _vm.visible = $$v
                      },
                      expression: "visible"
                    }
                  },
                  [
                    _c(
                      "MFormBuilder",
                      {
                        ref: "formBuild",
                        attrs: {
                          "form-data": _vm.formData,
                          "form-list": _vm.formList[_vm.params.radio]
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "pointCheckItem" },
                            slot: "pointCheckItem"
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { type: "primary" },
                                on: { click: _vm.pushCheckItem }
                              },
                              [_vm._v("添加")]
                            ),
                            _c("MTable", {
                              ref: "pointCheckTable",
                              attrs: {
                                height:
                                  _vm.$store.state.app.contentHeight -
                                  54 -
                                  100 -
                                  50 -
                                  60 -
                                  70 -
                                  192,
                                columns: _vm.columns.MaintenanceProject,
                                "columns-setting": false,
                                "show-page": false,
                                data: _vm.selectCheckCheckItem
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "index",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return _c("div", {}, [
                                        _vm._v(_vm._s($index + 1))
                                      ])
                                    }
                                  },
                                  {
                                    key: "needUpload",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return _c(
                                        "div",
                                        { staticClass: "is-enabled" },
                                        [
                                          _c("div", {
                                            style: {
                                              background: row.needUpload
                                                ? "#24CAD7"
                                                : "#FFCC78"
                                            }
                                          }),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                row.needUpload ? "是" : "否"
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    }
                                  },
                                  {
                                    key: "action",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.uploadPic(
                                                    row,
                                                    $index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("操作图")]
                                          ),
                                          _c("el-divider", {
                                            attrs: { direction: "vertical" }
                                          }),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delRow(row, $index)
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  }
                                ],
                                null,
                                false,
                                1523529450
                              )
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "MDialog",
                  {
                    attrs: { title: "添加保养项" },
                    on: { onOk: _vm.submitSelectCheckItem },
                    model: {
                      value: _vm.selectCheckItemVisible,
                      callback: function($$v) {
                        _vm.selectCheckItemVisible = $$v
                      },
                      expression: "selectCheckItemVisible"
                    }
                  },
                  [
                    _c("FunctionHeader", {
                      attrs: {
                        "search-title": "请输入保养项",
                        tags: _vm.functionHeaderS.tags,
                        radio: _vm.params.radio,
                        "columns-setting": false
                      },
                      on: {
                        search: function($event) {
                          return _vm.$refs.pointCheckTableSelect.setTableData(
                            true
                          )
                        }
                      },
                      model: {
                        value: _vm.functionHeaderS.searchVal,
                        callback: function($$v) {
                          _vm.$set(_vm.functionHeaderS, "searchVal", $$v)
                        },
                        expression: "functionHeaderS.searchVal"
                      }
                    }),
                    _c("MTable", {
                      ref: "pointCheckTableSelect",
                      attrs: {
                        height:
                          _vm.$store.state.app.contentHeight -
                          54 -
                          100 -
                          50 -
                          60 -
                          70 -
                          56,
                        columns: _vm.columns.SelectMaintenanceProject,
                        "columns-setting": false,
                        "set-data-method": _vm.getCheckItemTableData
                      },
                      on: { "selection-change": _vm.selectionChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "needUpload",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", { staticClass: "is-enabled" }, [
                                _c("div", {
                                  style: {
                                    background: row.needUpload
                                      ? "#24CAD7"
                                      : "#FFCC78"
                                  }
                                }),
                                _c("div", [
                                  _vm._v(_vm._s(row.needUpload ? "是" : "否"))
                                ])
                              ])
                            }
                          }
                        ],
                        null,
                        false,
                        225882197
                      )
                    })
                  ],
                  1
                ),
                _c("TableDialog", {
                  attrs: {
                    title: "查看保养项",
                    show: _vm.checkItemVisible,
                    columns: _vm.columns.MaintenanceProjectView,
                    "table-data": _vm.checkItemList
                  },
                  on: {
                    "update:show": function($event) {
                      _vm.checkItemVisible = $event
                    }
                  }
                }),
                _c(
                  "MDialog",
                  {
                    attrs: { title: "上传操作图" },
                    on: { onOk: _vm.submitUploadPic },
                    model: {
                      value: _vm.uploadPicVisible,
                      callback: function($$v) {
                        _vm.uploadPicVisible = $$v
                      },
                      expression: "uploadPicVisible"
                    }
                  },
                  [
                    _c("upload-pic", {
                      ref: "uploadPic",
                      attrs: { "upload-file-list": _vm.uploadFileList }
                    })
                  ],
                  1
                ),
                _c(
                  "MDialog",
                  {
                    attrs: { title: "新增维保" },
                    on: { onOk: _vm.submitDialogAddForm },
                    model: {
                      value: _vm.dialogAddVisible,
                      callback: function($$v) {
                        _vm.dialogAddVisible = $$v
                      },
                      expression: "dialogAddVisible"
                    }
                  },
                  [
                    _c("MFormBuilder", {
                      ref: "formBuildAdd",
                      attrs: { "form-list": _vm.formList.MaintenanceProject }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c("MFormBuilder", {
                ref: "formBuild",
                attrs: {
                  "form-data": _vm.formData,
                  "form-list": _vm.formList[_vm.params.radio]
                }
              })
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }