<template>
  <div class="params-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('CallParamsList')" label="CallParams">参数台账</el-radio-button>
      <el-radio-button v-if="permission('DataOriginList')" label="DataOrigin">数据源</el-radio-button>
    </el-radio-group>
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :tags="functionHeader.tags"
      :search-title="'请输入参数名称'"
      :hidden-search="params.radio === 'DataOrigin'"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      v-if="permission(params.radio + 'List')"
      ref="mTable"
      :columns="columns[params.radio]"
      :height="height"
      :columns-key="columnsKey"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="dataType" slot-scope="{ row }">{{ dataType[row.type] && dataType[row.type].name }}</div>
      <el-button slot="dataPoints" slot-scope="{ row }" type="text" @click="viewDataPoint(row)">查看</el-button>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="action" slot-scope="{ row }">
        <template v-if="params.radio === 'CallParams'">
          <el-button v-if="permission(params.radio + 'Modify')" :disabled="row.isFixed == 1" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
          <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
          <el-button v-if="permission(params.radio + 'Del')" :disabled="row.isFixed == 1" type="text" @click="del(row)">删除</el-button>
        </template>
        <template v-else>
          <el-button v-if="permission(params.radio + 'SetPoint')" type="text" @click="setDataPoint(row)">设置数据点</el-button>
          <el-divider v-if="permission(params.radio + 'SetPoint')" direction="vertical" />
          <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
          <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
          <el-dropdown @command="(command) => { more(row, command) }">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
              <el-dropdown-item v-if="permission(params.radio + 'Del')" command="del">删除</el-dropdown-item>
              <el-dropdown-item v-if="permission(params.radio + 'Status')" command="status">{{ row.isEnabled ? '停用' : '启用' }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </div>
    </MTable>
    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list=" !['addPoint', 'modifyPoint'].includes(currentType) ? formList[params.radio] : formList.dataPoint" />
    </MDialog>
    <MDialog v-model="dataPointVisible" :title="pointDialogTitle" :show-footer="false">
      <FunctionHeader
        v-if="['addPoint', 'modifyPoint'].includes(currentType)"
        :tags="functionHeader.tags2"
        :hidden-search="true"
        :columns-setting="false"
      />
      <MTable
        :columns="['addPoint', 'modifyPoint'].includes(currentType) ? columns.dataPointsColumns : columns.viewDataPointsColumns"
        :height="['addPoint', 'modifyPoint'].includes(currentType) ? viewHeight - 52 : viewHeight"
        :data="dataPoints"
        :columns-setting="false"
        :show-page="false"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifyOrCopy(row, 'modifyPoint')">修改</el-button>
          <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
          <el-button v-if="permission(params.radio + 'Del')" type="text" @click="delPoint(row)">删除</el-button>
        </div>
      </MTable>
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import columns, { getFunctionList } from './columns'
import conventionMixin from '@/mixin/conventional-page'
import formList from './form-list'
import api from '@/api/equipment/params'
import { dataType } from '@/config/options.config'
export default {
  name: 'EquipmentParams',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'CallParams'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('CallParamsAdd'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        tags2: [
          {
            hidden: !this.permission('CallParamsAdd'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '添加',
            on: {
              click: this.pointAdd
            }
          },
          {
            hidden: !this.permission('CallParamsAdd'),
            tagName: 'el-button',
            props: { type: 'default', size: 'small' },
            style: { width: 'auto' },
            innerText: '批量删除',
            on: {
              click: () => { this.delPoint() }
            }
          }
        ]
      },
      columns,
      visible: false,
      formList,
      formData: {},
      currentType: 'add',
      agreementList: [],
      dataPoints: [],
      dataPointVisible: false,
      pointDialogTitle: '设置数据点',
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制',
        addPoint: '添加数据点',
        modifyPoint: '修改数据点'
      },
      selectPointData: [],
      dataSourceData: {},
      dataType
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10
    },
    viewHeight() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 100
    }
  },
  beforeMount() {
    getFunctionList({
      adaptorChange: this.adaptorChange
    })
  },
  mounted() {
    this.getDataOriginAndAgreement()
  },
  methods: {
    async getDataOriginAndAgreement() {
      const res = await api.getDataOriginAndAgreement()
      if (res) {
        this.formList.DataOrigin[1].children[0].options = Object.keys(res).map(item => ({ id: item, name: item }))
        this.agreementList = res
      }
    },
    async viewDataPoint(row) {
      const res = await api.dataOriginDetail(row.id)
      if (res) {
        if (!res[0].dataPointList.length) return this.$message.info('未配置数据源')
        this.dataPointVisible = '查看数据点'
        this.currentType = 'viewPoint'
        this.dataPoints = res[0].dataPointList
        this.dataPointVisible = true
      }
    },
    adaptorChange(val) {
      this.formList.DataOrigin[4].children[0].options = val ? this.agreementList[val].map(item => ({ id: item, name: item })) : []
      this.$refs.formBuild.setForm({
        protocolFormat: this.agreementList[val].length ? this.agreementList[val][0] : ''
      })
    },
    // 更多操作
    more(row, command) {
      const functions = {
        status: () => { this.switchStatus(row, this.params.radio) },
        del: () => { this.del(row) }
      }
      functions[command]()
    },
    radioChange(label) {
      this.functionHeader.tags[0].hidden = !this.permission(label + 'Add')
      this.$refs.mTable.setTableData(true)
    },
    async setDataPoint(row) {
      const res = await api.dataOriginDetail(row.id)
      if (res) {
        this.dataPointVisible = '设置数据点'
        this.currentType = 'addPoint'
        this.dataSourceData = row
        this.dataPoints = res[0].dataPointList
        this.dataPointVisible = true
      }
    },
    async getDataPoint(row) {
      const res = await api.dataOriginDetail(row.id)
      if (res) {
        this.dataPoints = res[0].dataPointList
      }
    },
    selectionChange(val) {
      this.selectPointData = val
    },
    pointAdd() {
      this.currentType = 'addPoint'
      this.formData = {
        dataSourceId: this.dataSourceData.id,
        dataSourceName: this.dataSourceData.name
      }
      this.visible = true
    },
    delPoint(row) {
      if (!row && !this.selectPointData.length) return this.$message.info('请选择数据点')
      this.$confirm('您确定要删除数据点吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api.batchDelDataPoint({ idList: row ? row.id : this.selectPointData.map(item => item.id).join('^') })
        if (res) {
          this.$message.success('删除成功')
          await this.getDataPoint({ id: this.dataSourceData.id })
        }
      })
    },
    submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api[`${this.currentType}${this.params.radio}`](
            this.currentType !== 'modify' && this.currentType !== 'modifyPoint' ? this.createSetData(form) : this.createResetData(form)
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            if (this.currentType === 'addPoint' || this.currentType === 'modifyPoint') {
              this.visible = false
              await this.getDataPoint({ id: this.dataSourceData.id })
            } else {
              this.visible = false
              this.$refs.mTable.setTableData()
            }
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      return this.params.radio === 'CallParams' ? {
        attribute: raw.attribute,
        name: raw.name,
        type: raw.type,
        unit: raw.unit,
        remark: raw.remark
      } : this.currentType === 'addPoint' || this.currentType === 'modifyPoint' ? {
        dataSourceId: raw.dataSourceId,
        dataSourceName: raw.dataSourceName,
        location: raw.location,
        remark: raw.remark
      } : {
        adaptor: raw.adaptor,
        protocolFormat: raw.protocolFormat,
        name: raw.name,
        host: raw.host,
        port: raw.port,
        cycle: raw.cycle,
        userName: raw.userName,
        password: raw.password,
        isEnabled: raw.isEnabled
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.params-wrap {
  padding: 10px;
}
</style>
