<template>
    <div class="box">
      <div v-for="(item, index) in baseData" :key="index" class="box-li">
        <div v-if="index !== baseData.length -1" class="el-timeline-item__tail" />
        <div class="li-left">
          {{ formMap[item.key === 'checkClose'?'checkClose_1':item.key].label }}
        </div>
        <div class="li-right">
          <div v-for="(i,idx) in item.content" :key="idx">
            <div class="li-right-content">
              <!-- 顶部基本信息 -->
              <div class="content-top">
                <div v-for="(j,k) in getForArr(item,i)[0]" :key="k" class="tit">
                  <span v-if="j.key==='callSound'">
                    {{ j.label }}：
                    <audio v-if="getSound(i[j.key]||'{}')" controls>
                      <source :src="getSound(i[j.key])" type="audio/mpeg">
                      您的浏览器不支持 audio 元素。
                    </audio>
                    <span v-else>-</span>
                  </span>
                  <span v-else-if="j.key==='sort'" style="fontSize:30px">#{{ i[j.key] }}</span>
                  <span v-else>{{ j.label }}：{{ getVal(i,j.key) }}</span>
                </div>
              </div>
              <!-- 中间描述信息 -->
              <div
                v-for="(j,k) in getForArr(item,i).slice(1)"
                :key="k"
                class="content-center"
              >
                <div class="title">
                  {{ j.label }}
                </div>
                <div v-if="j.key==='pictures'&&i[j.key]">
                  <el-image
                    v-for="img in i[j.key].split(',')"
                    :key="img"
                    style="width: 100px; height: 100px;margin:10px"
                    :src="img"
                    :preview-src-list="i[j.key].split(',')"
                  />
                </div>
                <div v-else class="">
                  {{ i[j.key]||'-' }}
                </div>
              </div>
<!--              <div class="sub-desc">{{ i.submitUserJobNumber }} / {{ i.submitUserName }}提交于{{ i.submitTime }}</div>-->
            </div>

            <div class="li-right-arrow" />
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { judgeResultMap, finalResultMap, checkResultMap } from '../data'
  export default {
    name: 'TimeLine',
    props: {
      baseData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        judgeResultMap, finalResultMap, checkResultMap,
        formMap: {
          abnormalInitiate: {
            label: '异常发起',
            content: [{},
              { label: '异常现象', key: 'abnormalRemark' },
              { label: '现场照片', key: 'pictures' }
            ]
          },
          abnormalJudge: {
            label: '异常判定',
            content: [{},
              { label: '异常原因', key: 'abnormalReasonName' },
              { label: '原因分析', key: 'reason' },
            ]
          },
          handleProcess: {
            label: '异常处理',
            content: [{},
              { label: '处理措施', key: 'tempPolicy' },
              { label: '处理照片', key: 'pictures' }
            ]
          }
        }
      }
    },
    watch: {
      baseData: {
        handler(val) {
          this.setFormMap()
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      getForArr(item, i) {
        return item.key === 'checkClose' ? this.formMap['checkClose_' + i.checkResult].content : this.formMap[item.key].content
      },
      getVal(obj, key) {
        console.log("123",obj,key);
        return obj[key] || '-'
      },
      getSound(obj) {
        return JSON.parse(obj).url
      },
      setFormMap() {
        if (this.baseData[1]?.content[0]?.dutyPowerName === '供应商') {
          this.formMap.abnormalJudge.content[0] = [
            { label: '判定结果', key: 'judgeResult' },
            { label: '责任方', key: 'dutyPowerName' },
            { label: '责任供应商', key: 'dutySupplierName' },
            { label: '后续跟踪人', key: 'followUserJobNumber,followUserName' }
          ]
        }
        if (this.baseData[1]?.content[0]?.judgeResult === 1) { // 无需处理直接关闭
          this.formMap.abnormalJudge.content.splice(1, 3, { label: '关闭说明', key: 'closeExplain' })
        }
      }
    }
  }
  </script>

  <style lang="scss">
  .box {
    .box-li {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 22px;
      position: relative;
      .el-timeline-item__tail {
        position: absolute;
        top: 55px;
        left: 75px;
        height: 100%;
        border-left: 2px solid #607fff;
      }
      .el-timeline-item__tail:before {
        position: absolute;
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid #607fff;
        top: -7px;
        left: -5px;
      }
      .el-timeline-item__tail:after {
        position: absolute;
        content: "";
        width: 7px;
        height: 7px;
        background: #ffffff;
        border: 1px solid #607fff;
        bottom: 33px;
        left: -5px;
        border-radius: 100%;
      }
      .li-left {
        width: 154px;
        height: 48px;
        background: #eaedff;
        border-radius: 2px;
        font-size: 14px;
        color: #393d60;
        line-height: 48px;
        margin-right: 18px;
        text-align: center;
        .des {
          font-size: 12px;
        }
      }
      .li-right {
        position: relative;
        background: #f6f7f9;
        width: calc(100% - 175px);
        .li-right-content {
          position: relative;
          padding: 20px;
          .sub-desc{
            position: absolute;
            font-size: 12px;
            right: 20px;
            bottom: 5px;
            color: gray;
          }
          ::v-deep .el-timeline-item__tail {
            border-left: 1px dashed #607fff !important;
          }
          .content-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tit {
              line-height: 32px;
              font-size: 14px;
              color: #393d60;
              margin-right: 40px;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .content-center {
            margin-top: 10px;
            line-height: 20px;
            font-size: 14px;
            color: #393d60;
            .title {
              color: #393d60;
              font-weight: 600;
            }
          }
        }
        .li-right-arrow {
          position: absolute;
          top: 24px;
          left: -12px;
          border-color: transparent;
          border-style: solid;
          border-right-color: #f6f7f9;
          border-left-width: 0;
          width: 0;
          height: 0;
          border-width: 6px;
        }
      }
    }
  }
  </style>
