<template>
  <div class="scrap-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <AuxiliaryClass ref="equipmentClass" @nodeClick="setNodeId" @refresh="$refs.mTable.setTableData(true)" />
      </template>
      <template slot="right">
        <div class="bottom">
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入辅材编号/名称/SN"
            :tags="functionHeader.tags"
            :columns-key="columnsKey"
            @search="$refs.mTable.setTableData(true)"
            @checkSearch="checkSearch"
            :tabRadio="params.radio"
            :hiddenMulSearch="true"
          >
            <!-- <div slot="all" style="display: flex;justify-content: flex-start;align-items: center;margin-left: 10px;">
              <el-dropdown size="small" split-button class="all-operator" @command="(command) => { allMore(command) }">
                <span class="el-dropdown-link">
                  批量操作
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="permission('Export')" command="batchExport" :disabled="!selectionData.length">导出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div> -->
          </FunctionHeader>
          <div class="top">
            <SearchForm v-show="!isShow" ref="searchForm" :form-list-extend="searchFormList" @search="searchTable" />
          </div>
          <MTable
            ref="mTable"
            :columns="columns"
            :height="height"
            :set-data-method="getTableData"
            @selection-change="selectionChange"
            :columns-setting="isShow"
            :columns-key="columnsKey"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="status" slot-scope="{ row }">{{ auxiliaryType[row.status].name }}</div>
            <el-button slot="code" slot-scope="{ row }" style="white-space: pre-wrap" type="text" @click="viewDetail(row)">{{ row.code }}</el-button>
            <div slot="action" slot-scope="{ row }">
              <template>
                <el-button v-if="permission('Copy')" type="text" @click="modifyOrCopy(row, 'copy')">复制</el-button>
                <el-divider v-if="permission('Copy')" direction="vertical" />
                <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
                <el-divider v-if="permission('Modify')" direction="vertical" />
                <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
              </template>
            </div>
          </MTable>
          <MDialog v-model="visible" :title="messages[this.currentType]" @onOk="submitForm" width="1000px" class="extra-forms">
            <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="formList" />
          </MDialog>
        </div>
      </template>
    </Split>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import conventionMixin from '@/mixin/conventional-page'
import { searchFormList } from './form-list'
import { auxiliaryColumns } from './columns'
import { getUUid, setTreeData } from '@/utils'
import api from '@/api/xiewei/facility/facility'
import { Encrypt } from '@/utils/sercet'
import fixSearchNum from '@/mixin/fix-search-num'
import fixFieldShow from '@/mixin/fix-field-show'
import { equipmentType, auxiliaryType } from '@/config/options.config'
import Split from '@/components/Split/Split'
import AuxiliaryClass from '@/components/xiewei/AuxiliaryClass/AuxiliaryClass'
import fieldApi from '@/api/xiewei/field/field'
import keApi from '@/api/information/unit'
import { auxiliaryFormList } from './form-list'
import { quanlityInfoType } from '@/config/options.sales'

export default {
  name: 'auxiliaryMaterialsList',
  components: { SearchForm, FunctionHeader, Split, AuxiliaryClass },
  mixins: [fixFieldShow, fixSearchNum, conventionMixin],
  filters: {
    equipmentClassTypeName(id) {
      return equipmentType.find(item => item.id === id).name
    }
  },
  data() {
    return {
      api,
      fieldApi,
      formList: auxiliaryFormList,
      quanlityInfoType,
      auxiliaryType,
      searchFieldData: [],
      params: {
        radio: 'AuxiliaryMaterials'
      },
      columns: auxiliaryColumns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'exportFacility',
        importName: 'AuxiliaryMaterials',
        exportParams: {}
      },
      formData: {},
      searchKeyword: true,
      split: {
        offset: 15
      },
      selectionData: [],
      getTableDataParams: {},
      actionColumn: [
        {
          slotName: 'action',
          label: '操作',
          fixed: 'right',
          sortable: false,
          hiddenSearch: true,
          width: 175
        }
      ],
      tableSign: 'auxiliaryMaterials',
      visible: false,
      exportSelectionIds: [],
      allCustomer: [],
      classList: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - (this.isShow ? -40 : 120)
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 97
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    }
  },
  mounted() {
    this.getAllCustomer()
    this.getAuxiliaryClassList()
  },
  methods: {
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        let data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.searchFormList[2].props.options = setTreeData(data)
      }
    },

    async getAuxiliaryClassList() {
      const res = await api.getAuxiliaryClassList()
      if (res) {
        this.formList[4].children[0].options = res
        this.classList = res
      }
    },

    selectionChange(data) {
      this.selectionData = data
      this.exportSelectionIds = data.map(item => item.id)
    },

    allMore(command) {
      const functions = {
        batchExport: () => {
          this.batchExport()
        },
        batchAdd: () => {
          this.batchAdd()
        }
      }
      functions[command]()
    },

    batchAdd() {
      this.visible = true
    },

    setNodeId(val) {
      this.getTableDataParams.classId = val
      this.$refs.mTable.setTableData(true)
    },

    searchTable(data) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('customerId')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
        this.searchFormatData.splice(hasIndex, 1)
        let id = data[hasIndex].fieldValue
        const arr = this.allCustomer.filter(item => item.id === this._.first(id))
        const list = this.allCustomer.filter(item => item.id === this._.last(id))
        this.searchFormatData = [...this.searchFormatData, {
          id: getUUid(),
          fieldName: list[0].isCustomer === 1 ? 'customerSiteId' : 'customerId',
          fieldType: 'number',
          fieldValue: list[0].isCustomer === 1 ? list[0].id : arr[0].id,
          operator: 'eq'
        }]
      }
      this.$refs.mTable.setTableData(true)
    },

    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await api.getAuxiliaryMaterialsListPage({
        page: page.currentPage,
        limit: page.pageSize,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([ ...this.searchFormatData])),
        classId: this.getTableDataParams.classId
      })
      if (res) {
        const datas = res.records ? res.records.map(item => {
          return {
            ...item
          }
        }) : []
        callback({
          total: res.total,
          content: datas
        })
      }
      callback()
    },

    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'auxiliaryMaterialsDetail',
        params: { id: row.id }
      })
    },

    createSetData(raw) {
      return {
        ...raw,
        className: this.classList.find(c => c.id === raw.classId).name
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}
.nodata {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  color: #999;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
.content-container {
  max-height: 300px;
  overflow: auto;
}
.scrap-wrap {
  width: 100%;

  .bottom {
    padding: 10px;
  }
  .extra-forms {
    ::v-deep {
      .el-form-item {
        margin-bottom: 20px !important;
      }
    }
  }
}
</style>
