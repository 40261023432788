var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "package-wrap" },
    [
      _c("FunctionHeader", {
        attrs: {
          "search-title": "请输入类型名称",
          tags: _vm.functionHeader.tags
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "isEnabled",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { staticClass: "is-enabled" }, [
                _c("div", {
                  style: { background: row.isEnabled ? "#24CAD7" : "#FFCC78" }
                }),
                _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
              ])
            }
          },
          {
            key: "users",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewUserAndUserGroups(row)
                        }
                      }
                    },
                    [_vm._v("查看")]
                  )
                ],
                1
              )
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.permission("SetUser")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.setUserAndUserGroup(row)
                            }
                          }
                        },
                        [_vm._v("设置人员")]
                      )
                    : _vm._e(),
                  _vm.permission("SetUser")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Relation")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.relation(row)
                            }
                          }
                        },
                        [_vm._v("关联流程")]
                      )
                    : _vm._e(),
                  _vm.permission("Relation")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Status")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.switchStatus(row, _vm.params.radio)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.isEnabled ? "停用" : "启用"))]
                      )
                    : _vm._e(),
                  _vm.permission("Status")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Modify")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.modifyOrCopy(row, "modify")
                            }
                          }
                        },
                        [_vm._v("修改")]
                      )
                    : _vm._e(),
                  _vm.permission("Del")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Del")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.del(row)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "选择呼叫通知人员" },
          on: { onOk: _vm.submitUserAndUserGroup },
          model: {
            value: _vm.setPersonVisible,
            callback: function($$v) {
              _vm.setPersonVisible = $$v
            },
            expression: "setPersonVisible"
          }
        },
        [
          _c("SelectUserAndUserGroup", {
            ref: "userAndUserGroup",
            model: {
              value: _vm.transferSelectIds,
              callback: function($$v) {
                _vm.transferSelectIds = $$v
              },
              expression: "transferSelectIds"
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "关联流程" },
          on: { onOk: _vm.submitRelation },
          model: {
            value: _vm.showRelation,
            callback: function($$v) {
              _vm.showRelation = $$v
            },
            expression: "showRelation"
          }
        },
        [
          _c("div", { staticClass: "item-wrapper" }, [
            _c("div", { staticClass: "label" }, [_vm._v("选择流程：")]),
            _c(
              "div",
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.defaultRelation,
                      callback: function($$v) {
                        _vm.defaultRelation = $$v
                      },
                      expression: "defaultRelation"
                    }
                  },
                  _vm._l(_vm.lists, function(items) {
                    return _c(
                      "el-radio",
                      { key: items.id, attrs: { label: items.id } },
                      [_vm._v(" " + _vm._s(items.name) + " ")]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _c("TableDialog", {
        attrs: {
          title: "查看人员",
          show: _vm.userVisible,
          columns: _vm.userColumns,
          "table-data": _vm.userAndGroupList
        },
        on: {
          "update:show": function($event) {
            _vm.userVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }