export const columns = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 50
  },
  {
    prop: 'name',
    label: '模板名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name',
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'equipmentClassName',
    label: '适用设备分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 2,
      colSpan: 8,
      tagName: 'el-cascader',
      key: 'equipmentClassIds',
      required: false,
      props: {
        props: {
          label: 'name',
          value: 'id',
          multiple: true
        },
        options: [],
        filterable: true,
        clearable: true
      }
    }
  },
  {
    prop: 'customerName',
    label: '适用客户',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 3,
      tagName: 'el-select',
      required: false,
      key: 'customerIds',
      options: [],
      colSpan: 8,
      props: { multiple: true }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' },
      colSpan: 8
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]

export const columnsAdd = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 50
  },
  {
    prop: 'name',
    label: '参数名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name',
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'unit',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'unit',
    form: { index: 2, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'standardValuesMax',
    label: '标准值',
    sortable: false,
    hiddenSearch: true,
    slotName: 'standardValuesMax',
    form: { index: 3, tagName: 'el-input', colSpan: 8 }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 140
  }
]
