
export const columns = [
  {
    prop: 'maintainTaskNumber',
    label: '保养单号'
  },
  {
    prop: 'maintainType',
    label: '保养类别'
  },
  {
    prop: 'status',
    label: '状态'
  },
  {
    prop: 'equipmentCode',
    label: '设备编号'
  },
  {
    prop: 'equipmentName',
    label: '设备名称'
  },
  {
    prop: 'equipmentClassName',
    label: '设备类'
  },
  {
    prop: 'maintainPlanNumber',
    label: '计划单号'
  },
  {
    prop: 'maintainPlanTime',
    label: '计划日期'
  },
  {
    prop: 'maintainStartTime',
    label: '实际开始'
  },
  {
    prop: 'maintainEndTime',
    label: '实际结束'
  },
  {
    prop: 'maintainSpendTime',
    label: '消耗时间'
  },
  {
    prop: 'maintainerName',
    label: '保养人'
  },
  {
    prop: 'creator',
    label: '创建人'
  },
  {
    prop: 'createTime',
    label: '创建时间'
  },
  {
    prop: 'skipReason',
    label: '跳过原因'
  }
]

export const maintenanceItemView = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: false,
    width: 50,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '保养项名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'standard',
    sortable: false,
    hiddenSearch: true,
    label: '保养标准'
  },
  {
    prop: 'result',
    label: '保养状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'maintenanceResult'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]
