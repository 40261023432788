<template>
  <div class="call-search-wrap">
    <div class="top">
      <SearchForms ref="searchForms" @resetData="resetData" @searchData="searchTable" />
    </div>
    <el-divider class="e-divider" />
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      :tags="functionHeader.tags"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      :show-export="!selectData.length ? true : false"
      :hidden-search="true"
      style="padding: 10px 0 10px 0;margin-left: -10px;"
    >
      <div slot="all" class="all-select">
        <el-button v-show="permission('BatchDel') && selectData.length" type="primary" size="small" @click="allMore('allCheck')">批量删除</el-button>
        <el-button v-show="permission('Export') && selectData.length" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>

        <span v-show="selectData.length" style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
        <el-button v-show="selectData.length" type="text" size="small" @click="resetSelectData" style="margin-top:-4px;">清空</el-button>

        <div class="checkAll">
          <el-checkbox v-model="checked" :disabled="checked" :indeterminate="isIndeterminate" @change="selectCheck">全选</el-checkbox>
        </div>
        <div v-for="(item, index) in allStatus" :key="item.value" class="all-li" :class="item.checked ? 'active' : ''" @click="selectTab(item, index)">{{ item.label }}</div>
      </div>
    </FunctionHeader>
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="emergencyDegree" slot-scope="{ row }">{{ row.emergencyDegree ? '普通' : '紧急' }}</div>
      <el-image
        v-if="row.handlePicture"
        slot="handlePicture"
        slot-scope="{ row }"
        :src="fixImg(row.handlePicture)[0]"
        class="table-row-img"
        :preview-src-list="fixImg(row.handlePicture)"
      />
      <div slot="status" slot-scope="{ row }" class="faultStatus" :style="{ background: allStatus[row.status].background, color: allStatus[row.status].color }">{{ allStatus[row.status].label }}</div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('DelSearch')" type="text" @click="del(row)">删除</el-button>
      </div>
    </MTable>
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import api from '@/api/call/search'
import columns from './columns'
import SearchForms from './search-forms.vue'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'
import dayjs from 'dayjs'

export default {
  name: 'CallSearch',
  components: { FunctionHeader, SearchForms },
  data() {
    return {
      api,
      params: {
        radio: 'CallSearch'
      },
      isIndeterminate: false,
      searchFormatData: [],
      functionHeader: {
        exportName: 'exportCallSearch',
        tags: [],
        exportParams: {}
      },
      columns,
      currentId: undefined,
      formOptions: {},
      allStatus: [
        {
          value: 0,
          label: '待处理',
          checked: false,
          color: '#FA6400',
          background: 'rgba(250, 100, 0, 0.1)'
        },
        {
          value: 1,
          label: '处理中',
          checked: false,
          color: 'rgba(0, 171, 41, 1)',
          background: 'rgba(0, 171, 41, 0.1)'
        },
        {
          value: 2,
          label: '已处理',
          checked: false,
          color: 'rgba(149, 151, 174, 1)',
          background: 'rgba(149, 151, 174, 0.1)'
        }
      ],
      checked: true,
      currentIds: [],
      currentIdx: false,
      selectData: [],
      selectionDataList: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  watch: {
    checked(val) {
      if (val) {
        this.currentIds = []
        this.isIndeterminate = false
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      } else {
        this.isIndeterminate = true
      }
    }
  },
  methods: {
    allMore(command) {
      const functions = {
        allCheck: () => { this.allCheck() },
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    batchExport() {
      const ids = [
        {
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.selectData.join('^'),
          operator: 'in'
        }
      ]
      const params = {
        searchVal: Encrypt(JSON.stringify(ids))
      }
      this.$refs.functionHeader.export(params)
    },
    selectionChange(data) {
      this.selectData = data.map(item => item.id)
      this.selectionDataList = data
    },
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
    async del(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delCallSearch({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },
    async allCheck() {
      if (this.selectData.length > 0) {
        const res = await api.batchDel(this.selectData)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      } else {
        this.$message.error('请选择要删除的数据')
      }
    },
    fixImg(img) {
      const imgs = img.split(',')
      return imgs
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      if (this.currentIds.length) {
        search.push({
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: this.currentIds.join('^'),
          operator: 'in'
        })
      }
      const params = {
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData]))
      }
      // 导出
      this.functionHeader.exportParams = params
      const res = await this.api[`get${this.params.radio}ListPage`]({
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    selectTab(val, index) {
      // 判断是否是最后一个checked
      const isCheck = this.allStatus.filter(item => item.checked)
      const isAll = this.allStatus.filter(item => !item.checked)
      this.allStatus[index].checked = !this.allStatus[index].checked
      if (this.currentIds.includes(val.value)) {
        this.currentIds = this.currentIds.filter(item => item !== val.value)
        this.checked = isCheck.length === 1
      } else {
        this.checked = isAll.length === 1
        this.currentIds.push(val.value)
      }
      this.$refs.mTable.setTableData(true)
    },
    selectCheck() {
      if (this.checked) {
        this.currentIds = []
        this.$refs.mTable.setTableData(true)
      }
    },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    // 重置
    resetData() {
      this.searchFormatData = []
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
.call-search-wrap{
  padding: 10px;
  .all-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .checkAll {
      margin: 0 20px;
    }
    .all-li {
      width: 80px;
      height: 26px;
      background: #FFFFFF;
      border-radius: 16px;
      border: 1px solid #DDE3EB;
      margin-right: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A4B3C6;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .active {
      color: #607FFF;
      border-color: #607FFF;
      background: #EFF2FF;
    }
  }
  .li-btn{
    margin-right:10px;
    color:#607FFF;
  }
  .color-btns{
    color:#c4c4c4;
  }
  .searchs{
    padding-left: 0;
    padding-top: 0;
  }
  .input-select{
    width:100px;
    display:block;
  }
  .input-with-select{
    width:100%;
  }
  .all-operator{
    height: 32px;
    margin-right: 10px;
    ::v-deep{
      .el-button-group .el-button{
        height: 32px;
      }
    }
  }
}
::v-deep .customer-content div {
  width: 100%;
}

.tip-tit {
  height: 18px;
  line-height: 18px;
}
div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #303133;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #303133;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #303133;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center !important;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.faultDesc {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
}
::v-deep{
  .e-divider{
    margin: 0px 0 0 0;
  }
}
::v-deep {
  .table-row-img {
    >img {
      height: 50px;
    }
  }
}
</style>
