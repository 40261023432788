<template>
  <div class="auxiliary-detail-wrapper">
    <div class="auxiliary-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px" />
        <span style="padding-left: 10px; cursor: pointer">辅材详情</span>
      </div>
    </div>
    <div class="auxiliary-detail-body">
      <div class="detail-card">
        <div class="card-tit">辅材基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <div>
            <el-row class="view-list" style="padding: 10px 15px; color: #393d60" :gutter="24">
              <!--            暂时这么处理距离问题-->
              <el-col v-for="(item, index) in columnss" :key="item.prop" :span="6" :style="{ marginBottom: index !== 4 ? '20px' : 0 }">
                <div style="color: #9597ae">{{ item.label }}</div>
                <div class="bot-tis">{{ getValue(item) || '-' }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 15px;display: none">
      <el-tabs v-model="params.radio" type="border-card" tab-position="top" @tab-click="tabClick">
        <el-tab-pane label="使用记录" name="auxiliaryRecordRadio">
          <MTable
            v-if="params.radio === 'auxiliaryRecordRadio'"
            ref="mTable"
            :height="height"
            :columns="auxiliaryRecordColumns"
            :data="auxiliaryRecordList"
            :showPage="false"
          >
            <div slot="operationType" slot-scope="{ row }">
              {{ operationType[row.operationType].name }}
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="运入运出配置单" name="auxiliaryInstructionRecordRadio">
          <MTable
            v-if="params.radio === 'auxiliaryInstructionRecordRadio'"
            ref="mTable"
            :height="height"
            :columns="auxiliaryInstructionRecordColumns"
            :set-data-method="getTableData"
          >
            <div slot="action" slot-scope="{ row }">
              <el-button type="text" @click="getDetail(row)">详情</el-button>
            </div>
          </MTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <MDialog v-model="configurationVisible" title="查看配置明细">
      <MTable ref="mTable" :columns="configurationColumns" :height="height" :data="configurationList" :columns-setting="false" :show-page="false">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="shipOutMatch" slot-scope="{ row }">
          <el-radio disabled :label="1" :value="row.shipOutMatch"></el-radio>
        </div>
        <div slot="shipInMatch" slot-scope="{ row }">
          <el-radio disabled :label="1" :value="row.shipInMatch"></el-radio>
        </div>
      </MTable>
    </MDialog>
  </div>
</template>

<script>
const empty = 'https://oss.mubyte.cn/static/empty.png'
import api from '@/api/xiewei/facility/facility'
import { columns, auxiliaryRecordColumns, auxiliaryInstructionRecordColumns, configurationColumns } from './columns'
import { formList } from './form-list'
import { operationType } from '@/config/options.config'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import TableDialog from '@/components/TableDialog/TableDialog'

export default {
  name: 'auxiliaryMaterialsDetail',
  components: { TableDialog },
  data() {
    return {
      api,
      columns,
      auxiliaryRecordColumns,
      auxiliaryInstructionRecordColumns,
      configurationColumns,
      operationType,
      params: {
        radio: 'auxiliaryRecordRadio'
      },
      columnss: [],
      equipmentDetail: {},
      auxiliaryRecordList: [],
      configurationList: [],
      configurationVisible: false,
      formList,
      radio: '选中且禁用'
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 148
    }
  },
  mounted() {
    this.getAuxiliaryMaterialDetail()
    this.columnss = this.columns.filter((item, index) => index > 1)
  },
  methods: {
    submitForm() {},
    async getAuxiliaryMaterialDetail() {
      const res = await api.getAuxiliaryMaterialDetail({
        id: this.$route.params.id
      })
      if (res) {
        this.equipmentDetail = res
        this.auxiliaryRecordList = res.allUserRecordList
      }
    },

    async getTableData(condition, callback) {
      const { page, search, order } = condition
      let searchKey = [
        {
          id: getUUid(),
          fieldName: 'auxiliaryMaterialId',
          fieldType: 'number',
          fieldValue: this.$route.params.id,
          operator: 'eq'
        }
      ]
      const res = await api.getAuxiliaryInstructionRecordListPage({
        page: page.currentPage,
        limit: page.pageSize,
        searchVal: Encrypt(JSON.stringify([...searchKey]))
      })
      if (res) {
        const datas = res.records
          ? res.records.map(item => {
              return {
                ...item
              }
            })
          : []
        callback({
          total: res.total,
          content: datas
        })
      }
      callback()
    },
    async getAuxiliaryInstructionDetail(id) {
      let searchKey = [
        {
          id: getUUid(),
          fieldName: 'outInInstructionAuxiliaryRecordId',
          fieldType: 'number',
          fieldValue: id,
          operator: 'eq'
        }
      ]
      const res = await api.getAuxiliaryInstructionDetail({
        searchVal: Encrypt(JSON.stringify([...searchKey]))
      })
      if (res) {
        this.configurationList = res
      }
    },
    getValue(row) {
      const result = this.equipmentDetail[row.prop]
      if (row.form && row.form.options && row.form.options.length > 0) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    back() {
      this.$router.push({ name: 'auxiliaryMaterialsList' })
    },
    getDetail(row) {
      this.getAuxiliaryInstructionDetail(row.id)
      this.configurationVisible = true
    },
    tabClick() {}
  }
}
</script>

<style scoped lang="scss">
.auxiliary-detail-wrapper {
  .auxiliary-detail-header {
    width: 100%;
    padding: 10px 0;
  }
  .auxiliary-detail-body {
    padding-top: 10px;
    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #dde3eb;
      }
    }
  }

  ::v-deep .configuration-parameter__main {
    padding: 0;
  }
}
</style>
