<template>
  <div class="scrap-wrapss">
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList" @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入设备名称/编号"
        :tags="functionHeader.tags"
        :hidden-search="true"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
        <NewPageOpen slot="cycleCheckTaskNumber" slot-scope="{ row }" :path="{ name: 'inspectionBillsDetail', query: { id: row.id, type: 'view' }}" :text="row.cycleCheckTaskNumber" />
        <div slot="status" slot-scope="{ row }" :style="{ color: colors[row.status] }">{{ row.status | inspectionStatusName }}</div>
        <div v-if="row.checkSpendTime" slot="checkSpendTime" slot-scope="{ row }">{{ Math.ceil(row.checkSpendTime / 60) }}</div>
        <el-button slot="pointCheckItem" slot-scope="{ row }" type="text" @click="viewCheckItem(row)">查看</el-button>
      </MTable>
      <MDialog v-model="visible" width="400px" title="跳过原因" @onOk="submitPassInspectionBills">
        <MFormBuilder ref="formBuild" :form-list="reasonFormList" />
      </MDialog>
      <TableDialog :appends="true" title="查看巡检项" :show.sync="checkItemVisible" :columns="insColumns.PointCheckItemView()" :table-data="checkItemList" />
    </div>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import insColumns from '@/views/equipment/project-setting/columns'
import api from '@/api/equipment/inspection-bills'
import conventionMixin from '@/mixin/conventional-page'
import TableDialog from '@/components/TableDialog/TableDialog'
import settingMinix from '@/views/equipment/project-setting/setting.minix'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import { inspectionStatus } from '@/config/options.config'
import equipmentClassApi from '@/api/information/equipment-class'
import { getUUid, setTreeData } from '@/utils'

export default {
  name: 'InspectionBills',
  components: { NewPageOpen, TableDialog, SearchForm, FunctionHeader },
  filters: {
    inspectionStatusName(val) {
      return inspectionStatus.find(item => item.id === val).name
    }
  },
  mixins: [conventionMixin, settingMinix],
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    },
    planId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      api,
      extraParams: [],
      params: {
        radio: 'InspectionBills'
      },
      columns,
      insColumns,
      colors: {
        0: '#9597AE',
        1: '#FC9100',
        2: '#0091FF',
        3: '#9597AE',
        4: '#9597AE'
      },
      searchFormList: [
        {
          colSpan: 6,
          key: 'createTime',
          tagName: 'el-date-picker',
          dateType: 'date',
          props: {
            rangeSeparator: '至',
            startPlaceholder: '创建开始日期',
            endPlaceholder: '结束日期',
            align: 'center',
            type: 'daterange',
            pickerOptions: []
          },
          children: []
        }
      ],
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: []
      },
      visible: false,
      searchKeyword: true,
      selectData: [],
      reasonFormList: [{
        key: 'reason',
        colSpan: 24,
        tagName: 'el-autocomplete',
        props: { fetchSuggestions: this.querySearch },
        required: false,
        attrs: {
          placeholder: '请输入或选择跳过原因'
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: []
          }
        ]
      }],
      restaurants: ''
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 37 : this.$store.state.app.contentHeight - 289 - 155
    }
  },
  mounted() {
    if (this.planId) {
      this.extraParams = [
        {
          id: getUUid(),
          fieldName: 'checkPlanId',
          fieldType: 'number',
          fieldValue: this.planId,
          operator: 'eq'
        }
      ]
    }
  },
  methods: {
    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        this.searchFormList[2].props.options = setTreeData(res)
      }
    },
    async querySearch(qs, cb) {
      const res = await api.getSkipReason()
      if (res) {
        this.restaurants = qs ? res.filter(this.createFilter(qs)).map(item => ({ value: item })) : res.map(item => ({ value: item }))
      }
      cb(this.restaurants)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    async getCheckTypeList() {
      const res = await api.getCheckTypeList()
      if (res) {
        this.searchFormList[0].children[0].options = res.map(item => ({ id: item, name: item }))
      }
    },
    selectionChange(data) {
      this.selectData = data
    },
    searchTable(data) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('equipmentClassIds')
      this.searchFormatData = this._.cloneDeep(data)
      this.getTableDataParams = {}
      if (hasIndex !== -1) {
        this.getTableDataParams = { equipmentClassIds: data[hasIndex].fieldValue.map(item => this._.last(item)).join('^') }
        this.searchFormatData.splice(hasIndex, 1)
      }
      this.$refs.mTable.tableRefs().clearSelection()
      this.$refs.mTable.setTableData(true)
    },
    async changeIsEnabled(row) {
      const res = await api.modifyInspectionPlan({
        id: row.id,
        isEnabled: row.isEnabled ? 0 : 1
      })
      if (res) {
        this.$refs.mTable.setTableData()
      }
    },
    abolishInspectionBills() {
      if (this.selectData.find(item => item.status !== 1)) {
        return this.$message.info('选中数据中包含状态非待巡检的巡检单')
      }
      this.$confirm('您确定要作废这些巡检单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const body = this.selectData.map(item => ({ id: item.id }))
        const res = await api.abolishInspectionBills(body)
        if (res) {
          this.$message.success('作废成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    passInspectionBills() {
      if (this.selectData.find(item => item.status !== 1)) {
        return this.$message.info('选中数据中包含状态非待巡检的巡检单')
      }
      this.visible = true
    },
    async submitPassInspectionBills(callback) {
      const { form } = this.$refs.formBuild
      if (!form.reason) {
        callback()
        return this.$message.info('请输入或选择跳过原因')
      }
      const res = await api.passInspectionBills({
        skipReason: form.reason,
        ids: this.selectData.map(item => ({ id: item.id }))
      })
      if (res) {
        this.$message.success('跳过成功')
        this.visible = false
        this.$refs.mTable.setTableData()
      }
      callback(true)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}
.scrap-wrapss {
  width: 100%;
  ::v-deep {
    .columns-setting {
      top: -28px;
    }
    .el-col.el-col-6 >.el-form-item {
      min-height: 40px!important;
    }
    .search-form-wrap {
      padding: 0;
    }
    .el-dialog__body {
      padding: 20px 0 10px 20px;
    }
  }
  .bottom {
    padding: 10px;
  }
}

::v-deep{
  .e-divider{
    margin: 0px 0 0 0;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    height: 34px;
  }
  .el-date-editor .el-range__close-icon {
    line-height: 26px;
  }
}
</style>
