var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrap-wrap" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("SearchForm", {
          ref: "searchForm",
          attrs: { "form-list-extend": _vm.searchFormList },
          on: { search: _vm.searchTable }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "FunctionHeader",
          {
            ref: "functionHeader",
            attrs: {
              "search-title": "请输入计划单号/设备名称/编号",
              tags: _vm.functionHeader.tags,
              "export-name": _vm.functionHeader.exportName,
              "export-params": _vm.functionHeader.exportParams,
              "show-export": !_vm.selectData.length ? true : false
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          },
          [
            _vm.permission("PassCheckItem") && _vm.selectData.length
              ? _c(
                  "el-button",
                  {
                    staticStyle: { height: "32px", padding: "10px 20px" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.passInspectionBills }
                  },
                  [_vm._v("批量跳过")]
                )
              : _vm._e(),
            _vm.permission("AbolishCheckItem") && _vm.selectData.length
              ? _c(
                  "el-button",
                  {
                    staticStyle: { height: "32px", padding: "10px 20px" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.abolishInspectionBills }
                  },
                  [_vm._v("批量作废")]
                )
              : _vm._e(),
            _vm.permission("Export") && _vm.selectData.length
              ? _c(
                  "el-button",
                  {
                    staticStyle: { height: "32px", padding: "10px 20px" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.exportInspectionBills }
                  },
                  [_vm._v("批量导出")]
                )
              : _vm._e(),
            _vm.permission("Export") && _vm.selectData.length
              ? _c(
                  "span",
                  {
                    staticStyle: {
                      "line-height": "32px",
                      margin: "0 10px",
                      display: "inline-block",
                      "font-size": "12px",
                      color: "#393D60"
                    }
                  },
                  [
                    _vm._v(" 已选 "),
                    _c("span", { staticStyle: { color: "#607FFF" } }, [
                      _vm._v(_vm._s(_vm.getSelectionDataLength))
                    ]),
                    _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                  ]
                )
              : _vm._e(),
            _vm.permission("Export") && _vm.selectData.length
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: { click: _vm.resetSelectData }
                  },
                  [_vm._v("清空")]
                )
              : _vm._e()
          ],
          1
        ),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.columns,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          on: { "selection-change": _vm.selectionChange },
          scopedSlots: _vm._u(
            [
              {
                key: "cycleCheckTaskNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _vm.isShowChild
                    ? _c("NewPageOpen", {
                        attrs: {
                          path: {
                            name: "inspectionBillsDetail",
                            query: { id: row.id, type: "view" }
                          },
                          text: row.cycleCheckTaskNumber
                        }
                      })
                    : _vm._e()
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { style: { color: _vm.colors[row.status] } },
                    [_vm._v(_vm._s(_vm._f("inspectionStatusName")(row.status)))]
                  )
                }
              },
              {
                key: "result",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {
                      style: { color: row.result === 0 ? "#FA6400" : "#00AB29" }
                    },
                    [_vm._v(_vm._s(+row.result === 0 ? "异常" : "正常"))]
                  )
                }
              },
              {
                key: "checkSpendTime",
                fn: function(ref) {
                  var row = ref.row
                  return row.checkSpendTime
                    ? _c("div", {}, [
                        _vm._v(_vm._s(Math.ceil(row.checkSpendTime / 60)))
                      ])
                    : _vm._e()
                }
              },
              {
                key: "pointCheckItem",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewCheckItem(row)
                        }
                      }
                    },
                    [_vm._v("查看")]
                  )
                }
              }
            ],
            null,
            true
          )
        }),
        _c(
          "MDialog",
          {
            attrs: { width: "400px", title: "跳过原因" },
            on: { onOk: _vm.submitPassInspectionBills },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          },
          [
            _c("MFormBuilder", {
              ref: "formBuild",
              attrs: { "form-list": _vm.reasonFormList }
            })
          ],
          1
        ),
        _c("TableDialog", {
          attrs: {
            title: "查看巡检项",
            show: _vm.checkItemVisible,
            columns: _vm.insColumns.PointCheckItemView(),
            "table-data": _vm.checkItemList
          },
          on: {
            "update:show": function($event) {
              _vm.checkItemVisible = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }