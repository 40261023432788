<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.push({ name: 'maintenanceBillsList' })">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
      <div class="right" />
    </div>

    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <template v-for="(item, index) in columns">
              <el-col
                v-if="baseFormData[item.prop] || baseFormData[item.prop] === 0"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>

      <div class="detail-card">
        <div style="padding: 20px 22px 0 20px">
          <el-radio-group v-model="newParams.radio" class="m-radio-group" @change="radioChange">
            <el-radio-button label="Maintenance">保养项</el-radio-button>
            <el-radio-button v-if="baseFormData.sparePartList && baseFormData.sparePartList.length" label="Spare">备件更换</el-radio-button>
          </el-radio-group>
        </div>
        <div class="card-bot">
          <MTable
            ref="maintenanceTable"
            :height="$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 192"
            :columns="itemColumns[newParams.radio]"
            :columns-setting="false"
            :show-page="false"
            :data="itemList"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="sparePartDrawing" slot-scope="{ row }">
              <el-image
                v-if="row.sparePartDrawing"
                style="padding-left: 10px"
                :src="row.sparePartDrawing"
                class="table-row-img"
                :preview-src-list="[row.sparePartDrawing]"
              />
            </div>
            <div slot="maintenanceResult" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: row.result === 1 ? '#24CAD7' : '#FFCC78' }" />
              <div>{{ row.result === 1 ? '已保养' : '未保养' }}</div>
            </div>
            <div slot="spareStatus" slot-scope="{ row }" :style="{ color: colors[row.status] }">{{ row.status | spareStatusName }}</div>
            <div slot="action" slot-scope="{ row, $index }">
              <el-button type="text" @click="viewMaintenanceDetail(row, $index)">详情</el-button>
            </div>
          </MTable>
        </div>
      </div>

      <MDialog v-model="visible" title="保养详情" :show-footer="false">
        <div class="result-wrapper">
          <div>
            <span>保养项：</span>
            <span>{{ currentData.name }}</span>
          </div>
          <div>
            <span>保养标准：</span>
            <span>{{ currentData.standard }}</span>
          </div>
          <div v-if="currentData.sketchMap">
            <span>操作图：</span>
            <div slot="sketchMap">
              <el-image
                v-for="item in formatArr(currentData.sketchMap)"
                :key="item.id"
                style="padding-left: 10px"
                :src="item.url"
                class="table-row-img"
                :preview-src-list="[item.url]"
              />
            </div>
          </div>
          <div>
            <span>状态：</span>
            <span>
              <div class="is-enabled">
                <div :style="{background: currentData.result === 1 ? '#24CAD7' : '#FFCC78' }" />
                <div>{{ currentData.result === 1 ? '已保养' : '未保养' }}</div>
              </div>
            </span>
          </div>
          <div v-if="currentData.remark">
            <span>备注：</span>
            <span>{{ currentData.remark }}</span>
          </div>
          <div v-if="currentData.sceneMap">
            <span>图片：</span>
            <div slot="sketchMap">
              <el-image
                v-for="item in formatArr(currentData.sceneMap)"
                :key="item.id"
                style="padding-left: 10px"
                :src="item.url"
                class="table-row-img"
                :preview-src-list="[item.url]"
              />
            </div>
          </div>
        </div>
      </MDialog>
    </div>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import { columns, maintenanceItemView } from './columns'
import { spareColumns } from '../columns'
import api from '@/api/equipment/maintenance-bills'
import { maintenanceStatus, spareStatus } from '@/config/options.config'

export default {
  name: 'MaintenanceBillsDetail',
  filters: {
    spareStatusName(val) {
      if (!val) return ''
      return spareStatus.find(item => item.id === val).name
    }
  },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {},
      newParams: {
        radio: 'Maintenance'
      },
      colors: {
        '0': '#E02020',
        '1': '#9597AE',
        '2': '#9FA1B7',
        '-1': '#E02020'
      },
      columns,
      itemColumns: {
        Maintenance: maintenanceItemView,
        Spare: spareColumns
      },
      maintenanceItemView,
      saveButton: false,
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '保养单详情',
        addO: '新增保养单',
        modifyO: '修改保养单',
        copyO: '复制保养单'
      },
      itemList: [],
      baseFormData: {},
      visible: false,
      currentData: {}
    }
  },
  activated() {
    this.params = this.$route.query
    this.saveButton = this.params.type !== 'view'
    if (this.params.type !== 'add') {
      this.getInspectionBillsDetail(this.params.id)
    }
  },
  methods: {
    radioChange(radio) {
      this.itemList = radio === 'Maintenance' ? this.baseFormData.maintenanceItemList : this.baseFormData.sparePartList
    },
    formatArr(arr) {
      try {
        return JSON.parse(arr)
      } catch (err) {
        return []
      }
    },
    viewMaintenanceDetail(row) {
      this.currentData = row
      this.visible = true
    },
    async getInspectionBillsDetail(id) {
      const res = await api.getMaintenanceBillsDetail({ id })
      if (res) {
        this.baseFormData = res
        this.itemList = res.maintenanceItemList || []
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.prop === 'status') {
        const current = maintenanceStatus.find(item => item.id === result)
        return current && current.name
      }
      if (row.prop === 'maintainSpendTime' && result) {
        return Math.ceil(result / 60) + ' min'
      }
      return result
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-plan-detail-wrapper {
  height: 100%;

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  ::v-deep {
    .el-col.el-col-6 >.el-form-item {
      min-height: 91px;
    }
    .el-col.el-col-12 >.el-form-item {
      min-height: 91px;
    }
    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
  ::v-deep {
    .table-row-img {
      >img {
        height: 50px;
      }
    }
  }
}
.result-wrapper {
  >div {
    display: flex;
    font-size: 16px;
    padding: 10px 0;
    span {
      &:first-child {
        width: 80px;
        text-align-last: justify;
        text-align: justify;
      }
    }
  }
}
</style>
