var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap" },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("AuxiliaryClass", {
                ref: "equipmentClass",
                on: {
                  nodeClick: _vm.setNodeId,
                  refresh: function($event) {
                    return _vm.$refs.mTable.setTableData(true)
                  }
                }
              })
            ],
            1
          ),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "bottom" },
              [
                _c("FunctionHeader", {
                  ref: "functionHeader",
                  attrs: {
                    "search-title": "请输入辅材编号/名称/SN",
                    tags: _vm.functionHeader.tags,
                    "columns-key": _vm.columnsKey,
                    tabRadio: _vm.params.radio,
                    hiddenMulSearch: true
                  },
                  on: {
                    search: function($event) {
                      return _vm.$refs.mTable.setTableData(true)
                    },
                    checkSearch: _vm.checkSearch
                  },
                  model: {
                    value: _vm.functionHeader.searchVal,
                    callback: function($$v) {
                      _vm.$set(_vm.functionHeader, "searchVal", $$v)
                    },
                    expression: "functionHeader.searchVal"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "top" },
                  [
                    _c("SearchForm", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isShow,
                          expression: "!isShow"
                        }
                      ],
                      ref: "searchForm",
                      attrs: { "form-list-extend": _vm.searchFormList },
                      on: { search: _vm.searchTable }
                    })
                  ],
                  1
                ),
                _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    columns: _vm.columns,
                    height: _vm.height,
                    "set-data-method": _vm.getTableData,
                    "columns-setting": _vm.isShow,
                    "columns-key": _vm.columnsKey
                  },
                  on: { "selection-change": _vm.selectionChange },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "status",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(_vm.auxiliaryType[row.status].name))
                        ])
                      }
                    },
                    {
                      key: "code",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "el-button",
                          {
                            staticStyle: { "white-space": "pre-wrap" },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(row.code))]
                        )
                      }
                    },
                    {
                      key: "action",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            [
                              _vm.permission("Copy")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.modifyOrCopy(row, "copy")
                                        }
                                      }
                                    },
                                    [_vm._v("复制")]
                                  )
                                : _vm._e(),
                              _vm.permission("Copy")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission("Modify")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.modifyOrCopy(row, "modify")
                                        }
                                      }
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm.permission("Modify")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission("Del")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.del(row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ]
                          ],
                          2
                        )
                      }
                    }
                  ])
                }),
                _c(
                  "MDialog",
                  {
                    staticClass: "extra-forms",
                    attrs: {
                      title: _vm.messages[this.currentType],
                      width: "1000px"
                    },
                    on: { onOk: _vm.submitForm },
                    model: {
                      value: _vm.visible,
                      callback: function($$v) {
                        _vm.visible = $$v
                      },
                      expression: "visible"
                    }
                  },
                  [
                    _c("MFormBuilder", {
                      ref: "formBuild",
                      attrs: {
                        "label-position": "right",
                        "label-width": "100px",
                        "form-data": _vm.formData,
                        "form-list": _vm.formList
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }