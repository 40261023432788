import statisticsCardList, { getDateRange } from '@/views/statistics-board/board-manage/all-statistics/statistics-card'

const current1 = statisticsCardList[0].children.find(item => item.id === 10)
const procedureWork = {
  id: 13,
  name: '物料订单量统计图',
  type: 'charts',
  dataUrl: 'statistics/private/statistics/getProcedureMonthOutput',
  chartDataKey: 'quantity',
  chartType: 'bar',
  dataCondition: { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1] },
  chartLegendKey: 'info.name',
  chartXKey: 'month'
}
export default {
  bbelc: [
    [
      {
        type: 'minix',
        screenTitle: '包纸车间看板',
        title: ['产线产量统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '161' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '161' }]
      }
      // {
      //   type: 'charts',
      //   screenTitle: '包纸车间看板',
      //   chartType: 'bar',
      //   title: ['产线产量统计图'],
      //   currentData: current1,
      //   dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '161' }]
      // }
    ],
    [
      {
        type: 'minix',
        screenTitle: '浸漆车间看板',
        title: ['工序报工统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '166' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '166' }]
      }
      // {
      //   type: 'table',
      //   screenTitle: '浸漆车间看板',
      //   title: ['员工报工统计表'],
      //   columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
      //   columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
      //   tableColumns: statisticsCardList[2].children[0].tableColumns,
      //   dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '218', stationIds: '110' }]
      // },
      // {
      //   type: 'charts',
      //   screenTitle: '浸漆车间看板',
      //   chartType: 'bar',
      //   title: ['工序产量统计图'],
      //   currentData: procedureWork,
      //   dataUrlAndCondition: [procedureWork.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], procedureIds: '218' }]
      // },
      // {
      //   type: 'minix',
      //   screenTitle: '铁芯车间看板',
      //   title: ['产线产量统计表', '工单进度统计表'],
      //   columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
      //   columnWidth: [60, 170, 170, 150, 160, 170],
      //   columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
      //   tableColumns: statisticsCardList[0].children[0].tableColumns,
      //   singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '159' }],
      //   dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '159' }]
      // },
      // {
      //   type: 'charts',
      //   screenTitle: '铁芯车间看板',
      //   chartType: 'bar',
      //   title: ['产线产量统计图'],
      //   currentData: current1,
      //   dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '159' }]
      // }
    ],
    [
      {
        type: 'minix',
        screenTitle: '铁芯车间看板',
        title: ['产线产量统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '159' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '159' }]
      }
      // {
      //   type: 'charts',
      //   screenTitle: '铁芯车间看板',
      //   chartType: 'bar',
      //   title: ['产线产量统计图'],
      //   currentData: current1,
      //   dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '159' }]
      // }
    ],
    [
      {
        type: 'minix',
        screenTitle: '总装车间看板',
        title: ['工序报工统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '164' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '164' }]
      }
      // {
      //   type: 'table',
      //   screenTitle: '总装车间看板',
      //   title: ['员工报工统计表'],
      //   columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
      //   columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
      //   tableColumns: statisticsCardList[2].children[0].tableColumns,
      //   dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '221', stationIds: '142^141^140^139^138^137^136^135^134' }]
      // },
      // {
      //   type: 'charts',
      //   screenTitle: '总装车间看板',
      //   chartType: 'bar',
      //   title: ['工序产量统计图'],
      //   currentData: procedureWork,
      //   dataUrlAndCondition: [procedureWork.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], procedureIds: '221' }]
      // }
    ],
    [
      {
        type: 'tableTable',
        screenTitle: '发货区看板',
        title: ['月度订单达成率', '产线产量统计表'],
        columns: ['序号', '月份', '当月产能目标', '当月完成订单数量', '当月准时完成订单数量', '订单达成率', '订单准交率'],
        columnsKey: ['index', 'month', 'total', 'finishedTotal', 'finishedOnTimeTotal', 'slot:finishedRatio', 'slot:finishedOnTimeRatio'],
        tableColumns: statisticsCardList[0].children[1].tableColumns,
        currentData: statisticsCardList[0].children[2],
        chartsUrlAndCondition: [statisticsCardList[0].children[2].dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '164' }],
        dataUrlAndCondition: [statisticsCardList[0].children[2].dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '164' }],
        columns2: ['序号', '产线', '当日产量', '当周产量', '当月产量'],
        columnsKey2: ['index', 'slot:getDeepName:info.name', 'dayOutputTotal', 'weekOutputTotal', 'monthOutputTotal'],
        tableColumns2: statisticsCardList[0].children[5].tableColumns,
        currentData2: statisticsCardList[0].children[5],
        chartsUrlAndCondition2: [statisticsCardList[0].children[5].dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1] }],
        dataUrlAndCondition2: [statisticsCardList[0].children[5].dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1] }, (data) => {
          const sort = [169, 161, 166, 171, 159, 164]
          return data.sort((a, b) => {
            const newA = sort.findIndex(item => item === a.info.id)
            const newB = sort.findIndex(item => item === b.info.id)
            return (newA === -1 ? 10 : newA) - (newB === -1 ? 10 : newB)
          })
        }]
      }
    ],
    [
      {
        type: 'minix',
        screenTitle: '绕线车间看板',
        title: ['工序报工统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getProcedureReportQuantityTotal', { procedureIds: '217' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '166' }, (data) => data.filter(item => !item.currentProcedure || JSON.parse(item.currentProcedure)[0].procedureId !== 218)]
      }
      // {
      //   type: 'table',
      //   screenTitle: '绕线车间看板',
      //   title: ['员工报工统计表'],
      //   columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
      //   columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
      //   tableColumns: statisticsCardList[2].children[0].tableColumns,
      //   dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '217', stationIds: '109^108^107^106^105^104^103^102^101^100^99^98^97^96^95^94^93^92^91^90' }]
      // },
      // {
      //   type: 'charts',
      //   screenTitle: '绕线车间看板',
      //   chartType: 'bar',
      //   title: ['工序产量统计图'],
      //   currentData: procedureWork,
      //   dataUrlAndCondition: [procedureWork.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], procedureIds: '217' }]
      // }
    ]
  ],
  kiki: [
    [
      {
        type: 'minix',
        screenTitle: '生产车间看板',
        title: ['产线产量统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '138' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '138' }]
      },
      {
        type: 'charts',
        screenTitle: '生产车间看板',
        chartType: 'bar',
        title: ['产线产量统计图'],
        currentData: current1,
        dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '138' }]
      }
      // {
      //   type: 'table',
      //   screenTitle: '生产车间看板',
      //   title: ['员工报工统计表'],
      //   columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
      //   columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
      //   tableColumns: statisticsCardList[2].children[0].tableColumns,
      //   dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '217' }]
      // }
    ],
    [
      {
        type: 'minix',
        screenTitle: '注塑车间看板',
        title: ['产线产量统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '147' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '147' }]
      },
      {
        type: 'charts',
        screenTitle: '注塑车间看板',
        chartType: 'bar',
        title: ['产线产量统计图'],
        currentData: current1,
        dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '147' }]
      },
      {
        type: 'table',
        screenTitle: '注塑车间看板',
        title: ['员工报工统计表'],
        columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
        columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
        tableColumns: statisticsCardList[2].children[0].tableColumns,
        dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '217' }]
      }
    ],
    [
      {
        type: 'minix',
        screenTitle: '网袋车间看板',
        title: ['产线产量统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '146' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '146' }]
      },
      {
        type: 'charts',
        screenTitle: '网袋车间看板',
        chartType: 'bar',
        title: ['产线产量统计图'],
        currentData: current1,
        dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '146' }]
      },
      {
        type: 'table',
        screenTitle: '网袋车间看板',
        title: ['员工报工统计表'],
        columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
        columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
        tableColumns: statisticsCardList[2].children[0].tableColumns,
        dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '218^220' }]
      }
    ],
    [
      {
        type: 'minix',
        screenTitle: '圆管车间看板',
        title: ['产线产量统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '145' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '145' }]
      },
      {
        type: 'charts',
        screenTitle: '圆管车间看板',
        chartType: 'bar',
        title: ['产线产量统计图'],
        currentData: current1,
        dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '145' }]
      },
      {
        type: 'table',
        screenTitle: '圆管车间看板',
        title: ['员工报工统计表'],
        columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
        columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
        tableColumns: statisticsCardList[2].children[0].tableColumns,
        dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '216' }]
      }
    ],
    [
      {
        type: 'minix',
        screenTitle: 'PG车间看板',
        title: ['产线产量统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '144' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '144' }]
      },
      {
        type: 'charts',
        screenTitle: 'PG车间看板',
        chartType: 'bar',
        title: ['产线产量统计图'],
        currentData: current1,
        dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '144' }]
      },
      {
        type: 'table',
        screenTitle: 'PG车间看板',
        title: ['员工报工统计表'],
        columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
        columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
        tableColumns: statisticsCardList[2].children[0].tableColumns,
        dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '219' }]
      }
    ],
    [
      {
        type: 'minix',
        screenTitle: '大车间看板',
        title: ['产线产量统计表', '工单进度统计表'],
        columns: ['序号', '生产工单号', '关联生产订单号', '物料编码', '物料名称', '物料规格', '工单数量', '完工数量', '工单达成率', '当前所处工序', '计划结束时间', '实际开始时间', '交期', '工单状态'],
        columnWidth: [60, 170, 170, 150, 160, 170],
        columnsKey: ['index', 'produceWorkOrderNumber', 'produceOrderNumber', 'materialsCode', 'materialsName', 'materialsSpecifications', 'quantity', 'reportWorkQuantity', 'slot:orderOverPercent', 'slot:currentProcedure', 'slot:planEndTime', 'slot:actualStartTime', 'deliveryTime', 'slot:status'],
        tableColumns: statisticsCardList[0].children[0].tableColumns,
        singleUrlAndCondition: ['statistics/private/statistics/getAreaOutputTotal', { areaIds: '143' }],
        dataUrlAndCondition: [statisticsCardList[0].children[0].dataUrl, { statusList: '0^1', areaIds: '143' }]
      },
      {
        type: 'charts',
        screenTitle: '大车间看板',
        chartType: 'bar',
        title: ['产线产量统计图'],
        currentData: current1,
        dataUrlAndCondition: [current1.dataUrl, { beginTime: getDateRange(1)[0], endTime: getDateRange(1)[1], areaIds: '143' }]
      },
      {
        type: 'table',
        screenTitle: '大车间看板',
        title: ['员工报工统计表'],
        columns: ['序号', '员工工号', '员工姓名', '当日报工数量', '当周报工数量', '当月报工数量'],
        columnsKey: ['index', 'slot:getDeepName:info.userName', 'slot:getDeepName:info.name', 'dayReportQuantity', 'weekReportQuantity', 'monthReportQuantity'],
        tableColumns: statisticsCardList[2].children[0].tableColumns,
        dataUrlAndCondition: [statisticsCardList[2].children[0].dataUrl, { procedureIds: '215' }]
      }
    ]
  ]
}
