
// 紧急程度
export const emergencyDegreeOptions = [
  { name: '紧急', id: 0 },
  { name: '一般', id: 1 },
  { name: '低', id: 2 }
]
export const emergencyDegreeMap = emergencyDegreeOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
// 处理状态
// export const handleStatusOptions = [
//   { name: '待判定', id: 0 },
//   { name: '处理中', id: 1 },
//   { name: '待验收', id: 2 },
//   { name: '已关闭', id: 3 }
// ]
export const handleStatusOptions = [
  { name: '待开始', id: 0 },
  { name: '待判定', id: 1 },
  { name: '待处理', id: 2 },
  { name: '处理中', id: 3 },
  { name: '待验收', id: 4 },
  { name: '验收中', id: 5 },
  { name: '已关闭', id: 6 }
]
export const handleStatusMap = handleStatusOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
// 判定结果
export const judgeResultOptions = [
  { name: '无需处理，直接关闭', id: 1 },
  { name: '需要处理', id: 0 }
]
export const judgeResultMap = judgeResultOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})

// 最终结果
export const finalResultOptions = [
  { name: '已解决', id: 0 },
  { name: '长期跟踪', id: 1 },
  { name: '其他', id: 2 }
]
export const finalResultMap = finalResultOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
// 验收结果
export const checkResultOptions = [
  { name: '驳回，继续处理', id: 0 },
  { name: '通过已关闭', id: 1 }
]
export const checkResultMap = checkResultOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
// 异常呼叫列表
export const searchFormList = [
  {
    colSpan: 6,
    key: 'emergencyDegree',
    tagName: 'el-select',
    props: {
      placeholder: '紧急程度',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: emergencyDegreeOptions
      }
    ]
  },
  {
    colSpan: 6,
    key: 'status',
    tagName: 'el-select',
    props: {
      placeholder: '状态',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: handleStatusOptions
      }
    ]
  },
  {
    colSpan: 6,
    key: 'abnormalTypeId',
    tagName: 'el-select',
    props: {
      placeholder: '异常类型',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'judgeResult',
    tagName: 'el-select',
    props: {
      placeholder: '判定结果',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: judgeResultOptions
      }
    ]
  },
  {
    colSpan: 6,
    key: 'finalResult',
    tagName: 'el-select',
    props: {
      placeholder: '最终结果',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: finalResultOptions
      }
    ]
  },
  {
    colSpan: 6,
    key: 'initiatorId',
    tagName: 'el-select',
    props: {
      placeholder: '提出人',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'initiatorOrganizationId',
    tagName: 'el-select',
    props: {
      placeholder: '责任方',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 12,
    key: 'occurrenceTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '发生时间起',
      endPlaceholder: '发生时间迄',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  }
]
export const searchFormMap = searchFormList.reduce((pre, cur) => ({ ...pre, [cur.key]: cur?.children }), {})
// console.log('searchFormMap==', searchFormMap)
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'abnormalOrderCode',
    label: '异常单号',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName:'abnormalOrderCode'
  },
  {
    prop: 'businessUnitName',
    label: '业务部门',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'marjorTye',
    label: '专业',
    hiddenSearch: true,
    sortable: false,
    slotName:'majorType',
    width: 120
  },
  {
    prop: 'materialName',
    label: '机台名称',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'emergencyDegree',
    slotName: 'emergencyDegree',
    label: '紧急程度',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'currentProcess',
    label: '当前节点',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'currentProcessUser',
    slotName: 'currentProcessUser',
    label: '当前节点责任人',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'abnormalTypeName',
    label: '异常类型',
    sortable: false,
    hiddenSearch: true,
    slotName: '',
    width: 120
  },
  {
    prop: 'abnormalRemark',
    label: '异常现象',
    sortable: false,
    slotName: '',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'judgeResult',
    label: '判定结果',
    sortable: false,
    hiddenSearch: true,
    slotName: 'judgeResult',
    width: 120
  },
  {
    prop: 'dutyPowerName',
    label: '责任方',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'dutySupplierName',
    label: '归属供应商',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'tempPolicy',
    label: '临时对策',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'reason',
    label: '原因分析',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'finalResult',
    slotName: 'finalResult',
    label: '最终结果',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'occurrenceTime',
    label: '发生时间',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'initiatorUser',
    label: '提出人',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    label: '发起部门',
    prop: 'initiatorDepart',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'createUser',
    label: '创建人',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'judgeExpendTime',
    label: '判定耗时/h',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'handleExpendTime',
    label: '处理耗时/h',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'checkExpendTime',
    label: '验收耗时/h',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'totalExpendTime',
    label: '总耗时/h',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 100
  }
]

// 详情基本信息字段
export const detailBaseFields = [
  {
    key: 'abnormalOrderCode',
    name: '异常单号'
  },
  {
    key: 'status',
    name: '处理状态'
  },
  {
    key: 'emergencyDegree',
    name: '紧急程度'
  },
  {
    key: 'abnormalTypeName',
    name: '异常类型'
  },
  {
    key: 'finalResult',
    name: '最终结果'
  }]
export const detailColumn_1 = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    width: 60
  },
  {
    prop: 'saleOrderNumber',
    label: '生产订单',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'producePlanNumber',
    label: '生产工单',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'workOrderCount',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'unitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  }

]
export const detailColumn_2 = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    width: 60
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'unitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  }
]
export const detailColumn_3 = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    width: 60
  },
  {
    prop: 'projectNumber',
    label: '项目单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'projectName',
    label: '项目名称',
    hiddenSearch: true,
    sortable: false,
    slotName:"projectName"
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'businessUnitName',
    label: '业务部门',
    hiddenSearch: true,
    sortable: false
  }

]

