<template>
  <div class="equipment-monitoring">
    <div class="line-wrapper card-wrapper">
      <div class="top">
        <div class="title">设备参数趋势</div>
        <div class="right">
          <el-date-picker v-model="date" :clearable="false" @change="getDeviceParametersRecordTrend" />
          <img src="@/assets/table_filter_images/设置@2x.png" alt="" @click="setParams">
        </div>
      </div>
      <div v-if="chartData.rows.length>0" class="body">
        <ve-line
          ref="line"
          :height="chartHeight + 'px'"
          :data="chartData"
          :settings="lineChartSettings"
          :extend="lineChartExtend"
        />
      </div>
      <MBlank v-else title="如需配置设备数据对接请拨打热线：021- 3412 7798 预约专业顾问上门对接" :style="{height: viewHeight + 'px'}" />
    </div>
    <div class="equipment-params card-wrapper">
      <div class="top">
        <div class="title">设备参数</div>
        <div class="right">
          <img src="@/assets/table_filter_images/设置@2x.png" alt="" @click="paramsSetting()">
        </div>
      </div>
      <div class="body" :style="{height: viewHeight + 'px', overflowY: 'auto' }">
        <div  v-for="item in equipmentParamsValues" :key="item.id" class="li" @click="selectItem(item)">
          <span>{{ item.deviceParametersName }}</span>
          <span>{{ item.value }} / {{ item.unit }}</span>
        </div>
      </div>
    </div>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>
    <MDialog v-model="paramsSetVisible" title="选择参数" cancel-button-text="重置" :custom-cancel="true" @onOk="reloadParamsRecord" @onCancel="resetParamsRecord">
      <div style="display: flex;align-items: center">
        <span style="white-space: nowrap">参数名称：</span>
        <el-select v-model="value.paramsId" multiple style="width: 100%" placeholder="请选择参数名称" filterable>
          <el-option
            v-for="item in equipmentParamsList"
            :key="item.id"
            :label="item.deviceParametersName"
            :value="item.id"
          />
        </el-select>
      </div>
      <div style="padding-top: 20px">
        <span>参数区间：</span>
        <el-input-number v-model="value.min" style="width: 200px" controls-position="right" />
        <span> —— </span>
        <el-input-number v-model="value.max" style="width: 200px" controls-position="right" />
      </div>
    </MDialog>
    <MDialog v-model="paramsVisible" title="参数设置" :show-footer="false">
      <FunctionHeader
        :tags="functionHeader.tags"
        :hidden-search="true"
        :columns-setting="false"
      />
      <MTable
        ref="mTable"
        :columns="columns"
        :height="viewHeight"
        :set-data-method="getTableData"
        :columns-setting="false"
        :show-page="false"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="action" slot-scope="{ row }">
          <el-button type="text" :disabled="row.isFixed == 1" @click="modifyOrCopy(row, 'modify')">修改</el-button>
          <el-divider direction="vertical" />
          <el-button type="text" :disabled="row.isFixed == 1" @click="del(row)">删除</el-button>
        </div>
      </MTable>
    </MDialog>
  </div>
</template>

<script>
import { colorList } from '@/views/statistics-board/board-detail/components/base-setting'
import dayjs from 'dayjs'
import columns from './columns'
import formList from './form-list'
import 'echarts/lib/component/dataZoom'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/equipment/equipment-params'
import equipmentParams from '@/api/equipment/params'
import FunctionHeader from '@/components/FunctionHeader/index'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { Stomp } from '@/utils/plug/stomp'

export default {
  name: 'EquipmentMonitoring',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  props: {
    equipData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      api,
      date: dayjs().format('YYYY-MM-DD'),
      params: {
        radio: 'EquipmentParams'
      },
      currentType: 'add',
      columns,
      formList,
      tableData: [],
      formData: {},
      paramsVisible: false,
      visible: false,
      functionHeader: {
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addParams
            }
          }
        ]
      },
      chartData: {
        columns: ['时间'],
        rows: []
      },
      lineChartSettings: {},
      lineChartExtend: {
        color: colorList,
        legend: { show: true },
        // xAxis: { boundaryGap: false },
        // yAxis: {},
        // tooltip: { trigger: 'axis' },
        // textStyle: { color: '#fff' },
        // grid: { width: '94%', height: '80%', top: 40 },
        dataZoom: {
          show: true,
          realtime: true,
          type: 'slider',
          height: 22,
          start: 0,
          end: 60,
          filterMode: 'none',
          textStyle: {
            color: 'rgba(0, 0, 0, 0)'
          }
        },
        series: {
          type: 'line',
          smooth: false
        }
      },
      equipmentParamsValues: [],
      extraParams: [{
        id: getUUid(),
        fieldName: 'deviceId',
        fieldType: 'number',
        fieldValue: this.$route.params.id,
        operator: 'eq'
      }],
      dataPointIdList: [],
      value: {},
      equipmentParamsList: [],
      paramsSetVisible: false,
      currentParams: {},
      recordListData:[]
    }
  },
  computed: {
    chartHeight() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 100
    },
    viewHeight() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 120
    }
  },
  watch: {
    paramsSetVisible(val) {
      if (!val) {
        this.value = this._.cloneDeep(this.currentParams)
      }
    }
  },
  async mounted() {
    this.getEquipmentParamsList()
    this.getDataSourceList()
    this.getDeviceParametersRecord()
    this.getDeviceParametersRecordTrend()
    this.getAllTableData()
    // this.initWebSocket()
  },
  destroyed() {
  },
  methods: {
    initWebSocket() {
      const url = process.env.VUE_APP_MSG_API.split('http://')[1]
      const wsuri = `ws://${url}ws/${localStorage.getItem('memberCode')}/0`
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    selectItem(item){
      console.log(item.deviceParametersName)
      this.getVchatData(item.deviceParametersName)
    },
    initWebSocket() {
      const url = process.env.VUE_APP_MSG_API.split('http://')[1]
      const wsuri = `ws://${url}ws/${localStorage.getItem('memberCode')}/0`
      this.websock = new WebSocket(wsuri)
      // this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen() {
      console.log('设备websocket连接成功')
      // this.websock.subscribe('/topic/myTopic1', (data) => {
      //   const msg = data.body
      //   this.websocketonmessage(msg)
      // })
    },
    websocketclose(e) {
      console.log('断开连接', e)
    },
    websocketonerror() {
      this.initWebSocket()
    },
    // websocketonmessage(e) {
    //   const data = e.data.split(':')
    //   if (this.equipData === data[0]) {
    //     this.equipmentParamsValues.forEach(v => {
    //       if (v.deviceParametersName === data[1]) {
    //         this.$set(v, 'value', data[2])
    //       }
    //     })
    //   }
    // },
    async getAllTableData() {
      const res = await this.api[`get${this.params.radio}List`]({
        searchVal: Encrypt(JSON.stringify(this.extraParams))
      })
      if (res) {
        this.equipmentParamsList = res.filter(item => item.type === 0 || item.type === 1)
      }
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const params = this.getTableDataParams || {}
      if (this.searchKeyword) {
        params.searchKeyword = this.functionHeader.searchVal
      } else if (this.functionHeader.searchVal) {
        search.push({
          id: getUUid(),
          fieldName: this.searchFieldName || 'name',
          fieldType: 'string',
          fieldValue: this.functionHeader.searchVal,
          operator: 'like'
        })
      }
      const extraData = this.extraParams || []
      const res = await this.api[`get${this.params.radio}List`]({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        ...params,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData, ...extraData]))
      })
      if (res) {
        callback({
          total: res.length,
          content: res
        })
      }
      callback()
    },
    async getEquipmentParamsList() {
      const res = await equipmentParams.getCallParamsList()
      if (res) {
        this.formList[0].children[0].options = res
      }
    },
    async getDeviceParametersRecordTrend() {
      const res = await api.getDeviceParametersRecordTrend({
        deviceId: this.$route.params.id,
        dataPointIdList: this.dataPointIdList,
        maxValue: this.value.max,
        minValue: this.value.min,
        startTime: dayjs(this.date).format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs(this.date).format('YYYY-MM-DD 23:59:59')
      })
      if (res) {
        this.recordListData = res
        this.getVchatData()
        // const points = res.reduce((a, b) => a.concat(b.recordList), [])
        // points.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
        // this.chartData.rows = points.map(item => {
        //   const obj = { '时间': item.time.slice(10, 20) }
        //   this.chartData.columns.forEach(key => {
        //     const current = points.find(b => b.parametersName === key && item.time === b.time)
        //     if (key !== '时间') {
        //       obj[key] = current ? +current.value : null
        //     }
        //   })
        //   return obj
        // })
        console.log(this.chartData)
      }
    },
    getVchatData(name){
      this.chartData.columns = ['时间', name||this.recordListData[0].parametersName]
        console.log(this.chartData)
        const filteredList = this.recordListData.filter(item=>item.parametersName === this.chartData.columns[1]) .map(item => item.recordList)[0]
        console.log(filteredList)
        this.chartData.rows =filteredList.map(item=>{
          const obj = { '时间': item.time.slice(10, 20)}
          obj[name||this.recordListData[0].parametersName] = item.value
          return obj
        })
      console.log(this.chartData.rows)

    },
    async getDeviceParametersRecord() {
      const res = await api.getEquipmentParamsList({
        searchVal: Encrypt(JSON.stringify([
          {
            id: getUUid(),
            fieldName: 'deviceId',
            fieldType: 'number',
            fieldValue: this.$route.params.id,
            operator: 'eq'
          }
        ]))
      })
      if (res) {
        this.equipmentParamsValues = res
      }
    },
    async getDataSourceList() {
      const res = await equipmentParams.getDataSourceAndDataPointList()
      if (res) {
        this.formList[1].props.options = res.map(item => ({
          ...item,
          children: item.dataPointList.map(a => ({ ...a, name: a.location }))
        }))
      }
    },
    addParams() {
      this.currentType = 'add'
      this.formData = {}
      this.visible = true
    },
    paramsSetting() {
      this.paramsVisible = true
    },
    setParams() {
      this.paramsSetVisible = true
    },
    reloadParamsRecord(callback) {
      callback()
      if (this.value.min > this.value.max) return this.$message.info('参数区间错误')
      if (this.value.paramsId) {
        this.dataPointIdList = this.equipmentParamsList.filter(item => this.value.paramsId.includes(item.id)).map(item => item.dataPointId)
      }
      this.currentParams = this._.cloneDeep(this.value)
      this.paramsSetVisible = false
      this.getDeviceParametersRecordTrend()
    },
    resetParamsRecord() {
      this.currentParams = {}
      this.value = {}
      this.dataPointIdList = []
      this.paramsSetVisible = false
      this.getDeviceParametersRecordTrend()
    },
    createSetData(raw) {
      return {
        deviceId: this.$route.params.id,
        deviceParametersId: raw.deviceParametersId,
        dataPointId: Array.isArray(raw.dataPointId) ? this._.last(raw.dataPointId) : raw.dataPointId
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
   width: 250px;

  }
}
</style>
