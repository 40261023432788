import { equipmentStatus } from '@/config/options.config'
import _ from 'lodash'
import { columns } from './columns'
import { repairColumns } from './detail/columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(columns)
const newRepairColumns = _.cloneDeep(repairColumns)

newRepairColumns.splice(2, 0, {
  prop: 'exceptFinishTime',
  label: '期望处理时间',
  form: { tagName: 'el-date-picker', props: { type: 'datetime' }}
})

export const formList = transformColumnsToForm(newColumns)

export const repairFormList = transformColumnsToForm(newRepairColumns)

export const searchFormList = [
  {
    colSpan: 5,
    key: 'stationId',
    tagName: 'el-select',
    props: {
      placeholder: '所属工位',
      multiple: true,
      clearable: true,
      collapseTags: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 5,
    key: 'status',
    tagName: 'el-select',
    props: {
      placeholder: '设备使用状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: equipmentStatus
      }
    ]
  }
]

