/** write by luomingzhi */
import { equipmentStatus, equipmentType, status } from '@/config/options.config'

export const columns = [
  {
    prop: 'code',
    label: '设备编码'
  },
  {
    prop: 'name',
    label: '设备名称'
  },
  {
    prop: 'equipmentClassType',
    label: '设备属性',
    form: { options: equipmentType }
  },
  {
    prop: 'status',
    label: '设备使用状态',
    form: { options: equipmentStatus }
  },
  {
    prop: 'buyTime',
    label: '采购日期'
  },
  {
    prop: 'equipmentClassName',
    label: '设备类'
  },
  {
    prop: 'isEnabled',
    label: '设备状态',
    form: { options: status }
  },
  {
    prop: 'stationName',
    label: '所属工位'
  },
  {
    prop: 'customerName',
    label: '客户'
  },
  {
    prop: 'remark',
    label: '备注'
  }
]

export const faultColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '处理状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'faultStatus'
  },
  {
    prop: 'faultNumber',
    label: '报修单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reporter',
    label: '报修人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reporterTel',
    label: '联系方式',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reportTime',
    label: '报修时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '故障发生时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'faultDesc',
    label: '故障描述',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'emergenceDegree',
    label: '紧急程度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'faultPicture',
    label: '故障图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'picture'
  },
  {
    prop: 'repairNumber',
    label: '维修单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairMan',
    label: '维修人',
    hiddenSearch: true,
    sortable: false
  }
]

export const repairColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '处理状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'faultStatus'
  },
  {
    prop: 'repairNumber',
    label: '维修单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairMan',
    label: '维修人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairBeginTime',
    label: '维修开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairEndTime',
    label: '维修结束时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'faultDesc',
    label: '故障描述',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'emergenceDegree',
    label: '紧急程度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'faultPicture',
    label: '故障图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'picture'
  },
  {
    prop: 'faultNumber',
    label: '报修单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reporter',
    label: '报修人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reportTime',
    label: '报修时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairContinueTime',
    label: '维修用时',
    hiddenSearch: true,
    sortable: false,
    slotName: 'continueTime'
  },
  {
    prop: 'faultReason',
    label: '故障原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairPicture',
    label: '维修图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'picture'
  },
  {
    prop: 'repairDesc',
    label: '维修描述',
    hiddenSearch: true,
    sortable: false
  }
]

export const inspectionColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'inspectionStatus'
  },
  {
    prop: 'cycleCheckTaskNumber',
    label: '巡检单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkPlanNumber',
    label: '计划单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'taskCheckType',
    label: '巡检类别',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checker',
    label: '巡检人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkPlanTime',
    label: '计划日期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkStartTime',
    label: '实际开始',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkEndTime',
    label: '实际完成',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'errorCount',
    label: '异常数',
    hiddenSearch: true,
    sortable: false,
    slotName: 'errorCount'
  },
  {
    prop: 'checkItemList',
    label: '巡检项',
    hiddenSearch: true,
    sortable: false,
    slotName: 'checkItem'
  }
]

export const pointColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'pointStatus'
  },
  {
    prop: 'spotCheckNumber',
    label: '点检单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'spotCheckType',
    label: '点检类别',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checker',
    label: '点检人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkBeginTime',
    label: '实际开始',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'checkEndTime',
    label: '实际完成',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'errorCount',
    label: '异常数',
    hiddenSearch: true,
    sortable: false,
    slotName: 'errorCount'
  },
  {
    prop: 'pointCheckItem',
    label: '点检项',
    hiddenSearch: true,
    sortable: false,
    slotName: 'checkItem'
  }
]

export const maintenanceColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'maintenanceStatus',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'maintenanceStatus'
  },
  {
    prop: 'maintainTaskNumber',
    label: '保养单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainPlanNumber',
    label: '计划单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainType',
    label: '保养类别',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainerName',
    label: '保养人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainPlanTime',
    label: '计划日期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainStartTime',
    label: '实际开始',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'maintainEndTime',
    label: '实际完成',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'unMaintainCount',
    label: '未保养数',
    hiddenSearch: true,
    sortable: false,
    slotName: 'unMaintainCount'
  },
  {
    prop: 'maintenanceCheckItem',
    label: '保养项',
    hiddenSearch: true,
    sortable: false,
    slotName: 'checkItem'
  },
  {
    prop: 'maintenanceCheckItem',
    label: '备件更换',
    hiddenSearch: true,
    sortable: false,
    slotName: 'spareList'
  }
]

export const auxiliaryMaintainColumns = [
  {
    prop: 'className',
    label: '售后设备类型',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'code',
    label: '售后设备编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'sn',
    label: '售后设备SN',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'workOrderCode',
    label: '关联工单编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'operatorName',
    label: '操作人',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'operationTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true,
  }
]

export const auxiliaryRecordColumns = [
  {
    prop: 'operationType',
    label: '操作类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'operationType'
  },
  {
    prop: 'operationTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'operatorName',
    label: '操作人',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'className',
    label: '售后设备类型',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'code',
    label: '售后设备编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'sn',
    label: '售后设备SN',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerName',
    label: '关联客户',
    sortable: false,
    hiddenSearch: true
  }
]

