var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrap-wrapss" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("SearchForm", {
          ref: "searchForm",
          attrs: { "form-list-extend": _vm.searchFormList },
          on: { search: _vm.searchTable }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("FunctionHeader", {
          attrs: {
            "search-title": "请输入设备名称/编号",
            tags: _vm.functionHeader.tags,
            "hidden-search": true
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.columns,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          scopedSlots: _vm._u(
            [
              {
                key: "cycleCheckTaskNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "inspectionBillsDetail",
                        query: { id: row.id, type: "view" }
                      },
                      text: row.cycleCheckTaskNumber
                    }
                  })
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { style: { color: _vm.colors[row.status] } },
                    [_vm._v(_vm._s(_vm._f("inspectionStatusName")(row.status)))]
                  )
                }
              },
              {
                key: "checkSpendTime",
                fn: function(ref) {
                  var row = ref.row
                  return row.checkSpendTime
                    ? _c("div", {}, [
                        _vm._v(_vm._s(Math.ceil(row.checkSpendTime / 60)))
                      ])
                    : _vm._e()
                }
              },
              {
                key: "pointCheckItem",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewCheckItem(row)
                        }
                      }
                    },
                    [_vm._v("查看")]
                  )
                }
              }
            ],
            null,
            true
          )
        }),
        _c(
          "MDialog",
          {
            attrs: { width: "400px", title: "跳过原因" },
            on: { onOk: _vm.submitPassInspectionBills },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          },
          [
            _c("MFormBuilder", {
              ref: "formBuild",
              attrs: { "form-list": _vm.reasonFormList }
            })
          ],
          1
        ),
        _c("TableDialog", {
          attrs: {
            appends: true,
            title: "查看巡检项",
            show: _vm.checkItemVisible,
            columns: _vm.insColumns.PointCheckItemView(),
            "table-data": _vm.checkItemList
          },
          on: {
            "update:show": function($event) {
              _vm.checkItemVisible = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }