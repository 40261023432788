import api from '@/api/equipment/project-setting'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'

export default {
  data() {
    return {
      checkItemList: [],
      checkItemVisible: false,
      searchFieldName: 'name',
      selectCheckItem: [],
      selectCheckCheckItem: [],
      selectCheckItemVisible: false,
      currentRowIndex: 0,
      uploadPicVisible: false,
      uploadFileList: [],
      dialogAddVisible: false
    }
  },
  methods: {
    async getCheckItemTableData(condition, callback) {
      const { page, order, search } = condition
      if (this.functionHeaderS.searchVal) {
        search.push({
          id: getUUid(),
          fieldName: 'name',
          fieldType: 'string',
          fieldValue: this.functionHeaderS.searchVal,
          operator: 'like'
        })
      }
      const res = await this.api[`get${this.settingGeyKey}ListPage`]({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    viewCheckItem(row) {
      if (!row.checkItemList || row.checkItemList.length === 0) return this.$message.info('暂无检查项')
      this.checkItemList = row.checkItemList
      this.checkItemVisible = true
    },
    pushCheckItem() {
      this.selectCheckItemVisible = true
      this.$nextTick(() => {
        this.selectCheckCheckItem.forEach(item => {
          this.$refs.pointCheckTableSelect.tableRefs().toggleRowSelection(item, true)
        })
      })
    },
    selectionChange(data) {
      this.selectCheckItem = data
    },
    delRow(row, index) {
      this.selectCheckCheckItem.splice(index, 1)
    },
    uploadPic(row, index) {
      this.currentRowIndex = index
      this.uploadFileList = row.sketchMap ? JSON.parse(row.sketchMap) : []
      this.uploadPicVisible = true
    },
    submitUploadPic(callback) {
      const fileList = this.$refs.uploadPic.fileList
      if (!fileList.length) {
        callback(false)
        return this.$message.info('请至少上传一张操作图')
      }
      this.selectCheckCheckItem[this.currentRowIndex].sketchMap = JSON.stringify(fileList.map(item => ({
        id: item.id,
        name: item.name,
        url: item.url
      })))
      this.uploadPicVisible = false
      callback(false)
    },
    submitSelectCheckItem(callback) {
      if (this.selectCheckItem.length === 0) {
        callback(false)
        return this.$message.info('请至少选择一条检查项')
      }
      const newData = []
      const ids = this.selectCheckCheckItem.map(item => item.id)
      this.selectCheckItem.forEach(item => {
        const index = ids.indexOf(item.id)
        if (index !== -1) {
          newData.push({
            ...item,
            ...this.selectCheckCheckItem[index]
          })
        } else {
          newData.push(item)
        }
      })
      this.selectCheckCheckItem = newData
      this.selectCheckItemVisible = false
      callback(false)
    },
    async changeNeedUpload(row) {
      const res = await api.modifyPointCheckItem({
        id: row.id,
        needUpload: row.needUpload ? 0 : 1
      })
      if (res) {
        this.$refs.mTable.setTableData()
      }
    },
    async deleteRow(row, callback) {
      if (this.params.radio === 'PointCheckItem') {
        await this.del(row, callback)
      } else {
        const res = await api[`modify${this.params.radio}`]({
          equipmentClassId: row.equipmentClassId,
          spotCheckType: row.spotCheckType,
          checkItemList: []
        })
        if (res) {
          this.$message.success('删除成功')
          callback()
          this.$refs.mTable.setTableData()
        }
      }
    },
    dialogAdd() {
      this.dialogAddVisible = true
    },
    async equipmentClassChange(val) {
      const res = await this.api[`get${this.params.radio}List`]({
        equipmentClassIds: this._.last(val)
      })
      if (res) {
        this.selectCheckCheckItem = res[0] ? res[0].checkItemList || res[0].maintenanceItemList || [] : []
      }
    },
    submitDialogAddForm(callback) {
      const { form } = this.$refs.formBuildAdd
      this.$refs.formBuildAdd.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api[`add${this.dialogAddKey}`]({
            name: form.name,
            standard: form.standard,
            needUpload: form.needUpload || 0
          })
          if (res) {
            this.$message.success('新增成功')
            this.dialogAddVisible = false
            this.$refs.pointCheckTableSelect.setTableData(true)
          }
          callback(true)
        } else {
          callback()
        }
      })
    }
  }
}
