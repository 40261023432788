<template>
  <div>
    <el-upload ref="upload" :file-list="fileList" list-type="picture-card" v-bind="uploadProps">
      <i slot="default" class="el-icon-plus" />
      <div slot="file" slot-scope="{file}">
        <el-image
          ref="imageView"
          class="el-upload-list__item-thumbnail"
          :src="file.url"
          :preview-src-list="[file.url]"
        />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in" />
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
          >
            <i class="el-icon-download" />
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete" />
          </span>
        </span>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import saveAs from 'file-saver'

export default {
  name: 'UploadPic',
  props: {
    uploadFileList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      uploadProps: {
        limit: 10,
        accept: '.png,.jpeg,.jpg',
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        beforeUpload: this.beforeUpload
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: []
    }
  },
  mounted() {
    this.fileList = this._.cloneDeep(this.uploadFileList)
  },
  methods: {
    // 上传图纸/附件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.fileList.push({
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    handleRemove(file) {
      const fileIndex = this.fileList.findIndex(item => item.uid === file.uid)
      this.fileList.splice(fileIndex, 1)
    },
    handlePictureCardPreview() {
      this.$refs.imageView.clickHandler()
    },
    handleDownload(file) {
      saveAs(file.url, file.name)
    }
  }
}
</script>

<style scoped>

</style>
