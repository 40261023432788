var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equipment-monitoring" },
    [
      _c("div", { staticClass: "detail-cards" }, [
        _vm.isTitle
          ? _c("div", { staticClass: "card-tit" }, [_vm._v("备件更换")])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "card-bots" },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.addInfo }
                  },
                  [_vm._v("添加")]
                )
              ],
              1
            ),
            _c("MTable", {
              ref: "plansTable",
              attrs: {
                "show-page": false,
                height: _vm.isTitle ? 300 : _vm.heights,
                columns: _vm.techColumns,
                data: _vm.materialsMappingList,
                "only-key": "uuid"
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "describeInfo",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm.type === "new"
                        ? _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入用途描述" },
                                model: {
                                  value: row.describeInfo,
                                  callback: function($$v) {
                                    _vm.$set(row, "describeInfo", $$v)
                                  },
                                  expression: "row.describeInfo"
                                }
                              })
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.describeInfo))])
                    ])
                  }
                },
                {
                  key: "quantity",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm.type !== "new"
                        ? _c("div", [_vm._v(_vm._s(row.quantity))])
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "flex-start"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { color: "#f00", width: "10px" }
                                },
                                [_vm._v("*")]
                              ),
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "controls-position": "right",
                                  step: 1,
                                  min: 0
                                },
                                model: {
                                  value: row.quantity,
                                  callback: function($$v) {
                                    _vm.$set(row, "quantity", $$v)
                                  },
                                  expression: "row.quantity"
                                }
                              })
                            ],
                            1
                          )
                    ])
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c(
                      "div",
                      {},
                      [
                        _vm.type === "new"
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delInfos($index)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          : [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.modifyInfo(row)
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delInfo(row)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                      ],
                      2
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: {
          visible: _vm.showMaterials,
          "is-add": true,
          title: "添加物料",
          single: _vm.type !== "new"
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials,
          addMaterial: _vm.addMaterial
        }
      }),
      _c("edit-materials", {
        ref: "editMaterials",
        on: { onSuccess: _vm.onSuccess }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType], width: "500px" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: {
              "label-position": "right",
              "label-width": "100px",
              "form-data": _vm.formData,
              "form-list": _vm.techFormList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }