<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">技术参数</div>
      <div class="card-bots">
        <div style="margin-bottom: 10px;">
          <el-button v-if="type === 'new'" size="small" type="primary" @click="selectInfo">套用模板</el-button>
          <el-button size="small" type="primary" @click="addInfo">新增</el-button>
        </div>
        <MTable
          ref="plansTable"
          :show-page="false"
          :height="tabHeight"
          :columns="techColumns"
          :data="facilityMappingParametersList"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="name" slot-scope="{ row }">
            <div v-if="type === 'new'" style="display: flex;align-items: center;justify-content: flex-start;">
              <span style="color: #f00;width: 10px;">*</span>
              <el-input v-model="row.name" />
            </div>
            <span v-else>{{ row.name }}</span>
          </div>
          <div slot="unit" slot-scope="{ row }">
            <div v-if="type === 'new'">
              <el-input v-model="row.unit" />
            </div>
            <span v-else>{{ row.unit }}</span>
          </div>
          <div slot="standardValues" slot-scope="{ row }">
            <template v-if="type === 'new'">
              <div style="display: flex;justify-content: center;align-items: center">
                <el-input-number v-model="row.standardValuesMin" controls-position="right" :step="1" style="width: 40%" />
                <span style="margin: 0 10px"> ~ </span>
                <el-input-number v-model="row.standardValuesMax" controls-position="right" :step="1" style="width: 40%" />
              </div>
            </template>
            <span v-else>
              {{ row.standardValuesMin && row.standardValuesMax ?
                `${row.standardValuesMin}~${row.standardValuesMax}` :
                row.standardValuesMin ? `≥${row.standardValuesMin}` :
                row.standardValuesMax ? `≤${row.standardValuesMax}` : '' }}
            </span>
          </div>
          <div slot="action" slot-scope="{ row, $index }">
            <template v-if="type === 'new'">
              <el-button type="text" @click="copyInfo(row)">复制</el-button>
              <el-button type="text" @click="delInfos($index)">删除</el-button>
            </template>
            <template v-else>
              <el-button type="text" @click="modifyInfo(row)">修改</el-button>
              <el-button type="text" @click="delInfo(row)">删除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <MDialog v-model="visible" :title="messages[currentType]" width="500px" @onOk="submitForms">
      <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="techFormList">
        <div slot="standardValues">
          <div style="display: flex;justify-content: flex-start;align-items: center">
            <el-input-number v-model="standardValuesMin" controls-position="right" :step="1" style="width: 40%" />
            <span style="margin: 0 10px"> ~ </span>
            <el-input-number v-model="standardValuesMax" controls-position="right" :step="1" style="width: 40%" />
          </div>
        </div>
      </MFormBuilder>
    </MDialog>
    <SelectTechnicalParameter
      ref="selectTechnicalParameter"
      :visible.sync="showTechnicalParameter"
      @submitForm="selectTechnicalParameters"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { techColumns } from './columns'
import { techFormList } from './form-list'
import api from '@/api/xiewei/facility/technical-params'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import SelectTechnicalParameter from '@/views/facility/account/components/SelectTechnicalParameter/SelectTechnicalParameter'

export default {
  name: 'TechnicalParameter',
  components: { SelectTechnicalParameter },
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => ([])
    },
    facilityId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      api,
      showTechnicalParameter: false,
      standardValuesMin: undefined,
      standardValuesMax: undefined,
      facilityMappingParametersList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      techColumns,
      techFormList,
      tableData: [],
      formData: {},
      paramsVisible: false,
      visible: false,
      messages: {
        add: '新增参数',
        modify: '修改参数'
      }
    }
  },
  watch: {
    list(val) {
      this.facilityMappingParametersList = val || []
    }
  },
  computed: {
    tabHeight() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 184
    }
  },
  mounted() {
    this.facilityMappingParametersList = this.list
  },
  methods: {
    selectInfo() {
      this.showTechnicalParameter = true
    },
    async selectTechnicalParameters(val) {
      this.showTechnicalParameter = false
      const res = await api.getFacilityTechnicalParameterTemplateDetail({ id: val[0].id })
      if (res) {
        this.facilityMappingParametersList =
          res.parametersList ? res.parametersList.map(item => {
            return {
              ...item,
              standardValuesMax: item.standardValuesMax || item.standardValuesMax === 0 ? item.standardValuesMax : undefined,
              standardValuesMin: item.standardValuesMin || item.standardValuesMin === 0 ? item.standardValuesMin : undefined
            }
          }) : []
        this.$emit('facilityInfoParams', this.facilityMappingParametersList)
      }
      this.showTechnicalParameter = false
    },
    copyInfo(raw) {
      const data = {
        name: raw ? raw.name : '',
        unit: raw ? raw.unit : '',
        standardValuesMin: raw ? raw.standardValuesMin : undefined,
        standardValuesMax: raw ? raw.standardValuesMax : undefined
      }
      this.facilityMappingParametersList.push(data)
      this.$emit('facilityInfoParams', this.facilityMappingParametersList)
    },
    addInfo() {
      if (this.type === 'new') {
        this.copyInfo()
      } else {
        this.currentType = 'add'
        this.standardValuesMin = undefined
        this.standardValuesMax = undefined
        this.formData = {}
        this.visible = true
      }
    },
    modifyInfo(row) {
      this.currentType = 'modify'
      this.formData = this._.cloneDeep(row)
      this.standardValuesMin = row.standardValuesMin || row.standardValuesMin === 0 ? row.standardValuesMin : undefined
      this.standardValuesMax = row.standardValuesMax || row.standardValuesMax === 0 ? row.standardValuesMax : undefined
      this.visible = true
    },
    delInfos(index) {
      this.facilityMappingParametersList.splice(index, 1)
    },
    async delInfo(node) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delFacilityParameters({ id: node.id })
        if (res) {
          this.$message.success('删除成功')
          this.$emit('refrash')
        }
      })
    },
    submitForms(callback) {
      const that = this
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api[`${this.currentType}FacilityParameters`](
            this.currentType !== 'modify' ? Object.assign({}, {
              facilityId: this.facilityId
            }, this.createSetData(form)) : this.createResetData(form)
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            this.$emit('refrash')
            this.visible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      return {
        name: raw.name,
        unit: raw.unit,
        standardValuesMin: this.standardValuesMin || this.standardValuesMin === 0 ? this.standardValuesMin : '',
        standardValuesMax: this.standardValuesMax || this.standardValuesMax === 0 ? this.standardValuesMax : ''
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id,
        facilityId: raw.facilityId
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    .el-form-item__content {
      width: 300px;
    }
    .el-form-item {
      margin-bottom: 20px!important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
</style>
