<template>
  <div class="inventory-account-container">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data="searchFormData"
        @search="searchTable"
      >
        <div slot="sparePartM">
          <el-autocomplete
            v-model="spartType"
            popper-class="my-autocomplete"
            :fetch-suggestions="querySearchs"
            :value-key="'name'"
            clearable
            placeholder="请输入内容"
            class="input-with-select"
            @clear="handleSelects('partType', null)"
            @select="(val) => { handleSelects('partType', val && val.id) }"
          >
            <el-select slot="prepend" v-model="partType" placeholder="选择备件" class="input-select" @change="selectPart">
              <el-option label="备件名称" :value="0" />
              <el-option label="备件编码" :value="1" />
            </el-select>
          </el-autocomplete>
        </div>
        <div slot="account">
          <el-autocomplete
            v-model="inOrOutType"
            popper-class="my-autocomplete"
            :fetch-suggestions="querySearch"
            :value-key="'name'"
            clearable
            placeholder="请输入内容"
            class="input-with-select"
            @clear="handleSelect('type', null)"
            @select="(val) => { handleSelect('type', val && val.id) }"
          >
            <el-select slot="prepend" v-model="type" clearable placeholder="选择类型" class="input-select" @change="(val) => { handleSelect('recordType', val) }">
              <el-option label="出库" :value="1" />
              <el-option label="入库" :value="0" />
            </el-select>
          </el-autocomplete>
        </div>
      </SearchForm>
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入出入库单号/源单据号"
        :tags="functionHeader.tags"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export="!selectionData.length ? true : false"
        :style="{marginLeft :selectionData.length ? 0 :'-10px'}"
        style="margin-left: -10px;"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="selectionData.length">
        <el-button v-if="permission('BatchExport')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="recordType" slot-scope="{ row }">{{ row.recordType ? '出库' : '入库' }}</div>
        <div slot="type" slot-scope="{ row }">{{ getType(row) }}</div>
        <div slot="warehouseArea" slot-scope="{ row }">{{ row.warehouseAreaName }} - {{ row.warehouseLocationName }}</div>
      </MTable>
    </div>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import columns from './columns'
import { searchFormList } from './form-list'
import api from '@/api/equipment/part-nums'
import PartApi from '@/api/equipment/spare-part'
import SelectMultipleMaterials from '@/components/SelectMultipleMaterials/SelectMultipleMaterials'
import userApi from '@/api/sets/user/user'
import warehouseApi from '@/api/information/warehouse/model'
import PartClassApi from '@/api/equipment/part-class'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'

export default {
  name: 'PartBills',
  components: { SelectMultipleMaterials, SearchForm, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'PartBills'
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        exportName: 'exportSparePartPipeline',
        exportParams: {},
        searchVal: '',
        tags: []
      },
      inOrOutTypeList: [[
        {
          id: 0,
          name: '采购入库'
        }],
      [{
        id: 0,
        name: '领用出库'
      }]
      ],
      inOrOutType: '',
      type: null,
      partType: null,
      spartType: '',
      selectionData: [],
      searchKeyword: true,
      extraParams: [],
      typeList: [],
      getTableDataParams: {
        sparePartClassIds: ''
      },
      exportSelectionIds: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 60
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.getUserList()
    this.getWarehouseList()
    this.getSparePartClassList()
    this.getSparePartList()
  },
  methods: {
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    selectPart() {
      this.spartType = ''
      this.extraParams = this.extraParams.filter(item => item.fieldName !== 'sparePartId')
    },
    async getSparePartList() {
      const res = await PartApi.getSparePartList()
      if (res) {
        const spartPartCode = res.map(item => ({
          id: item.id,
          name: item.code
        }))
        const spartPartName = res
        this.typeList = [spartPartName, spartPartCode]
      }
    },
    async getSparePartClassList() {
      const res = await PartClassApi.getSparePartClassList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.searchFormList[5].children[0].options = res
      }
    },
    async getUserList() {
      const res = await userApi.getUserList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.searchFormList[3].children[0].options = res
      }
    },
    async getWarehouseList() {
      const res = await warehouseApi.getRepositoryModelList({
        searchVal: Encrypt(JSON.stringify([...this.defaultSearch, {
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }]))
      })
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    getType(row) {
      const current = this.inOrOutTypeList[row.recordType].find(item => row.type === item.id)
      return current && current.name
    },
    selectionChange(data) {
      this.selectionData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    querySearch(queryString, cb) {
      const restaurants = this.inOrOutTypeList[this.type]
      const results = queryString ? restaurants.filter(item => item.name.indexOf(queryString) !== -1) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelect(key, val) {
      this.extraParams = this.extraParams.filter(item => item.fieldName !== key)
      if (val || val === 0) {
        this.extraParams.push({
          id: getUUid(),
          fieldName: key,
          fieldType: 'number',
          fieldValue: val,
          operator: 'eq'
        })
      }
      if (key === 'recordType') {
        this.inOrOutType = ''
        this.extraParams = this.extraParams.filter(item => item.fieldName !== 'type')
      }
      this.$refs.mTable.setTableData(true)
    },
    querySearchs(queryString, cb) {
      const restaurants = this.typeList[this.partType]
      const results = queryString ? restaurants.filter(item => item.name.indexOf(queryString) !== -1) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelects(key, val) {
      this.extraParams = this.extraParams.filter(item => item.fieldName !== 'sparePartId')
      if (val || val === 0) {
        this.extraParams.push({
          id: getUUid(),
          fieldName: 'sparePartId',
          fieldType: 'number',
          fieldValue: val,
          operator: 'eq'
        })
      }
      this.$refs.mTable.setTableData(true)
    },
    searchTable(data, type) {
      if (type !== 1) {
        if (data.length > 0) {
          const newData = data.filter(item => item.fieldName === 'sparePartId')
          this.getTableDataParams.sparePartClassIds = newData.length > 0
            ? newData.length === 1 ? newData[0].fieldValue : newData[0].fieldValue.split('^') : []
        } else {
          this.getTableDataParams.sparePartClassIds = []
        }
      }
      this.searchFormatData = data.filter(item => item.fieldName !== 'sparePartId')
      if (type === 1) {
        this.inOrOutType = null
        this.type = null
        this.partType = null
        this.spartType = ''
        this.extraParams = []
        this.getTableDataParams.sparePartClassIds = []
      }
      this.$refs.mTable.setTableData(true)
    }
    // batchExport() {
    //   const exportData = this._.cloneDeep(this.selectionData)
    //   exportData.forEach(item => {
    //     item.type = this.getType(item)
    //     item.recordType = item.recordType ? '出库' : '入库'
    //   })
    //   const params = {
    //     key: this.columns.filter(item => item.prop && item.prop !== 'index').map(item => item.prop),
    //     header: this.columns.filter(item => item.prop && item.prop !== 'index').map(item => item.label),
    //     merges: [],
    //     data: exportData,
    //     filename: '备件库存流水'
    //   }
    //   export_json_to_excel2(params)
    // }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.inventory-account-container {
  width: 100%;

  .bottom {
    padding: 10px;
  }
}
::v-deep .customer-content div {
  width: 100%;
}
.input-with-select {
  width: 100%;

  .input-select {
    width: 105px !important;
  }
}
::v-deep {
  .el-input-group {
    width: 100%;

    .el-input-group__prepend {
      width: 105px;
    }
  }
}
::v-deep{
  .e-divider{
  margin: 0px 0 0 0;
  }
}
</style>
