<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">关联设备</div>
      <div class="card-bots">
        <div style="margin-bottom: 10px">
          <el-button size="small" type="primary" @click="addInfo">添加</el-button>
        </div>
        <MTable
          ref="plansTable"
          :show-page="false"
          :height="isTitle ? 300 : heights"
          :columns="equipmentColumns"
          :data="facilityMappingList"
          :only-key="'uuid'"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="action" slot-scope="{ row, $index }">
            <template v-if="type === 'new'">
              <el-button type="text" @click="delInfos(row, $index)">删除</el-button>
            </template>
            <template v-else>
              <el-button type="text" @click="delInfo(row)">删除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <select-facility
      ref="selectFacility"
      :visible.sync="showFacility"
      :select-facilitys="selectFacilitys"
      :customer-id="customerIds"
      @submitForm="submitFacility"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { equipmentColumns } from './columns'
import { equipmentFormList } from './form-list'
import api from '@/api/xiewei/facility/maintenance-equipment'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import SelectFacility from '@/components/SelectFacility/SelectFacility.vue'

export default {
  name: 'RelationEquipment',
  components: { SelectFacility },
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => []
    },
    facilityMaintenancePlanId: {
      type: Number,
      default: undefined
    },
    selecteFacilityList: {
      type: String,
      default: ''
    },
    customerId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      api,
      facilityMappingList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      equipmentColumns,
      equipmentFormList,
      tableData: [],
      formData: {},
      showFacility: false,
      messages: {
        add: '添加',
        modify: '修改'
      },
      selectFacilitys: [],
      customerIds: undefined
    }
  },
  computed: {
    heights() {
      return this.$store.state.app.contentHeight - 256 - 250 - 50
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.facilityMappingList = val
      }
    },
    selecteFacilityList(val) {
      if (val) {
        this.selectFacilitys = JSON.parse(val)
      }
    },
    customerId(val) {
      this.customerIds = val
      this.selectFacilitys = []
    }
  },
  mounted() {
    this.facilityMappingList = this.list
    this.customerIds = this.customerId
  },
  methods: {
    async delInfo(node) {
      this.selectFacilitys = this.selectFacilitys.filter(item => item.id !== +node.facilityId)
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delPlanMaintenanceFacility({
          id: node.id,
          planId: this.facilityMaintenancePlanId,
          selecteFacility: this.selectFacilitys.length > 0 ? JSON.stringify(this.selectFacilitys) : ''
        })
        if (res) {
          this.$message.success('删除成功')
          this.$emit('refrash')
        }
      })
    },
    async submitFacility(val) {
      const arr = val.map(item => {
        return {
          ...item,
          facilityId: item.id,
          selectable: true
        }
      })
      this.selectFacilitys = [...arr]
      this.$emit('selectFacilitys', this.selectFacilitys)
      const data = val.map(item => {
        return {
          ...item,
          facilityId: item.id,
          uuid: getUUid()
        }
      })
      if (this.type === 'new') {
        this.facilityMappingList = [...data]
        this.$emit('facilityInfo', this.facilityMappingList)
      } else {
        const res = await this.api[`addPlanMaintenanceFacility`]({
          planId: this.facilityMaintenancePlanId,
          selecteFacility: this.selectFacilitys.length > 0 ? JSON.stringify(this.selectFacilitys) : '',
          facilityMaintenancePlanFacilityMappingList: data.map(item => {
            return {
              facilityId: item.facilityId,
              facilityCode: item.code,
              facilityName: item.name,
              sn: item.sn,
              model: item.model,
              equipmentClassName: item.equipmentClassName,
              customerName: item.customerName
            }
          })
        })
        if (res) {
          this.$message.success(this.getSuccessMessage())
          this.$emit('refrash')
        }
      }
      this.showFacility = false
    },
    addInfo() {
      if (this.customerIds) {
        this.showFacility = true
        return
      }
      return this.$message.error('请选择客户')
    },
    delInfos(row, index) {
      this.facilityMappingList.splice(index, 1)
      this.selectFacilitys = this.selectFacilitys.filter(item => item.id !== +row.facilityId)
      this.$emit('selectFacilitys', this.selectFacilitys)
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    // .el-form-item__content {
    //   width: 300px;
    // }
    .el-form-item {
      margin-bottom: 20px !important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
</style>
