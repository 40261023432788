import { columns, packageColumns } from './columns'
import { transformColumnsToForm } from '@/utils'

export const packageFormList = transformColumnsToForm(packageColumns)
export const formList = [
  {
    tagName: 'labelTitle',
    label: '基本信息',
    colSpan: 24
  },
  ...transformColumnsToForm(columns),
  {
    tagName: 'div',
    colSpan: 24,
    children: [{
      customSlots: ['unit']
    }]
  },
  {
    label: '库存预警',
    colSpan: 24,
    tagName: 'labelTitle'
  },
  {
    label: '库存上限',
    key: 'upperLimit',
    colSpan: 8,
    tagName: 'el-input-number',
    props: {
      controlsPosition: 'right'
    },
    children: []
  },
  {
    label: '库存下限',
    key: 'lowerLimit',
    colSpan: 8,
    tagName: 'el-input-number',
    props: {
      controlsPosition: 'right'
    },
    children: []
  },
  {
    label: '默认仓库',
    key: 'defaultRepositoryId',
    colSpan: 8,
    tagName: 'el-select',
    children: [{
      tagName: 'el-option',
      props: {
        label: 'name',
        value: 'id'
      },
      options: []
    }]
  },
  {
    tagName: 'labelTitle',
    label: '关联设备',
    colSpan: 24
  },
  {
    tagName: 'div',
    colSpan: 24,
    key: 'equipmentList',
    children: [{
      customSlots: ['package']
    }]
  }
]

