var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "big-screen-container",
    { ref: "container" },
    [
      _vm.loading
        ? _c("dv-loading")
        : [
            _c("div", { staticClass: "top-container" }, [
              _c("div", { staticClass: "title-wrapper" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.groupName) + "车间看板")
                ]),
                _c("img", {
                  attrs: {
                    src:
                      "https://oss.mubyte.cn/static/%E5%A4%B4%E9%83%A8%402x.png",
                    alt: ""
                  }
                })
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div"),
                _c("div", { staticClass: "time-container" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://oss.mubyte.cn/static/%E7%BC%96%E7%BB%84%402x.png",
                      alt: ""
                    }
                  }),
                  _c("div", { staticClass: "date-time" }, [
                    _c("div", { staticClass: "date" }, [
                      _vm._v(
                        _vm._s(_vm.currentDate) +
                          "（" +
                          _vm._s(_vm.currentWeek) +
                          "）"
                      )
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.currentTime))
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "content-container" }, [
              _c(
                "div",
                { staticClass: "left", staticStyle: { width: "30%" } },
                [
                  _c(
                    "div",
                    { staticClass: "screen-card-list" },
                    _vm._l(_vm.cardList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "screen-card-wrapper" },
                        [
                          _c("div", { staticClass: "left" }, [
                            _c("img", { attrs: { src: item.src, alt: "" } }),
                            _c("span", [_vm._v(_vm._s(item.name))])
                          ]),
                          _c(
                            "div",
                            { staticClass: "right" },
                            [
                              _c("dv-digital-flop", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  config: _vm.getDigitalConfig(
                                    _vm.singleData[item.key]
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info",
                      staticStyle: { padding: "0 1%", display: "inline-block" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "screen-card-title",
                          staticStyle: {
                            padding: "10px 20px 10px 20px",
                            "margin-bottom": "10px"
                          }
                        },
                        [_vm._v("返工信息")]
                      ),
                      _c("scroll-board", {
                        style: {
                          width: "100%",
                          height: _vm.table2Height + "px"
                        },
                        attrs: { config: _vm.scrollBoardConfig1 },
                        scopedSlots: _vm._u([
                          {
                            key: "currentProcedure",
                            fn: function(ref) {
                              var index = ref.index
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tableData1[index]
                                        .produceWorkOrderTaskList
                                        ? _vm.tableData1[index]
                                            .produceWorkOrderTaskList[0]
                                            .procedureName
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("scroll-board", {
                    style: { width: "100%", height: _vm.tableHeight + "px" },
                    attrs: { config: _vm.scrollBoardConfig2 },
                    scopedSlots: _vm._u([
                      {
                        key: "currentProcedure",
                        fn: function(ref) {
                          var index = ref.index
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tableData2[index].produceWorkOrderTaskList
                                    ? _vm.tableData2[index]
                                        .produceWorkOrderTaskList[0]
                                        .procedureName
                                    : ""
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "priority",
                        fn: function(ref) {
                          var index = ref.index
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "tag-priority",
                                style: {
                                  color: _vm.priorityStatus[
                                    _vm.tableData2[index].priority
                                  ].color
                                    ? _vm.priorityStatus[
                                        _vm.tableData2[index].priority
                                      ].color === "#4A4F76"
                                      ? "#fff"
                                      : _vm.priorityStatus[
                                          _vm.tableData2[index].priority
                                        ].color
                                    : "rgb(74, 79, 118)",
                                  background:
                                    (_vm.priorityStatus[
                                      _vm.tableData2[index].priority
                                    ].color
                                      ? _vm.priorityStatus[
                                          _vm.tableData2[index].priority
                                        ].color
                                      : "rgb(74, 79, 118)") + "20"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.priorityStatus[
                                        _vm.tableData2[index].priority
                                      ].name
                                        ? _vm.priorityStatus[
                                            _vm.tableData2[index].priority
                                          ].name
                                        : "普通"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "planStartTime",
                        fn: function(ref) {
                          var index = ref.index
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tableData2[index].producePlan
                                    ? _vm.tableData2[index].producePlan
                                        .planStartTime
                                    : ""
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "planEndTime",
                        fn: function(ref) {
                          var index = ref.index
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getPlanEdnTime(
                                    "",
                                    _vm.tableData2[index].producePlan
                                      ? _vm.tableData2[index].producePlan
                                          .planEndTime
                                      : ""
                                  )
                                )
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "procedureRatio",
                        fn: function(ref) {
                          var index = ref.index
                          return [
                            _c("el-progress", {
                              attrs: {
                                "text-inside": true,
                                "stroke-width": 15,
                                percentage: _vm.fixNumber2(
                                  _vm.tableData2[index].procedureRatio
                                )
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }