var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "params-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("CallParamsList")
            ? _c("el-radio-button", { attrs: { label: "CallParams" } }, [
                _vm._v("参数台账")
              ])
            : _vm._e(),
          _vm.permission("DataOriginList")
            ? _c("el-radio-button", { attrs: { label: "DataOrigin" } }, [
                _vm._v("数据源")
              ])
            : _vm._e()
        ],
        1
      ),
      _c("FunctionHeader", {
        attrs: {
          tags: _vm.functionHeader.tags,
          "search-title": "请输入参数名称",
          "hidden-search": _vm.params.radio === "DataOrigin"
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _vm.permission(_vm.params.radio + "List")
        ? _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columns[_vm.params.radio],
              height: _vm.height,
              "columns-key": _vm.columnsKey,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "index",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "dataType",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(
                        _vm._s(
                          _vm.dataType[row.type] && _vm.dataType[row.type].name
                        )
                      )
                    ])
                  }
                },
                {
                  key: "dataPoints",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.viewDataPoint(row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  }
                },
                {
                  key: "isEnabled",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", { staticClass: "is-enabled" }, [
                      _c("div", {
                        style: {
                          background: row.isEnabled ? "#24CAD7" : "#FFCC78"
                        }
                      }),
                      _c("div", [
                        _vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))
                      ])
                    ])
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _vm.params.radio === "CallParams"
                          ? [
                              _vm.permission(_vm.params.radio + "Modify")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: row.isFixed == 1,
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.modifyOrCopy(row, "modify")
                                        }
                                      }
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm.permission(_vm.params.radio + "Modify")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission(_vm.params.radio + "Del")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: row.isFixed == 1,
                                        type: "text"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.del(row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ]
                          : [
                              _vm.permission(_vm.params.radio + "SetPoint")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setDataPoint(row)
                                        }
                                      }
                                    },
                                    [_vm._v("设置数据点")]
                                  )
                                : _vm._e(),
                              _vm.permission(_vm.params.radio + "SetPoint")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission(_vm.params.radio + "Modify")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.modifyOrCopy(row, "modify")
                                        }
                                      }
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm.permission(_vm.params.radio + "Modify")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _c(
                                "el-dropdown",
                                {
                                  on: {
                                    command: function(command) {
                                      _vm.more(row, command)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(" 更多"),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticClass: "td-dropdown-menu",
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _vm.permission(_vm.params.radio + "Del")
                                        ? _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "del" } },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                      _vm.permission(
                                        _vm.params.radio + "Status"
                                      )
                                        ? _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "status" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  row.isEnabled
                                                    ? "停用"
                                                    : "启用"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                      ],
                      2
                    )
                  }
                }
              ],
              null,
              false,
              3863212021
            )
          })
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: {
              "form-data": _vm.formData,
              "form-list": !["addPoint", "modifyPoint"].includes(
                _vm.currentType
              )
                ? _vm.formList[_vm.params.radio]
                : _vm.formList.dataPoint
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.pointDialogTitle, "show-footer": false },
          model: {
            value: _vm.dataPointVisible,
            callback: function($$v) {
              _vm.dataPointVisible = $$v
            },
            expression: "dataPointVisible"
          }
        },
        [
          ["addPoint", "modifyPoint"].includes(_vm.currentType)
            ? _c("FunctionHeader", {
                attrs: {
                  tags: _vm.functionHeader.tags2,
                  "hidden-search": true,
                  "columns-setting": false
                }
              })
            : _vm._e(),
          _c("MTable", {
            attrs: {
              columns: ["addPoint", "modifyPoint"].includes(_vm.currentType)
                ? _vm.columns.dataPointsColumns
                : _vm.columns.viewDataPointsColumns,
              height: ["addPoint", "modifyPoint"].includes(_vm.currentType)
                ? _vm.viewHeight - 52
                : _vm.viewHeight,
              data: _vm.dataPoints,
              "columns-setting": false,
              "show-page": false
            },
            on: { "selection-change": _vm.selectionChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission(_vm.params.radio + "Modify")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.modifyOrCopy(row, "modifyPoint")
                                }
                              }
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                      _vm.permission(_vm.params.radio + "Modify")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission(_vm.params.radio + "Del")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.delPoint(row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }