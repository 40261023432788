
export const columns = [
  {
    prop: 'index',
    label: '序号',
    width: 60,
    slotName: 'index',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'content',
    label: '项目内容',
    hiddenSearch: true,
    sortable: false,
    form: { tagName: 'el-input', colSpan: 24, props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

export const techColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'name',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '使用数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'quantity',
    form: { tagName: 'el-input-number', colSpan: 24, props: { controlsPosition: 'right', min: 0 }}
  },
  {
    prop: 'describeInfo',
    label: '用途描述',
    hiddenSearch: true,
    sortable: false,
    minWidth: 300,
    slotName: 'describeInfo',
    form: { tagName: 'el-input', colSpan: 24, props: { type: 'textarea' }, required: false }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

export const equipmentColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '设备编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'name',
    label: '设备名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'sn',
    label: 'SN',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'model',
    label: '型号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'equipmentClassName',
    label: '分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'customerName',
    label: '客户/现场',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]
