var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "model-wrap" },
    [
      _vm._m(0),
      _c("FunctionHeader", {
        attrs: { "hidden-search": true, tags: _vm.functionHeader.tags },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "isSystem",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [_vm._v(_vm._s(row.isSystem ? "是" : "否"))])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.permission("DownLoad")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.downloadTemplate(row)
                            }
                          }
                        },
                        [_vm._v("下载")]
                      )
                    : _vm._e(),
                  _vm.permission("DownLoad")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Modify")
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            disabled: row.isSystem ? true : false
                          },
                          on: {
                            click: function($event) {
                              return _vm.modify(row)
                            }
                          }
                        },
                        [_vm._v("修改")]
                      )
                    : _vm._e(),
                  _vm.permission("Modify")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Del")
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            disabled: row.isSystem ? true : false
                          },
                          on: {
                            click: function($event) {
                              return _vm.del(row)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { width: "600px", title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "form-data": _vm.formData,
                "form-list": _vm.formList,
                "label-position": "left"
              }
            },
            [
              _c(
                "el-upload",
                _vm._b(
                  {
                    attrs: { slot: "drawing", "file-list": _vm.attachments },
                    slot: "drawing"
                  },
                  "el-upload",
                  _vm.uploadProps,
                  false
                ),
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "default",
                        size: "small",
                        icon: "el-icon-upload2"
                      }
                    },
                    [_vm._v("上传文件")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("仅支持扩展名.docx")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "background-color": "#f6f6f6", padding: "10px" } },
      [
        _c("div", [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "line-height": "20px",
                "font-size": "14px",
                "font-weight": "bold"
              }
            },
            [
              _c("div", { staticStyle: { color: "#616161" } }, [
                _vm._v("Word模板功能介绍")
              ]),
              _c("div", { staticStyle: { color: "#2790ff" } }, [
                _vm._v("使用帮助与常见问题")
              ])
            ]
          ),
          _c("div", { staticClass: "text" }, [
            _vm._v("您可以通过直接使用系统模板或创建自定义模板进行打印")
          ])
        ]),
        _c("div", { staticStyle: { "margin-top": "10px" } }, [
          _c(
            "div",
            {
              staticStyle: {
                "line-height": "20px",
                "font-size": "14px",
                "font-weight": "bold",
                color: "#616161"
              }
            },
            [_vm._v("如何制作自定义模板")]
          ),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "1. 您可以下载已有的模板进行编辑，也可以使用空白Word文件来创建新模板；"
            )
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "2. 进入对象列表 获取可使用的所有字段，复制所需要的字段代码粘贴到Word文件当中；"
            )
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v("3. 保存编辑好的Word文件；")
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "4. 点击下方「新增」或「修改」上传模板（注：文件格式只支持docx格式）"
            )
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }