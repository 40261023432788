<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">保养项目</div>
      <div class="card-bots">
        <div style="margin-bottom: 10px;">
          <el-button size="small" type="primary" @click="addInfo">添加</el-button>
        </div>
        <MTable
          ref="planTable"
          :show-page="false"
          :height="isTitle ? 300 : heights"
          :columns="columns"
          :data="itemMappingList"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="action" slot-scope="{ row, $index }">
            <template v-if="type === 'new'">
              <el-button type="text" @click="modifyInfo(row, $index)">修改</el-button>
              <el-button type="text" @click="delInfo($index)">删除</el-button>
            </template>
            <template v-else>
              <el-button type="text" @click="modifyInfos(row)">修改</el-button>
              <el-button type="text" @click="delInfos(row)">删除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <MDialog v-model="visible" :title="messages[currentType]" width="500px" @onOk="submitForm">
      <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="formList" />
    </MDialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { columns } from './columns'
import { formList } from './form-list'
import api from '@/api/xiewei/facility/maintenance-item'

export default {
  name: 'ItemsInfo',
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => ([])
    },
    facilityPlanMaintenanceTemplateId: {
      type: Number,
      default: undefined
    },
    facilityMaintenancePlanId: {
      type: Number,
      default: undefined
    },
    isTemplate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      api,
      itemMappingList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      columns,
      formList,
      tableData: [],
      formData: {},
      paramsVisible: false,
      visible: false,
      messages: {
        add: '新增项目',
        modify: '修改项目'
      },
      currentIndex: -1
    }
  },
  computed: {
    heights() {
      return this.$store.state.app.contentHeight - 256 - 250 - 50
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.itemMappingList = val
      }
    }
  },
  mounted() {
    this.itemMappingList = this.list
  },
  methods: {
    async delInfos(node) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let res
        if (this.isTemplate) {
          res = await api.delFacilityPlanMaintenanceTemplateItem({ id: node.id })
        } else {
          res = await api.delFacilityPlanMaintenanceItem({ id: node.id, facilityMaintenancePlanId: this.facilityMaintenancePlanId })
        }
        if (res) {
          this.$message.success('删除成功')
          this.$emit('refrash')
        }
      })
    },
    addInfo() {
      this.currentType = 'add'
      this.formData = {}
      this.visible = true
    },
    modifyInfo(row, index) {
      this.currentType = 'modify'
      this.formData = this._.cloneDeep(row)
      this.currentIndex = index
      this.visible = true
    },
    modifyInfos(row) {
      this.currentType = 'modify'
      this.formData = this._.cloneDeep(row)
      this.visible = true
    },
    delInfo(index) {
      this.itemMappingList.splice(index, 1)
    },
    submitForm(callback) {
      const that = this
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          if (this.type === 'new') {
            if (this.currentType === 'modify') {
              this.$set(this.itemMappingList, this.currentIndex, form)
            } else {
              this.itemMappingList.push(this.createSetData(form))
            }
            this.visible = false
            callback(true)
            return false
          }
          let res
          if (this.isTemplate) {
            res = await this.api[`${this.currentType}FacilityPlanMaintenanceTemplateItem`](
              this.currentType !== 'modify' ? Object.assign({}, {
                facilityPlanMaintenanceTemplateId: this.facilityPlanMaintenanceTemplateId
              }, this.createSetData(form)) : this.createResetData(form)
            )
          } else {
            res = await this.api[`${this.currentType}FacilityPlanMaintenanceItem`](
              this.currentType !== 'modify' ? Object.assign({}, {
                facilityMaintenancePlanId: this.facilityMaintenancePlanId
              }, this.createSetData(form)) : this.createResetData(form)
            )
          }

          if (res) {
            this.$message.success(this.getSuccessMessage())
            this.$emit('refrash')
            this.visible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      return {
        content: raw.content
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    .el-form-item__content {
      width: 300px;
    }
    .el-form-item {
      margin-bottom: 20px!important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
</style>
