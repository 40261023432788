var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equipment-monitoring" },
    [
      _c("div", { staticClass: "detail-cards" }, [
        _vm.isTitle
          ? _c("div", { staticClass: "card-tit" }, [_vm._v("点检项目")])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "card-bots" },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.addInfo }
                  },
                  [_vm._v("添加")]
                )
              ],
              1
            ),
            _c("MTable", {
              ref: "planTable",
              attrs: {
                "show-page": false,
                height: _vm.isTitle ? 300 : _vm.heights,
                columns: _vm.columns,
                data: _vm.itemMappingList
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c(
                      "div",
                      {},
                      [
                        _vm.type === "new"
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.modifyInfo(row, $index)
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delInfo($index)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          : [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.modifyInfos(row)
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delInfos(row)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                      ],
                      2
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType], width: "500px" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: {
              "label-position": "right",
              "label-width": "100px",
              "form-data": _vm.formData,
              "form-list": _vm.formList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }