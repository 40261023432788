<template>
  <div class="scrap-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <PartClass @nodeClick="setEquipmentClassId" @refrash="refrash" />
      </template>
      <template slot="right">
        <div class="bottom">
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入备件编码/名称/规格"
            :tags="!selectionData.length ? functionHeader.tags : []"
            :export-name="functionHeader.exportName"
            :export-params="functionHeader.exportParams"
            :import-name="!selectionData.length ? functionHeader.importName : ''"
            :show-export="!selectionData.length ? true : false"
            @search="$refs.mTable.setTableData(true)"
          >
            <div slot="all" v-show="selectionData.length">
              <el-button v-if="permission('Export')" size="small" type="primary"  @click=" allMore('batchExport')">批量导出</el-button>
              <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                已选 
                <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                / {{ total }}项
              </span>
                <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
            </div>
          </FunctionHeader>
          <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <el-button slot="code" slot-scope="{ row }" type="text" @click="viewDetail(row)">{{ row.code }}</el-button>
            <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
            <div slot="drawing" slot-scope="{ row }">
              <el-image
                v-if="row.drawing"
                :src="row.drawing"
                class="drawing-img"
                :preview-src-list="fixImg(row.drawing)"
              />
            </div>
            <div slot="equipmentList" slot-scope="{ row }">
              {{ fixList(row.equipmentList) }}
            </div>
            <div slot="action" slot-scope="{ row }">
              <el-button v-if="permission('equipment')" type="text" @click="selectEquipment(row)">关联设备</el-button>
              <el-divider v-if="permission('equipment')" direction="vertical" />
              <el-button v-if="permission('parts')" type="text" @click="viewEquipment(row)">使用记录</el-button>
              <el-divider v-if="permission('parts')" direction="vertical" />
              <el-dropdown @command="(command) => { more(row, command) }">
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                  <el-dropdown-item v-if="permission('Status')" command="switchStatus">{{ row.isEnabled ? '停用' : '启用' }}</el-dropdown-item>
                  <el-dropdown-item v-if="permission('Modify')" command="modify">修改</el-dropdown-item>
                  <el-dropdown-item v-if="permission('Copy')" command="copy">复制</el-dropdown-item>
                  <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </MTable>
          <el-dialog v-if="dialogVisible" :show-close="false">
            <img :src="dialogImageUrl" alt="">
          </el-dialog>
          <!-- 关联设备 -->
          <select-setting
            ref="selectSetting"
            :visible.sync="showSetting"
            :select-materials="selectParts"
            @submitForm="submitSetting"
          />
          <!-- 新增编辑复制备件 -->
          <edit-part ref="editPart" @onSuccess="onSuccess" />
          <!-- 查看备件使用记录 -->
          <TableDialog title="备件使用记录" :show.sync="spareVisible" :columns="spareColumns" :table-data="spareList" :calc-height="32">
            <el-row :gutter="24" style="padding-bottom: 15px">
              <el-col :span="8">备件编号：{{ currentRow.code }}</el-col>
              <el-col :span="8">备件名称：{{ currentRow.name }}</el-col>
              <el-col :span="8">规格型号：{{ currentRow.specifications }}</el-col>
            </el-row>
          </TableDialog>
        </div>
      </template>
    </Split>
    <CURDDialog :visible.sync="visible" :data="formData" :purchased="purchased" @submit="submitDialog" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import { columns, spareColumns } from './columns'
import { getUUid } from '@/utils'
import api from '@/api/equipment/spare-part'
import { Encrypt } from '@/utils/sercet'
import conventionMixin from '@/mixin/conventional-page'
import Split from '@/components/Split/Split'
import PartClass from '@/components/PartClass/PartClass'
import { getToken } from '@/utils/auth'
import SelectSetting from '@/components/SelectSetting/SelectSetting'
import EditPart from './components/edit-part.vue'
import TableDialog from '@/components/TableDialog/TableDialog'
import CURDDialog from '../part-apply/components/add-part'
export default {
  name: 'PartAccount',
  components: { TableDialog, FunctionHeader, Split, PartClass, SelectSetting, EditPart, CURDDialog },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      spareColumns,
      currentRow: {},
      spareList: [],
      spareVisible: false,
      params: {
        radio: 'SparePart'
      },
      columns,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addSparePart
            }
          }
        ],
        exportName: 'exportSparePart',
        importName: 'sparePart',
        exportParams: {}
      },
      // formList: {
      //   add: formList,

      // },
      formData: {},
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      currentType: 'add',
      visible: false,
      formOptions: {
        equipmentClassList: [],
        shiftOptions: [],
        userOptions: [],
        stationList: []
      },
      statusColor: ['#FFCC78', '#24CAD7', '#E02020'],
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制',
        addRepair: '新建维保任务'
      },
      searchKeyword: true,
      split: {
        offset: 15
      },
      getTableDataParams: {
        sparePartClassIds: ''
      },
      stationSearch: [{
        id: getUUid(),
        fieldName: 'equipmentId',
        fieldType: 'number',
        fieldValue: 0,
        operator: 'eq'
      }],
      options: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false
      },
      equipmentImageUrl: '',
      editId: undefined,
      dialogVisible: false,
      dialogImageUrl: '',
      showSetting: false,
      selectSettingId: undefined,
      selectParts: [],
      selectionData: [],
      exportSelectionIds: [],
      data: '',
      purchased: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 210
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 83
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  beforeMount() {

  },
  mounted() {
    // this.getSparePartClassList()
  },
  methods: {
    async viewEquipment(row) {
      const res = await api.getSpartPartDetail(row.id)
      if (res) {
        this.spareList = res.detailsList
        this.currentRow = res
        this.spareVisible = true
      }
    },
    selectionChange(data) {
      this.selectionData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() },
        batchPurchase: () => {
          this.currentType = 'add'
          this.visible = true
          this.purchased = this.selectionData
        }
      }
      functions[command]()
    },
    onSuccess() {
      this.$refs.mTable.setTableData()
    },
    // 关联设备
    async modifySparePart(ids) {
      const data = {
        id: this.selectSettingId,
        equipmentIdList: ids
      }
      console.log('data=>', data)
      const res = await api.modifySparePart(data)
      if (res) {
        this.$message.success('关联成功')
        this.$refs.mTable.setTableData()
      }
    },
    fixList(val) {
      if (val && val.length > 0) {
        const data = val.map(item => item.name)
        return data.join()
      } else {
        return ''
      }
    },
    selectEquipment(row) {
      this.selectParts = row.equipmentList ? row.equipmentList : []
      this.showSetting = true
      this.selectSettingId = row.id
    },
    submitSetting(data) {
      this.showSetting = false
      const datas = data.map(item => item.id)
      this.modifySparePart(datas)
    },
    refrash() {
      this.getTableDataParams.sparePartClassIds = ''
      this.$refs.mTable.setTableData(true)
    },
    fixImg(img) {
      return [img]
    },
    setEquipmentClassId(val) {
      this.getTableDataParams.sparePartClassIds = val
      this.$refs.mTable.setTableData(true)
    },
    // 新增
    addSparePart() {
      this.$refs.editPart.add(this.getTableDataParams.sparePartClassIds)
    },
    modify(row, command) {
      this.$refs.editPart.modifyOrCopy(row, command)
    },
    viewDetail(row) {
      this.$refs.editPart.modifyOrCopy(row, 'view')
    },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    // 更多操作
    more(row, command) {
      const functions = {
        switchStatus: () => { this.switchStatus(row, this.params.radio) },
        modify: () => { this.modify(row, command) },
        copy: () => { this.modify(row, command) },
        del: () => { this.del(row) }
      }
      functions[command]()
    },
    submitDialog() {
      this.$refs.mTable.setTableData()
    }

  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;

  .bottom {
    padding: 10px;
  }
  ::v-deep {
    .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 150px;
    display: block;
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }

}
</style>
