var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-apply" },
    [
      _c(
        "MDialog",
        {
          attrs: {
            width: "70%",
            title: _vm.messages[_vm.currentType],
            "confirm-button-text": "保 存",
            "save-button": _vm.saveButton
          },
          on: { onSave: _vm.commitForm, onOk: _vm.submitForm },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
            },
            [
              _c(
                "el-upload",
                _vm._b(
                  {
                    attrs: { slot: "drawing", "file-list": _vm.attachments },
                    slot: "drawing"
                  },
                  "el-upload",
                  _vm.uploadProps2,
                  false
                ),
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("上传附件")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "15px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.openMaterials()
                }
              }
            },
            [_vm._v("添加备件")]
          ),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.accountColumns,
              height: _vm.height,
              data: _vm.applyData,
              "show-page": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "quantity",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 1,
                          "controls-position": "right",
                          step: 1
                        },
                        model: {
                          value: row.quantity,
                          callback: function($$v) {
                            _vm.$set(row, "quantity", $$v)
                          },
                          expression: "row.quantity"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "drawing",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      row.drawing
                        ? _c("el-image", {
                            staticClass: "drawing-img",
                            attrs: {
                              src: row.drawing,
                              "preview-src-list": _vm.fixImg(row.drawing)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.deleteRow($index)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c("select-partAccount", {
        attrs: {
          visible: _vm.showMaterials,
          "select-materials": _vm.selectMaterials
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }