var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  "label-position": "right",
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "110px"
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "变更单号", prop: "code" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "", disabled: "" },
                              model: {
                                value: _vm.ruleForm.prePlanChangeNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "prePlanChangeNumber",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.prePlanChangeNumber"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "变更类型", prop: "changeType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  disabled: ["view", "add"].includes(
                                    _vm.$route.query.type
                                  )
                                },
                                model: {
                                  value: _vm.ruleForm.changeType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "changeType", $$v)
                                  },
                                  expression: "ruleForm.changeType"
                                }
                              },
                              _vm._l(_vm.changeNewType, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "工单编号", prop: "prePlanId" } },
                          [
                            _c("m-remote-select", {
                              staticClass: "input-prepend",
                              attrs: {
                                searchType: "prePlanId",
                                placeholder: "请选择工单编号",
                                "interface-fun": _vm.api.getPrePlanListPage,
                                options: _vm.orderLists,
                                filterable: "",
                                clearable: "",
                                "label-name": "prePlanNumber",
                                "search-name": "prePlanNumber",
                                "value-name": "prePlanId"
                              },
                              on: {
                                "update:options": function($event) {
                                  _vm.orderLists = $event
                                },
                                "on-change": _vm.workChange
                              },
                              model: {
                                value: _vm.ruleForm.prePlanId,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "prePlanId", $$v)
                                },
                                expression: "ruleForm.prePlanId"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "8px" },
          attrs: {
            type: "primary",
            size: "small",
            disabled: !_vm.materialsBomId
          },
          on: { click: _vm.addMaterials }
        },
        [_vm._v("新增")]
      ),
      _c(
        "div",
        { staticClass: "detail-card", staticStyle: { padding: "10px" } },
        [
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.productionTaskColumn,
              height: 500,
              data: _vm.materialsList,
              "show-page": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "upMaterial",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function(val) {
                              return _vm.selectUpMaterials(row, val)
                            }
                          },
                          model: {
                            value: row.upMaterialsId,
                            callback: function($$v) {
                              _vm.$set(row, "upMaterialsId", $$v)
                            },
                            expression: "row.upMaterialsId"
                          }
                        },
                        _vm._l(_vm.upMaterialsList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "attributionProcess",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function(val) {
                              return _vm.selectProcess(row, val)
                            }
                          },
                          model: {
                            value: row.procedureId,
                            callback: function($$v) {
                              _vm.$set(row, "procedureId", $$v)
                            },
                            expression: "row.procedureId"
                          }
                        },
                        _vm._l(_vm.processList, function(item) {
                          return _c("el-option", {
                            key: item.procedureId,
                            attrs: {
                              label: item.procedureName,
                              value: item.procedureId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "direct",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: row.directId,
                            callback: function($$v) {
                              _vm.$set(row, "directId", $$v)
                            },
                            expression: "row.directId"
                          }
                        },
                        _vm._l(_vm.directList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "quantity",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          min: 0,
                          "controls-position": "right",
                          step: 1
                        },
                        model: {
                          value: row.quantity,
                          callback: function($$v) {
                            _vm.$set(row, "quantity", $$v)
                          },
                          expression: "row.quantity"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var $index = ref.$index
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.del($index)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v("取 消")
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("确 定")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: { visible: _vm.showMaterials, title: "添加物料" },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }