/** write by luomingzhi */
import { equipmentType, status } from '@/config/options.config'
import { quanlityInfoType,afterSaleEquStatus } from '@/config/options.sales'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    width: 140,
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 2, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'sn',
    label: 'SN',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 3, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'model',
    label: '型号',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 4, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'equipmentClassName',
    label: '分类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      colSpan: 8,
      tagName: 'el-cascader',
      key: 'equipmentClassId',
      required: false,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
  },
  {
    prop: 'type',
    label: '质保状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    form: { index: 6, options: quanlityInfoType },
    width: 180
  },
  {
    prop: 'customerName',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 7,
      tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 8,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.mulChange(val) }
      }
    }
  },
  {
    prop: 'equipmentName',
    label: '当前关联客户设备',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '售后设备状态',
    sortable: false,
    hiddenSearch: true,
    form: { index: 10, options: afterSaleEquStatus }
  },
  {
    prop: 'addUpUserTime',
    label: '累计使用时长',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 8,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' },
      colSpan: 8
    }
  }
]
export const zColumns = [
  {
    prop: 'moveInTime',
    label: '搬入时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-date-picker',
      colSpan: 8,
      required: false,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
        // pickerOptions: {
        //   disabledDate(time) {
        //     return time.getTime() < Date.now() - 8.64e7
        //   }
        // }
      }
    }
  },
  {
    prop: 'surveyTime',
    label: '会勘时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      tagName: 'el-date-picker',
      colSpan: 8,
      required: false,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
        // pickerOptions: {
        //   disabledDate(time) {
        //     return time.getTime() < Date.now() - 8.64e7
        //   }
        // }
      }
    }
  },
  {
    prop: 'startingUpTime',
    label: '开机时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-date-picker',
      colSpan: 8,
      required: false,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
        // pickerOptions: {
        //   disabledDate(time) {
        //     return time.getTime() < Date.now() - 8.64e7
        //   }
        // }
      }
    }
  },
  {
    prop: 'debuggingTime',
    label: '调试时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      tagName: 'el-date-picker',
      colSpan: 8,
      required: false,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
        // pickerOptions: {
        //   disabledDate(time) {
        //     return time.getTime() < Date.now() - 8.64e7
        //   }
        // }
      }
    }
  },
  {
    prop: 'checkAndAcceptTime',
    label: '验收时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      tagName: 'el-date-picker',
      colSpan: 8,
      required: false,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
        // pickerOptions: {
        //   disabledDate(time) {
        //     return time.getTime() < Date.now() - 8.64e7
        //   }
        // }
      }
    }
  }
]
