<template>
  <div class="account-detail-wrapper">
    <div class="account-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">设备详情</span>
      </div>
    </div>
    <div class="account-detail-body">
      <div class="detail-card">
        <div class="card-tit">设备基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <div>
            <el-row class="view-list" style="padding: 10px 15px; color: #393d60" :gutter="24">
              <!--            暂时这么处理距离问题-->
              <el-col v-for="(item, index) in columnss" :key="item.prop" :span="6" :style="{ marginBottom: index !== 4 ? '20px' : 0 }">
                <div style="color: #9597ae">{{ item.label }}</div>
                <div class="bot-tis">{{ getValue(item) || '-' }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 15px">
      <el-tabs v-model="params.radio" class="detail-tabs" type="border-card" tab-position="top" @tab-click="tabClick">
        <el-tab-pane label="保养记录" name="MaintainRadio">
          <MTable v-if="params.radio === 'MaintainRadio'" ref="mTable" :height="height" :columns="maintainColumns" :set-data-method="getTableData">
            <el-button slot="workOrderCode" slot-scope="{ row }" type="text" @click="toDetail(row.id, 'maintainDetail', 'view')">
              {{ row.workOrderCode }}
            </el-button>

            <div v-if="row.isDeletePlan === 1" slot="facilityPlanName" slot-scope="{ row }">
              {{ row.facilityPlanCode }} {{ row.facilityPlanName }}
            </div>
            <el-button
              v-else
              slot="facilityPlanName"
              slot-scope="{ row }"
              type="text"
              @click="toDetail(row.facilityPlanId, 'facilityMaintenanceDetail')"
            >
              {{ row.facilityPlanCode }} {{ row.facilityPlanName }}
            </el-button>

            <div slot="level" slot-scope="{ row }">{{ getLevelDesc(row) }}</div>
            <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
            <div slot="executorName" slot-scope="{ row }">{{ row.executorName }}</div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="点检记录" name="SpotCheckRadio">
          <div class="clearfix" style="margin-bottom: 10px">
            <el-upload v-if="spotTab === 'File'" :file-list="[]" v-bind="uploadProps" class="spot-upload">
              <el-button type="primary" size="small">上传</el-button>
            </el-upload>
            <div class="right-btns">
              <el-button size="small" :type="spotTab === 'List' ? 'primary' : ''" @click="changeSpotTab('List')">记录</el-button>
              <el-button size="small" :type="spotTab === 'File' ? 'primary' : ''" @click="changeSpotTab('File')">附件</el-button>
            </div>
          </div>
          <MTable
            v-if="params.radio === 'SpotCheckRadio' && spotTab === 'List'"
            ref="spotListTable"
            :height="tabHeight"
            :columns="spotCheckColumns"
            :set-data-method="getTableData"
          >
            <el-button slot="workOrderCode" slot-scope="{ row }" type="text" @click="toDetail(row.id, 'spotCheckDetail', 'view')">
              {{ row.workOrderCode }}
            </el-button>

            <div v-if="row.isDeletePlan === 1" slot="facilitySpotCheckPlanName" slot-scope="{ row }">
              {{ row.facilitySpotCheckPlanCode }} {{ row.facilitySpotCheckPlanName }}
            </div>
            <el-button
              v-else
              slot="facilitySpotCheckPlanName"
              slot-scope="{ row }"
              type="text"
              @click="toDetail(row.facilitySpotCheckPlanId, 'facilityPointDetail')"
            >
              {{ row.facilitySpotCheckPlanCode }} {{ row.facilitySpotCheckPlanName }}
            </el-button>

            <div slot="executiveOutcome" slot-scope="{ row }">{{ row.executiveOutcome === 1 ? '全部正常' : '存在异常' }}</div>
            <div slot="processMode" slot-scope="{ row }">
              {{ (row.facilityInspectionFieldNotes.facilityInspectionSpotDetectioList[0] || {}).processMode }}
            </div>
            <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
            <div slot="executorName" slot-scope="{ row }">{{ row.executorName }}</div>
          </MTable>
          <MTable
            v-if="params.radio === 'SpotCheckRadio' && spotTab === 'File'"
            ref="spotFileTable"
            :height="tabHeight"
            :columns="fileColumns"
            :data="equipmentDetail.facilityAttachmentsList"
            :show-page="false"
          >
            <el-button slot="name" slot-scope="{ row }" type="text" @click="handlePreview(row)">{{ row.name }}</el-button>
            <DelPopover slot="action" slot-scope="{ row }" @onOk="callback => delFile(row, callback)" />
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="维修记录" name="RepairsRadio">
          <MTable
            v-if="params.radio === 'RepairsRadio'"
            ref="mTable"
            :height="height"
            :columns="repairsColumns"
            :show-page="false"
            :data="equipmentDetail.facilityRepairWordOrderList || []"
          >
            <el-button slot="workOrderCode" slot-scope="{ row }" type="text" @click="toDetail(row.id, 'repairsDetail', 'view')">
              {{ row.workOrderCode }}
            </el-button>

            <el-button v-if="row.demandCode" slot="demandCode" slot-scope="{ row }" type="text" @click="viewDemandCenters(row)">
              {{ row.demandCode }}
            </el-button>
            <div v-else slot="demandCode" slot-scope="{ row }" type="text">{{ '-' }}</div>

            <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
            <div slot="executorName" slot-scope="{ row }">{{ row.executorName }}</div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="改造记录" name="ReformRadio">
          <MTable
            v-if="params.radio === 'ReformRadio'"
            ref="mTable"
            :height="height"
            :columns="reformColumns"
            :show-page="false"
            :data="equipmentDetail.facilityReformWordOrderList || []"
          >
            <el-button slot="workOrderCode" slot-scope="{ row }" type="text" @click="toDetail(row.id, 'repairsDetail', 'view')">
              {{ row.workOrderCode }}
            </el-button>

            <el-button v-if="row.demandCode" slot="demandCode" slot-scope="{ row }" type="text" @click="viewDemandCenters(row)">
              {{ row.demandCode }}
            </el-button>
            <div v-else slot="demandCode" type="text">{{ '-' }}</div>

            <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
            <div slot="executorName" slot-scope="{ row }">{{ row.executorName }}</div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="装机记录" name="InstalledRadio">
          <div class="clearfix" style="margin-bottom: 10px">
            <div class="right-btns">
              <el-button size="small" :type="installTab === 'Process' ? 'primary' : ''" @click="changeInstallTab('Process')">过程</el-button>
              <el-button size="small" :type="installTab === 'List' ? 'primary' : ''" @click="changeInstallTab('List')">工单</el-button>
            </div>
          </div>
          <MTable
            v-if="params.radio === 'InstalledRadio' && installTab === 'Process'"
            ref="installProcessTable"
            :height="tabHeight"
            :columns="processColumns"
            :show-page="false"
            :data="equipmentDetail.facilityDemandAssembleList || []"
          />
          <MTable
            v-if="params.radio === 'InstalledRadio' && installTab === 'List'"
            ref="installListTable"
            :height="tabHeight"
            :columns="installedColumns"
            :data="equipmentDetail.facilityInstallWordOrderList || []"
            :show-page="false"
          >
            <el-button slot="workOrderCode" slot-scope="{ row }" type="text" @click="toRepairsDetail(row)">
              {{ row.workOrderCode }}
            </el-button>

            <el-button v-if="row.demandCode" slot="demandCode" slot-scope="{ row }" type="text" @click="viewDemandCenters(row)">
              {{ row.demandCode }}
            </el-button>
            <div v-else slot="demandCode" slot-scope="{ row }" type="text">{{ '-' }}</div>

            <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
            <div slot="executorName" slot-scope="{ row }">{{ row.executorName }}</div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="备件记录" name="SparePartRadio">
          <MTable
            v-if="params.radio === 'SparePartRadio'"
            ref="mTable"
            :height="height"
            :columns="sparePartColumns"
            :show-page="false"
            :data="equipmentDetail.facilityRepairSparePartList || []"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="toDetail(row.materialsId, 'materialsDetail', 'view')">
              {{ row.materialsCode }}
            </el-button>

            <el-button
              v-if="row.orderCoder"
              slot="orderCoder"
              slot-scope="{ row }"
              type="text"
              @click="toDetail(row.orderId, 'repairsDetail', 'view')"
            >
              {{ row.orderCoder }}
            </el-button>
            <div v-else slot="orderCoder" type="text">{{ '-' }}</div>

            <div slot="ownershipSparePartsAssets" slot-scope="{ row }">{{ quanlityInfoFeeType[row.ownershipSparePartsAssets].name }}</div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="质保信息" name="quanityRadio">
          <QuanlityInfo
            :type="'detail'"
            :list="equipmentDetail.facilityWarrantyList"
            :facility-id="equipmentDetail.id"
            @refrash="getEquipmentDetail"
          />
        </el-tab-pane>
        <el-tab-pane label="技术参数" name="technialRadio">
          <TechnicalParameter
            :type="'detail'"
            :list="equipmentDetail.facilityMappingParametersList"
            :facility-id="equipmentDetail.id"
            @refrash="getEquipmentDetail"
          />
        </el-tab-pane>
        <el-tab-pane label="配置参数" name="configurationRadio">
          <ConfigurationParameter
            :show-title="false"
            :is-view="true"
            :facility-id="equipmentDetail.id"
            :list="parametersList"
            @refrash="getEquipmentDetail"
          />
        </el-tab-pane>
        <el-tab-pane label="工厂参数" name="factoryRadio">
          <FactoryParameter
            :type="'detail'"
            :list="equipmentDetail.facilityFactoryParameterMappingList"
            :facility-id="equipmentDetail.id"
            @refrash="getEquipmentDetail"
          />
        </el-tab-pane>
        <el-tab-pane label="额外信息" name="extraRadio" :style="{height: `${height}px` }">
          <el-row class="view-list" style="padding: 10px 15px; color: #393d60; font-size: 14px" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <el-col
              v-for="(item, index) in equipmentDetail.facilityFieldList"
              :key="item.id"
              :span="6"
              :style="{ marginBottom: index !== 4 ? '20px' : 0 }"
            >
              <div style="color: #9597ae">{{ item.fieldSetName }}</div>
              <div class="bot-tis">{{ item.value || '-' }}</div>
            </el-col>
          </el-row>
        </el-tab-pane>
<!--        <el-tab-pane label="关联辅材" name="auxiliaryMaterialsRadio">-->
<!--          <MTable v-if="params.radio === 'auxiliaryMaterialsRadio'" ref="mTable" :height="height" :columns="auxiliaryMaintainColumns" :data="auxiliaryList" :showPage="false" />-->
<!--        </el-tab-pane>-->
<!--        <el-tab-pane label="辅材记录" name="auxiliaryRecordRadio">-->
<!--          <MTable v-if="params.radio === 'auxiliaryRecordRadio'" ref="mTable" :height="height" :columns="auxiliaryRecordColumns" :data="auxiliaryRecordList" :showPage="false" >-->
<!--            <div slot="operationType" slot-scope="{ row }">{{ operationType[row.operationType].name }}</div>-->
<!--          </MTable>-->
<!--        </el-tab-pane>-->

        <!-- 辅材管理里的使用记录 运入运出配置单 挪到这里了 -->
        <el-tab-pane label="使用记录" name="auxiliaryRecordRadio2">
          <MTable
            v-if="params.radio === 'auxiliaryRecordRadio2'"
            ref="mTable"
            :height="height"
            :columns="auxiliaryRecordColumns2"
            :data="auxiliaryRecordList2"
            :showPage="false"
          >
            <div slot="operationType" slot-scope="{ row }">
              {{ operationType[row.operationType].name }}
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="运入运出配置单" name="auxiliaryInstructionRecordRadio">
          <MTable
            v-if="params.radio === 'auxiliaryInstructionRecordRadio'"
            ref="mTable"
            :height="height"
            :columns="auxiliaryInstructionRecordColumns"
            :set-data-method="getTableData2"
          >
            <div slot="action" slot-scope="{ row }">
              <el-button type="text" @click="getDetail(row)">详情</el-button>
            </div>
          </MTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <MDialog v-model="configurationVisible" title="查看配置明细" :needSubBtn="false">
      <MTable ref="mTable" :columns="configurationColumns" :height="height" :data="configurationList" :columns-setting="false" :show-page="false">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="shipOutMatch" slot-scope="{ row }">
          <el-radio disabled :label="1" v-model="row.shipOutMatch">{{""}}</el-radio>
        </div>
        <div slot="shipInMatch" slot-scope="{ row }">
          <el-radio disabled :label="1" v-model="row.shipInMatch">{{""}}</el-radio>
        </div>
      </MTable>
    </MDialog>
  </div>
</template>

<script>
import { getUUid } from '@/utils'

const empty = 'https://oss.mubyte.cn/static/empty.png'
import api from '@/api/xiewei/facility/facility'
import dayjs from 'dayjs'
import DelPopover from '@/components/DelPopover/index'
import { columns } from '../columns'
import {
  maintainColumns,
  repairsColumns,
  spotCheckColumns,
  fileColumns,
  reformColumns,
  sparePartColumns,
  processColumns,
  installedColumns,
  auxiliaryMaintainColumns,
  auxiliaryRecordColumns,
  auxiliaryRecordColumns2,
  auxiliaryInstructionRecordColumns,
  configurationColumns
} from './columns'
import { getToken } from '@/utils/auth'
import conventionMixin from '@/mixin/conventional-page'
import QuanlityInfo from '@/views/facility/account/components/quanlity-info'
import TechnicalParameter from '@/views/facility/account/components/technical-parameter'
import ConfigurationParameter from '@/views/facility/configuration/components/configuration-parameter'
import { quanlityInfoFeeType } from '@/config/options.sales'
import FactoryParameter from '@/views/facility/account/components/factory-parameter'
import { operationType } from '@/config/options.config'
import { Encrypt } from '@/utils/sercet'


export default {
  name: 'FacilityAccountDetails',
  components: { FactoryParameter, DelPopover, QuanlityInfo, TechnicalParameter, ConfigurationParameter },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      columns,
      configurationVisible:false,
      configurationList: [],
      columnss: [],
      maintainColumns,
      repairsColumns,
      spotCheckColumns,
      fileColumns,
      reformColumns,
      sparePartColumns,
      processColumns,
      installedColumns,
      auxiliaryMaintainColumns,
      auxiliaryRecordColumns,
      auxiliaryRecordColumns2,
      auxiliaryInstructionRecordColumns,
      operationType,
      functionHeader: {},
      params: {
        radio: 'MaintainRadio'
      },
      getTableDataParams: {
        facilityId: this.$route.params.id
      },
      empty,
      equipmentDetail: {
        id: undefined,
        facilityWarrantyList: [],
        facilityFieldList: [],
        facilityUpkeepWorkOrderList: [],
        facilityInspectionWorkOrderList: [],
        facilityAttachmentsList: [],
        facilityRepairWordOrderList: [],
        facilityReformWordOrderList: [],
        facilityInstallWordOrderList: [],
        facilityRepairSparePartList: []
      },
      spotTab: 'List',
      installTab: 'Process',
      fileList: [],
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange
      },
      parametersList: [],
      quanlityInfoFeeType,
      auxiliaryList: [],
      auxiliaryRecordList: [],
      auxiliaryRecordList2: [],
      configurationColumns
    }
  },
  computed: {
    tableDialogHeight() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
    },
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 148
    },
    tabHeight() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 184
    },
    getLevelDesc() {
      return row => {
        const { level } = row
        return {
          1: '一级',
          2: '二级',
          3: '三级'
        }[level]
      }
    },
    getTimeDesc() {
      return (start, end) => {
        const format = 'YYYY年MM月DD日 HH:mm'
        if (start && end) {
          const isSameDay = dayjs(start).isSame(end, 'day')
          const isSameYear = dayjs(start).isSame(end, 'year')
          if (isSameDay) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('HH:mm')}`
          } else if (isSameYear) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('MM月DD日 HH:mm')}`
          } else {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
          }
        } else if (start) {
          return `${dayjs(start).format(format)}`
        } else if (end) {
          return `${dayjs(end).format(format)}`
        }
        return '-'
      }
    }
  },
  mounted() {
    this.getEquipmentDetail()
    // this.getAuxiliaryList()
    // this.getAuxiliaryRecordList()
    this.columnss = this.columns.filter((item, index) => index > 1)
  },
  methods: {
    // 查询使用记录
    async getAuxiliaryMaterialDetail() {
      // const res = await api.getAuxiliaryMaterialDetail({
      //   id: this.$route.params.id
      // })
      let searchKey = [
        {
          id: getUUid(),
          fieldName: 'auxiliaryMaterialId',
          fieldType: 'number',
          fieldValue: this.$route.params.id,
          operator: 'eq'
        }
      ]
      const res = await api.getAfterSaleEquRecDetail({
        page: 1,
        limit: 200,
        searchVal: Encrypt(JSON.stringify([...searchKey]))
      })
      if (res) {
        this.auxiliaryRecordList2 = res.records
      }
    },
    // 查询运入运出配置单
    async getTableData2(condition, callback) {
      const { page, search, order } = condition
      let searchKey = [
        {
          id: getUUid(),
          fieldName: 'auxiliaryMaterialId',
          fieldType: 'number',
          fieldValue: this.$route.params.id,
          operator: 'eq'
        }
      ]
      const res = await api.getAuxiliaryInstructionRecordListPage({
        page: page.currentPage,
        limit: page.pageSize,
        searchVal: Encrypt(JSON.stringify([...searchKey]))
      })
      if (res) {
        const datas = res.records
          ? res.records.map(item => {
            return {
              ...item
            }
          })
          : []
        callback({
          total: res.total,
          content: datas
        })
      }
      callback()
    },
    // 获取运入运出配置单 详情
    getDetail(row) {
      this.getAuxiliaryInstructionDetail(row.id)
      this.configurationVisible = true
    },
    // 获取运入运出配置单 详情
    async getAuxiliaryInstructionDetail(id) {
      let searchKey = [
        {
          id: getUUid(),
          fieldName: 'outInInstructionAuxiliaryRecordId',
          fieldType: 'number',
          fieldValue: id,
          operator: 'eq'
        }
      ]
      const res = await api.getAuxiliaryInstructionDetail({
        searchVal: Encrypt(JSON.stringify([...searchKey]))
      })
      if (res) {
        this.configurationList = res
      }
    },
    async getEquipmentDetail() {
      const res = await api.getFacilityDetail({ id: this.$route.params.id })
      if (res) {
        this.equipmentDetail = res
        this.parametersList = res.facilityConfigurationParameterList
      }
    },
    // async getAuxiliaryList() {
    //   const res = await api.getAuxiliaryList({ id: this.$route.params.id })
    //   if (res) {
    //     this.auxiliaryList = res.auxiliaryMaterialList
    //   }
    // },
    // async getAuxiliaryRecordList() {
    //   const res = await api.getAuxiliaryRecordList({ equipmentId: this.$route.params.id })
    //   if (res) {
    //     this.auxiliaryRecordList = res
    //   }
    // },
    getValue(row) {
      const result = this.equipmentDetail[row.prop]
      if (row.form && row.form.options && row.form.options.length > 0) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    back() {
      this.$router.push({ name: 'facilityAccountList' })
    },
    searchTable(data) {
      this.searchFormatData = data
      if (this.$refs.mTable) this.$refs.mTable.setTableData(true)
    },
    tabClick() {
      this.spotTab = 'List'
      this.installTab = 'Process'
      //获取使用记录
      if(this.params.radio === "auxiliaryRecordRadio2"){
        this.getAuxiliaryMaterialDetail();
      }
    },
    async changeSpotTab(type) {
      this.spotTab = type
      if (type === 'List') {
        this.$nextTick(() => {
          this.$refs.spotListTable.setTableData(true)
        })
      }
    },
    async changeInstallTab(type) {
      this.installTab = type
    },
    // 上传附件
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    async fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        const data = await api.addFacilityAttachments({
          facilityId: +this.$route.params.id,
          name: file.name,
          attachments: JSON.stringify([
            {
              url: res.result
            }
          ])
        })
        if (data) {
          this.$message.success('上传成功')
          this.getEquipmentDetail()
        }
      } else {
        this.$message.error(res.result)
      }
    },
    handlePreview(row) {
      const links = JSON.parse(row.attachments || '[]')
      if (links.length) {
        window.open(links[0].url)
      }
    },
    async delFile(row) {
      const res = await api.delFacilityAttachments({ id: row.id })
      if (res) {
        this.$message.success('删除成功')
        this.getEquipmentDetail()
      }
    },
    toDetail(id, name, type) {
      if (type) {
        this.$router.push({ name, query: { id, type }})
      } else {
        this.$router.push({ name, query: { id }})
      }
    },
    toRepairsDetail(row) {
      this.$router.push({
        name: 'repairsDetail',
        query: { type: 'view', id: row.id }
      })
    },
    viewDemandCenters(row) {
      this.$router.push({ name: 'demandCenterDetail', query: { id: row.demandId }})
    }
  }
}
</script>

<style scoped lang="scss">
.account-detail-wrapper {
  .account-detail-header {
    width: 100%;
    padding: 10px 0;
  }
  .account-detail-body {
    padding-top: 10px;
    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #dde3eb;
      }
      // ::v-deep .el-tabs__nav {
      //   position: relative;
      //   left: 50%;
      //   transform: translateX(-50%) !important;
      // }
    }
  }

  .right-btns {
    float: right;
    ::v-deep.el-button {
      margin-left: 0;
      border-radius: 0;
    }
  }

  .spot-upload {
    display: inline-block;
    ::v-deep .el-upload-list {
      display: none;
    }
  }

  ::v-deep .configuration-parameter__main {
    padding: 0;
  }
}
.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.result-wrapper {
  > div {
    display: flex;
    font-size: 16px;
    padding: 10px 0;
    span {
      &:first-child {
        width: 80px;
        text-align-last: justify;
        text-align: justify;
      }
    }
  }
}
::v-deep {
  .table-row-img {
    > img {
      height: 50px;
    }
  }
}
.all-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .checkAll {
    margin-right: 20px;
  }
  .all-li {
    width: 80px;
    height: 26px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #dde3eb;
    margin-right: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a4b3c6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .active {
    color: #607fff;
    border-color: #607fff;
    background: #eff2ff;
  }
}
</style>
