/** write by luomingzhi */
// 参数台账
const columns = [
  {
    prop: 'index',
    label: '序号',
    width: 80,
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'deviceParametersName',
    label: '参数名',
    hiddenSearch: true,
    sortable: false,
    form: { key: 'deviceParametersId', tagName: 'el-select', options: [] }
  },
  {
    prop: 'dataPointName',
    label: '数据点',
    hiddenSearch: true,
    sortable: false,
    form: { key: 'dataPointId', tagName: 'el-cascader', props: {
      props: {
        label: 'name',
        value: 'id'
      },
      options: [],
      filterable: true
    }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]
export default columns

