<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">工厂参数</div>
      <div class="card-bots">
        <div style="margin: 10px;">
          <el-button v-if="type === 'new'" size="small" type="primary" @click="selectInfo">套用模板</el-button>
        </div>
        <MTable
          ref="plansTable"
          :show-page="false"
          :height="tabHeight"
          :columns="getColumn"
          :data="facilityFactoryParameterMappingList"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="factoryParameterName" slot-scope="{ row }">
            <span>{{ row.factoryParameterName }}</span>
          </div>
          <div slot="factoryParameterDetail" slot-scope="{ row }">
            <div v-if="type === 'new'">
              <el-input v-model="row.factoryParameterDetail" />
            </div>
            <span v-else>{{ row.factoryParameterDetail }}</span>
          </div>

          <div slot="action" slot-scope="{ row, $index }">
            <template v-if="type !== 'new'">
              <el-button type="text" @click="modifyInfo(row)">修改</el-button>
              <el-button type="text" @click="delInfo(row)">删除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <MDialog v-model="visible" :title="messages[currentType]" width="500px" @onOk="submitForms">
      <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="factoryFormList" />
    </MDialog>
    <SelectFactoryParameter
      ref="selectFactoryParameter"
      :visible.sync="showTechnicalParameter"
      @submitForm="selectTechnicalParameters"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { factoryColumns } from './columns'
import { factoryFormList } from './form-list'
import api from '@/api/xiewei/facility/factory'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import SelectFactoryParameter from '@/views/facility/account/components/SelectFactoryParameter/SelectFactoryParameter'

export default {
  name: 'FactoryParameter',
  components: { SelectFactoryParameter },
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => ([])
    },
    facilityId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      api,
      showTechnicalParameter: false,
      facilityFactoryParameterMappingList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      factoryColumns,
      factoryFormList,
      tableData: [],
      formData: {},
      paramsVisible: false,
      visible: false,
      messages: {
        add: '新增参数',
        modify: '修改参数'
      }
    }
  },
  computed: {
    getColumn() {
      const data = this._.cloneDeep(this.factoryColumns)
      data.pop()
      return this.type === 'new' ? data : this.factoryColumns
    },
    tabHeight() {
      return this.$store.state.app.contentHeight - 256 - 46 - 22 - 148
    }
  },
  watch: {
    list(val) {
      this.facilityFactoryParameterMappingList = val || []
    }
  },
  mounted() {
    this.facilityFactoryParameterMappingList = this.list
  },
  methods: {
    selectInfo() {
      this.showTechnicalParameter = true
    },
    async selectTechnicalParameters(val) {
      this.facilityFactoryParameterMappingList =
        val[0].parameterList ? val[0].parameterList.map(item => {
          return {
            factoryParameterName: item.paramName,
            factoryParameterDetail: item.paramDetail
          }
        }) : []
      this.$emit('factoryInfoParams', this.facilityFactoryParameterMappingList)
      this.showTechnicalParameter = false
    },
    modifyInfo(row) {
      this.currentType = 'modify'
      this.formData = this._.cloneDeep(row)
      this.visible = true
    },
    delInfos(index) {
      this.facilityMappingParametersList.splice(index, 1)
    },
    async delInfo(node) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delFacilityFactoryParameter({ id: node.id })
        if (res) {
          this.$message.success('删除成功')
          this.$emit('refrash')
        }
      })
    },
    submitForms(callback) {
      const that = this
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api[`${this.currentType}FacilityFactoryParameter`](
            this.currentType !== 'modify' ? Object.assign({}, {
              facilityId: this.facilityId
            }, this.createSetData(form)) : this.createResetData(form)
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            this.$emit('refrash')
            this.visible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      return {
        factoryParameterName: raw.factoryParameterName,
        factoryParameterDetail: raw.factoryParameterDetail
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id,
        facilityId: raw.facilityId
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    .el-form-item__content {
      width: 300px;
    }
    .el-form-item {
      margin-bottom: 20px!important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
</style>
