<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">质保信息</div>
      <div class="card-bots">
        <div style="margin-bottom: 10px">
          <el-button size="small" type="primary" @click="addInfo">新增</el-button>
        </div>
        <MTable ref="planTable" :show-page="false" :height="tabHeight" :columns="columns" :data="facilityWarrantyList">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="serviceContent" slot-scope="{ row }">{{ row.serviceContent }}</div>
          <div slot="laborCost" slot-scope="{ row }">{{ quanlityInfoFeeType[row.laborCost].name }}</div>
          <div slot="sparePartsCost" slot-scope="{ row }">{{ quanlityInfoFeeType[row.sparePartsCost].name }}</div>
          <div slot="consumptionOfProductCost" slot-scope="{ row }">{{ quanlityInfoFeeType[row.consumptionOfProductCost].name }}</div>
          <div slot="type" slot-scope="{ row }">{{ quanlityInfoType[row.type].name }}</div>
          <div slot="guaranteePeriod" slot-scope="{ row }">{{ fixData(row.guaranteePeriodStart) }} - {{ fixData(row.guaranteePeriodEnd) }}</div>
          <div v-if="row.isUpdate === 1" slot="action" slot-scope="{ row, $index }">
            <el-button v-if="type === 'new'" type="text" @click="delInfos($index)">移除</el-button>
            <template v-else>
              <el-button type="text" @click="modifyInfo(row)">修改</el-button>
              <el-button type="text" @click="delInfo(row)">删除</el-button>
            </template>
          </div>
          <div v-else slot="action">{{ '-' }}</div>
        </MTable>
      </div>
    </div>
    <MDialog v-model="visible" :title="messages[currentType]" width="500px" @onOk="submitForm">
      <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="formList" />
    </MDialog>
  </div>
</template>

<script>
import { colorList } from '@/views/statistics-board/board-detail/components/base-setting'
import dayjs from 'dayjs'
import { columns } from './columns'
import { formList } from './form-list'
import api from '@/api/xiewei/facility/facility'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { quanlityInfoServeType, quanlityInfoType, quanlityInfoFeeType } from '@/config/options.sales'

export default {
  name: 'QuanlityInfo',
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => []
    },
    facilityId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      api,
      quanlityInfoFeeType,
      quanlityInfoType,
      quanlityInfoServeType,
      facilityWarrantyList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      columns,
      formList,
      tableData: [],
      formData: {},
      paramsVisible: false,
      visible: false,
      messages: {
        add: '新增质保信息',
        modify: '修改质保信息'
      }
    }
  },
  watch: {
    list(val) {
      this.facilityWarrantyList = val || []
    }
  },
  computed: {
    tabHeight() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 184
    }
  },
  mounted() {
    this.facilityWarrantyList = this.list
  },
  methods: {
    fixData(val) {
      return val.replace(/-/g, '.')
    },
    addInfo() {
      this.currentType = 'add'
      this.formData = {}
      this.visible = true
    },
    modifyInfo(row) {
      this.currentType = 'modify'
      this.formData = this._.cloneDeep(row)
      const list = this.formData.serviceContent.split('、')
      this.formData.serviceContent = this.quanlityInfoServeType.filter(item => list.includes(item.name)).map(item => item.id)
      this.formData.guaranteePeriod = [this.formData.guaranteePeriodStart, this.formData.guaranteePeriodEnd]
      this.visible = true
    },
    delInfos(index) {
      this.facilityWarrantyList.splice(index, 1)
      this.$emit('facilityInfo', this.facilityWarrantyList)
    },
    async delInfo(node) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delFacilityWarranty({ id: node.id })
        if (res) {
          this.$message.success('删除成功')
          this.$emit('refrash')
        }
      })
    },
    submitForm(callback) {
      const that = this
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async valid => {
        if (valid) {
          if (this.type === 'new') {
            this.facilityWarrantyList.push(this.createSetData(form))
            that.$emit('facilityInfo', this.facilityWarrantyList)
            this.visible = false
            callback(true)
            return false
          }

          const res = await this.api[`${this.currentType}FacilityWarranty`](
            this.currentType !== 'modify'
              ? Object.assign(
                {},
                {
                  facilityId: this.facilityId
                },
                this.createSetData(form)
              )
              : this.createResetData(form)
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            this.$emit('refrash')
            this.visible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      const data = this.quanlityInfoServeType
        .filter(item => raw.serviceContent.includes(item.id))
        .map(item => item.name)
        .join('、')
      return {
        serviceContent: data,
        laborCost: raw.laborCost,
        sparePartsCost: raw.sparePartsCost,
        consumptionOfProductCost: raw.consumptionOfProductCost,
        type: raw.type,
        guaranteePeriodStart: raw.guaranteePeriod[0],
        guaranteePeriodEnd: raw.guaranteePeriod[1]
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id,
        facilityId: raw.facilityId
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    .el-form-item__content {
      width: 300px;
    }
    .el-form-item {
      margin-bottom: 20px !important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
</style>
