<template>
  <MDialog v-model="modalShow" width="1000px" :append-to-body="true" title="套用模板" @onOk="submitForm" @fullScreen="fullScreenChange">
    <div class="materials-info">
      <MTable
        ref="mTable"
        :highlight-current-row="single"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
        :columns-setting="false"
        @selection-change="selectionChange"
        @current-change="selectionChange"
        @row-click="handleRowClick"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      </MTable>
    </div>
  </MDialog>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader'
import { columns } from './columns'
import { getUUid } from '@/utils'
import api from '@/api/xiewei/facility/factory'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'SelectFactoryParameter',
  components: { FunctionHeader },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modalShow: false,
      split: {
        offset: 20
      },
      columns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      fullScreen: false,
      selectionData: []
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
    },
    modalShow(val) {
      if (!val) {
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    }

  },
  mounted() {
    this.modalShow = this.visible
  },
  methods: {
    updateTable() {
      this.$refs.mTable.setTableData()
    },
    // 选中行复选框也会选中
    handleRowClick(row) {
      if (!row.selectable) {
        this.$refs.mTable.tableRefs().toggleRowSelection(row)
      }
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    selectionChange(data) {
      this.selectionData = []
      this.selectionData.push(data[data.length - 1])
    },
    // 获取数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const params = {
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search]))
      }
      const res = await api.getFactoryParameterListPage(params)
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条数据')
        callback()
        return
      }
      this.$emit('submitForm', this.selectionData)
      // callback()
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  padding: 10px;
}
.materials-head{
  padding:0 0 10px 0;
}
</style>
