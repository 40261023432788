<template>
  <big-screen-container ref="container">
    <dv-loading v-if="loading" />
    <template v-else>
      <div class="top-container">
        <div class="title-wrapper">
          <span class="title">{{ groupName }}车间看板</span>
          <img src="https://oss.mubyte.cn/static/%E5%A4%B4%E9%83%A8%402x.png" alt="">
        </div>
        <div class="info">
          <div />
          <div class="time-container">
            <img src="https://oss.mubyte.cn/static/%E7%BC%96%E7%BB%84%402x.png" alt="">
            <div class="date-time">
              <div class="date">{{ currentDate }}（{{ currentWeek }}）</div>
              <div class="time">{{ currentTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container">
        <div class="left" style="width: 30%">
          <div class="screen-card-list">
            <div v-for="(item, index) in cardList" :key="index" class="screen-card-wrapper">
              <div class="left">
                <img :src="item.src" alt="">
                <span>{{ item.name }}</span>
              </div>
              <div class="right">
                <dv-digital-flop :config="getDigitalConfig(singleData[item.key])" style="width: 100%" />
              </div>
            </div>
          </div>
          <div class="info" style="padding: 0 1%;display: inline-block">
            <div class="screen-card-title" style="padding: 10px 20px 10px 20px;margin-bottom: 10px">返工信息</div>
            <scroll-board :config="scrollBoardConfig1" :style="{width:'100%', height: table2Height + 'px'}">
              <template slot="currentProcedure" slot-scope="{ index }">
                {{ tableData1[index].produceWorkOrderTaskList ? tableData1[index].produceWorkOrderTaskList[0].procedureName : '' }}
              </template>
            </scroll-board>
          </div>
        </div>
        <div class="right">
          <scroll-board :config="scrollBoardConfig2" :style="{width:'100%', height: tableHeight + 'px'}">
            <template slot="currentProcedure" slot-scope="{ index }">
              {{ tableData2[index].produceWorkOrderTaskList ? tableData2[index].produceWorkOrderTaskList[0].procedureName : '' }}
            </template>
            <template slot="priority" slot-scope="{ index }">
              <div
                :style="{ color: priorityStatus[tableData2[index].priority].color ? priorityStatus[tableData2[index].priority].color === '#4A4F76' ? '#fff' : priorityStatus[tableData2[index].priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[tableData2[index].priority].color ? priorityStatus[tableData2[index].priority].color : 'rgb(74, 79, 118)'}20`}"
                class="tag-priority"
              >
                {{ priorityStatus[tableData2[index].priority].name ? priorityStatus[tableData2[index].priority].name : '普通' }}
              </div>
            </template>
            <template slot="planStartTime" slot-scope="{ index }">
              {{ tableData2[index].producePlan ? tableData2[index].producePlan.planStartTime : '' }}
            </template>
            <template slot="planEndTime" slot-scope="{ index }">
              <div v-html="getPlanEdnTime('', tableData2[index].producePlan ? tableData2[index].producePlan.planEndTime : '')" />
            </template>
            <template slot="procedureRatio" slot-scope="{ index }">
              <el-progress :text-inside="true" :stroke-width="15" :percentage="fixNumber2(tableData2[index].procedureRatio)" />
            </template>
          </scroll-board>
        </div>
      </div>
    </template>
  </big-screen-container>
</template>

<script>
import BigScreenContainer from '@/components/BigScreenContainer/BigScreenContainer'
import dayjs from 'dayjs'
const dayPic = 'https://oss.mubyte.cn/static/%E6%97%A5.png'
const weekPic = 'https://oss.mubyte.cn/static/%E5%91%A8.png'
const monthPic = 'https://oss.mubyte.cn/static/%E6%9C%88.png'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import api from '@/api/statistics-board/all-statistics'
import procedureApi from '@/api/information/production/procedure'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'Board',
  components: { BigScreenContainer, scrollBoard },
  data() {
    return {
      loading: false,
      currentDate: dayjs().format('YYYY-MM-DD'),
      currentTime: dayjs().format('HH:mm:ss'),
      weeks: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      cardList: [{
        name: '当日产量',
        src: dayPic,
        key: 'day'
      }, {
        name: '当周产量',
        src: weekPic,
        key: 'week'
      }, {
        name: '当月产量',
        src: monthPic,
        key: 'month'
      }, {
        name: '当日报废数量',
        src: dayPic,
        key: 'scarp'
      }, {
        name: '当日返修数量',
        src: dayPic,
        key: 'return'
      }],
      scrollBoardConfig1: {
        carousel: 'page',
        headerHeight: 50,
        hoverPause: false,
        headerBGC: 'linear-gradient(180deg, #5160FF 0%, #2336FF 100%)',
        oddRowBGC: '#082182',
        evenRowBGC: '#0E3973',
        waitTime: 10000,
        header: ['生产订单号', '物料规格', '当前所处工序', '返工数量'],
        data: []
      },
      scrollBoardConfig2: {
        carousel: 'page',
        headerHeight: 50,
        hoverPause: false,
        headerBGC: 'linear-gradient(180deg, #5160FF 0%, #2336FF 100%)',
        oddRowBGC: '#082182',
        evenRowBGC: '#0E3973',
        waitTime: 10000,
        header: ['生产订单号', '物料规格', '优先级', '工单数量', '当前所处工序', '工序进程', '计划开始时间', '距离计划结束时间还剩'],
        data: []
      },
      table2Height: 100,
      tableHeight: 100,
      currentWeek: '',
      singleData: {
        day: 0,
        month: 0,
        week: 0,
        scarp: 0,
        return: 0
      },
      tableData1: [],
      tableData2: [],
      procedureList: [],
      groupName: ''
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  mounted() {
    this.fullScreen()
    this.$nextTick(this.getHeight)
    window.onresize = this.getHeight
    this.getProcedureGroupDetail()
    this.getScarpData()
    this.getReturnData()
    this.getSingleData()
    // 普通工单
    this.getTableData('production/kiki/private/produceWorkOrder/getProduceWorkOrderList', {
      isRepair: 0,
      searchVal: Encrypt(JSON.stringify(
        [{
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '0^1',
          operator: 'in'
        }]
      )),
      sort: '+priority'
    }, {
      key: 'scrollBoardConfig2',
      key2: 'tableData2',
      columns: ['生产订单号', '物料规格', '优先级', '工单数量', '当前所处工序', '工序进程', '计划开始时间', '距离计划结束时间还剩'],
      columnsKey: ['produceOrderNumber', 'materialsSpecifications', 'slot:priority', 'quantity', 'slot:currentProcedure', 'slot:procedureRatio', 'slot:planStartTime', 'slot:planEndTime'],
      columnWidth: [210, 150, 100, 100]
    })
    // 返修工单
    this.getTableData('displayBoard/private/displayBoard/getProduceWorkOrderList', {
      isRepair: 1
    }, {
      key: 'scrollBoardConfig1',
      key2: 'tableData1',
      columns: ['生产订单号', '物料规格', '当前所处工序', '返工数量'],
      columnsKey: ['produceOrderNumber', 'materialsSpecifications', 'slot:currentProcedure', 'quantity'],
      columnWidth: [210, 150, 150, 100]
    })
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.launchFullscreen)
  },
  methods: {
    async getProcedureGroupDetail() {
      const res = await procedureApi.getProcedureGroupList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'id',
            fieldType: 'number',
            fieldValue: +this.$route.params.id,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.groupName = res[0].name
      }
    },
    fixNumber2(number) {
      const newNumber = Number(number)
      return Number(newNumber.toFixed(2))
    },
    fullScreen() {
      document.body.addEventListener('click', this.launchFullscreen)
      this.currentWeek = this.weeks[dayjs().day()]
      setInterval(() => {
        this.currentTime = dayjs().format('HH:mm:ss')
        this.currentDate = dayjs().format('YYYY-MM-DD')
        this.currentWeek = this.weeks[dayjs().day()]
        if (this.$refs.line) {
          this.$refs.line.resize()
        }
        if (this.$refs.histogram) {
          this.$refs.line.resize()
        }
      }, 1000)
    },
    getDigitalConfig(number) {
      return {
        number: [Number(number)],
        content: '{nt}',
        style: {
          fontSize: 80,
          color: '#fff'
        }
      }
    },
    async getSingleData() {
      const res = await api.getTableData('statistics/private/statistics/getProcedureGroupReportQuantityTotal', {
        // procedureIds: this.procedureList.map(item => item.id).join('^'),
        procedureGroupIds: this.$route.params.id,
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        if (!res[0]) return
        let day = 0
        let week = 0
        let month = 0
        res.forEach(item => {
          day += Number(item.dayReportQuantity)
          month += Number(item.monthReportQuantity)
          week += Number(item.weekReportQuantity)
        })
        Object.assign(this.singleData, {
          day,
          week,
          month
        })
      }
    },
    async getScarpData() {
      const res = await api.getTableData('statistics/private/statistics/getScrapCount', {
        // procedureIds: this.procedureList.map(item => item.id).join('^')
        procedureGroupIds: this.$route.params.id
      })
      if (res) {
        this.singleData.scarp = res
      }
    },
    async getReturnData() {
      const res = await api.getTableData('statistics/private/statistics/getRepairCount', {
        // procedureIds: this.procedureList.map(item => item.id).join('^')
        procedureGroupIds: this.$route.params.id
      })
      if (res) {
        this.singleData.return = res
      }
    },
    async getTableData(url, condition, tableInfo) {
      this.loading = true
      this.getHeight()
      const { columns, columnsKey, columnWidth, key, key2 } = tableInfo
      const res = await api.getTableData(url, {
        ...condition,

        currentProcedureGroupIds: this.$route.params.id,
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        this[key2] = res[0] && res[0].totalList ? res[0].totalList : res
        const delayIndex = []
        this[key2].forEach((item, index) => {
          if (item.isDelay) {
            delayIndex.push(index)
          }
        })
        this[key] = {
          carousel: 'page',
          headerHeight: 50,
          hoverPause: false,
          headerBGC: 'linear-gradient(180deg, #5160FF 0%, #2336FF 100%)',
          oddRowBGC: '#082182',
          evenRowBGC: '#0E3973',
          waitTime: 10000,
          header: columns,
          rowNum: key === 'scrollBoardConfig1' ? this[key2].length < 4 ? this[key2].length : 4 : this[key2].length < 8 ? this[key2].length : 8,
          columnWidth,
          delayIndex,
          align: columns.map(item => 'center'),
          data: this[key2].map((item, index) => (columnsKey.map((c, i) => {
            if (c.indexOf('slot:') !== -1) {
              const slots = c.split(':')
              return `${slots[1]}${slots[2] || ''}|${index}`
            }
            if (c === 'index') {
              return index + 1
            }
            return item[c]
          })))
        }
        this.loading = false
      }
    },
    getPlanEdnTime(start, end) {
      if (!end) return ''
      const msTime = {}
      const startTime = start ? new Date(start).getTime() : new Date().getTime()
      const timeDistance = startTime - new Date(end).getTime()
      msTime.day = (timeDistance / 86400000).toFixed(1)
      return Number(msTime.day) >= 0 ? `<span>${msTime.day}天</span>` : `<span style="color: red">已延期${-Number(msTime.day)}天</span>`
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.table2Height = (window.innerHeight * 0.28) / scale
      this.tableHeight = (window.innerHeight * 0.855) / scale
    },
    launchFullscreen() {
      this.getHeight()
      const element = document.documentElement
      if (element.requestFullscreencreen) {
        element.requestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.top-container {
  color: #FFFFFF;
  .title-wrapper {
    text-align: center;
    position: relative;
    .title {
      font-size: 33px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 80%;
      margin: 0 auto;
    }
  }
  .info {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5%;
    position: relative;
    top: -20px;
    .time-container {
      position: relative;
      display: inline-block;
      width: 16%;
      top: 2px;
      img {
        width: 100%;
      }
      .date-time {
        position: absolute;
        top: 55%;
        left: 60%;
        transform: translate(-50%, -50%);
        color: #00B7EF;
        .date {
          font-size: 18px;
          word-wrap: normal;
          white-space:nowrap;
        }
        .time {
          font-size: 27px;
          font-weight: 700;
        }
      }
    }
  }
}
.info {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .screen-card-title {
    font-size: 25px;
    color: #FFFFFF;
    padding: 10px 20px 10px 40px;
    letter-spacing: 5px;
    word-break: keep-all;
    background: linear-gradient(90deg, #2337FF 0%, rgba(35, 54, 255, 0) 100%);
  }
}
.screen-card-list {
  color: #FFFFFF;
  padding: 0 1%;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .screen-card-wrapper {
    width: 100%;
    display: inline-flex;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .left {
      box-sizing: content-box;
      width: 34%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      background: #34489D;
      img {
        width: 28%;
        padding-bottom: 10px;
      }
      span {
        //padding-top: 15px;
        font-size: 25px;
        word-wrap: normal;
        white-space:nowrap;
      }
    }
    .right {
      width: 66%;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #0D2485;
    }
  }
  ::v-deep {
    .dv-digital-flop {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 5px;
      height: 100px;
    }
  }
}
.content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .right {
    padding-right: 5px;
    flex: 1;
  }
}
::v-deep .dv-scroll-board div {
  font-size: 18px !important;
}
::v-deep .dv-scroll-board .el-progress-bar__innerText {
  font-size: 16px !important;
}
</style>
