
export const columns = [
  {
    prop: 'code',
    label: '计划编号',
    sortable: false,
    hiddenSearch: true,
    width: 140
  },
  {
    prop: 'name',
    label: '计划名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'customerName',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'level',
    label: '保养级别',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'cycleType',
    label: '执行逻辑',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    slotName: 'cycleType'
  },
  {
    prop: 'lifeCycle',
    label: '周期/天',
    sortable: false,
    hiddenSearch: true,
    slotName: 'lifeCycle'
  },
  {
    prop: 'toleranceRange',
    label: '容差范围/天',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'startTime',
    label: '起始日期',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'reminderTime',
    label: '提醒提前期',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operatorName',
    label: '执行人'
  },
  {
    prop: 'salesOrderNumber',
    label: '销售订单号',
    sortable: false,
    hiddenSearch: true
  },
]

