<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">备件更换</div>
      <div class="card-bots">
        <div style="margin-bottom: 10px;">
          <el-button size="small" type="primary" @click="addInfo">添加</el-button>
        </div>
        <MTable
          ref="plansTable"
          :show-page="false"
          :height="isTitle ? 300 : heights"
          :columns="techColumns"
          :data="materialsMappingList"
          :only-key="'uuid'"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="describeInfo" slot-scope="{ row }">
            <div v-if="type === 'new'">
              <el-input v-model="row.describeInfo" placeholder="请输入用途描述" />
            </div>
            <span v-else>{{ row.describeInfo }}</span>
          </div>
          <div slot="quantity" slot-scope="{ row }">
            <div v-if="type !== 'new'">{{ row.quantity }}</div>
            <div v-else style="display: flex;align-items: center;justify-content: flex-start;">
              <span style="color: #f00;width: 10px;">*</span>
              <el-input-number v-model="row.quantity" controls-position="right" :step="1" :min="0" style="width: 100%" />
            </div>
          </div>
          <div slot="action" slot-scope="{ row, $index }">
            <template v-if="type === 'new'">
              <el-button type="text" @click="delInfos($index)">删除</el-button>
            </template>
            <template v-else>
              <el-button type="text" @click="modifyInfo(row)">修改</el-button>
              <el-button type="text" @click="delInfo(row)">删除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <select-materials
      ref="selectMaterials"
      :visible.sync="showMaterials"
      :is-add="true"
      :title="'添加物料'"
      :single="type !== 'new'"
      @submitForm="submitMaterials"
      @addMaterial="addMaterial"
    />
    <!-- 新增物料 -->
    <edit-materials ref="editMaterials" @onSuccess="onSuccess" />
    <MDialog v-model="visible" :title="messages[currentType]" width="500px" @onOk="submitForm">
      <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="techFormList" />
    </MDialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { techColumns } from './columns'
import { techFormList } from './form-list'
import api from '@/api/xiewei/facility/maintenance-material'
import { getUUid } from '@/utils'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import EditMaterials from '@/views/information/materials/components/edit-materials'

export default {
  name: 'MaterialExchange',
  components: { EditMaterials, SelectMaterials },
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => ([])
    },
    facilityPlanMaintenanceTemplateId: {
      type: Number,
      default: undefined
    },
    facilityMaintenancePlanId: {
      type: Number,
      default: undefined
    },
    isTemplate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      api,
      materialsMappingList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      techColumns,
      techFormList,
      tableData: [],
      formData: {},
      showMaterials: false,
      messages: {
        add: '添加',
        modify: '修改'
      },
      visible: false
    }
  },
  computed: {
    heights() {
      return this.$store.state.app.contentHeight - 256 - 250 - 50
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.materialsMappingList = val
      }
    }
  },
  mounted() {
    this.materialsMappingList = this.list
  },
  methods: {
    onSuccess() {
      this.$refs.selectMaterials.updateTable()
    },
    async delInfo(node) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let res
        if (this.isTemplate) {
          res = await api.delPlanMaintenanceTemplateMaterials({ id: node.id })
        } else {
          res = await api.delPlanMaintenanceMaterials({ id: node.id })
        }
        if (res) {
          this.$message.success('删除成功')
          this.$emit('refrash')
        }
      })
    },
    modifyInfo(row) {
      this.currentType = 'modify'
      this.formData = this._.cloneDeep(row)
      this.visible = true
    },
    async submitMaterials(val) {
      const data = val.map(item => {
        return {
          ...item,
          materialsId: item.id,
          quantity: undefined,
          describeInfo: '',
          uuid: getUUid()
        }
      })
      if (this.type === 'new') {
        this.materialsMappingList = [...this.materialsMappingList, ...data]
        this.$emit('materialsInfo', this.materialsMappingList)
      } else {
        this.selectSingle = data
        this.formData = {}
        this.currentType = 'add'
        this.visible = true
      }
      this.showMaterials = false
    },
    addMaterial() {
      this.$refs.editMaterials.add()
    },
    addInfo() {
      this.showMaterials = true
    },
    delInfos(index) {
      this.materialsMappingList.splice(index, 1)
    },
    async submitForm(callback) {
      const that = this
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          if (form.quantity < 0 || form.quantity === 0) {
            callback()
            return this.$message.error('使用数量必须大于0')
          }
          if (this.currentType === 'modify') {
            await this.modifyData(form)
          } else {
            await this.saveData(form)
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    async modifyData(form) {
      let res
      if (this.isTemplate) {
        res = await this.api[`modifyPlanMaintenanceTemplateMaterials`](
          Object.assign({}, {
            facilityPlanMaintenanceTemplateId: this.facilityPlanMaintenanceTemplateId
          }, this.createResetData(form))
        )
      } else {
        res = await this.api[`modifyPlanMaintenanceMaterials`](
          Object.assign({}, {
            facilityMaintenancePlanId: this.facilityMaintenancePlanId
          }, this.createResetData(form))
        )
      }
      if (res) {
        this.$message.success(this.getSuccessMessage())
        this.$emit('refrash')
        this.visible = false
      }
    },
    async saveData(form) {
      console.log('baocun=>', form)
      let res
      const list = [{
        materialsId: this.selectSingle[0].id,
        materialsCode: this.selectSingle[0].code,
        materialsName: this.selectSingle[0].name,
        materialsTypeName: this.selectSingle[0].materialsTypeName,
        mainUnitName: this.selectSingle[0].mainUnitName,
        quantity: form.quantity,
        describeInfo: form.describeInfo
      }]
      if (this.isTemplate) {
        res = await this.api[`addBatchPlanMaintenanceTemplateMaterials`](
          {
            planId: this.facilityPlanMaintenanceTemplateId,
            facilityPlanMaintenanceTemplateMaterialsMappingList: list
          }
        )
      } else {
        res = await this.api[`addBatchPlanMaintenanceMaterials`](
          {
            planId: this.facilityMaintenancePlanId,
            facilityMaintenancePlanMaterialsMappingList: list
          }
        )
      }

      if (res) {
        this.$message.success(this.getSuccessMessage())
        this.$emit('refrash')
        this.visible = false
      }
    },
    createSetData(raw) {
      return {
        materialsId: raw.materialsId,
        materialsCode: raw.materialsCode,
        materialsName: raw.materialsName,
        materialsTypeName: raw.materialsTypeName,
        mainUnitName: raw.mainUnitName,
        quantity: raw.quantity,
        describeInfo: raw.describeInfo
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    // .el-form-item__content {
    //   width: 300px;
    // }
    .el-form-item {
      margin-bottom: 20px!important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
</style>
