export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 50
  },
  {
    prop: 'templateName',
    label: '配置参数模板名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentClassName',
    label: '适用设备分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'customerNames',
    label: '适用客户',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
