<template>
  <div class="inventory-wrap common-tab">
    <el-radio-group v-model="nowRadio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="toMeHandle">
        待我处理<span v-if="total1 > 0">（{{ total1 }}）</span>
      </el-radio-button>
      <el-radio-button label="hadHandle">我已处理</el-radio-button>
    </el-radio-group>
    <div v-show="nowRadio === 'toMeHandle'" style="margin-top: 7px">
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <NewPageOpen slot="code" slot-scope="{ row }" :path="getDetailPath(row)" :text="row.code"/>
      </MTable>
    </div>
    <div v-show="nowRadio === 'hadHandle'" style="margin-top: 7px">
      <MTable ref="mTable2" :columns="columns" :height="height" :set-data-method="getTableData2">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <NewPageOpen slot="code" slot-scope="{ row }" :path="getDetailPath2(row)" :text="row.code"/>
      </MTable>
    </div>
  </div>
</template>

<script>

import NewPageOpen from '@/components/NewPageOpen/NewPageOpen.vue'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import api from '@/api/quality/expection-task'

export default {
  name: 'WarehouseAssignList',
  components: { NewPageOpen },
  filters: {
    // receiptType(val) {
    //   if (val || val=== 0) return docType.find(item => item.id === val).name
    // },
    // receiptStatus(val) {
    //   return materialsRequisitionStatus.find(item => item.id === val).name
    // }
  },
  data() {
    return {
      total1: 0,
      nowRadio: 'toMeHandle',
      columns: [
        {
          prop: 'index',
          label: '序号',
          slotName: 'index',
          hiddenSearch: true,
          fixed: 'left',
          width: 50
        },
        {
          prop: 'code',
          label: '异常编号',
          sortable: false,
          hiddenSearch: true,
          slotName: 'code',
          minWidth: 160
        },
        {
          prop: 'abnormalTypeName',
          label: '异常类型',
          sortable: false,
          hiddenSearch: true,
          minWidth: 160
        },
        {
          prop: 'emergencyDegreeName',
          label: '紧急程度',
          hiddenSearch: true,
          sortable: false,
          width: 160
        },
        {
          prop: 'abnormalRemark',
          label: '呼叫内容',
          hiddenSearch: true,
          sortable: false,
          minWidth: 160
        },
        {
          prop: 'taskName',
          label: '当前节点',
          hiddenSearch: true,
          sortable: false,
          minWidth: 150
        },
        {
          prop: 'submitUserName',
          label: '创建人',
          hiddenSearch: true,
          sortable: false,
          minWidth: 150
        },
        {
          prop: 'submitTime',
          label: '创建时间',
          hiddenSearch: true,
          sortable: false,
          minWidth: 150
        }
      ],
      emergencyDegreeOptions: [
        { label: '紧急', value: 0, color: '#E02020' },
        { label: '一般', value: 1, color: '#FA6400' },
        { label: '低', value: 2, color: '#797B8F' }
      ]

    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 120 - 95 - 20
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {

  },
  methods: {
    radioChange(s) {
      s === 'toMeHandle' ? this.getTableData() : this.getTableData2()
    },
    getDetailPath(item) {
      item.editFieldMap ? '' : item.editFieldMap = {}
      const a = item.editFieldMap
      const canEditColumns = []
      const canEditColumns_mastHaveValue = []
      for (let key in a) {
        if (a.hasOwnProperty(key)) {
          canEditColumns.push(key)
          if (a[key] == true) {
            canEditColumns_mastHaveValue.push(key)
          }
        }
      }
      item.buttonNameList ? '' : item.buttonNameList = []
      const obj = {
        'editFieldList': canEditColumns,
        'mastHaveValue': canEditColumns_mastHaveValue,
        'buttonNameList': item.buttonNameList,
        'id': item.businessObject.id,
        'abnormalTypeId': item.businessObject.abnormalTypeId,
        'taskId': item.taskId
      }
      return {
        name: 'taskDetail',
        query: {
          id: item.businessObject.id,
          needInfo: encodeURIComponent(JSON.stringify(obj))
        }
      }
    },
    getDetailPath2(item) {
      item.editFieldMap ? '' : item.editFieldMap = {}
      const a = item.editFieldMap
      const canEditColumns = []
      const canEditColumns_mastHaveValue = []
      for (let key in a) {
        if (a.hasOwnProperty(key)) {
          canEditColumns.push(key)
          if (a[key] == true) {
            canEditColumns_mastHaveValue.push(key)
          }
        }
      }
      item.buttonNameList ? '' : item.buttonNameList = []
      const obj = {
        'editFieldList': canEditColumns,
        'mastHaveValue': canEditColumns_mastHaveValue,
        'buttonNameList': item.buttonNameList,
        'id': item.businessObject.id,
        'abnormalTypeId': item.businessObject.abnormalTypeId,
        'taskId': item.taskId,
        'showFlag':1,
      }
      return {
        name: 'taskDetail',
        query: {
          id: item.businessObject.id,
          needInfo: encodeURIComponent(JSON.stringify(obj))
        }
      }
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      //待我处理
      const res = await api.getMyTaskListPage({
        page: page.currentPage,
        limit: page.pageSize,
        processDefinitionKey: 'abnormal'
      })
      if (res) {
        res.records.forEach(item => {
          item.code = item.businessObject.code
          item.abnormalTypeName = item.businessObject.abnormalTypeName
          item.emergencyDegreeName = this.emergencyDegreeOptions[item.businessObject.emergencyDegree].label
          item.abnormalRemark = item.businessObject.abnormalRemark
          item.submitUserName = item.businessObject.submitUserName
          item.submitTime = item.businessObject.submitTime
        })
        this.total1 = res.total
        callback({
          total: res.total,
          content: res.records
        })
      } else {
        callback()
      }
    },
    async getTableData2(condition, callback) {
      const { page, search, order } = condition
      //我已处理
      const res = await api.getMyFinishTaskListPage({
        page: page.currentPage,
        limit: page.pageSize,
        processDefinitionKey: 'abnormal'
      })
      if (res) {
        res.records.forEach(item => {
          item.code = item.businessObject.code
          item.abnormalTypeName = item.businessObject.abnormalTypeName
          item.emergencyDegreeName = this.emergencyDegreeOptions[item.businessObject.emergencyDegree].label
          item.abnormalRemark = item.businessObject.abnormalRemark
          item.submitUserName = item.businessObject.submitUserName
          item.submitTime = item.businessObject.submitTime
        })
        callback({
          total: res.total,
          content: res.records
        })
      } else {
        callback()
      }
    }

  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-date-editor .el-range__close-icon {
    line-height: 26px;
  }
}

.inventory-wrap {
  width: 100%;
  padding: 10px;

  .bottom {
    width: 100%;
    height: 100%;
    padding-top: 10px;

    .materials-info {
      padding: 10px;
    }
  }

  a {
    color: rgb(96, 127, 255);
  }
}

div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #DF6A6A;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #DF6A6A;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #DF6A6A;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 1;
}

</style>
