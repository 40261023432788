<template>
  <div class="scrap-wrap">
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList" @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入计划单号/设备名称/编号"
        :tags="functionHeader.tags"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export = "!selectData.length ? true : false"
        @search="$refs.mTable.setTableData(true)"
      >
        <el-button v-if="permission('PassCheckItem') && selectData.length" size="small" type="primary" style="height: 32px;padding: 10px 20px" @click="passInspectionBills">批量跳过</el-button>
        <el-button v-if="permission('AbolishCheckItem') && selectData.length" size="small" type="primary" style="height: 32px;padding: 10px 20px" @click="abolishInspectionBills">批量作废</el-button>
        <el-button v-if="permission('Export') && selectData.length" size="small" type="primary" style="height: 32px;padding: 10px 20px" @click="exportInspectionBills">批量导出</el-button>
        <span v-if="permission('Export') && selectData.length" style="line-height:32px;margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
        <el-button v-if="permission('Export') && selectData.length" type="text" size="small" @click="resetSelectData">清空</el-button>

      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <NewPageOpen v-if="isShowChild" slot="cycleCheckTaskNumber" slot-scope="{ row }" :path="{ name: 'inspectionBillsDetail', query: { id: row.id, type: 'view' }}" :text="row.cycleCheckTaskNumber" />
        <div slot="status" slot-scope="{ row }" :style="{ color: colors[row.status] }">{{ row.status | inspectionStatusName }}</div>
        <div slot="result" slot-scope="{ row }" :style="{ color: row.result === 0 ? '#FA6400' : '#00AB29' }">{{ +row.result === 0 ? '异常' : '正常' }}</div>

        <div v-if="row.checkSpendTime" slot="checkSpendTime" slot-scope="{ row }">{{ Math.ceil(row.checkSpendTime / 60) }}</div>
        <el-button slot="pointCheckItem" slot-scope="{ row }" type="text" @click="viewCheckItem(row)">查看</el-button>
      </MTable>

      <MDialog v-model="visible" width="400px" title="跳过原因" @onOk="submitPassInspectionBills">
        <MFormBuilder ref="formBuild" :form-list="reasonFormList" />
      </MDialog>
      <TableDialog title="查看巡检项" :show.sync="checkItemVisible" :columns="insColumns.PointCheckItemView()" :table-data="checkItemList" />
    </div>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import formList from './form-list'
import { columns } from './columns'
import insColumns from '@/views/equipment/project-setting/columns'
import api from '@/api/equipment/inspection-bills'
import conventionMixin from '@/mixin/conventional-page'
import TableDialog from '@/components/TableDialog/TableDialog'
import settingMinix from '@/views/equipment/project-setting/setting.minix'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import { inspectionStatus } from '@/config/options.config'
import equipmentClassApi from '@/api/information/equipment-class'
import { setTreeData, getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import fixCommonPage from '@/mixin/fix-common-page'

export default {
  name: 'InspectionBillsList',
  components: { NewPageOpen, TableDialog, SearchForm, FunctionHeader },
  filters: {
    inspectionStatusName(val) {
      return inspectionStatus.find(item => item.id === val).name
    }
  },
  mixins: [conventionMixin, settingMinix, fixCommonPage],
  data() {
    return {
      api,
      params: {
        radio: 'InspectionBills'
      },
      columns,
      insColumns,
      colors: {
        0: '#9597AE',
        1: '#FC9100',
        2: '#0091FF',
        3: '#9597AE',
        4: '#9597AE'
      },
      searchFormList: formList.searchFormList,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [],
        exportName: 'exportInspectionBills',
        exportParams: {}
      },
      visible: false,
      searchKeyword: true,
      selectData: [],
      reasonFormList: [{
        key: 'reason',
        colSpan: 24,
        tagName: 'el-autocomplete',
        props: { fetchSuggestions: this.querySearch },
        required: false,
        attrs: {
          placeholder: '请输入或选择跳过原因'
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: []
          }
        ]
      }],
      restaurants: '',
      exportSelectionIds: []
    }
  },
  computed: {
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    height() {
      return this.$store.state.app.contentHeight - 235 - 60
    }
  },
  mounted() {
    this.getEquipmentClassList()
    this.getCheckTypeList()
  },
  methods: {
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        this.searchFormList[2].props.options = setTreeData(res)
      }
    },
    async querySearch(qs, cb) {
      const res = await api.getSkipReason()
      if (res) {
        this.restaurants = qs ? res.filter(this.createFilter(qs)).map(item => ({ value: item })) : res.map(item => ({ value: item }))
      }
      cb(this.restaurants)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    async getCheckTypeList() {
      const res = await api.getCheckTypeList()
      if (res) {
        this.searchFormList[0].children[0].options = res.map(item => ({ id: item, name: item }))
      }
    },
    selectionChange(data) {
      this.selectData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    searchTable(data) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('equipmentClassIds')
      this.searchFormatData = this._.cloneDeep(data)
      this.getTableDataParams = {}
      if (hasIndex !== -1) {
        this.getTableDataParams = { equipmentClassIds: data[hasIndex].fieldValue.map(item => this._.last(item)).join('^') }
        this.searchFormatData.splice(hasIndex, 1)
      }
      this.$refs.mTable.tableRefs().clearSelection()
      this.$refs.mTable.setTableData(true)
    },
    async changeIsEnabled(row) {
      const res = await api.modifyInspectionPlan({
        id: row.id,
        isEnabled: row.isEnabled ? 0 : 1
      })
      if (res) {
        this.$refs.mTable.setTableData()
      }
    },
    abolishInspectionBills() {
      if (this.selectData.find(item => item.status !== 1)) {
        return this.$message.info('选中数据中包含状态非待巡检的巡检单')
      }
      this.$confirm('您确定要作废这些巡检单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const body = this.selectData.map(item => ({ id: item.id }))
        const res = await api.abolishInspectionBills(body)
        if (res) {
          this.$message.success('作废成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    passInspectionBills() {
      if (this.selectData.find(item => item.status !== 1)) {
        return this.$message.info('选中数据中包含状态非待巡检的巡检单')
      }
      this.visible = true
    },
    exportInspectionBills() {
      this.$refs.functionHeader.export({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.exportSelectionIds.join('^'),
          operator: 'in'
        }]))
      })
    },
    async submitPassInspectionBills(callback) {
      const { form } = this.$refs.formBuild
      if (!form.reason) {
        callback()
        return this.$message.info('请输入或选择跳过原因')
      }
      const res = await api.passInspectionBills({
        skipReason: form.reason,
        ids: this.selectData.map(item => ({ id: item.id }))
      })
      if (res) {
        this.$message.success('跳过成功')
        this.visible = false
        this.$refs.mTable.setTableData()
      }
      callback(true)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}
.scrap-wrap {
  width: 100%;

  .bottom {
    padding: 10px;
  }
}
::v-deep{
  .e-divider{
    margin: 0px 0 0 0;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    height: 34px;
  }
  .el-date-editor .el-range__close-icon {
    line-height: 26px;
  }
}
</style>
