import columns from './columns'
import { transformColumnsToForm } from '@/utils'
const formList = {
  CallParams: transformColumnsToForm(columns.CallParams),
  DataOrigin: transformColumnsToForm([...columns.DataOrigin, {
    prop: 'userName',
    label: '用户名',
    form: { index: 7, tagName: 'el-input', required: false }
  }, {
    prop: 'password',
    label: '密码',
    form: { index: 8, tagName: 'el-input', required: false }
  }]),
  dataPoint: transformColumnsToForm(columns.dataPointsColumns)
}
export default formList
