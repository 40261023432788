<template>
  <div class="apply-all">
    <div class="apply-bg">
      <div class="apply-container">
        <div class="apply-header">
          <div><img src="@/assets/apply/logo.png" alt=""></div>
          <div style="display:flex;justify-content: space-between;align-items: center;">
            <div>应用中心</div>
            <div style="padding-left:40px;display: flex;align-items: center;">
              <img src="@/assets/apply/touxiang.png" alt="">
              <div style="display:inline-block;padding-left:10px;">{{ userInfo.name }}</div>
            </div>
          </div>
        </div>
        <div style="display:flex;justify-content: space-between;margin-top:10px;">
          <div>
            <div>
              <span style="font-size:24px;">挑选您的应用</span>
              <img src="@/assets/apply/supervisor.png" alt="" style="margin-left:5px;">
            </div>
            <div>
              <span style="font-size:12px;">木白智造应用市场为您提供提高生产管理效率的应用，支持按需配置
                <img src="@/assets/apply/planner.png" alt="" style="margin-left:5px"><br>更多优质应用即将推出</span>
            </div>
            <div class="apply-search">
              <el-input v-model="searchVal" placeholder="请输入您要搜索的应用名称" @keyup.enter.native="search()">
                <i slot="suffix" class="el-input__icon el-icon-search" style="cursor:pointer" @click="search" />
              </el-input>
            </div>
            <p style="font-size:12px;color:#666">
              为您推荐：
              <span style="padding-left:10px;">全场呼叫</span>
              <span style="padding-left:10px;">工单自定义引擎</span>
              <span style="padding-left:10px;">审批流程自定义</span>
              <span style="padding-left:10px;">智能排程插件</span>
            </p>
          </div>
          <div><img src="https://oss.mubyte.cn/static/%E4%BD%8D%E5%9B%BE.png" alt=""></div>
        </div>
      </div>
    </div>
    <div class="apply-container">

      <!-- 应用选项卡 -->
      <div class="apply-tab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            v-for="(itemt, indext) in tableData"
            :key="itemt.tableName"
            :label="itemt.tableName"
            :name="`${indext + 1}`"
          >
            <div v-for="itemp in produceList" :key="itemp.id">
              <div style="margin:10px 0px" class="add-apply-class">{{ itemp.name }}
                <i v-if="permission('Add')" class="el-icon-circle-plus" @click="addApply(itemp.id)" />
              </div>
              <div class="order">
                <div v-for="(item, index) in itemp.marketDetailList" :key="item.id" class="orderlist">
                  <div class="orderd" @click="applyDetails(item.id)">

                    <div v-show="item.picture != null"><img :src="item.picture" alt="" style="width:75px;height:75px;">
                    </div>
                    <div style="margin-left:10px;">
                      <div>{{ item.name }}</div>
                      <div class="apply-text">{{ item.description }}</div>
                    </div>
                  </div>
                  <hr color="#eff2f6">
                  <div style="justify-content:space-between;display: flex;">
                    <div>
                      <span style="color:#607FFF;font-size:18px;">{{ item.cost }}</span>
                      <span style="font-size:12px;color:#c2c2d1;">元起</span>
                    </div>
                    <div
                      :class="{ getActive: currentIndex == item.id }"
                      class="get-default"
                      @click="getConsult(index)"
                      @mouseenter="hoverGet(item.id)"
                      @mouseleave="hoverRemove()"
                    >获取</div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>

        </el-tabs>
      </div>
    </div>
    <!-- 新增弹框 -->
    <MDialog v-model="applyVisible" width="700px" title="新增" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList">
        <div slot="drawing">
          <el-upload
            class="avatar-uploader"
            v-bind="options"
            accept=".png,.jpg"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="applyImageUrl" :src="applyImageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </div>
      </MFormBuilder>
    </MDialog>
  </div>

</template>

<script>
import { getToken } from '@/utils/auth'
import api from '@/api/apply/market'
export default {
  name: 'ApplyMarket',
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      tableData: [{ tableName: '生产' }, { tableName: '物料' }, { tableName: '业务' }, { tableName: '仓库' },
        { tableName: '工模具' }, { tableName: '设备' }, { tableName: '质检' }, { tableName: '看板' }, { tableName: 'mubyte APass' }, { tableName: '其他' }],
      searchVal: '',
      activeName: '1',
      currentIndex: -1,
      applyVisible: false,
      options: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false
      },
      applyImageUrl: '',
      produceList: [],
      marketTypeId: '',
      formData: {},
      formList: [
        {
          label: '应用名称',
          key: 'name',
          tagName: 'el-input',
          required: true
        },
        {
          label: '应用价格',
          key: 'price',
          tagName: 'el-input',
          required: true
        },
        {
          label: '应用描述',
          key: 'description',
          tagName: 'el-input',
          colSpan: 24,
          required: true,
          props: {
            type: 'textarea'
          }
        },
        {
          label: '图片（建议尺寸不小于：72*72PX）',
          key: 'imgUrl',
          tagName: 'div',
          required: true,
          children: [
            {
              customSlots: ['drawing'],
              tagName: 'el-upload'
            }
          ]
        }

      ]

    }
  },
  mounted() {
    this.getApplyList()
  },

  methods: {
    // 获取列表详情
    async getApplyList() {
      const res = await api.getApplyList(parseInt(this.activeName), this.searchVal)
      if (res) {
        this.produceList = res[0].marketTypeList.reverse()
      }
    },
    search() {
      this.getApplyList()
    },
    handleClick(tab, event) {
      this.getApplyList()
    },
    // 新增事件
    addApply(id) {
      this.applyImageUrl = ''
      this.marketTypeId = id
      this.applyVisible = true
    },
    // 提交新增
    submitForm(callback) {
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          await this.getDatas()
          callback(true)
        } else {
          callback()
        }
      })
    },
    async getDatas() {
      const { form } = this.$refs.formBuild
      const ress = await api.addApplyMarket({
        marketTypeId: this.marketTypeId,
        name: form.name,
        cost: form.price,
        description: form.description,
        picture: this.applyImageUrl
      })
      if (ress) {
        this.$message.success('新增成功')
        // this.applyImageUrl = ''
        this.applyVisible = false
        this.getApplyList()
      }
    },
    applyDetails(id) {
      this.$router.push({ path: '/applyDetails', query: { id: id }})
    },
    // 获取
    getConsult() {
      this.$confirm('请联系客户开通，联系方式：021-3412 7798', '咨询', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
      }).catch(() => {

      })
    },
    hoverGet(id) {
      this.currentIndex = id
    },
    hoverRemove() {
      this.currentIndex = null
    },
    // 判断图片大小
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M && new Promise((resolve, reject) => {
        const width = 72
        const height = 72
        const imgsrc = URL.createObjectURL(file)
        const img = new Image()
        img.src = imgsrc
        img.onload = () => {
          if (img.width >= width && img.height >= height) {
            resolve()
          } else {
            this.$message.error('图片尺寸应不小于72*72')
            reject(img)
          }
        }
      })
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.$refs.formBuild.setForm({
          drawing: res.result

        })
        console.log(res)
        this.applyImageUrl = res.result
        console.log('ceshi', this.applyImageUrl)
      } else {
        this.$message.error(res.result)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.apply-all {
  overflow: auto;
  position: absolute;
  width: 100%;
}

.apply-container {
  width: 80%;
  margin: 0 auto;
}

.apply-header {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

}

.apply-bg {
  background: url('https://oss.mubyte.cn/static/bj.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.apply-search {
  width: 360px;
  margin-top: 30px;
  ::v-deep {
      .el-input__inner {
        border-radius: 30px;
      }
       .el-icon-search {
          &::after {
            width: 1px;
            height: 50%;
            left: -2.5px;
            position: absolute;
            top: 25%;
            background-color: rgb(233, 233, 233);
          }
        }
  }
}

.apply-tab {
  ::v-deep .el-tabs {
    .el-tabs__header {
      .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
          background: linear-gradient(#f5f8ff 0%, #edf1ff 99%);

          .el-tabs__nav {
            .el-tabs__active-bar {
              display: none;
            }

            .el-tabs__item {
              width: 100px;
              padding: 0;
              text-align: center;
            }

            .el-tabs__item.is-active {
              background-color: #eef0ff;
              box-shadow: 0 0 10px #bcc0de;
            }
          }
        }
      }
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }

  }
}

.order {
  display: flex;
  flex-wrap: wrap;
}

.orderlist {
  border: 1px solid #eee;
  width: 320px;
  // height: 135px;
  border-radius: 5px;
  padding: 10px;
  margin: 0px 0px 10px 16px
}

.orderd {
  display: flex;
  cursor: pointer;
  height: 75px;
}

.get-default {
  background-color: #eff2ff;
  width: 80px;
  height: 23px;
  line-height: 23px;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
  color: #607fff;
}

.getActive {
  background-color: #607fff;
  color: #fff;
  cursor: pointer;
}

.apply-text {
  font-size: 12px;
  color: #c2c2d1;
  margin-top: 10px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

::v-deep {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.add-apply-class {
  i {
    color: rgb(96, 127, 255);
    cursor: pointer;
    font-size: 20px;
    position: relative;
    top: 1px;
    left: 5px;
  }
}
</style>
