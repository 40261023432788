<template>
  <div />
</template>

<script>
import { getToken, setToken } from '@/utils/auth'
import Hapi from '@/api/home'
export default {
  name: 'Console',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const token = getToken()
      const queryToken = to.query ? to.query.tenant : ''
      const code = to.query ? to.query.code : ''
      if (queryToken && queryToken !== token) {
        vm.getUserInfos(queryToken, to.params.memberCode, code)
      } else {
        vm.$router.push({ name: 'layout' })
      }
    })
  },
  methods: {
    // 根据token获取用户信息
    async getUserInfos(token, memberCode, login) {
      const res = await Hapi.getInfoByToken({
        token: token,
        memberCode: memberCode || 'huawei',
        login: login })
      if (res) {
        const code = res.memberCode === 'mubyte' ? 'mubai' : res.memberCode
        localStorage.setItem('memberCode', code)
        setToken(res.token)
        await this.$store.dispatch('user/getInfo')
        this.$router.push({ name: 'layout' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
