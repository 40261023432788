export default [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'warehouseName',
    label: '仓库名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'recordType',
    label: '流水方向',
    sortable: false,
    hiddenSearch: true,
    slotName: 'recordType'
  },
  {
    prop: 'typeName',
    label: '出入库类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    minWidth: 120
  },
  {
    prop: 'billNumber',
    label: '出/入库单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'sourceBillNumber',
    label: '源单据号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartCode',
    label: '备件编码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'sparePartName',
    label: '备件名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'sparePartSpecification',
    label: '规格型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    minWidth: 100
  },
  {
    prop: 'sparePartClass',
    label: '备件类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '出/入库数量',
    sortable: false,
    hiddenSearch: true,
    minWidth: 140
  },
  {
    prop: 'operator',
    label: '制单人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operateTime',
    label: '制单时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
