<template>
  <div class="scrap-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="PointCheckItem">点巡检项</el-radio-button>
      <el-radio-button label="PointCheckType">点检类别</el-radio-button>
      <el-radio-button label="Inspection">巡检配置</el-radio-button>
    </el-radio-group>
    <div class="top">
      <SearchForm
        v-if="params.radio !== 'PointCheckItem'"
        ref="searchForm"
        :form-list-extend="searchFormList[params.radio]"
        @search="searchTable"
      />
      <div v-else style="height: 57px;border-bottom: 1px solid #DDE3EB" />
    </div>
    <div class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        :search-title="functionHeader.searchTitle"
        :tags="functionHeader.tags"
        :radio="params.radio"
        :columns-setting="params.radio === 'PointCheckItem'"
        :columns-key="columnsKey"
        :export-name="functionHeader.exportName"
        :import-name="functionHeader.importName"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable ref="mTable" :columns="columns[params.radio]" :height="height" :columns-key="columnsKey" :columns-setting="params.radio === 'PointCheckItem'" :set-data-method="getTableData">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <el-button slot="pointCheckItem" slot-scope="{ row }" type="text" @click="viewCheckItem(row)">查看</el-button>
        <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.needUpload ? '是' : '否' }}</div>
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
          <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
          <DelPopover v-if="permission(params.radio + 'Del')" @onOk="(callback) => deleteRow(row, callback)" />
        </div>
      </MTable>

      <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]">
          <div slot="pointCheckItem">
            <el-button type="primary" style="margin-bottom: 10px" @click="pushCheckItem">添加</el-button>
            <MTable ref="pointCheckTable" :height="$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 192" :columns="columns.PointCheckItemAdd(params.radio === 'PointCheckType' ? 'point' : 'inspection')" :columns-setting="false" :show-page="false" :data="selectCheckCheckItem">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
                <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
                <div>{{ row.needUpload ? '是' : '否' }}</div>
              </div>
              <div slot="action" slot-scope="{ row, $index }">
                <el-button type="text" @click="uploadPic(row, $index)">操作图</el-button>
                <el-divider direction="vertical" />
                <el-button type="text" @click="delRow(row, $index)">删除</el-button>
              </div>
            </MTable>
          </div>
        </MFormBuilder>
      </MDialog>
      <MDialog v-model="dialogAddVisible" title="新增检查项" @onOk="submitDialogAddForm">
        <MFormBuilder ref="formBuildAdd" :form-list="formList.PointCheckItem" />
      </MDialog>
    </div>
    <MDialog v-model="selectCheckItemVisible" title="添加检查项" @onOk="submitSelectCheckItem">
      <FunctionHeader
        v-model="functionHeaderS.searchVal"
        :search-title="'请输入检查项'"
        :tags="functionHeaderS.tags"
        :radio="params.radio"
        :columns-setting="false"
        @search="$refs.pointCheckTableSelect.setTableData(true)"
      />
      <MTable ref="pointCheckTableSelect" :height="$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 56" :columns="columns.SelectCheckItem" :columns-setting="true" :set-data-method="getCheckItemTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.needUpload ? '是' : '否' }}</div>
        </div>
      </MTable>
    </MDialog>
    <TableDialog :title="params.radio === 'PointCheckType' ? '查看点检项' : '查看巡检项'" :show.sync="checkItemVisible" :columns="columns.PointCheckItemView(params.radio === 'PointCheckType' ? 'point' : 'inspection')" :table-data="checkItemList" />

    <MDialog v-model="uploadPicVisible" title="上传操作图" @onOk="submitUploadPic">
      <upload-pic ref="uploadPic" :upload-file-list="uploadFileList" />
    </MDialog>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import settingMinix from './setting.minix'
import DelPopover from '@/components/DelPopover/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader/index'
import api from '@/api/equipment/project-setting'
import columns, { getFunctionList } from './columns'
import { formList, searchFormList } from './form-list'
import equipmentClassApi from '@/api/information/equipment-class'
import TableDialog from '@/components/TableDialog/TableDialog'
import { setTreeData } from '@/utils'
import UploadPic from '@/views/equipment/project-setting/upload-pic'

export default {
  name: 'ProjectSetting',
  components: { UploadPic, TableDialog, DelPopover, SearchForm, FunctionHeader },
  mixins: [conventionMixin, settingMinix],
  data() {
    return {
      api,
      params: {
        radio: 'PointCheckItem'
      },
      columns,
      searchFormList,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        searchTitle: '请输入检查项',
        tags: [
          {

            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'exportPointCheckItem',
        importName: 'pointCheckItem'
      },
      functionHeaderS: {
        searchVal: '',
        tags: [
          {

            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.dialogAdd
            }
          }
        ]
      },
      formList,
      formData: {},
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制'
      },
      currentType: 'add',
      visible: false,
      formOptions: {
        equipmentClass: []
      },
      getTableDataParams: {},
      searchKeyword: true,
      settingGeyKey: 'PointCheckItem',
      dialogAddKey: 'PointCheckItem',
      continuousAdd: false
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    }
  },
  beforeMount() {
    getFunctionList({
      equipmentClassChange: this.equipmentClassChange,
      checkEquipmentClassChange: this.checkEquipmentClassChange
    })
  },
  // activated() {
  //   console.log('权限=》', this.permission('PointCheckItemList'))
  // },
  activated() {
    //  console.log('权限=》', this.permission('PointCheckItemList'))
    this.getEquipmentClassList()
  },
  methods: {
    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        const equipmentClassList = setTreeData(res)
        this.searchFormList.PointCheckType[0].props.options = equipmentClassList
        this.searchFormList.Inspection[0].props.options = equipmentClassList
        this.formList.PointCheckType[1].props.options = equipmentClassList
        this.formList.Inspection[0].props.options = equipmentClassList
        this.formOptions.equipmentClass = res
      }
    },
    radioChange(label) {
      this.functionHeader.exportName = label === 'PointCheckItem' ? 'exportPointCheckItem' : ''
      this.functionHeader.importName = label === 'PointCheckItem' ? 'pointCheckItem' : ''
      this.functionHeader.searchTitle = label === 'PointCheckItem' ? '请输入检查项' : label === 'PointCheckType' ? '请输入类别名称' : '请输入设备类'
      this.searchFieldName = label === 'PointCheckItem' ? 'name' : 'spotCheckType'
      this.searchFormatData = []
      this.getTableDataParams = {}
      if (this.$refs.searchForm) this.$refs.searchForm.reset()
      else this.$refs.mTable.setTableData(true)
    },
    async checkEquipmentClassChange(val) {
      const { form } = this.$refs.formBuild
      if (this.params.radio === 'PointCheckType' && (!form.equipmentClassId || !form.equipmentClassId.length || !form.spotCheckType)) return false
      const res = await this.api[`get${this.params.radio}List`]({
        equipmentClassIds: this._.last(val),
        spotCheckType: form.spotCheckType
      })
      if (res) {
        this.selectCheckCheckItem = res[0] ? res[0].checkItemList || res[0].maintenanceItemList || [] : []
      }
    },
    searchTable(data) {
      if (data.length) {
        this.getTableDataParams = {
          equipmentClassIds: data[0].fieldValue.map(item => this._.last(item)).join('^')
        }
      } else {
        this.getTableDataParams = {}
      }
      this.$refs.mTable.setTableData(true)
    },
    add() {
      this.formData = {}
      this.selectCheckCheckItem = []
      this.currentType = 'add'
      this.continuousAdd = this.params.radio === 'PointCheckItem'
      this.visible = true
    },
    modifyOrCopy(row, command) {
      this.formData = row
      this.continuousAdd = false
      if (this.params.radio !== 'PointCheckItem') {
        this.selectCheckCheckItem = this._.cloneDeep(row.checkItemList)
      }
      this.currentType = command
      this.visible = true
    },
    createSetData(raw) {
      let obj = {}
      switch (this.params.radio) {
        case 'PointCheckItem':
          obj = {
            name: raw.name,
            standard: raw.standard,
            needUpload: raw.needUpload || 0
          }
          break
        case 'PointCheckType':
          obj = {
            equipmentClassId: Array.isArray(raw.equipmentClassId) ? this._.last(raw.equipmentClassId) : raw.equipmentClassId,
            spotCheckType: raw.spotCheckType,
            checkItemList: this.selectCheckCheckItem.map(item => ({
              id: item.id,
              name: item.name,
              standard: item.standard,
              needUpload: item.needUpload || 0,
              sketchMap: item.sketchMap
            }))
          }
          break
        case 'Inspection':
          obj = {
            equipmentClassId: Array.isArray(raw.equipmentClassId) ? this._.last(raw.equipmentClassId) : raw.equipmentClassId,
            checkItemList: this.selectCheckCheckItem.map(item => ({
              id: item.id,
              name: item.name,
              standard: item.standard,
              needUpload: item.needUpload || 0,
              sketchMap: item.sketchMap
            }))
          }
          break
        default: break
      }
      return obj
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;
  .bottom {
    padding: 10px;
  }
  .m-radio-group {
    margin-left: 10px;
  }
}
.role-tags {
  margin: 5px;
  border-color: #DDE3EB;
  color: #9597AE;
}
</style>
