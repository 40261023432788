<template>
  <div class="add-apply">
    <MDialog
      v-model="modalShow"
      width="70%"
      :title="messages[currentType]"
      confirm-button-text="保 存"
      :save-button="saveButton"
      @onSave="commitForm"
      @onOk="submitForm"
    >
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList">
        <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2">
          <el-button type="primary" size="small">上传附件</el-button>
        </el-upload>

      </MFormBuilder>
      <el-button type="primary" style="margin-bottom: 15px" @click="openMaterials()">添加备件</el-button>
      <MTable ref="mTable" :columns="accountColumns" :height="height" :data="applyData" :show-page="false">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="quantity" slot-scope="{ row }">
          <el-input-number v-model="row.quantity" :min="1" controls-position="right" :step="1" style="width: 100%" />
        </div>
        <div slot="drawing" slot-scope="{ row }">
          <el-image v-if="row.drawing" :src="row.drawing" class="drawing-img" :preview-src-list="fixImg(row.drawing)" />
        </div>
        <div slot="action" slot-scope="{ $index }">
          <el-button type="text" @click="deleteRow($index)">删除</el-button>
        </div>
      </MTable>
    </MDialog>
    <select-partAccount
      :visible.sync="showMaterials"
      :select-materials="selectMaterials"
      @submitForm="submitMaterials"
    />
  </div>
</template>

<script>
import SelectPartAccount from '@/components/SelectPartAccount/SelectPartAccount'
import api from '@/api/equipment/part-apply'
import { accountColumns } from '@/views/equipment/part-apply/columns'
import conventionMixin from '@/mixin/conventional-page'
import dayjs from 'dayjs'
import { getFunctionList, formList } from '@/views/equipment/part-apply/form-list'
import { getToken } from '@/utils/auth'
import rapi from '@/api/equipment/equipment-fault'
import applyequipment from '@/api/equipment'
import saveAs from 'file-saver'

export default {
  name: 'CURDDialog',
  components: { SelectPartAccount },
  mixins: [conventionMixin],

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    visible: {
      required: true,
      type: Boolean,
      default: false
    },
    purchased: {
      type: Array,
      default: () => ([])
    }

  },
  data() {
    return {
      api,
      saveButton: true,
      accountColumns,
      applyData: [],
      showMaterials: false,
      modalShow: false,
      currentType: 'add',
      materialsList: [],
      formList,
      formData: {},
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile,
        onPreview: this.handlePreview
      },
      attachments: [],
      selectMaterials: [],
      repaireList: [],
      equipmentList: [],
      valId: 1
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 55
    }

  },
  watch: {
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', val)
      }
    },

    visible(val) {
      if (val) {
        this.currentType = 'add'
        this.accountColumns = accountColumns
        this.attachments = []
        if (Object.keys(this.data).length) {
          // 处理备件表格数据
          const dataNew = []
          this.data.sparePartProcurementDetails.map(item => {
            const obj = {
              code: item.sparePartCode,
              name: item.sparePartName,
              specifications: item.specifications,
              drawing: item.drawing,
              mainUnitName: item.mainUnitName,
              quantity: item.quantity,
              id: item.sparePartId
            }
            dataNew.push(obj)
          })
          if (this.data.status == '0') {
            this.data.status = '待提交'
          } else if (this.data.status == '1') {
            this.data.status = '已提交'
          } else {
            this.data.status = '已采购'
          }
          this.formData = this.data
          if (this.formData.attachments) {
            this.attachments = JSON.parse(this.formData.attachments)
          } else {
            this.attachments = []
          }
          this.applyData = dataNew
          this.accountColumns = accountColumns
          this.currentType = 'modify'
        } else {
          this.applyData = this._.cloneDeep(this.purchased)
          this.formData = {}
        }
        this.modalShow = true
        getFunctionList({
          equipmentRepaireChange: this.equipmentRepaireChange
        })
      }
    }
  },
  beforeMount() {
    getFunctionList({
      equipmentRepaireChange: this.equipmentRepaireChange
    })
  },
  mounted() {
    this.getRepaireList()
    this.getEquipmentList()
  },
  methods: {
    openMaterials() {
      // 记录上一次勾选的记录
      this.selectMaterials = this.materialsList
      this.showMaterials = true
    },
    async submitMaterials(data) {
      console.log(data)
      data.forEach(item => {
        this.applyData.push(item)
        this.materialsList.push(item)
      })
      // 备件数组去重
      const object = {}
      const open = this.applyData
      this.applyData = open.reduce((cur, next) => {
        object[next.id] ? '' : object[next.id] = true && cur.push(next)
        return cur
      }, [])
      this.showMaterials = false
    },
    fixImg(img) {
      return [img]
    },
    deleteRow(index) {
      this.applyData.splice(index, 1)
    },
    // 获取关联报修单
    async getRepaireList() {
      const res = await rapi.getEquipmentFaultList()
      if (res) {
        this.repaireList = res
        this.$set(this.formList[3].children[0], 'options', res)
      }
    },
    async equipmentRepaireChange(val) {
      this.valId = val
      const res = await rapi.getEquipmentFaultDetails({ id: this.valId })
      if (res) {
        const filename = res.faultPicture.substring(res.faultPicture.lastIndexOf('/') + 1)
        const aa = []
        if (filename != '') {
          aa.push({
            id: '',
            name: filename,
            url: res.faultPicture
          })
          this.attachments = aa
        } else {
          this.attachments = []
        }
        this.$refs.formBuild.setForm({
          reportTime: res.createTime,
          remark: res.faultDesc,
          equipmentId: res.equipmentId
        })
      }
    },
    // 获取相关设备
    getEquipmentList() {
      applyequipment.getEquipmentListNoPage().then(res => {
        this.$set(this.formList[4].children[0], 'options', res)
        this.equipmentList = res
      })
    },
    // 保存并提交
    async commitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          let res
          if (this.applyData.length != 0) {
            if (this.currentType === 'add') {
              res = await api.saveAndCommitSparePartProcurement(this.createSetData(form))
            } else {
              res = await api.updateCommiSparePartProcurement(this.createResetData(form))
            }
            if (res) {
              this.$emit('submit')
              this.$message.success(this.getSuccessMessage())
              this.materialsList = []
              this.modalShow = false
            }
            callback(false)
          } else {
            callback(false)
            this.$message.info('采购明细不能为空')
          }
        } else {
          callback(false)
        }
      })
    },
    // 保存
    async submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          let res
          if (this.applyData.length != 0) {
            if (this.currentType === 'add') {
              res = await api.addSparePartProcurement(this.createSetData(form))
            } else {
              res = await api.modifySparePartProcurement(this.createResetData(form))
            }
            if (res) {
              this.$emit('submit')
              this.$message.success(this.getSuccessMessage())
              this.materialsList = []
              this.modalShow = false
            }
            callback(false)
          } else {
            callback(false)
            this.$message.info('采购明细不能为空')
          }
        } else {
          callback(false)
        }
      })
    },
    createSetData(raw) {
      const repaireList = this.repaireList.find(item => item.id === raw.faultId)
      const equipmentList = this.equipmentList.find(item => item.id === raw.equipmentId)
      return {
        userId: this.userInfo.id,
        userName: this.userInfo.userName,
        applicationNumber: raw.applicationNumber,
        emergencyLevel: raw.emergencyLevel,
        faultId: repaireList && repaireList.id,
        faultNumber: repaireList && repaireList.faultNumber,
        equipmentId: equipmentList && equipmentList.id,
        equipmentName: equipmentList && equipmentList.name,
        reportTime: dayjs(raw.reportTime).format('YYYY-MM-DD'),
        status: 0,
        attachments: JSON.stringify(this.attachments),
        remark: raw.remark,
        sparePartProcurementDetails: this.applyData.map(item => ({
          sparePartId: item.id,
          quantity: item.quantity
        }))
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },

    // 上传附件
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    }
  }
}
</script>

<style scoped lang="scss">
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.add-apply{
  ::v-deep {
   .el-upload{
   border:none !important;
   }
  }

}
</style>
