<template>
  <div class="procedure-detail-wrappers">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder v-if="!isView" ref="baseForm" :form-list="formList" :form-data="baseFormData" label-position="right" />
          <el-row v-else class="view-list" :gutter="24">
            <el-col v-for="item in viewColums" :key="item.id" :span="6">
              <div class="view-list__label">{{ item.label }}</div>
              <div class="view-list__value">{{ getValue(item) || '-' }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <ConfigurationParameter :is-view="isView" :list.sync="parametersList" />
    </div>
    <div v-if="!isView" class="order-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import formList from '../form-list'
import { Templates } from '../columns'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import keApi from '@/api/information/unit'
import equipmentClassApi from '@/api/information/equipment-class'
import ConfigurationParameter from '../components/configuration-parameter'
import api from '@/api/xiewei/facility/configuration'
import { setTreeData } from '@/utils'

export default {
  name: 'AddConfigurationParameter',
  components: { ConfigurationParameter },
  data() {
    return {
      baseFormData: {},
      formList: formList.Templates,
      params: {},
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '配置模板详情',
        addO: '新增配置模板',
        modifyO: '修改配置模板',
        copyO: '复制配置模板'
      },
      buttonLoading: false,
      parametersList: [],
      equipmentClassList: []
    }
  },

  computed: {
    isView() {
      return this.$route.query.type === 'view'
    },

    viewColums() {
      return Templates.filter((item, index) => index > 1 && item.slotName !== 'action')
    },

    getValue() {
      return row => {
        return this.baseFormData[row.prop]
      }
    }
  },

  mounted() {
    this.params = this.$route.query
    this.getEquipmentClassList()
    this.getUser()
    if (this.params.type !== 'add') {
      this.getTemplatesDetail()
    }
  },

  methods: {
    returns() {
      this.$router.push({ name: 'configurationParameterList' })
    },

    async getTemplatesDetail() {
      const res = await api.getTemplatesDetail({ id: this.$route.query.id })
      if (res) {
        this.baseFormData = {
          id: res.id,
          remark: res.remark,
          customerIds: res.customerIds ? res.customerIds.split('^').map(item => +item) : res.customerIds,
          equipmentClassIds: res.equipmentClassIdList ? JSON.parse(res.equipmentClassIdList) : [],
          templateName: this.params.type === 'copy' ? '' : res.templateName
        }
        this.parametersList = (res.list || []).map(item => {
          return {
            ...item
          }
        })
      }
    },

    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        this.equipmentClassList = res
        const equipmentClassList = setTreeData(res)
        this.formList[1].props.options = equipmentClassList
      }
    },

    // 获取客户
    async getUser() {
      const res = await keApi.getCustomerList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.formList[2].children[0].options = res
      }
    },

    submitForm() {
      const that = this
      this.buttonLoading = true
      const isNull = this.parametersList.find(item => !item.configurationParameter)

      if (isNull) {
        this.buttonLoading = false
        return this.$message.error('参数名称不能为空')
      }
      const { form } = this.$refs.baseForm
      const equipmentClassIds = (form.equipmentClassIds || []).map(item => item[item.length - 1])
      const equipmentClassNames = this.equipmentClassList.filter(item => equipmentClassIds.includes(item.id))
      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          that.saveData(
            Object.assign({
              ...form,
              customerIds: (form.customerIds || []).join('^'),
              equipmentClassIds: equipmentClassIds.join('^'),
              equipmentClassName: (equipmentClassNames || []).map(item => item.name).join('、'),
              list: this.parametersList,
              equipmentClassIdList: form.equipmentClassIds && form.equipmentClassIds.length > 0 ? JSON.stringify(form.equipmentClassIds) : ''
            })
          )
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },

    async saveData(form) {
      const res = await api[`${this.params.type === 'copy' ? 'add' : this.params.type}Templates`](form)
      if (res) {
        this.$router.push({ name: 'configurationParameterList' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style .li-label-style {
    color: #607fff;
  }
  .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style.active-li {
    background-color: rgba(96, 127, 255, 0.1);
    color: #607fff;
  }
}
.procedure-detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }

      ::v-deep.el-autocomplete {
        width: 100%;
      }
    }
  }

  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
      .e-btn {
        margin-bottom: 10px;
      }
    }
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.technical-table-input {
  width: 40%;
}
</style>
