/** write by luomingzhi */
import { dataType, status } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
// 参数台账
const CallParams = [
  {
    prop: 'index',
    label: '序号',
    width: 80,
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'attribute',
    label: '参数属性',
    hiddenSearch: true,
    sortable: false,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '参数名称',
    hiddenSearch: true,
    sortable: false,
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'type',
    label: '数据类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'dataType',
    form: { tagName: 'el-select', options: dataType }
  },
  {
    prop: 'unit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    form: { tagName: 'el-input', required: false }
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 98
  }
]
// 数据源
const DataOrigin = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'adaptor',
    label: '适配器',
    hiddenSearch: true,
    sortable: false,
    form: { index: 1, tagName: 'el-select', options: [], on: { change: (val) => { functionList.adaptorChange(val) } }}
  },
  {
    prop: 'protocolFormat',
    label: '协议格式',
    hiddenSearch: true,
    sortable: false,
    form: { index: 4, tagName: 'el-select', options: [], props: { placeholder: '请先选择适配器' }}
  },
  {
    prop: 'name',
    label: '名称',
    hiddenSearch: true,
    sortable: false,
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'host',
    label: '主机',
    hiddenSearch: true,
    sortable: false,
    form: { index: 2, tagName: 'el-input', required: false }
  },
  {
    prop: 'port',
    label: '端口',
    hiddenSearch: true,
    sortable: false,
    form: { index: 3, tagName: 'el-input', required: false }
  },
  {
    prop: 'cycle',
    label: '周期',
    hiddenSearch: true,
    sortable: false,
    form: { index: 5, tagName: 'el-input' }
  },
  {
    prop: 'dataPoints',
    label: '数据点',
    hiddenSearch: true,
    sortable: false,
    slotName: 'dataPoints'
  },
  {
    prop: 'isEnabled',
    label: '数据源状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    form: {
      index: 9,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 205
  }
]

const dataPointsColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 50,
    hiddenSearch: true
  },
  {
    prop: 'dataSourceName',
    label: '数据源',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      props: { disabled: true }
    }
  },
  {
    prop: 'location',
    label: '地址',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input'
    }
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

const viewDataPointsColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 50,
    hiddenSearch: true
  },
  {
    prop: 'dataSourceName',
    label: '数据源',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      props: { disabled: true }
    }
  },
  {
    prop: 'location',
    label: '地址',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input'
    }
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  }
]
export default {
  CallParams,
  DataOrigin,
  dataPointsColumns,
  viewDataPointsColumns
}
